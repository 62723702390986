export const GET_NUMBER_OF_GROUPS_REQUEST = "GET_NUMBER_OF_GROUPS_REQUEST";
export const GET_NUMBER_OF_GROUPS_SUCCESS = "GET_NUMBER_OF_GROUPS_SUCCESS";
export const GET_NUMBER_OF_GROUPS_FAIL = "GET_NUMBER_OF_GROUPS_FAIL";

export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";
export const CONFIRM_CREATE_GROUP = "CONFIRM_CREATE_GROUP";

export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAIL = "EDIT_GROUP_FAIL";
export const CONFIRM_EDIT_GROUP = "CONFIRM_EDIT_GROUP";

export const REMOVE_GROUP_REQUEST = "REMOVE_GROUP_REQUEST";
export const REMOVE_GROUP_SUCCESS = "REMOVE_GROUP_SUCCESS";
export const REMOVE_GROUP_FAIL = "REMOVE_GROUP_FAIL";
