import styled from "styled-components";
import { Button } from "@material-ui/core";


export const ContainerModalImport = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    min-width: 768px;
    max-height: 75vh;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
    }
`;

export const ActionButton = styled(Button)`
    span {
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 20px;
        letter-spacing: 0.1%;
    }
    width: calc(180px - 3rem);
    width: 180px;
    height: 40px;
    border-radius: 48px;
    padding: 0 1.5rem;
`;

export const ManualButton = styled.div`
    font-size: 0.8rem;
    line-height: 20px;
    letter-spacing: 0.1%;
    padding-left: 1rem;
    font-weight: 900;
    color: var(--primary-one);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
`;

export const Subtitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.25rem;
    line-height: 0.5rem;
`;

export const LoadingPresentation = styled.div`
    width: 350px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
`;

export const WrapperInfo = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0.5rem;
    width: calc(100% - 1rem);
    justify-content: space-between;
    div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 100%;
    }
    svg {
        color: var(--secondary-four);
        font-size: 1.15rem;
    }
`;

export const WrapperLinear = styled.div`
    width: calc(100% - 1rem);
    padding: 0.5rem;
    transform: ${p => (p.scale ? `scale(${p.scale}%)` : 'scale(100%)')};
    display: grid;
    align-items: center;
    /* gap: 0.5rem; */
    grid-template-columns: 4fr 1fr;
    justify-items: end;
`;

export const Linear = styled.div`
    width: 100%;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 4px;
    transition: all 0.2s ease;
    position: relative;

    :before {
        transition: all 0.2s ease;
        position: absolute;
        content: '';
        top: 0;
        border-radius: 4px;
        left: 0;
        height: 100%;
        width: 100%;
        /* width: ${p => `${p.value}%`}; */
        background-color: ${p => p.color};
    }
`;
