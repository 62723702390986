import React from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import { Skeleton } from "@material-ui/lab";

const AnalyticsGroupsSkt = () => {
	return (
		<Wrapper width="100%">
			<Container>
				<Skeleton variant="rect" animation="wave" height={60}/>
				<Skeleton1>
					<Skeleton variant="rect" animation="wave" height={500} />
					<Skeleton variant="rect" animation="wave" height={500} />
				</Skeleton1>
			</Container>
		</Wrapper>
	);
};

export default AnalyticsGroupsSkt;

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	flex-direction: column;
	grid-gap: 2rem;
	padding: 2rem;
`;
const Skeleton1 = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	flex-direction: column;
	grid-gap: 2rem;
`;
