import React from 'react';

// Components
import DefaultStateFilter from '../../../../components/filters/DefaultStateFilter';

const FilterResource = props => {

    // eslint-disable-next-line no-unused-vars
    const { filter, handleFilter } = props;

    const resourcesTypes = [
        { id: 2, name: 'Audiovisuales', value: 'audiovisual' },
        { id: 3, name: 'De lectura', value: 'reading' },
        { id: 4, name: 'De evaluación', value: 'evaluation' },
    ];

    const handleCurrentState = newValue => {
        newValue === "Audiovisuales" ?
        handleFilter({name: 'Audiovisuales', value: 'audiovisual'}) :
        newValue === "De lectura" ?
        handleFilter({name: 'De lectura', value: 'reading'}) :
        handleFilter({name: 'De evaluación', value: 'evaluation'});
    };

    return (
        <DefaultStateFilter
            currentState={filter}
            handleCurrentState={handleCurrentState}
            estados={resourcesTypes}
        />
    )
};

export default FilterResource;
