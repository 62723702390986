import React, { useState } from "react";
import styled from "styled-components";

import { Form, Formik } from "formik";
import DefaultFieldWithText from "../../shared/common/DefaultFieldWithText";
import { Button } from "@material-ui/core";
import NewUnitContent from "./NewUnitContent";
import UnitsTable from "./UnitsTable";
import { useDispatch } from "react-redux";
import { myContentActions } from "../../redux/actions";

const headCells = [
	{ id: "nombre", label: "NOMBRE" },
	{ id: "tipo", label: "TIPO" },
	{ id: "link_material", label: "LINK MATERIAL" },
];

const NewUnitForm = ({ id, handleClose }) => {
	const dispatch = useDispatch();

	const [contents, setContents] = useState([]);
	const initialValues = {
		name: "",
	};

	function addContent(value) {
		setContents((prevState) => [...prevState, value]);
	}

	function deleteContent(id) {
		setContents((prevState) => [prevState.filter((i) => i.id !== id)]);
	}

	function handleSubmit(values) {
		dispatch(myContentActions.createUnitRequest({ id, values: { ...values, contents } }));
		handleClose();
	}

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{() => (
				<Container>
					<FormWrapper>
						<DefaultFieldWithText
							name="name"
							type="text"
							title="Nombre unidad"
							placeholder="Nombre de la unidad"
						/>
						<NewUnitContent addContent={addContent} />
						<UnitsTable headCells={headCells} rows={contents} deleteContent={deleteContent} />
					</FormWrapper>
					<FooterWrapper>
						<Button variant="contained" color="primary" type="submit">
							Crear unidad
						</Button>
					</FooterWrapper>
				</Container>
			)}
		</Formik>
	);
};

export default NewUnitForm;

const Container = styled(Form)`
	display: flex;
	flex-direction: column;
	width: 1000px;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	padding: 1.5rem 2rem;
`;

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding: 1rem 1.5rem;
	border-top: 1px solid #dadada;
`;
