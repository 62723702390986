import * as types from "../types/licenses.types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getLicenses } from "../api/licenses.api";
import { licensesActions } from "../actions";

function* getLicensesRequest(action) {
	const res = yield call(getLicenses, action.payload);
	if (res.status) {
		yield put(licensesActions.getLicensesSuccess(res.data));
	} else {
		yield put(licensesActions.getLicensesFail(res.data));
	}
}

function* licensesWatcher() {
	yield takeEvery(types.GET_LICENSES_REQUEST, getLicensesRequest);
}

export { licensesWatcher };
