import { call, put, takeEvery } from "redux-saga/effects";
import { GET_COUNTRIES_REQUEST, GET_THEMATICS_REQUEST } from "../types";
import { getCountries, getThematics } from "../api/utils";
import { utilsActions } from "../actions";

function* getCountriesRequest() {
	try {
		const countries = yield call(getCountries, null);
		yield put(utilsActions.getCountriesSuccess(countries.data));
	} catch (error) {
		yield put(utilsActions.getCountriesFail(error));
	}
}

function* getThematicsRequest() {
	try {
		const thematics = yield call(getThematics, null);
		yield put(utilsActions.getThematicsSuccess(thematics.data.response_data));
	} catch (error) {
		yield put(utilsActions.getThematicsFail(error));
	}
}

function* utilsWatcher() {
	yield takeEvery(GET_COUNTRIES_REQUEST, getCountriesRequest);
	yield takeEvery(GET_THEMATICS_REQUEST, getThematicsRequest);
}

export { utilsWatcher };
