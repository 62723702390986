import React from 'react';
import styled from 'styled-components';
import Text from '../../../../../../shared/common/Text';
import AnswersListMultipleOption from './AnswersListMultipleOption';

const MultipleAnswer = props => {
    const { formik, index } = props;

    return (
        <ContainerAnswer>
            <Title>
                <Text textalign={'left'} fontWeight={500} fontcolor={'#616161'} lineheight={'1.3rem'}>
                    Ingresa las respuestas posibles para la pregunta.{' '}
                </Text>
                <Text textalign={'left'} fontWeight={700} fontcolor={'#616161'} lineheight={'1.3rem'}>
                    {' '}
                    No olvides seleccionar las respuestas correctas, al menos 2.
                </Text>
            </Title>
            <AnswersListMultipleOption formik={formik} index={index} />
        </ContainerAnswer>
    );
};

export default MultipleAnswer;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

const ContainerAnswer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;
