import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import Layout from "../shared/layout/Layout";
import Header from "../shared/layout/header/Header";
import { Button } from "@material-ui/core";
import { DeleteOutlineRounded } from "@material-ui/icons";
import AboutContent from "../components/custom-content/AboutContent";
import Text from "../shared/common/Text";
import GridUnits from "../components/custom-content/GridUnits";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { myContentActions } from "../redux/actions";

const CustomContent = () => {
	let { id } = useParams();
	let history = useHistory();

	const dispatch = useDispatch();
	const { myContent } = useSelector((state) => state.myContent);

	const [course, setCourse] = useState(null);

	useEffect(() => {
		setCourse(myContent.find((i) => i.id === id));
	}, [id, myContent]);

	function deleteCourse() {
		dispatch(myContentActions.deleteContentRequest(id));
		history.push("/mis-cursos");
	}

	return (
		<Layout
			header={
				<Header route={!course ? <Skeleton animation="wave" width={200} /> : course.name}>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<DeleteOutlineRounded />}
						onClick={deleteCourse}
					>
						Eliminar
					</Button>
				</Header>
			}
		>
			{!myContent || !course ? (
				<></>
			) : (
				<Container>
					<AboutContent course={course} />
					<Text fontSize="1.5rem" fontWeight="700" color="primary" component="h1">
						Unidades
					</Text>
					<GridUnits id={course.id} units={course.units} />
				</Container>
			)}
		</Layout>
	);
};

export default CustomContent;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;
