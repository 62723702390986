import React, { useEffect } from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import Text from "../../shared/common/Text";
import AdminFields from "./AdminFields";
import AdminPhoto from "./AdminPhoto";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../redux/actions";
import Loading from "../../shared/common/Loading";

const Admin = () => {
	// REDUX
	const dispatch = useDispatch();

	const ondemand_id = useSelector((state) => state.user.user.id);

	const admin = useSelector((state) => state.admin.admin);
	const loadingAdmin = useSelector((state) => state.admin.loadingAdmin);
	const errorAdmin = useSelector((state) => state.admin.errorAdmin);

	// EFFECTS
	useEffect(() => {
		if (admin === null) {
			dispatch(adminActions.getAdminRequest({ ondemand_id }));
		}
	}, []); // eslint-disable-line

	// RETURN
	if (admin === null || loadingAdmin) {
		return <Loading padding="4rem" />;
	}

	return (
		<Wrapper padding="1.5rem">
			<Container>
				<Text fontSize="1rem" fontWeight="700" color="error">
					Administrador
				</Text>
				<BodyWrapper>
					<AdminFields admin={admin} />
					<AdminPhoto photo={admin.imagen} admin_id={admin.id} />
				</BodyWrapper>
			</Container>
		</Wrapper>
	);
};

export default Admin;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;

const BodyWrapper = styled.div`
	display: grid;
	grid-template-columns: 8fr 2fr;
	grid-gap: 2rem;
`;
