import React from 'react';
import styled from 'styled-components';

import { ArrowBackRounded } from '@material-ui/icons';
import Text from '../../common/Text';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Header = props => {
    const { route, group } = props;
    let history = useHistory();

    // FUNCTIONS
    function handleBack() {
        history.goBack();
    }

    // RETURN
    return (
        <Container>
            <Wrapper>
                <IconButton onClick={handleBack}>
                    <ArrowBackRounded color="primary" fontSize="large" />
                </IconButton>

                <Text fontSize="1.5rem" fontWeight="700" color="primary">
                    {group === true && <Text color="error"> GRUPO </Text>}
                    {route}
                </Text>
            </Wrapper>
            {props.children}
        </Container>
    );
};

export default Header;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    background-color: #ffffff;
    padding: calc(70px + 1rem) 4rem 1rem calc(70px + 4rem);
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
