import React, { useState } from 'react';
import DefaultTabs from '../../../../../shared/common/DefaultTabs';
import { EvaluationTabsContainer } from '../../../styled-components/forms.styled.components';
import FirstStepEvaluation from './firstStep/FirstStepEvaluation';
import MessageModal from '../../../../../shared/common/MessageModal';
import SecondStepEvaluation from './secondStep/SecondStepEvaluation';

const EvaluationTabs = props => {
    const { handleSubmit, handleDismiss } = props;

    //STATE
    const [tabValue, setTabValue] = useState(0);
    const [estructureComplete, setEstructureComplete] = useState(false);
    //mensaje para recordar completar todos los campos del form
    const [messageOpen, setMessageOpen] = useState(false);

    const tabOptions = [
        { id: 0, label: '1. Detalles' },
        { id: 1, label: '2. Preguntas' },
    ];

    const tab1Option = [{ id: 0, label: '1. Detalles' }];

    // FUNCTIONS
    function handleTabValue(e, newValue) {
        setTabValue(newValue);
        // currentStep(newValue);
    }

    const handleNext = () => {
        setEstructureComplete(true);
        setTabValue(1);
    };

    const handlePrev = () => {
        setTabValue(0);
    };

    function getStepOption(option) {
        switch (option) {
            case 0:
                return (
                    <FirstStepEvaluation
                        handleSubmit={handleSubmit}
                        handleNext={handleNext}
                        handleCompleted={handleCompleted}
                        handleDismiss={handleDismiss}
                    />
                );
            case 1:
                return (
                    !!estructureComplete && (
                        <SecondStepEvaluation
                            handleSubmit={handleSubmit}
                            handlePrev={handlePrev}
                            handleCompleted={handleCompleted}
                            handleDismiss={handleDismiss}
                        />
                    )
                );
            default:
                return null;
        }
    }

    const handleCompleted = () => {
        setMessageOpen(true);
    };

    const handleClose = () => {
        setMessageOpen(false);
    };

    //RETURN
    return (
        <>
            <EvaluationTabsContainer>
                <DefaultTabs
                    tabValue={tabValue}
                    handleTabValue={handleTabValue}
                    tabOptions={!!estructureComplete ? tabOptions : tab1Option}
                    paddingleft="3rem"
                />
                {getStepOption(tabValue)}
            </EvaluationTabsContainer>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¡Ups!"
                image="./rediseño_2024/fallo_accion.png"
                subtitle="Recuerda que debes completar todos los campos para guardar el nuevo recurso."
            />
        </>
    );
};

export default EvaluationTabs;
