import * as types from "../types";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

const initialState = {
	// USER
	user: null,
	authToken: null,
	loading: false,
	error: null,
	// COMPANY
	company: null,
	loadingCompany: false,
	errorCompany: null,
	// PHOTO
	loadingPhoto: false,
	errorPhoto: null,
};

export const user = persistReducer(
	{ storage, key: "root", whitelist: ["user", "authToken"] },
	(state = initialState, action) => {
		switch (action.type) {
			// SIGN IN
			case types.SIGNIN_USER_REQUEST:
				return {
					...state,
					loading: true,
					error: null,
				};
			case types.SIGNIN_USER_SUCCESS:
				localStorage.setItem("token", action.payload.token);
				// localStorage.setItem("token", action.payload.token.data.response_data.token);
				return {
					...state,
					authToken: action.payload.response_data.token,
				};
			case types.SIGNIN_USER_FAIL:
				return {
					...state,
					loading: false,
					error: action.payload,
				};
			// GET USER
			case types.GET_USER_REQUEST:
				return {
					...state,
					error: null,
				};
			case types.GET_USER_SUCCESS:
				return {
					...state,
					loading: false,
					user: action.payload,
				};
			case types.GET_USER_FAIL: {
				return {
					...state,
					loading: false,
					error: action.payload,
				};
			}
			// LOGOUT
			case types.LOGOUT_USER: {
				window.location.reload();
				localStorage.clear();
				return initialState;
			}
			// GET COMPANY
			case types.GET_COMPANY_REQUEST:
				return {
					...state,
					loadingCompany: true,
					errorCompany: null,
				};
			case types.GET_COMPANY_SUCCESS:
				return {
					...state,
					loadingCompany: false,
					company: action.payload,
				};
			case types.GET_COMPANY_FAIL:
				return {
					...state,
					loadingCompany: false,
					errorCompany: action.payload,
				};
			// CHANGE PHOTO COMPANY
			case types.PHOTO_COMPANY_REQUEST:
				return {
					...state,
					loadingPhoto: true,
					errorPhoto: null,
				};
			case types.PHOTO_COMPANY_SUCCESS:
				return {
					...state,
					loadingPhoto: false,
					company: {
						...state.company,
						imagen: action.payload,
					},
					user: {
						...state.user,
						imagen: action.payload,
					},
				};
			case types.PHOTO_COMPANY_FAIL:
				return {
					...state,
					loadingPhoto: false,
					errorPhoto: action.payload,
				};
			case types.USER_SELECTED:
				return {
					...state,
					loading: false,
					user: action.payload,
				};
			case types.SET_DATA:
				return {
					...state,
					loading: false,
					user: { ...state.user, ...action.payload },
				};
			default:
				return state;
		}
	}
);
