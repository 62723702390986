import { Form, Formik } from 'formik';
import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { updateCustomRoute } from '../../redux/api/content-creation.api';
import Loading from '../../shared/common/Loading';
import Snack from '../../shared/common/Snack';
import RouteForm from './RouteForm';
import { useFormik } from 'formik';
import { RouteFormSchema } from '../../utils/schemas';
import MessageModal from '../../shared/common/MessageModal';
import { useModal } from '../../hooks/ModalContext';
// import { attributesFromImgUrl, getFileContentAsBase64, imgBlob } from '../../hooks/attributesFromImgUrl';

const EditRouteForm = props => {
    const { customRouteToEdit, routeId, handleState, handlePublished, ondemand_id } = props;

    console.log('customRouteToEdit', customRouteToEdit);

    //reestructuracion de modulos
    const reduceModules = customRouteToEdit?.courses?.reduce((acumulador, objeto, index) => {
        const clave = objeto.module;
        if (!acumulador[clave]) {
            acumulador[clave] = {
                list_oa: [],
                key_module: index,
            };
        }
        acumulador[clave].list_oa.push(objeto);
        return acumulador;
    }, {});
    const modulesArray = Object.entries(reduceModules).map(([name_module, { list_oa, key_module }]) => ({
        name_module,
        list_oa,
        key_module,
    }));

    //Initial Values de UseFormik
    const formik = useFormik({
        initialValues: {
            title: customRouteToEdit.name,
            description: customRouteToEdit.description,
            image: customRouteToEdit.img,
            modules: modulesArray,
        },
        validationSchema: RouteFormSchema,
        onSubmit: values => {
            console.log('Formulario final:', values);
            handleSubmit();
        },
    });

    //STATES
    const [customRouteUpdate, setCustomRouteUpdate] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null); //img seleccionada, para navegación entre ambos pasos del form
    const [errorOpen, setErrorOpen] = useState(false);
    const [modulesListSelected, setModulesListSelected] = useState(modulesArray);
    const [customRoutePublished, setCustomRoutePublished] = useState(null);
    const [file, setFile] = useState(formik.initialValues.image);
    const [fileEdited, setFileEdited] = useState(null);
    const [oneMoreModule, setOneMoreModule] = useState(false);
    const [oneLessModule, setOneLessModule] = useState(false);

    //manejo de modales
    const { state, openModal, closeModal } = useModal();
    const handleOpenModal = modalType => {
        openModal(modalType);
    };
    const handleCloseModal = () => {
        closeModal();
    };

    // EFFECTS
    useEffect(() => {
        if (customRouteUpdate !== null) {
            //ruta actualizada
            handleState(customRouteUpdate);
            handleOpenModal('guardada_ok');
            console.log('guardada_ok');
        }
        if (customRoutePublished !== null) {
            //ruta publicada _ resta definir propiedad en el EP y relacion con total_emrolled
            handlePublished(customRoutePublished);
            handleOpenModal('publicada_ok');
            console.log('publicada_ok');
        }
    }, [customRoutePublished, customRouteUpdate]);

    //sumar o restar modulos
    useEffect(() => {
        if (oneMoreModule === true) {
            setModulesListSelected(formik.values.modules);
        }
    }, [oneMoreModule, formik.values.modules]);

    useEffect(() => {
        if (oneLessModule === true) {
            setModulesListSelected(formik.values.modules);
        }
    }, [oneLessModule, formik.values.modules]);

    // FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    //nueva img adjunta y convertida a base 64
    const handleSubmitFile = async file => {
        if (!file) {
            console.log('No hay archivo adjunto');
        }
        setSelectedImage(file);
        const fileTransform = await toBase64(file);
        const splitFileTransform = fileTransform.split(',');
        const base64_texto = splitFileTransform[1];
        if (fileTransform !== undefined) {
            setFileEdited(base64_texto);
        }
    };
    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(file);
            reader.onerror = error => reject(error);
        });

    const handleSubmit = async values => {
        console.log('values', values);
        const lastIndexModule = values.modules.length ? Math.max(...values.modules.map(q => q.key_module)) : 0;
        const validateOa = values.modules[lastIndexModule].list_oa;
        if (validateOa.length === 0) {
            alert('Por favor seleccionar los Objetos de Aprendizaje que conforman el último módulo');
        } else {
            //enviar solo el id de OA de cada módulo
            const modulesList = values.modules.map(item => ({
                name_module: item.name_module,
                list_oa: item.list_oa.map(subItem => subItem.id),
            }));
            console.log('modulesList', modulesList);
            let data = '';
            if (fileEdited !== null) {
                data = {
                    id: Number(routeId),
                    name: values.title,
                    description: values.description,
                    duration: 0,
                    duration_unit: '',
                    modules: modulesList,
                    image: fileEdited,
                };
            } else {
                data = {
                    id: Number(routeId),
                    name: values.title,
                    description: values.description,
                    duration: 0,
                    duration_unit: '',
                    modules: modulesList,
                };
            }
            let response = await updateCustomRoute(ondemand_id, data);
            if (response.error) {
                setErrorOpen(true);
                <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                    El curso no pudo enviarse, favor de contactarse con el administrador.
                </Snack>;
            } else {
                setCustomRouteUpdate(response);
                handleState(response);
            }
        }
    };

    //sumar o restar modulos
    const addModule = formik => {
        const lastIndexModule = formik.values.modules.length - 1;
        const lastModule = formik.values.modules.length > 0 ? formik.values.modules[lastIndexModule].key_module : 0; //para asignar key_module siguiente
        const validateOa = formik.values?.modules[lastIndexModule].list_oa; //para validar que no se agregue un módulo sin OA
        if (validateOa.length === 0) {
            alert('Por favor seleccionar los Objetos de Aprendizaje que conforman el módulo');
        } else {
            formik.setValues({
                ...formik.values,
                modules: [
                    ...formik.values.modules,
                    {
                        name_module: '',
                        key_module: lastModule + 1,
                        list_oa: [],
                    },
                ],
            });
            setOneMoreModule(true);
        }
    };

    const deleteModule = (formik, order) => {
        const nuevosModulos = formik.values.modules.filter(x => x.key_module !== order);
        // .map((q, index) => ({
        //     ...q,
        //     key_module: index + 1,
        // })); //reordena el array
        formik.setValues({
            ...formik.values,
            modules: nuevosModulos,
        });
        setOneLessModule(true);
    };

    //Modal para confirmar guardado de edicion de ruta
    const handleConfirmedEditRoute = formik => {
        // console.log('confirmado')
        handleSubmit(formik.values);
        setTimeout(() => {}, 500);
        handleCloseModal();
    };

    // RETURN
    if (!formik.initialValues) {
        return <Loading />;
    }
    return (
        <>
            <Formik
                initialValues={formik.initialValues}
                onSubmit={values => {
                    console.log('values :>> ', values);
                    handleSubmit(values);
                }}
                validationSchema={RouteFormSchema}>
                {formik => (
                    <FormContainer>
                        <RouteForm
                            initialValues={formik.initialValues}
                            handleSubmitFile={handleSubmitFile}
                            valueModules={formik?.values?.modules}
                            modulesListSelected={modulesListSelected} //este valor lleva la info actualizada
                            type="edit-course"
                            selectedImage={selectedImage}
                            addModule={addModule}
                            deleteModule={deleteModule}
                            formik={formik}
                        />

                        <ButtonWrapper>
                            <WrapperButtons>
                                {file === null ||
                                formik.values?.name === '' ||
                                formik.values?.description === '' ||
                                formik.values?.modules.length === 0 ? (
                                    <ButtonToComplete variant="contained" type="button" onClick={() => handleOpenModal('entendido')}>
                                        {' '}
                                        Guardar
                                    </ButtonToComplete>
                                ) : (
                                    <ButtonToSave variant="contained" type="button" onClick={() => handleOpenModal('desea_editar')}>
                                        {' '}
                                        Guardar
                                    </ButtonToSave>
                                )}
                            </WrapperButtons>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
            {state.isOpen &&
                state.modalType === 'entendido' && ( //mensaje entendido
                    <MessageModal
                        isOpen={state.isOpen}
                        handleClose={() => handleCloseModal('entendido')}
                        message="¡Ups!"
                        image="/images/ups_form_ruta.svg"
                        subtitle="Recuerda que debes completar
                    todos los pasos, antes de guardar
                    la ruta formativa editada."
                    />
                )}
            {state.isOpen && state.modalType === 'desea_editar' && (
                <MessageModal
                    isOpen={state.isOpen}
                    handleClose={() => handleCloseModal('desea_editar')}
                    message="¿Estás seguro que deseas guardar los cambios?"
                    image="/images/alerta_edicion.svg"
                    subtitle="Recuerda que los cambios realizados se verán reflejados en la plataforma de cursado de los alumnos y se verá afectado su progreso de avance."
                    handleClickConfirm={() => handleConfirmedEditRoute(formik)}
                />
            )}
            {state.isOpen && state.modalType === 'guardada_ok' && (
                <MessageModal
                    isOpen={state.isOpen}
                    handleClose={() => handleCloseModal('guardada_ok')}
                    message="¡Edición de ruta guardada!"
                    image="/images/saved_article.svg"
                    subtitle="La edición de la ruta formativa se guardó como borrador."
                />
            )}
            {state.isOpen && state.modalType === 'publicada_ok' && (
                <MessageModal
                    isOpen={openModal.value}
                    handleClose={() => handleCloseModal('publicada_ok')}
                    message="¡Ruta publicada!"
                    image="/images/published_article.svg"
                    subtitle="La ruta fue publicada con éxito"
                />
            )}
        </>
    );
};

export default EditRouteForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;

const ButtonToComplete = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: #c4c4c4;
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;
