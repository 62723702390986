import * as yup from "yup";

export const loginSchema = yup.object().shape({
	user: yup.string().email("Formato incorrecto").required("* El campo es requerido"),
	password: yup
		.string()
		.min(8, "Mínimo 8 caracteres")
		.max(24, "Máximo 24 caracteres")
		.required("* El campo es requerido"),
});
