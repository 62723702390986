import { Card } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import MarkdownIt from 'markdown-it';

const Preview = props => {
    const { articleToView } = props;

    const parser = new MarkdownIt({
        html: false, // desactivamos el uso de HTML dentro del markdown
        breaks: true, // transforma los saltos de línea a un <br />
        linkify: true, // detecta enlaces y los vuelve enlaces
        xhtmlOut: true, // devuelve XHTML válido (por ejemplo <br /> en vez de <br>)
        typographer: true, // reemplaza ciertas palabras para mejorar el texto
        langPrefix: 'language-', // agrega una clase `language-[lang]` a los bloques de código
    });

    return (
        <>
            <CardFake>
                <Header
                    bgi={
                        articleToView?.cover?.data?.attributes?.url
                            ? `${process.env.REACT_APP_CMS}${articleToView?.cover?.data?.attributes?.url}`
                            : ``
                    }
                />
                <Body>
                    <WrapperTag>{articleToView.tags?.length > 0 && articleToView.tags?.map(tag => <Chip>{tag}</Chip>)}</WrapperTag>
                    <h2>{articleToView.title || ''}</h2>
                    <SubTitle>{articleToView.preview || ''}</SubTitle>
                    <Span>
                        {articleToView.updatedAt.toLocaleString('en-US') || ''}
                        {articleToView.author ? `| Actualizado por ${articleToView.author}` : ''}
                    </Span>
                </Body>
                <DescriptionWrapper>
                    <div dangerouslySetInnerHTML={{ __html: parser.render(articleToView.content) }} />
                </DescriptionWrapper>
            </CardFake>
        </>
    );
};

export default Preview;

const CardFake = styled(Card)`
    border-radius: 20px;
    width: 100%;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Header = styled.div`
    background-image: url(${p => `${p.bgi}`});
    height: 50vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    object-fit: cover;
    object-position: center;
`;

const Body = styled.div`
    padding: 0.5rem 1rem;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    h2 {
        font-size: 1.5rem;
    }
`;

const WrapperTag = styled.div`
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0;
`;

const Chip = styled.div`
    width: calc(min-content - 1.2rem);
    height: calc(auto - 0.6rem);
    font-weight: bold;
    text-transform: capitalize;
    padding: 0.3rem 0.6rem;
    background-color: var(--primary-one);
    color: #fff;
    border-radius: 5px;
`;

const SubTitle = styled.p`
    color: #757575;
    font-size: 1.1rem;
`;

const Span = styled.p`
    color: var(--secondary-four);
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    gap: 1rem;
    span {
        font-weight: bold;
    }
`;

const DescriptionWrapper = styled.div`
    padding: 1rem;
    line-height: 140%;
`;
