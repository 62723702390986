import axios from "axios";
import { useState } from "react";

const useTicketForm = (initialValues, inputArea) => {

    const [openModal, setOpenModal] = useState(false)
    const [openModalError, setOpenModalError] = useState(false)
    const [ticketNumber, setTicketNumber] = useState(0)
    const [loading, setLoading] = useState(false)

    const URL_API = "https://pmapi.internal.aden.org"

    const handleSubmit = async (e) => {
        if (inputArea !== "") {
            let deadLine = ""
            if (!e.dateDeadline) {
                var fechaActual = new Date();
                // Si la fecha viene vacía, se coloca como fecha 5 días después del día actual
                fechaActual.setDate(fechaActual.getDate() + 5)
                var dia = fechaActual.getDate();
                var mes = fechaActual.getMonth() + 1;
                var año = fechaActual.getFullYear();

                var fechaFormateada = año + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (dia < 10 ? '0' + dia : dia);

                deadLine = fechaFormateada;
            } else {
                deadLine = e.dateDeadline;
            }
            const emptyFile = new File([], 'empty_file.txt', { type: 'text/plain' });
            try {
                setLoading(true)
                const formData = new FormData();
                formData.append("ticket_name", e.ticketName);
                formData.append("external_client_name", initialValues.externalClientName);
                formData.append("external_client_email", initialValues.externalClientEmail);
                formData.append("description", initialValues.description);
                formData.append("image_attachment", emptyFile);
                formData.append("project_id", parseInt(e.projectId));
                formData.append("date_deadline", deadLine + " 12:00");
                const response = await axios.post(`${URL_API}/api/pm/v1/create_ticket`, formData)
                setTicketNumber(response.data.response_data.id)
                setLoading(false)
                setOpenModal(true)
                return response.data;
            } catch (e) {
                setLoading(false)
                setOpenModalError(true);
            }
            return;
        }
    };

    return { handleSubmit, openModal, setOpenModal, openModalError, setOpenModalError, ticketNumber, loading };
}

export default useTicketForm;