import React from 'react';
import styled from 'styled-components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DefaultField from '../../shared/common/DefaultField';
import { useState } from 'react';
import ModalCourseAggregation from './ModalCourseAggregation';
import CheckedCourseCard from './CheckedCourseCard';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { FieldArray } from 'formik';

const DivModuleAggregationDinamic = props => {
    const { valueModule, formik, index, deleteThisModule, legthModulesListSelected } = props;

    const [open, setOpen] = useState(false);
    const [oAChecked, setOAChecked] = useState(valueModule.list_oa);
    const [infoToGrid, setInfoToGrid] = useState(valueModule.list_oa);
    const [nameModule, setNameModule] = useState(valueModule.name_module);

    //modal para agregar cursos al listado del módulo
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //FUNCTIONS
    const handleCourseAggregation = modules => {
        valueModule.list_oa = modules;
        formik.values.modules[index].list_oa = modules;
        setOAChecked(modules);
        setInfoToGrid(modules);
        handleClose();
    };

    const handleCourseRemove = oa => {
        const filterList = valueModule.list_oa.filter(item => {
            return item.id !== oa;
        });
        valueModule.list_oa = filterList;
        setOAChecked(filterList);
        setInfoToGrid(filterList);
    };

    const handleInputChange = e => {
        valueModule.name_module = e.target.value;
        setNameModule(e.target.value);
    };

    const handleModuleRemove = () => {
        console.log('valueModule.key_module', valueModule.key_module)
        if (legthModulesListSelected > 1) {
            deleteThisModule(valueModule.key_module);
        } else {
            alert('No se puede eliminar sin antes generar otro módulo inicial de contenido');
        }
    };

    return (
        <>
            <ModuleDefined>
                <AccordionSummaryFake expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <NumberModule>
                        {' '}
                        <DragIndicatorRoundedIcon /> MÓDULO
                        {` `}
                        {index + 1}
                    </NumberModule>
                    <DeleteRoundedIcon onClick={handleModuleRemove} disabled={legthModulesListSelected === 1} />
                </AccordionSummaryFake>

                <AccordionDetailsFake>
                    <Label>
                        Nombre
                        <DefaultField
                            name="name_module"
                            type="text"
                            label=""
                            required
                            variant="outlined"
                            placeholder="Ej: Unidad 1, Introducción"
                            border="1px solid #616161"
                            borderradius="8px"
                            onChange={handleInputChange}
                            value={nameModule}
                        />
                    </Label>
                    <Label>
                        Contenido
                        <FieldArray name="list_oa">
                            <>
                                <RowButtons>
                                    <AddCourseButton onClick={handleOpen}>
                                        <AddRoundedIcon />
                                        Añadir cursos
                                    </AddCourseButton>
                                </RowButtons>

                                {infoToGrid ? (
                                    <GridValues>
                                        {infoToGrid.map((item, index) => (
                                            <CheckedCourseCard
                                                key={index}
                                                value={item}
                                                handleItemRemove={handleCourseRemove}
                                                name={`oa.${item.id}`}
                                                type='oa'
                                            />
                                        ))}
                                    </GridValues>
                                ) : null}
                            </>
                        </FieldArray>
                    </Label>
                </AccordionDetailsFake>
            </ModuleDefined>
            <ModalCourseAggregation
                open={open}
                handleClose={handleClose}
                handleCourseAggregation={handleCourseAggregation}
                changeChecked={oAChecked}
            />
        </>
    );
};

export default DivModuleAggregationDinamic;

const ModuleDefined = styled(Accordion)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
    height: auto;
    margin: 1rem 0;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 25px;

    div .MuiOutlinedInput-root {
        width: 50%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    div.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
        width: 100%;
        position: relative;
        overflow: visible;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
`;

const NumberModule = styled.div`
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.001em;
    text-align: left;
    color: var(--primary-one);
    padding-left: 0;
`;

const Label = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2.5rem;
    color: #222222;
    margin: 1rem 0;
`;

const AddCourseButton = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    gap: 0.5rem;
    width: max-content;
    background: var(--secondary-one);
    border: 1px solid var(--primary-one);
    color: var(--primary-one);
    border-radius: 3rem;
    font-size: 0.875rem;
    letter-spacing: 0.001em;
    line-height: 1.25rem;
    font-weight: 600;
`;

const GridValues = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100%-2rem);
    height: auto;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    gap: 1rem;
    padding: 1rem 0;
`;

const RowButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

const AccordionDetailsFake = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;

    .MuiFormControl-root.MuiFormControl-fullWidth.MuiInputBase-root {
        width: 50%;
        margin: auto;
    }
`;

const AccordionSummaryFake = styled(AccordionSummary)`
    &.MuiAccordionSummary-root {
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    span,
    svg {
        color: var(--primary-one);
        font-family: Montserrat;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .MuiAccordionSummary-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
        box-shadow: none;
        border-radius: 20px;
        justify-content: space-between;
        align-content: center;
    }
    padding: 0;
    margin-bottom: 1rem;
`;
