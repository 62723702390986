import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = (props) => {
	const { isAuthenticated, component: Component, ...rest } = props;

	return (
		<Route
			{...rest}
			component={(props) =>
				isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.user.user,
});

export default connect(mapStateToProps)(PrivateRoute);
