import { all } from "redux-saga/effects";

// SAGAS
import { userWatcher } from "./user";
import { utilsWatcher } from "./utils";
import { adminWatcher } from "./admin";
import { participantWatcher } from "./participant.saga";
import { groupWatcher } from "./group.saga";
import { contentWatcher } from "./content.saga";
import { licensesWatcher } from "./licenses.saga";
import { analyticsWatcher } from "./analytics.saga";
import { subjectWatcher } from "./subject.saga";


function* rootSaga() {
	yield all([
		userWatcher(),
		utilsWatcher(),
		adminWatcher(),
		participantWatcher(),
		groupWatcher(),
		contentWatcher(),
		licensesWatcher(),
		analyticsWatcher(),
		subjectWatcher()
	]);
}

export { rootSaga };
