import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import LicensesSkt from "../../../../components/skeletons/LicensesSkt";
import { licensesActions } from "../../../../redux/actions";
import Text from "../../../../shared/common/Text";
import LicensesBackground from "../../../../assets/licenses-background.png"
import { Icon } from "@iconify/react/dist/iconify.js";

const CurrentLicense = () => {
	const dispatch = useDispatch();
	const { licenses, loading, error } = useSelector((state) => state.licenses);
	const { id, fecha_inicio, plazo } = useSelector((state) => state.user.user);

	useEffect(() => {
		if (!licenses) {
			dispatch(licensesActions.getLicensesRequest(id));
		}
	}, [id, licenses, dispatch]);

	if (error) {
		return null;
	}

	if (!licenses || loading) {
		return <LicensesSkt columns={'span 16'} />;
	}

	return (
		<Wrapper backgroundImage={LicensesBackground}>
			<BodyWrapper>
				<TextWrapper>
					<Text fontSize="40px" fontWeight="500" fontcolor="var(--primary-one)">
						{licenses.total}
					</Text>
					<Text fontSize="16px" fontWeight="500" fontcolor="#acacac">
						TOTALES
					</Text>
				</TextWrapper>
				<TextWrapper>
					<Text fontSize="40px" fontWeight="500" fontcolor="var(--primary-one)">
						{licenses.restantes}
					</Text>
					<Text fontSize="16px" fontWeight="500" fontcolor="#acacac">
						DISPONIBLES
					</Text>
				</TextWrapper>
				<TextWrapper>
					<Text fontSize="40px" fontWeight="500" fontcolor="var(--primary-one)">
						{licenses.usadas}
					</Text>
					<Text fontSize="16px" fontWeight="500" fontcolor="#acacac">
						USADAS
					</Text>
				</TextWrapper>
			</BodyWrapper>
			<DateWrapper>
				<DateContainer>
					<Icon icon="ph:calendar-dots-fill" width="40px" height="40px" />
					<section>
						<Text fontSize="12px" fontWeight="500" fontcolor="var(--primary-one)">
							FECHA DE INICIO
						</Text>
						<Text fontSize="16px" fontWeight="500" fontcolor="var(--primary-two)">
							{new Date(fecha_inicio).toLocaleDateString()}
						</Text>
					</section>
				</DateContainer>
				<DateContainer>
					<Icon icon="ph:calendar-x-fill" width="40px" height="40px" />
					<section>
						<Text fontSize="12px" fontWeight="500" fontcolor="var(--primary-one)">
							FECHA DE VENCIMIENTO
						</Text>
						<Text fontSize="16px" fontWeight="500" fontcolor="var(--primary-two)">
							{new Date(plazo).toLocaleDateString()}
						</Text>
					</section>
				</DateContainer>
			</DateWrapper>
		</Wrapper>
	);
};

export default CurrentLicense;

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 360px;
	background-color: #CBCACA;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(${(props) => `${props.backgroundImage}`});
	background-size: 105% 110%;
	background-position: 50% 20%;
	box-shadow: 0px 4px 4px 0px #00000040;
`

const BodyWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
`;

const DateWrapper = styled.div`
	position: absolute;
	right: 7%;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	@media (width < 1600px) {
		right: 1%;
	}
`

const DateContainer = styled.div`
	display: flex;
	justify-content: start;
	gap: 1rem;

	svg {
		color: var(--primary-one);
	}

	section {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center;
		gap: .5rem;
	}
`

const TextWrapper = styled.div`
	height: 100px;
	width: 130px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--secondary-one);
	border-radius: 20px;
`;
