import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import styled from "styled-components";

const BodyPopup = (props) => {
    const { currentSlide, handleClose } = props;
    const colorAlab = useSelector((state) => state.user?.user?.color_primario);

    return (
        <Wrapper>
            <Header>
                <Tag bg={colorAlab || "var(-primary-one)"}>{currentSlide?.tag}</Tag>
                <CloseIconFake onClick={handleClose}/>
            </Header>
            <Title bg={colorAlab || "var(-primary-one)"}>{currentSlide?.title}</Title>
            <WrapperText>
                <p>{currentSlide?.description}</p>
            </WrapperText>
        </Wrapper>
    );
};

export default BodyPopup;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const Tag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 16px;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: 8px;
    background-color: ${(p) => `${p.bg}20`};
    color: ${(p) => p.bg};
`;

const CloseIconFake = styled(CloseIcon)`
    cursor: pointer;
    color: var(--primary-one);
`;

const Title = styled.div`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 44px;
    color: ${(p) => p.bg};
`;

const WrapperText = styled.div`
    max-height: 170px;
    width: 100%;
    overflow: auto;
    padding-right: 3px;

    p {
        font-size: 1rem;
        line-height: 140%;
        letter-spacing: 0.0025em;
        color: #616161;
    }
`;
