import React from 'react';
import styled from 'styled-components';
import Layout from '../shared/layout/Layout';
import Header from '../shared/layout/header/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { getStrapiCompany } from '../redux/api/blog';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MessageModal from '../components/blog/MessageModal';
import { getOneBanner } from '../redux/api/announcements';
import EditBannerForm from '../components/announcementsAndNews/EditBannerForm';

const EditBanner = () => {
    const { id } = useParams();
    const ondemandId = useSelector(state => state.user.user.id);

    //STATES
    const [bannerToEdit, setBannerToEdit] = useState(null);
    const [editedBanner, setEditedBanner] = useState(null); 
    const [publishedBanner, setPublishedBanner] = useState(null);
    const [strapiId, setStrapiId] = useState(null); //la generación de banner se vincula por id de strapi del ondemand, el cual se consulta por repo_id

    const [messageOpen, setMessageOpen] = useState(false);
    const [contentMessage, setContentMessage] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getBanner(id);
        }
    }, [id]);

    useEffect(() => {
        if (strapiId === null) {
            getStrapiId(ondemandId);
        }
    }, []);

    useEffect(() => {
        if (!!editedBanner) {
            setMessageOpen(true);
            setContentMessage({
                message: '¡Banner guardado!',
                image: '/images/saved_article.svg',
                subtitle: 'El banner se guardó como borrador',
            });
            getBanner(id);
        }
    }, [editedBanner]);

    useEffect(() => {
        if (!!publishedBanner) {
            setMessageOpen(true);
            setContentMessage({
                message: '¡Banner publicado!',
                image: '/images/published_article.svg',
                subtitle: 'El banner fue publicado con éxito',
            });
            getBanner(id);
        }
    }, [publishedBanner]);

    //FUNCTIONS
    const getBanner = async id => {
        let req = await getOneBanner(id);
        // console.log('req', req);
        if (!!req) {
            setBannerToEdit(req.data.attributes);
        }
    };

    const getStrapiId = async id => {
        let req = await getStrapiCompany(id);
        if (!!req) {
            setStrapiId(req.data[0].id);
        }
    };

    const handlePublished = Banner => {
        setEditedBanner(null);
        setPublishedBanner(Banner);
    };

    const handleUpdate = Banner => {
        setEditedBanner(Banner);
    };

    const handleClose = () => {
        // console.log('handleClose');
        setMessageOpen(false);
        setContentMessage(null);
    };

    //RETURN
    return (
        <>
            <Layout header={<Header route="Edición de Banner" />}>
                <HeaderWrapper>
                    {!!bannerToEdit && (
                        <EditBannerForm
                            bannerToEdit={bannerToEdit}
                            strapiId={strapiId}
                            artId={id}
                            handleUpdate={handleUpdate}
                            handlePublished={handlePublished}
                        />
                    )}
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message={contentMessage?.message}
                image={contentMessage?.image}
                subtitle={contentMessage?.subtitle}
            />
        </>
    );
};

export default EditBanner;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
