import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { participantActions } from '../../redux/actions';
import MemberCard from '../cards/MemberCard';
import Loading from './Loading';

const GridMembers = props => {
    const {
        height,
        search,
        select,
        handleChecked,
        handleCleanChecked,
        checkedItems,
        confirm,
        copyItems,
        handleForceChecked,
        title,
        participantsToRemove = null,
    } = props;

    //REDUX
    const dispatch = useDispatch();
    const ondemand_id = useSelector(state => state.user.user.id);
    const participants = useSelector(state => state.participant.participants);
    const loadingParticipants = useSelector(state => state.participant.loadingParticipants);

    //STATES
    const [user, setUser] = useState(null);

    // EFFECTS
    useEffect(() => {
        participantsToRemove !== null 
        ? setUser(participantsToRemove) 
        : participants !== null 
            ? setUser(participants) 
            : dispatch(participantActions.getParticipantsRequest({ ondemand_id }));
    }, [participantsToRemove, participants, select])

    useEffect(() => {
        if (select === null && checkedItems.length === 0) {
            handleCleanChecked(checkedItems);
        }
    }, [select]);

    useEffect(() => {
        if (select !== null && select !== undefined) {
            setUser(select);
            handleForceChecked(select);
        }
    }, [select]);

    // RETURN
    if (participants === null || user === null || loadingParticipants) {
        return <Loading padding="5rem" />;
    }
    return (
        <OverflowWrapper height={height} title={title}>
            {!!title ? <TitleDiv>{title}</TitleDiv> : null}
            <Overflow>
                {confirm
                    ? user.map(
                          (participant, i) =>
                              copyItems.some(x => x.id === participant.participante_id) && (
                                  <MemberCard
                                      key={i}
                                      participant={participant}
                                      handleChecked={handleChecked}
                                      checked={checkedItems.some(x => x.id === participant.participante_id)}
                                  />
                              ),
                      )
                    : user
                          .filter(
                              x =>
                                  x.nombre.toLowerCase().includes(search.toLowerCase()) ||
                                  (x.email.length > 0 ? x.email.toLowerCase().includes(search.toLowerCase()) : null),
                          )
                          .map((participant, i) => (
                              <MemberCard
                                  key={i}
                                  participant={participant}
                                  handleChecked={handleChecked}
                                  checked={checkedItems.some(x => x.id === participant.participante_id)}
                              />
                          ))}
            </Overflow>
        </OverflowWrapper>
    );
};

export default GridMembers;

const OverflowWrapper = styled.div`
    position: relative;
    height: ${p => p.height};
    overflow: hidden;
    border-radius: 1.25rem;
    /* border: 1px solid #a8a8a8; */
    background: #fff;
    box-shadow: 0px 6px 12px -6px rgba(0, 0, 0, 0.12);
    margin: 0 1rem;
    padding: 16px;
`;

const TitleDiv = styled.div`
    height: 30px;
    overflow: hidden;
    color: #000;
    font-family: Montserrat;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-content: center;
`;

const Overflow = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 1rem;
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 99%;
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
`;
