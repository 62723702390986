import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const FakeImage = styled.img`
    width: 250px;
    height: auto;
    margin-top: 1rem;
`;

export const AddUserSuccessButton = styled(Button)`
    margin-top: 0.5rem;
    width: 100%;
`;
