import * as types from "../types";

const initialState = {
	countries: null,
	loadingCountries: false,
	errorCountries: null,
	thematics: null,
	loadingThematics: false,
	errorThematics: null,
};

export const utils = (state = initialState, action) => {
	switch (action.type) {
		// COUNTRIES
		case types.GET_COUNTRIES_REQUEST:
			return {
				...state,
				loadingCountries: true,
				errorCountries: null,
			};
		case types.GET_COUNTRIES_SUCCESS:
			return {
				...state,
				loadingCountries: false,
				countries: action.payload,
			};
		case types.GET_COUNTRIES_FAIL:
			return {
				...state,
				loadingThematics: false,
				errorCountries: action.payload,
			};
		//THEMATICS
		case types.GET_THEMATICS_REQUEST:
			return {
				...state,
				loadingThematics: true,
				errorThematics: null,
			};
		case types.GET_THEMATICS_SUCCESS:
			return {
				...state,
				loadingThematics: false,
				thematics: action.payload,
			};
		case types.GET_THEMATICS_FAIL:
			return {
				...state,
				loadingCountries: false,
				errorThematics: action.payload,
			};
		default:
			return state;
	}
};
