import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import Loading from '../../shared/common/Loading';
import styled from "styled-components";
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from '../../styled-components/accordion';

const CourseAccordion = ({ course: courseId, setSelectedClass, setOpenDetail, setIsOpen }) => {

	//STATES
	const [loading, setloading] = useState(true)
	const [units, setUnits] = useState(null)


	//FUNCTIONS
	const handleSelectClass = (e) => {
		setSelectedClass(e.target.dataset);
		setIsOpen(false)
		setOpenDetail(true)
	};

	const cleanSelectClass = (e) => {
		console.info("knsfbhsdbf")
		setSelectedClass([]);
	}

	const getData = async () => {
		const URL = `${process.env.REACT_APP_SISAPI_ONDEMAND}/acropolis/objeto_aprendizaje/${courseId?.id}`
		try {
			const res = await axios.get(URL);
			const data = res.data;
			setUnits(data.unidades)
			setloading(false);
		} catch (error) {
			return {
				error
			}
		}
	}

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		cleanSelectClass();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ListItemFake]);

	return (
		<>
			{
				loading === true ?
					<Loading />
					:
					<AccordionCourse>
						{
							units.map(course => (
								<AccordionContainer key={`course_${course.id}`}>
									{
										course.nombre === "No aplica" ?
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												Contenido
											</AccordionSummary>
											:
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												{course.nombre}
											</AccordionSummary>
									}
									<AccordionDetails>
										{
											course.clases.map((classItem, i) => (
												<ListItemFake
													key={i}
												>
													<span>{classItem.nombre_publicacion}</span>
													<button
														data-id={`${classItem.id}`}
														data-nombre={`${classItem.nombre}`}
														data-nombre_publicacion={`${classItem.nombre_publicacion}`}
														data-link_storage={classItem.link_storage ? `${classItem.link_storage}` : ""}
														data-link_video={classItem.link_video ? `${classItem.link_video}` : ""}
														data-formato={classItem.formato ? `${classItem.formato}` : ""}
														data-estado={classItem.estado ? `${classItem.estado}` : ""}
														onClick={handleSelectClass}
													>
														Ver detalle
													</button>
												</ListItemFake>
											))
										}
									</AccordionDetails>
								</AccordionContainer>
							))
						}
					</AccordionCourse>
			}
		</>
	);
}

export default CourseAccordion;

const AccordionCourse = styled.div`
    max-width: 1000px;
	width: calc(100% + 20px);
    max-height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
	scrollbar-color: transparent transparent;
    
    @media screen and (max-width: 768px) {
        max-width:100%;
        max-height: 40vh;
        font-size:12px;
    }
`

const AccordionContainer = styled(StyledAccordion)`
    width: calc(100% - 2px);
    background-color: var(--secondary-two) !important;  
    border-radius: 10px !important;
    box-shadow: none !important;
    border: 1px solid #acacac;
`

const AccordionSummary = styled(StyledAccordionSummary)`
    background-color: var(--secondary-two) !important;
    border-radius: 10px !important;
    box-shadow: none !important;
	font-size: 20px;
	font-weight: 700;
`

const AccordionDetails = styled(StyledAccordionDetails)`
    background-color: var(--secondary-two) !important;
	display: flex;
	flex-direction: column;
	gap: .5rem !important;
`

const ListItemFake = styled.div`
    transition: ease-in-out .3s all;
    display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	border-radius: 20px;
	padding: 0 .5rem;
    align-items: center;
    margin: 5px 0;
    font-size: 14px;
    color: #555;
    background-color: var(--secondary-one);

	button {
		background-color: var(--primary-one);
		border-radius: 50px;
		color: var(--secondary-one);
		height: 70%;
		font-size: 12px;
		font-weight: 500 !important;
		padding: 0 1rem;
	}

	:hover {
		outline: 1px solid #000;
		box-shadow: 0px 4px 4px 0px #00000040;
	}
`;