import { AssessmentRounded} from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import Text from "./Text";

const NoWrapper = (props) => {
	const { columns, rows, padding, height, width, title } = props;

	return (
		<Container columns={columns} rows={rows} padding={padding} height={height} width={width}>
			<AssessmentRoundedFake
			 	fontSize="large"
				color="secondary" 
				justifyContent="center" />			
				{props.children}

			<Text fontSize="1.5rem" fontWeight="700" color="error">
				{title}
			</Text>

		</Container>
	);
};

export default NoWrapper;

const Container = styled.div`
	padding: ${(p) => p.padding};
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
	grid-column: ${(p) => p.columns};
	grid-row: ${(p) => p.rows};
	height: ${(p) => p.height};
	width: ${(p) => p.width};	
	align-content: center;
	vertical-align: middle;
    text-align: center;
`;

const AssessmentRoundedFake = styled(AssessmentRounded)`
	position: relative;
	width: 200px;
	height: 100px;
`;
