import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Divider } from '@material-ui/core';
import { ChevronRightRounded } from '@material-ui/icons';
import GridMembers from '../../shared/common/GridMembers';
import SelectAndSearch from '../../shared/common/SelectAndSearch';
import Text from '../../shared/common/Text';

const AssignMembers = props => {
    const { nextStep, 
        checkedItems, 
        handleChecked, 
        course, 
        select, 
        handleSelect, 
        handleForceChecked, 
        handleCleanChecked, 
        handleClose, 
        titleAssign, 
        participantsToRemove} = props;

    // STATE
    const [search, setSearch] = useState('');

    // FUNCTIONS
    function handleSearch(e) {
        setSearch(e.target.value);
    }

    // console.log('checkedItems', checkedItems)

    // RETURN
    return (
        <Container>
            <Text fontSize="1rem" fontWeight="400" color="error">
                {titleAssign} 
                <b> {course.nombre_publicacion 
                        ? course.nombre_publicacion 
                        : course.name 
                            ? course.name
                            : course.publication_name
                    }
                </b>
            </Text>
            <SelectAndSearch
                search={search}
                handleSearch={handleSearch}
                //select={select}
                handleSelect={handleSelect}
            />
            <GridMembers
                select={select}
                height="300px"
                search={search}
                handleChecked={handleChecked}
                handleCleanChecked={handleCleanChecked}
                checkedItems={checkedItems}
                handleForceChecked={handleForceChecked}
                participantsToRemove={participantsToRemove}
            />
            <Divider />
            <ButtonWrapper>
                <div>
                    <TotalSelected>
                        <DivCounter>{checkedItems.length}</DivCounter>
                        Alumnos seleccionados
                    </TotalSelected>
                </div>
                <div>
                    <CancelButton onClick={handleClose}>Cancelar</CancelButton>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<ChevronRightRounded />}
                        onClick={nextStep}
                        disabled={checkedItems.length === 0}>
                        Siguiente
                    </Button>
                </div>
            </ButtonWrapper>
        </Container>
    );
};

export default AssignMembers;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    min-width: 768px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
    }
`;

const CancelButton = styled(Button)`
    display: flex;
    flex-direction: row;
    display: flex;
    height: 3rem;
    padding: 0rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 3rem;
    color: var(--primary-one);
    font-feature-settings: 'liga' off;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0.00088rem;

    :hover {
        background:var(--tertiary-five);
    }
`;

const TotalSelected = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    color: #616161;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const DivCounter = styled.div`
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.3125rem;
    background: #616161;
    color: white;
`;
