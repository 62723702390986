import React from 'react';
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { updateCustomOA } from '../../redux/api/content-creation.api';
import CourseForm from './CourseForm';
import { Button } from '@mui/material';
import Snack from '../../shared/common/Snack';
import { CourseFormSchema } from '../../utils/schemas';
import { patchAssignResource } from '../../pages/Resources/services/resources.services';
// import MessageModal from '../../shared/common/MessageModal';
// import Loading from '../../shared/common/Loading';

const listTimeUnities = [
    { id: 0, name: 'meses' },
    { id: 1, name: 'semanas' },
    { id: 2, name: 'días' },
    { id: 3, name: 'horas' },
    { id: 4, name: 'selecciona una opción' },
];

const typesOfCourse = [
    { id: 1, name: 'Bloque BIenvenida' },
    { id: 3, name: 'Bloque Contenido' },
    { id: 4, name: 'Bloque Integrador' },
    { id: 5, name: 'Encuesta de Satisfacción' },
    { id: 6, name: 'Bloque Bienvenida' },
];

const EditCourseForm = props => {
    const { customCourseToEdit, artId, handleUpdate, handlePublished } = props;

    //Default Autocomplete
    const formatUnityTime = () => {
        const duracion_unidad_tiempo = customCourseToEdit?.duracion_unidad_tiempo !== false ? customCourseToEdit?.duracion_unidad_tiempo : 'semanas';
        return listTimeUnities.filter(values => values.name === duracion_unidad_tiempo)[0];
    };
    const filterTypes = () => {
        const initial =
            customCourseToEdit?.tipo_objeto_aprendizaje === '' ||
            customCourseToEdit?.tipo_objeto_aprendizaje === null ||
            customCourseToEdit?.tipo_objeto_aprendizaje === undefined
                ? 'Bloque Bienvenida'
                : customCourseToEdit?.tipo_objeto_aprendizaje;
        const filter = typesOfCourse?.filter(values => values.name === initial);
        return filter[0];
    };

    // STATE INICIAL
    const initialValues = {
        title: customCourseToEdit?.nombre_publicacion || '',
        description: customCourseToEdit?.descripcion || '',
        author: customCourseToEdit?.autor || '',
        duration: customCourseToEdit?.duracion || '',
        timeunity: formatUnityTime(),
        type: filterTypes(),
        image: customCourseToEdit?.imagen || '',
        theme: customCourseToEdit?.eje_tematico_ids || '',
        tag: customCourseToEdit?.tag_id || '',
        unidades: customCourseToEdit?.unidades || '',
        // published: customCourseToEdit?.published || '',
    };

    //STATES
    const [customCourseUpdate, setCustomCourseUpdate] = useState(null);
    const [customCoursePublished, setCustomCoursePublished] = useState(null);
    const [file, setFile] = useState(initialValues?.image);
    const [fileEdited, setFileEdited] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null); //img seleccionada, para navegación entre ambos pasos del form
    const [errorOpen, setErrorOpen] = useState(false);
    const [tagsListSelected, setTagsListSelected] = useState([]);
    const [themesListSelected, setThemesListSelected] = useState([]);

    //lista de recursos asociados _ clases de unidades sin especificar
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [selectedResources, setSelectedResources] = useState(null);
    // const [messageSuccesOpen, setMessageSuccesOpen] = useState(false);
    // const [messageErrorOpen, setMessageErrorOpen] = useState(false);

    // EFFECTS
    //asignación de recursos al OA
    // useEffect(() => {
    //     if (status === 'success') {
    //         setMessageSuccesOpen(true);
    //     } else if (status === 'error') {
    //         setMessageErrorOpen(true);
    //     }
    // }, [status]);

    useEffect(() => {
        if (customCourseUpdate !== null) {
            handleUpdate(customCourseUpdate);
        }
        if (customCoursePublished !== null) {
            handlePublished(customCoursePublished);
        }
    }, [customCoursePublished, customCourseUpdate]);

    // FUNCTIONS
    //lista de recursos asociados _ clases de unidades sin especificar
    const confirmedResourceList = list => {
        setSelectedResources(list);
    };

    function handleCloseError() {
        setErrorOpen(false);
    }

    //nueva img adjunta y convertida a base 64
    const handleSubmitFile = async file => {
        if (!file) {
            console.log('no hay archivo adjunto');
        }
        setSelectedImage(file);
        const fileTransform = !!file && (await toBase64(file));
        const splitFileTransform = fileTransform.split(',');
        const base64_texto = splitFileTransform[1];
        if (fileTransform !== undefined) {
            setFileEdited(base64_texto);
        }
    };

    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    const listTagsValuesSelected = list => {
        console.log('list_ listTagsValuesSelected', list);
        let idList = [];
        list?.forEach(element => {
            idList.push(element.id);
        });
        setTagsListSelected(idList);
    };

    const listThemesValuesSelected = list => {
        // console.log('list_ listThemesValuesSelected', list);
        let idList = [];
        list?.forEach(element => {
            idList.push(element.id);
        });
        setThemesListSelected(idList);
    };

    const handleAssingment = async () => {
        setLoading(true);

        const initialArray = customCourseToEdit?.unidades.flatMap(item => item.clases);
        const modifiedArray = selectedResources;

        // Encontrar los valores agregados y eliminados
        const addedValues = modifiedArray.filter(value => !initialArray.includes(value));
        const removedValues = initialArray.filter(value => !modifiedArray.includes(value));

        // Crear instrucciones
        const instructions = [
            ...addedValues.map(resource => ({
                curse_id: artId,
                resource_id: resource.id || resource.recurso_id || resource.id_recurso,
                area_id: '188', // El área se hardcodea en 188 por pedido de Juan Francisco Indelicato
                action: 'assign',
            })),
            ...removedValues.map(resource => ({
                curse_id: artId,
                resource_id: resource.id || resource.recurso_id || resource.id_recurso,
                area_id: '188', // El área se hardcodea en 188 por pedido de Juan Francisco Indelicato
                action: 'unassign',
            })),
        ];

        const body = { instructions };

        try {
            await patchAssignResource(body);
            setStatus('success');
        } catch (error) {
            console.error(error);
            setStatus('error');
        } finally {
            setLoading(false);
        }
    };

    // function handleClose() {
    //     setMessageSuccesOpen(false);
    //     setMessageErrorOpen(false);
    // }

    const handleSubmit = async values => {
        handleAssingment(values.unidades);
        const a = listTimeUnities.filter(x => x.id === values.timeunity.id);
        const b = typesOfCourse.filter(x => x.id === values.type.id);
        let data = '';
        if (fileEdited !== null) {
            data = {
                titulo: values.title,
                descripcion: values.description,
                autor: values.author,
                duracion: values.duration,
                duracion_unidad_tiempo: a[0].name,
                tipo_oa_id: b[0].id,
                eje_ids: themesListSelected,
                tag_ids: tagsListSelected,
                imagen: fileEdited,
                published: false,
            };
        } else {
            data = {
                titulo: values.title,
                descripcion: values.description,
                autor: values.author,
                duracion: values.duration,
                duracion_unidad_tiempo: a[0].name,
                tipo_oa_id: b[0].id,
                eje_ids: themesListSelected,
                tag_ids: tagsListSelected,
                published: false,
            };
        }
        // console.log('data', data);
        let response = await updateCustomOA(artId, data);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El Curso personalizado no pudo actualizarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setCustomCourseUpdate(response);
        }
    };

    // RETURN
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CourseFormSchema}>
                {({ values, setFieldValue }) => (
                    <FormContainer>
                        <CourseForm
                            initialValues={initialValues}
                            handleSubmitFile={handleSubmitFile}
                            type="edit-course"
                            listTagsValuesSelected={listTagsValuesSelected}
                            setFieldValue={setFieldValue}
                            listThemesValuesSelected={listThemesValuesSelected}
                            timeUnityComplete={values.timeunity}
                            typeComplete={values.type}
                            selectedImage={selectedImage}
                            confirmedResourceList={confirmedResourceList}
                        />

                        <ButtonWrapper>
                            <WrapperButtons>
                                <ButtonToSave variant="contained" type="submit" disabled={file === null}>
                                    Guardar cambios
                                </ButtonToSave>
                            </WrapperButtons>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
            {/* {loading && <Loading />} */}
            {/* status === "error" en Asignacion de Recursos*/}
            {/* <MessageModal
                isOpen={messageErrorOpen}
                handleClose={handleClose}
                message="¡Ups!"
                image="/rediseño_2024/fallo_accion.png"
                subtitle="Hubo un error al asignar los recursos"
            /> */}
            {/* status === "success" en Asignacion de Recursos*/}
            {/* <MessageModal
                isOpen={messageSuccesOpen}
                handleClose={handleClose}
                message="¡La asignación del recurso ha sido exitosa!"
                image="/rediseño_2024/accion_exitosa.png"
                subtitle=""
            /> */}
        </>
    );
};

export default EditCourseForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;
