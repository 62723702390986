import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";
// import { UpdateRounded } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Wrapper from "../../../shared/common/Wrapper";
import Loading from "../../../shared/common/Loading";

const SelectUser = (props) => {
	const { handleSelected, userAdvanceByCourses, userQuery  } = props;
	//STATES
	const [value, setValue] = useState( userAdvanceByCourses ?
											userQuery !== null ? userAdvanceByCourses.find(user=> user.repo_id === userQuery)
											:userAdvanceByCourses[0]
										:null
										);
	const [inputValue, setInputValue] = useState(null);

	// EFFECTS
	// useEffect(() => {
	// 	if (inputValue === null) {
	// 		handleSelected(value)
	// 	}
	// }, [inputValue]);

	useEffect(() => {
		if (inputValue !== null) {
			handleSelected(value)
		}
	}, [value, inputValue, handleSelected]); 

	//RETURN
	return (
		<Wrapper padding="1.5rem" columns="span 24">
			<Container>
			{userAdvanceByCourses === null ? 
				<Loading/>
			:
				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					id="autocomplete-userAdvanceByCourses"
					options={userAdvanceByCourses}
					getOptionLabel={(option) => option.name}
					style={{ width: "100%" }}
					renderInput={(params) => 
						<TextField {...params} 
						label="Selecciona un usuario" 
						variant="outlined" />}
				/>
			}
				<Button
					variant="contained"
					color="primary"
					onClick={(event, newValue) => {
						setValue("all");
						}}
					// startIcon={<UpdateRounded />}
				>
					Todos
				</Button>
			</Container>
		</Wrapper>
	);
};

export default SelectUser;

const Container = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1.5rem;
`;