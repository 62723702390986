import React from "react";
import styled from "styled-components";

import { SearchRounded } from "@material-ui/icons";
import Text from "./Text";

const NoSearchResults = () => {
	return (
		<Container>
			<SearchRounded fontSize="large" color="secondary" />
			<Text fontSize="1.5rem" fontWeight="700" color="secondary">
				¡Ups! No encontramos lo que buscabas, intenta una búsqueda diferente.
			</Text>
		</Container>
	);
};

export default NoSearchResults;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	row-gap: 1rem;
	padding: 3rem 15rem;
`;
