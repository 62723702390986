import React from 'react';
import styled from 'styled-components';
import Layout from '../../shared/layout/Layout';
import { useSelector } from 'react-redux';
import NewContent from './components/newContent/NewContent';
import Text from '../../shared/common/Text';
import Services from './components/services/Services';
import CurrentLicense from './components/licensesStats/CurrentLicense';
import Stats from './components/stats/Stats';

const Dashboard = () => {
    const admin = useSelector(state => state.user.user.detalle);
    const is_incompany = useSelector(state => state.user.user.is_incompany);

    return (
        <Layout>
            <Text fontSize="28px" fontWeight="700" fontcolor="var(--primary-one)" lineheight="3rem">
                Hola, {admin}
            </Text>
            <Text fontSize="20px" fontWeight="400" fontcolor="#222222" lineheight="2rem">
                <i>
                    <b>¡Qué alegría verte de nuevo por aquí! </b>
                    Échale un vistazo a tus pendientes y ten un excelente día.
                </i>
            </Text>
            <Container>
                <PrincipalInfo>
                    <LeftInfo>
                        <Stats />
                        <h2>Tu plan de licencias actual</h2>
                        <CurrentLicense />
                    </LeftInfo>
                    <RightInfo>
                        <NewContent />
                    </RightInfo>
                </PrincipalInfo>
                {!is_incompany && <Services />}
            </Container>
        </Layout>
    );
};

export default Dashboard;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
`;

const PrincipalInfo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @media (width < 1200px) {
        flex-direction: column;
    }
`;

const LeftInfo = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h2 {
        font-size: 20px;
        font-weight: 700;
        color: var(--primary-one);
    }

    @media (width < 1200px) {
        width: 100%;
    }
`;

const RightInfo = styled.div`
    width: 50%;

    @media (width < 1200px) {
        width: 100%;
    }
`;
