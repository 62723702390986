import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { participantActions } from '../../../redux/actions';
import Text from '../../../shared/common/Text';
import { AddUserForm, AddUserImage, AddUserTextField, FormAddUserButton } from '../styled-components/addUser/formAddUsers.styled.components';
import { TextWrapper } from '../styled-components/users.styled.components';

const FormAddUser = () => {
    // REDUX
    const dispatch = useDispatch();

    const ondemand_id = useSelector(state => state.user.user.id);

    const loadingNewParticipant = useSelector(state => state.participant.loadingNewParticipant);

    // STATE
    const [newParticipant, setNewParticipant] = useState({
        nombre: '',
        email: '',
        password: '',
    });

    // FUNCTIONS
    function handleChange(e) {
        setNewParticipant({
            ...newParticipant,
            [e.target.name]: e.target.value,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(
            participantActions.createParticipantRequest({
                newParticipant,
                ondemand_id,
            }),
        );
    }
    // RETURN
    return (
        <>
            <AddUserImage src="/images/male-avatar.svg" alt="avatar" />
            <TextWrapper>
                <Text fontSize="1.5rem" fontWeight="700">
                    Nuevo usuario
                </Text>
                <Text fontSize="1rem" fontWeight="700">
                    Crea un nuevo usuario
                </Text>
            </TextWrapper>
            <AddUserForm onSubmit={handleSubmit}>
                <AddUserTextField
                    name="nombre"
                    value={newParticipant.nombre}
                    onChange={handleChange}
                    type="text"
                    required
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Nombre completo"
                />
                <AddUserTextField
                    name="email"
                    value={newParticipant.email}
                    onChange={handleChange}
                    type="email"
                    required
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Correo electrónico"
                />
                <AddUserTextField
                    name="password"
                    value={newParticipant.password}
                    onChange={handleChange}
                    type="text"
                    required
                    variant="outlined"
                    size="small"
                    color="primary"
                    label="Contraseña"
                />
                <FormAddUserButton variant="contained" color="primary" size="small" type="submit" disabled={loadingNewParticipant}>
                    {loadingNewParticipant ? 'Creando...' : 'Crear'}
                </FormAddUserButton>
            </AddUserForm>
        </>
    );
};

export default FormAddUser;
