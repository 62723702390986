import React from "react";
import styled from "styled-components";

import Text from "../common/Text";
import { EmojiEventsRounded } from "@material-ui/icons";

const RankingCard = (props) => {
	const {row} = props;
	return (
		<Container>
			<InfoWrapper>
				<Photo src="/images/female-avatar.svg" alt="user" />
				<Text fontSize="1rem" fontWeight="400">
					{row.nombre?row.nombre:"Participante"}
				</Text>
			</InfoWrapper>
			<EmojiEventsRounded htmlColor="#FEE101" />
		</Container>
	);
};

export default RankingCard;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1rem 0.5rem 0.5rem;
`;

const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;

const Photo = styled.img`
	width: 50px;
	height: 50px;
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
`;
