import React, { useEffect, useState } from 'react';
import { ContainerForm, IndicationTitle } from '../../../styled-components/forms.styled.components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EvaluationTabs from './EvaluationTabs';
import { createCustomResource } from '../../../services/resource.services';
import Snack from '../../../../../shared/common/Snack';

const EvaluationForm = props => {
    const { ondemand_id, handleState, handleLoading } = props;
    let history = useHistory();

    //STATES
    const [Resource, setResource] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    //EFFECTS
    //creacion de recurso
    useEffect(() => {
        if (Resource !== null) {
            handleState(Resource);
            localStorage.removeItem('1evaluation');
            localStorage.removeItem('2evaluation');
            console.log('limpieza local storage :>> ');
        }
        //eslint-disable-next-line
    }, [Resource]);

    // FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    const handleDismiss = () => {
        history.push('/new-custom-resource');
    };

    const handleSubmit = async values => {
        const firstStateEvaluation = JSON.parse(localStorage.getItem('1evaluation'));
        const secondStateEvaluation = JSON.parse(localStorage.getItem('2evaluation'));

        console.log('firstStateEvaluation :>> ', firstStateEvaluation);
        console.log('secondStateEvaluation :>> ', secondStateEvaluation);
        handleLoading(true);

        const intentos = firstStateEvaluation.permitted_attempts === false ? 'ilimitado' : 'limitado';
        const formatQuestions = secondStateEvaluation.questions.map(question => ({
            description: question.description,
            type: question.type,
            order: question.order,
            score: parseInt(question.score, 10),
            data_question_options: question.data_question_options.map(option => ({
                name: option.name,
                es_opcion_correcta: option.es_opcion_correcta,
                comentario: option.comentario,
            })),
        }));

        const data = {
            name: `ALAB_CL_${firstStateEvaluation.publication_name}`,
            publication_name: `ALAB_CL_${firstStateEvaluation.publication_name}`,
            type: 'material_plano',
            resource_subtype_id: 5, //evaluación
            category_id: 4, //AcropolisLAB
            date_deadline: '2024-12-31',
            evaluation_data: {
                name: firstStateEvaluation.publication_name,
                description: firstStateEvaluation.description,
                activity: 'evaluacion',
                permitted_attempts: intentos,
                ...(!!firstStateEvaluation.permitted_attempts ? { number_attempts: firstStateEvaluation.number_attempts } : { number_attempts: 100 }),
                type: 'auto_corregible',
                time: firstStateEvaluation.time,
                data_questions: formatQuestions,
            },
        };

        console.log('handleSubmit - data', data);

        let response = await createCustomResource(ondemand_id, data);
        console.log('response', response);
        setTimeout(() => {
            handleLoading(false);

            if (response.error) {
                setErrorOpen(true);
                <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                    El recurso no pudo enviarse, favor de contactarse con el administrador.
                </Snack>;
            } else if (response.response_data.includes('ERROR')) {
                setErrorOpen(true);
                <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                    El recurso no pudo enviarse, favor de contactarse con el administrador.
                </Snack>;
            } else {
                setResource(response);
                handleState(response);
            }
        }, 3000);
    };

    return (
        <ContainerForm>
            <IndicationTitle>Creación de recurso de evaluación</IndicationTitle>
            <EvaluationTabs handleSubmit={handleSubmit} handleDismiss={handleDismiss} />
        </ContainerForm>
    );
};

export default EvaluationForm;
