import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getDataEvaluationService, getResource } from './services/resource.services';
import styled from 'styled-components';
import { ContainerResourceForm } from './styled-components/forms.styled.components';
import MessageModal from '../../shared/common/MessageModal';
import Layout from '../../shared/layout/Layout';
import Header from '../../shared/layout/header/Header';
import ReadingForm from './components/forms/ReadingForm';
import AudioVisualForm from './components/forms/AudioVisualForm';
import Loading from '../../shared/common/Loading';
// import EvaluationForm from './components/forms/evaluationForms/EvaluationForm';

const EditResource = () => {
    const { id } = useParams();
    let history = useHistory();
    const ondemandId = useSelector(state => state.user.user.id);

    //STATES
    const [customResourceToEdit, setCustomResourceToEdit] = useState(null);
    const [resourceEdited, setResourceEdited] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);

    const [dataActivity, setDataActivity] = useState(null);

 

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getCustomResource(ondemandId, id);
        }
    }, [id]);

    useEffect(() => {
        if (dataActivity === null) {
            customResourceToEdit?.format === 'evaluacion' && getEvaluation();
        }
    }, [customResourceToEdit, dataActivity]);

    useEffect(() => {
        if (!!resourceEdited) {
            setMessageOpen(true);
        }
    }, [resourceEdited]);

    //FUNCTIONS
    const getCustomResource = async (ondemandId, resource_id) => {
        let req = await getResource(ondemandId, resource_id);
        // console.log('req getCustomResource', req);
        // console.log('error status :>> ', req.error?.response?.status);
        if (req?.error?.response?.status > 400) {
            history.push('/');
        } else {
            setCustomResourceToEdit(req?.response_data);
        }
    };

    const handleLoading = boolean => {
        console.log('handleLoading :>> ', boolean);
        setIsLoading(boolean);
    };

    const handleState = art => {
        console.log('art :>> ', art);
        setResourceEdited(art);
    };

    function handleClose() {
        setMessageOpen(false);
        history.push(`/resources?tab=recursos_empresa`);
    }

    const getResourceOption = option => {
        switch (option) {
            case 'audiovisual':
                return (
                    <AudioVisualForm
                        customResourceToEdit={customResourceToEdit}
                        ondemand_id={ondemandId}
                        handleState={handleState}
                        handleLoading={handleLoading}
                    />
                );
            case 'material_plano':
                return (
                    <ReadingForm
                        customResourceToEdit={customResourceToEdit}
                        ondemand_id={ondemandId}
                        handleState={handleState}
                        handleLoading={handleLoading}
                    />
                );
            case 'evaluacion':
                return (
                    // <EvaluationForm
                    //     customResourceToEdit={customResourceToEdit}
                    //     ondemand_id={ondemandId}
                    //     handleState={handleState}
                    //     handleLoading={handleLoading}
                    // />
                    history.push('/resources')
                );
            default:
                return null;
        }
    };

    // FUNCTION
    const getEvaluation = async () => {
        let body = {
            id: customResourceToEdit.id_evaluacion,
            user: ondemandId,
            obj: 100001,
            reso: id,
        };
        const response = await getDataEvaluationService(body);
        if (!response) {
            setDataActivity(false);
        } else {
            setDataActivity(response);
        }
    };

    // console.log('dataActivity', dataActivity);
    // console.log('customResourceToEdit :>> ', customResourceToEdit);
    //RETURN
    if (customResourceToEdit === null) {
        <Loading />;
    }
    return (
        <>
            <Layout header={<Header route={`Recurso ${customResourceToEdit?.format} -  ${customResourceToEdit?.publication_name}`} />}>
                <HeaderWrapper>
                    {customResourceToEdit?.length !== 0 ? (
                        <ContainerResourceForm>{getResourceOption(customResourceToEdit?.format)}</ContainerResourceForm>
                    ) : null}
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={isLoading}
                message=""
                image="/rediseño_2024/Loading.gif"
                subtitle="Espera unos segundos mientras se actualiza el recurso..."
                withoutButton={true}
            />
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message={`¡La edición del recurso ${customResourceToEdit?.publication_name} ha sido exitosa!`}
                image="/rediseño_2024/accion_exitosa.png"
                subtitle=""
            />
        </>
    );
};

export default EditResource;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 2rem);
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
`;
