import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import Snack from '../../shared/common/Snack';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ActionButton from '../blog/ActionButton';
import { publishPopUpSlide, updatePopUpSlide } from '../../redux/api/announcements';
import { PopUpFormSchema } from '../../utils/schemas';
import PopUpForm from './PopUpForm';

const EditPopUpForm = props => {
    const { strapiId, popUpToEdit, artId, handleUpdate, handlePublished } = props;
    const [popUpUpdate, setPopUpUpdate] = useState(null);
    const [popUpPublished, setPopUpPublished] = useState(null);
    const [file, setFile] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (popUpToEdit.image?.data?.attributes !== undefined ){
            setFile(popUpToEdit.image?.data?.attributes)
        } else {
            setFile(null)
        }
    }, [])

    useEffect(() => {
        if (popUpUpdate !== null) {
            handleUpdate(popUpUpdate);
        }
        if (popUpPublished !== null) {
            handlePublished(popUpPublished);
        }
    }, [popUpPublished,popUpUpdate ]);

    // STATE INICIAL
    const initialValues = {
        title: popUpToEdit.title || '',
        description: popUpToEdit.description || '',
        tag: popUpToEdit.tag || '' ,
        published: popUpToEdit.published || '',
        image_url: popUpToEdit.image_url || '',
        image: popUpToEdit.image?.data?.attributes || '',
    };

    // FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    const handleSubmitFile = file => {
        setFile(file);
    };

    const handleSubmit = async values => {
        const data = {
            title: values.title,
            description: values.description,
            tag: values.tag,
            image_url: values.image_url,
            company: strapiId,
            user_roles: ['4'],
            published: false,
            //la edicion fuerza su estado de publicado a borrador, 
            //si estaba publicado necesariamente debe publicarlo nuevamente tras el update de modificaciones 
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(data) || '');
        formData.append('files.image', file || '');

        let response = await updatePopUpSlide( artId, formData);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El Pop Up no pudo actualizarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setPopUpUpdate(response);
        }
    };

    const handlePublish = async () => {
        let response = await publishPopUpSlide( artId, {publish: true});
        // console.log('response-publishPopUpSlide', response);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El Pop Up no pudo publicarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setPopUpPublished(response);
        }
    };

    // RETURN
    return (
        <>
             <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={PopUpFormSchema}
            >
                {({ values }) => (
                    <FormContainer>
                        <PopUpForm
                            initialValues={initialValues}
                            handleSubmitFile={handleSubmitFile}
                            type="edit"
                        />
                        <ButtonWrapper>
                            {<WrapperButtons>
                                    <ButtonToPublish 
                                        variant="contained" 
                                        type="submit" 
                                        disabled={initialValues.published}
                                        onClick={handlePublish}
                                    >
                                        Publicar
                                    </ButtonToPublish>
                                    <ButtonToSave 
                                        variant="contained" 
                                        type="submit" 
                                        disabled={file === null}
                                        onClick={handleSubmit}
                                    >
                                        Guardar cambios
                                    </ButtonToSave>
                                    <ActionButton 
                                        title="Vista previa" 
                                        type="submit" 
                                        icon={<VisibilityRoundedIcon />} 
                                        bgc="var(--secondary-one)"
                                        textColor="var(--primary-one)"
                                        border={"1px solid var(--primary-one)"}
                                        action={`/pop-up${artId}`}
                                    />
                                </WrapperButtons>
                           }
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default EditPopUpForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToPublish = styled(Button)`

    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--secondary-one);
        border: 1px solid var(--primary-one);
        color: var(--primary-one);
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }

        &.Mui-disabled{
            background-color: rgba(0, 0, 0, 0.12) !important;
            color: rgba(0, 0, 0, 0.26);
        }
    }

`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }

    &.Mui-disabled{
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26);
    }
`;


