import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import { Label, RadioGroupResourcesContainer, RadioGroupStyle, RadioStyle } from '../styled-components/forms.styled.components';

const RadioGroupResources = props => {
    const { list, resourceType, label, preSelected, formik } = props;
    const [value, setValue] = useState(preSelected);

    const handleChange = event => {
        setValue(event.target.value);
        resourceType(event.target.value);
        formik.values.subtype = event.target.value;
    };
    
    return (
        <RadioGroupResourcesContainer>
            <Label>{label}</Label>
            <RadioGroupStyle
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}>
                {list.map(l => (
                    <FormControlLabel key={l.id} value={l.id} control={<RadioStyle />} label={l.label} />
                ))}
            </RadioGroupStyle>
        </RadioGroupResourcesContainer>
    );
};

export default RadioGroupResources;
