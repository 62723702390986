import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    html {
        --primary-one: #B31D15;
        --primary-two: #535353;
        --primary-three: #1D1D1B;
        --secondary-one: #FFFFFF;
        --secondary-two: #F1F1F1;
        --secondary-three: #CD2118;
        --secondary-four: #A8A8A8;
        --tertiary-one : #35D0A5;
        --tertiary-two: #C29F43;
        --tertiary-three: #F9F5EC;
        --tertiary-four: #5E80DB;
        --tertiary-five: #E6EBF9;
        --tertiary-six: #1E8065;
        --tertiary-seven: #C1F0E3;
    }
`;