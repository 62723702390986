import axios from 'axios';

export async function signInFA(user) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SISAPI_LOGIN}`, user);
        return response;
    } catch (error) {
        return {
            error,
        };
    }
}

//login y determinacion del estado de user / cuentas asociada al administrador
export async function signInUser(email) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v2/repo_aden/ondemand/login/${email}`);
        return response;
    } catch (error) {
        return {
            error,
        };
    }
}

export async function getUsersIC(email) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v2/repo_aden/ondemand/login/${email}`);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

// COMPANY
export function getCompany({ ondemand_id }) {
    return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/empresa/${ondemand_id}`);
}

export function changePhotoCompany({ imagen, ondemand_id }) {
    return axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/actualizar_foto_ondemand/repositorio_id?id=${ondemand_id}`, { base64_image: imagen });
}
