import axios from 'axios';

const instance = axios.create({ baseURL: `${process.env.REACT_APP_CMS}` });
instance.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_CMS_API_KEY}`;

export async function getStrapiCompany(company_repo_id) {
    return await instance
        .get(`/api/companies?filters[repo_id]=${company_repo_id}`)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

//CATEGORIES
export async function getAllCategories(company_id) {
    return await instance
        .get(`/api/categories?&filters[company][repo_id][$eq]=${company_id}&filters[company][repo_id][$eq]=0`)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

export async function getAllCategoriesByCompany(company_id) {
    return await instance
        .get(`/api/categories?populate=*&filters[company][repo_id][$eq]=${company_id}`)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}


export async function createCategory(body) {
    return await instance
        .post(`/api/categories`, body)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

export async function updateCategory(category_id, body) {
    return await instance
        .put(`/api/categories/${category_id}`, body)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}


// export async function deleteCategory(category_id) {
//     return await instance
//         .delete(`/api/categories/${category_id}`)
//         .then(res => {
//             return res.data;
//         })
//         .catch(error => {
//             return { error };
//         });
// }


//ARTICLES
export async function getAllArticles(company_id) {
    return await instance
        .get(`/api/blog-articles?populate=*&filters[company][repo_id][$eq]=${company_id}`)
        // .get(`/api/blog-articles?populate=*&filters[company][repo_id][$eq]=${company_id}`)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

export const createArticle = async body => {
    return await instance
        .post(`api/blog-articles`, body)
        .then(res => {
            console.log('res-createArticle', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};

export async function getOneArticle(article_id) {
    return await instance
        .get(`/api/blog-articles/${article_id}?populate=*`)
        // .get(`/api/blog-articles/${article_id}?populate=*`)
        .then(res => {
            console.log('res-getOneArticle', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

export const updateArticle = async (article_id, body) => {
    return await instance
        .put(`api/blog-articles/${article_id}`, body)
        .then(res => {
            console.log('res-updateArticle', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};

export const publishArticle = async (article_id, body) => {
    return await instance
        .put(`api/blog-articles/publish/${article_id}`, body)
        .then(res => {
            console.log('res-publishArticle', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};


export const deleteArticle = async (article_id) => {
    return await instance
        .delete(`api/blog-articles/${article_id}`)
        .then(res => {
            console.log('res-deleteArticle', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};
