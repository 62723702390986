import * as types from "../types/subjects.types";

const actions = {};

// GET SUBJECT
actions.getSubjectRequest = (payload) => {
	return {
		type: types.GET_SUBJECT_REQUEST,
		payload: payload,
	};
};

actions.getSubjectSuccess = (subject) => {
	return {
		type: types.GET_SUBJECT_SUCCESS,
		payload: subject,
	};
};

actions.getSubjectFail = (error) => {
	return {
		type: types.GET_SUBJECT_FAIL,
		payload: error,
	};
};


export { actions };
