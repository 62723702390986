import styled from 'styled-components';

export const CourseFiltersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

export const SpanWrapper = styled.div`
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 100px;
    background-color: ${props => (props.currentState ? '#fafafa' : 'transparent')};
    box-shadow: ${props => (props.currentState ? '0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F' : 'none')};
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) => props.currentState && "1px solid #f1f1f1"};
    font-size: 14px;
    
    :hover {
        color: var(--primary-one);
        background-color: ${props => (props.currentState ? '#fff' : '#FFF7F6')};
        font-weight: 700;
    }
`;

export const Span = styled.span`
    font-size: 1rem;
    color: ${props => (props.currentState ? 'var(--primary-one)' : '#A8A8A8')};
    font-weight: ${props => (props.currentState ? '700' : '400')};
`;
