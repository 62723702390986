import React from "react";
import styled from "styled-components";

import { Skeleton } from "@material-ui/lab";
import Wrapper from "../../shared/common/Wrapper";

const UsersSkt = () => {
	return (
		<Container>
			<HeaderWrapper>
				<Wrapper width="75%">
					<Skeleton variant="rect" animation="wave" width="100%" height={65} />
				</Wrapper>
				<Wrapper width="25%">
					<Skeleton variant="rect" animation="wave" width="100%" height={65} />
				</Wrapper>
			</HeaderWrapper>
			<Wrapper width="100%">
				<Skeleton variant="rect" animation="wave" width="100%" height={530} />
			</Wrapper>
		</Container>
	);
};

export default UsersSkt;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1.5rem;
`;
