import axios from 'axios';

//Creación de un recurso personalizado
export async function createCustomResource(ondemand_id, body) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/resources/create_customised_resource/${ondemand_id}`, body);
        console.log('response createCustomRoute', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Get recurso personalizado
export async function getResource(ondemand_id, resource_id) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/resources/${resource_id}`);
        console.log('response getCustomResource', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Edición de un recurso personalizado
export async function editCustomResource(ondemand_id, resource_id, body) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_REPOURL}/v1/repo_aden/resources/customised_resource/${ondemand_id}/${resource_id}`,
            body,
        );
        console.log('response editCustomResource', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Archivo  de un recurso personalizado
export async function archiveCustomResource(ondemand_id, resource_id, action) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_REPOURL}/v1/repo_aden/resources/customised_resource/archive/${ondemand_id}/${resource_id}`,
        );
        console.log('response archiveCustomResource', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Eliminación de un recurso personalizado
// export async function deleteCustomResource(ondemand_id, resource_id) {
//     try {
//         const response = await axios.delete(
//             `${process.env.REACT_APP_REPOURL}/v1/repo_aden/resources/delete_customised_resource/${ondemand_id}/${resource_id}`,
//         );
//         console.log('response deleteCustomResource', response);
//         return response.data;
//     } catch (error) {
//         return {
//             error,
//         };
//     }
// }

export const getDataEvaluationService = async ({ id, user, obj, reso, assignmentId = '' }) => {
    const assignmentIdQuery = `&id_asignatura=${assignmentId}`;
    const URL = `${process.env.REACT_APP_REPOURL}/v1/repo_aden/resources/test/${id}?user=${user}&obj=${obj}&resource=${reso}`;
    try {
        if (assignmentId) {
            const response = await axios.get(`${URL}${assignmentIdQuery}`);
            return response.data.response_data;
        }
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return false;
    }
};
