import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, FormControlLabel, Switch } from '@material-ui/core';
import { ChevronLeftRounded } from '@material-ui/icons';
import GridMembers from '../../shared/common/GridMembers';
import { useSelector } from 'react-redux';
import CourseAddedCard from './CourseAddedCard';

const ConfirmMembers = props => {
    const {
        backStep,
        checkedItems,
        handleChecked,
        course,
        handleForceChecked,
        titleConfirm,
        handleSubmit,
        stateObligatory = false,
        handleClose,
    } = props;

    // REDUX
    const loadingAssign = useSelector(state => state.participant.loadingAssign);

    // STATE
    const [copyItems, setCopyItems] = useState([]);

    // EFFECTS
    useEffect(() => {
        setCopyItems(checkedItems);
    }, []);

    const handleSwitch = event => {
        stateObligatory();
    };

    // RETURN
    return (
        <Container onSubmit={handleSubmit}>
            <InfoWrapper>
                <CourseAddedCard
                    course={course}
                    swictch={
                        <FormControlLabel
                            control={<Switch checked={stateObligatory.obligatory} onChange={handleSwitch} name="obligatory" color="primary" />}
                            label="Curso obligatorio"
                        />
                    }
                />
                <GridMembers
                    confirm
                    height="280px"
                    checkedItems={checkedItems}
                    handleChecked={handleChecked}
                    copyItems={copyItems}
                    handleForceChecked={handleForceChecked}
                    title={titleConfirm}
                />
            </InfoWrapper>
            <Divider />
            <ButtonWrapper>
                <Button variant="outlined" color="primary" size="small" startIcon={<ChevronLeftRounded />} onClick={backStep}>
                    Volver
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    onClick={() => {
                        handleSubmit();
                        handleClose()
                    }}
                    disabled={checkedItems.length === 0 || loadingAssign}>
                    Confirmar
                </Button>
            </ButtonWrapper>
        </Container>
    );
};

export default ConfirmMembers;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    min-width: 768px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        display: flex;
        height: 3rem;
        padding: 0rem 1.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border-radius: 3rem;
        font-feature-settings: 'liga' off;
        font-family: Montserrat;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: 0.00088rem;
    }
`;

const InfoWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 2rem;
    border-radius: 1.25rem;
    background: #f8f8f8;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 4px 4px 0px rgba(24, 39, 75, 0.12);
    padding: 2rem 1rem;
`;
