import React from 'react';
import styled from 'styled-components';

import LoginForm from './LoginForm';

export default function AsideLogin() {
    return (
        <AsideContainer>
            <TextWrapper>
                <Title>Panel Administrativo</Title>
            </TextWrapper>
            <LogoImg src="/images/acropolis_lab_horizontal_blanca.svg" alt="logo" />
            <LoginText>Iniciar sesión</LoginText>
            <LoginForm />
        </AsideContainer>
    );
}

const AsideContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5rem;
    padding: 0 25%;
    background-color: var(--primary-one);
`;

const LogoImg = styled.img`
    width: 450px;
    height: auto;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    text-align: center;
    margin-bottom: 2rem;
`;

const Title = styled.h1`
    font-size: 1.5rem;
    color: #fff;
    font-weight: 300;
`;


const LoginText = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: -1.5rem;
`;
