import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const styleProgress = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#3BD39C',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));


const CircularProgressBox = (props) =>{
const {percentage}=props;
const classes = styleProgress();

return (

  <Box position="relative" display="inline-flex" className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={50}
        thickness={4.5}
        value={100} 
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={50}
        thickness={4.5}
        value={percentage} 
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
          <Typography variant="caption" component="div" color="secondary">
              { percentage}%
          </Typography>
      </Box>
  </Box>
  );
}
export default CircularProgressBox;

