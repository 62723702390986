import React from "react";
import styled from "styled-components";
import Text from "../../../shared/common/Text";
import Wrapper from "../../../shared/common/Wrapper";
//import Activity from "./user/Activity";
import AnalyticsUsersSkt from "../../skeletons/AnalyticsUsersSkt";
import DownloadGroup from "./DownloadGroup";
import AccordionProgress from "../common/AccordionProgress";


const AllGroups = (props) => {
    const {groupAdvanceByCourses}=props;

	return (
        <Container>
		{	groupAdvanceByCourses === null ?
				<AnalyticsUsersSkt/>
			:
            <>
                <Wrapper padding="1.5%" columns="span 24">
                    <DownloadGroup 
                    title="Descarga el listado completo de grupos, sus cursos y usuarios"
                    print={groupAdvanceByCourses}/>
                </Wrapper>
                {
                    groupAdvanceByCourses.map((group, i)=>(
                        <Wrapper padding="2%" columns="span 24">
                            <Text fontSize="1rem" fontWeight="700" color="error">{groupAdvanceByCourses[i].group}</Text>   
                            <AccordionProgress
                                title=""
                                type="allGroups"
                                data={group.courses}
                                print="false"
                                gtc="repeat(auto-fill, minmax(140px, 1%))"
                            />
                        </Wrapper>
                    ))
                }
			</>
		}
		</Container>
	)
};

export default AllGroups;

const Container = styled.div`
	display: contents;
    width: 100%;
    margin: 0 auto;
    gap: 1.5rem;
`;
