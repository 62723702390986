import React from "react";
import styled from "styled-components";

import Text from "./Text";
import { ErrorMessage, Field } from "formik";
import { TextField } from "@material-ui/core";

const DefaultFieldWithText = (props) => {
	const { name, type, placeholder, title } = props;

	return (
		<Container>
			<Text fontWeight="700" color="primary" component="span">
				{title}
			</Text>
			<Field
				as={TextField}
				name={name}
				helperText={<ErrorMessage name={name} component={CustomError} />}
				type={type}
				required
				label=""
				placeholder={placeholder}
				variant="outlined"
				color="primary"
				size="medium"
				fullWidth
				{...props}
			/>
		</Container>
	);
};

export default DefaultFieldWithText;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
`;

const CustomError = styled.span`
	font-size: 0.75rem;
	font-weight: 700;
	color: var(--primary-one);
`;
