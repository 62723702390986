import { Divider } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { licensesActions } from "../../redux/actions";
import Text from "../../shared/common/Text";
import Wrapper from "../../shared/common/Wrapper";
import LicensesSkt from "../skeletons/LicensesSkt";

const CurrentLicense = () => {
	const dispatch = useDispatch();
	const { licenses, loading, error } = useSelector((state) => state.licenses);
	const { id, fecha_inicio, plazo } = useSelector((state) => state.user.user);

	useEffect(() => {
		if (!licenses) {
			dispatch(licensesActions.getLicensesRequest(id));
		}
	}, [id, licenses, dispatch]);

	if (error) {
		return null;
	}

	if (!licenses || loading) {
		return <LicensesSkt columns={'span 16'}/>;
	}

	return (
		<Wrapper columns="span 16" padding="1.05rem 1.5rem">
			<Container>
				<Text fontSize="1rem" fontWeight="700" color="error" component="h1">
					Tu plan de licencias actual
				</Text>
				<BodyWrapper>
					<ImageWrapper>
						<FakeImage src="/images/small-team.svg" alt="license" />
					</ImageWrapper>
					<TextWrapper>
						<Text fontSize="0.75rem" fontWeight="400" color="secondary">
							TOTALES
						</Text>
						<Text fontSize="1.5rem" fontWeight="700" color="error">
							{licenses.total}
						</Text>
					</TextWrapper>
					{/* <FakeDivider orientation="vertical" flexItem /> */}
					<TextWrapper>
						<Text fontSize="0.75rem" fontWeight="400" color="secondary">
							DISPONIBLES
						</Text>
						<Text fontSize="1.5rem" fontWeight="700" color="error">
							{licenses.restantes}
						</Text>
					</TextWrapper>
					{/* <FakeDivider orientation="vertical" flexItem /> */}
					<TextWrapper>
						<Text fontSize="0.75rem" fontWeight="400" color="secondary">
							USADAS
						</Text>
						<Text fontSize="1.5rem" fontWeight="700" color="error">
							{licenses.usadas}
						</Text>
					</TextWrapper>
					{/* <FakeDivider orientation="vertical" flexItem /> */}
					<TextWrapper>
						<Text fontSize="0.75rem" fontWeight="400" color="secondary">
							FECHA INICIO
						</Text>
						<Text fontSize="1.35rem" fontWeight="700" color="error">
							{new Date(fecha_inicio).toLocaleDateString()}
						</Text>
					</TextWrapper>
					{/* <FakeDivider orientation="vertical" flexItem /> */}
					<TextWrapper>
						<Text fontSize="0.75rem" fontWeight="400" color="secondary">
							VENCIMIENTO
						</Text>
						<Text fontSize="1.35rem" fontWeight="700" color="error">
						{new Date(plazo).toLocaleDateString()}
						</Text>
					</TextWrapper>
				</BodyWrapper>
			</Container>
		</Wrapper>
	);
};

export default CurrentLicense;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

const BodyWrapper = styled.div`
	display: grid;
	width: 100%;
	overflow: hidden;
	grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
	grid-gap: 0.5rem;
	align-items: center;
`;

const ImageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 1rem;
	border-radius: 5px;
	background-color: #fff5f5;
	padding: 1rem 1rem 0 1rem;

`;

const FakeImage = styled.img`
	width: 85px;
	height: auto;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.25rem;
	border-right: 1px solid #a8a8a8;
	align-self: flex-start;
`;
