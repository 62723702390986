import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPaper, Detail, ImportTableChips } from '../styled-components/importUsers/importTable.styled.components';

const useStyles = makeStyles({
    root: {
        width: '100%',
        border: '1px solid #C4C4C4',
        maxHeight: '40vh',
        borderRadius: 20,
        zIndex: 10,
        '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
            borderRadius: 'none',
            boxShadow: 'none',
            border: 'none',
            padding: 0,
        },
    },
    container: {
        maxHeight: '35vh',
        borderRadius: 20,
    },
    pagination: {
        height: 'auto',
        borderRadius: 20,
        '&.MuiTablePagination-root': {
            fontSize: 10,
            color: '#616161',
        },
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#F1F1F1',
        color: 'var(--primary-one)',
        fontSize: 14,
        fontWeight: 700,
        flexWrap: 'nowrap',
        padding: '0 1rem',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: 500,
        color: '#616161',
        backgroundColor: '#FFFFFF',
    },
}));

const ImportTable = props => {
    const { columns, rows, handleViewError, usersImported } = props;
    const classes = useStyles();

    //STATES
    const [page, setPage] = useState(0);
    const [fullRows, setFullRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [importStatus, setImportStatus] = useState(null);
    const [error, setError] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (rows.length > 0) {
            setFullRows(rows);
        }
    }, [rows]);

    useEffect(() => {
        if (!!usersImported && usersImported.length > 0) {
            usersImportStatus(rows, usersImported);
        } else {
            setError(usersImported); //error en el proceso de envio de archivo csv
        }
        // eslint-disable-next-line
    }, [usersImported]);


    useEffect(() => {
        !!error && handleViewError(error);
        // eslint-disable-next-line
    }, [error]);

    //comparacion resultado EP creacion masiva
    const usersImportStatus = () => {
        const resultado = rows.map(elemento => {
            const coincidencia = usersImported.find(item => item.email === elemento.Correo);
            if (coincidencia) {
                return { ...elemento, Estado: coincidencia?.response?.status ? coincidencia?.response?.status : coincidencia?.response_error };
            } else {
                return elemento;
            }
        });
        setImportStatus(resultado);
        setFullRows(resultado);
    };

    //PAGINATION
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <CustomPaper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow key={'headTable_row'}>
                            {columns.map(column => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fullRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    {columns.map((column, index) => {
                                        const value = row[column.id];
                                        return importStatus?.length > 0 && column.id === 'Estado' ? (
                                            <StyledTableCell key={column.id}>
                                                {value === 200 ? (
                                                    <ImportTableChips state={200}>Creado</ImportTableChips>
                                                ) : (
                                                    <ImportTableChips state={value}>
                                                        <Detail onClick={() => handleViewError(value)}>Ver error</Detail>
                                                    </ImportTableChips>
                                                )}
                                            </StyledTableCell>
                                        ) : (
                                            <StyledTableCell key={column.id} align={column.align}>
                                                {value}
                                            </StyledTableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                className={classes.pagination}
                rowsPerPageOptions={[5, 10, 50, { label: 'Todos', value: -1 }]}
                component="div"
                count={fullRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </CustomPaper>
    );
};

export default ImportTable;

