import React from 'react';
import styled from 'styled-components';
import DefaultField from '../../shared/common/DefaultField';
import InputFile from '../../shared/common/InputFile';

const TabRouteDefinition = props => {
    const { initialValues, handleSubmitFile, type, selectedImage } = props;

    return (
        <Content>
            <First>
                <Label>
                    Nombre de la ruta formativa
                    <Subtitle>Elige un nombre claro pero llamativo para captar la atención de los estudiantes.</Subtitle>
                    <DefaultField
                        name="title"
                        type="text"
                        label=""
                        required
                        variant="outlined"
                        placeholder="Ingresa el título"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Descripción
                    <Subtitle>Cuéntale a tus alumnos de qué se trata o qué aprenderán en esta ruta formativa.</Subtitle>
                    <DefaultField
                        name="description"
                        type="text"
                        variant="outlined"
                        label=""
                        required
                        placeholder="¿De qué trata este curso?"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>
            </First>

            <Second>
                <Label>
                    Portada
                    <Subtitle>Elige una imagen que represente a esta ruta.</Subtitle>
                    <InputFile initialContent={initialValues.image} 
                    handleSubmitFile={handleSubmitFile} 
                    type={type} 
                    selectedImage={selectedImage} />
                </Label>
            </Second>
        </Content>
    );
};

export default TabRouteDefinition;
const Content = styled.div`
    display: grid;
    width: 100%;
    padding: 2rem 3rem;
    grid-template-areas: 'first second';
    gap: 2rem;
    margin-top: 25px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Label = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--primary-one);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0 1rem 0;
`;

const First = styled.div`
    width: calc(100%-64px);
    grid-area: first;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Second = styled.div`
    grid-area: second;
    width: calc(100%-64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Subtitle = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color: #616161;
`;
