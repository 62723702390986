import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { AssessmentRounded, DeleteOutlineRounded } from '@material-ui/icons';
import EditGroup from '../groups/EditGroup';
import { Link } from 'react-router-dom';
import DeactivateButton from './DeactivateButton';
import { useDispatch, useSelector } from 'react-redux';
import { participantActions } from '../../redux/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MessageModal from '../../shared/common/MessageModal';

const HeaderButtons = props => {
    const { group, id, tab, rol, currentParticipant } = props;

    // REDUX
    const dispatch = useDispatch();
    const ondemand_id = useSelector(state => state.user.user.id);

    // STATE
    const [messageOpen, setMessageOpen] = useState(false);
    let history = useHistory();

    // FUNCTIONS

    function handleClose() {
        setMessageOpen(false);
    }

    function removeUser() {
        setMessageOpen(true);
    }

    const handleConfirm = async () => {
        await dispatch(participantActions.removeParticipantRequest({ id, ondemand_id }));
        setTimeout(() => {}, 500);
        setMessageOpen(false);
        history.push(`/users`);
    };

    return (
        <>
            <Container>
                {group === true && <EditGroup />}
                <Link to={`/analytics/?tab=${tab}&${rol}=${id}`}>
                    <AnaliticButton variant="contained" size="small" startIcon={<AssessmentRounded />}>
                        Analítica
                    </AnaliticButton>
                </Link>
                {group !== true && (currentParticipant.estado === 'activo' || currentParticipant.estado === 'bloqueado') ? (
                    <DisableButton variant="contained" size="small">
                        <DeactivateButton repositorio_id={currentParticipant.repositorio_id} status={currentParticipant.estado} />
                    </DisableButton>
                ) : null}

                {group !== true ? (
                    <DeleteButton variant="contained" size="small" startIcon={<DeleteOutlineRounded />} onClick={removeUser}>
                        Eliminar
                    </DeleteButton>
                ) : null}
            </Container>
            {!!messageOpen && (
                <MessageModal
                    isOpen={messageOpen}
                    handleClose={handleClose}
                    message="¿Confirmas la eliminación de este participante?"
                    image="/images/desmatriculacion_usuario.svg"
                    subtitle=""
                    handleClickConfirm={handleConfirm}
                />
            )}
        </>
    );
};

export default HeaderButtons;

const Container = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const AnaliticButton = styled(Button)`
    background-color: #2f2e41;
    color: #ffffff;
    height: 2.5rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    span {
        font-size: 0.75rem;
        font-weight: 400;
    }
`;

const DisableButton = styled(Button)`
    background-color: #fce3cc;
    color: #f2851f;
    height: 2.5rem;
    padding: 0;
`;

const DeleteButton = styled(Button)`
    background-color: #f4f4f4;
    color: #616161;
    height: 2.5rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    span {
        font-size: 0.75rem;
        font-weight: 400;
    }
`;
