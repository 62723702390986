import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Button, CircularProgress, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { connect } from 'react-redux';
import { userActions } from '../../redux/actions';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { loginSchema } from '../../utils/validate';
import Snack from '../../shared/common/Snack';
import ListUserAvaliable from './ListUserAvaliable';

const LoginForm = props => {
    const { signInRequest, authToken, loading, error } = props;
    // STATE
    const initialValues = {
        user: '',
        password: '',
    };
    const [user, setUser] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [login, setLogin] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (authToken) {
            // getUserRequest(user);
            setLogin(true);
        }
    }, [authToken]); // eslint-disable-line

    useEffect(() => {
        if (error) {
            setErrorOpen(true);
        }
    }, [error]);

    // FUNCTIONS
    function handleSubmit(values) {
        setUser(values.user);
        signInRequest(values);
    }

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    function handleCloseError() {
        setErrorOpen(false);
    }

    if (login) {
        return <ListUserAvaliable user={user} />;
    }

    // RETURN
    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={loginSchema}>
            {() => (
                <LoginFormContainer>
                    {/** Email */}
                    <div>
                        <FieldFake name="user" type="email" placeholder="Ingresa tu correo electrónico" fullWidth />
                        <ErrorMessage name="user" component={CustomError} />
                    </div>
                    {/** Password */}

                    <PassWrapper>
                        <FieldFake name="password" type={showPassword ? 'text' : 'password'} placeholder="Contraseña" fullWidth />
                        <InputAdornmentFake position="end">
                            <IconButton size="small" onClick={toggleShowPassword}>
                                {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                            </IconButton>
                        </InputAdornmentFake>
                        <ErrorMessage name="password" component={CustomError} />
                    </PassWrapper>
                    {/** Submit */}
                    {loading ? (
                        <CircularProgress color="primary" size={32} />
                    ) : (
                        <FakeButton variant="contained" color="secondary" size="large" type="submit" fullWidth>
                            Ingresar
                        </FakeButton>
                    )}
                    <ForgotPassword to="/">Olvidé mi contraseña</ForgotPassword>
                    {/** Snackbar error */}
                    <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                        Usuario y/o contraseña incorrectos
                    </Snack>
                </LoginFormContainer>
            )}
        </Formik>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        signInRequest: user => dispatch(userActions.signInRequest(user)),
        getUserRequest: user => dispatch(userActions.getUserRequest(user)),
    };
};

const mapStateToProps = state => {
    return {
        loading: state.user.loading,
        authToken: state.user.authToken,
        error: state.user.error,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

// STYLED-COMPONENTS

const LoginFormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`;

const FakeButton = styled(Button)`
    margin-top: 1rem;
    color: #fff;
    border-radius: 10px;
    span {
        font-size: 1.3rem;
    }
`;

const ForgotPassword = styled(Link)`
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 300;

    :hover {
        color: #bfbfbf;
    }
`;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #fff;
    margin-left: 0.5rem;
`;

const PassWrapper = styled.div`
    position: relative;
`;

const FieldFake = styled(Field)`
    background-color: #efefef;
    font-size: 1rem;
    outline: none;
    border: none;
    padding: 1rem;
    height: 20px;
    width: calc(100% - 2rem);
    border-radius: 25px;
`;

const InputAdornmentFake = styled(InputAdornment)`
    position: absolute;
    top: 50%;
    right: calc(-10px + 2rem);
`;
