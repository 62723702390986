import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ContentCard from '../../shared/cards/ContentCard';
import NoSearchResults from '../../shared/common/NoSearchResults';
import { ModalProvider } from '../../hooks/ModalContext';

const GridCards = props => {
    const { content, search, themes, type } = props;
    const [contentTheme, setContentTheme] = useState(null);

    useEffect(() => {
        if (themes !== null) {
            searchContentThematic(content, themes);
            search !== '' && searchContent(contentTheme !== null ? contentTheme : content, search);
        } else {
            if (search === '') {
                setContentTheme(content);
            } else {
                searchContent(content, search);
            }
        }
    }, [search, themes]);

    const searchContentThematic = (array, theme) => {
        let newArray = [];
        array.forEach(item => {
            item.eje_tematico_ids?.forEach(th => {
                if (th.name === theme) newArray.push(item);
            });
        });
        setContentTheme(newArray.length === 0 ? null : newArray);
    };

    const searchContent = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (
                item.nombre_publicacion?.toLowerCase().includes(search.toLowerCase()) ||
                item.publication_name?.toLowerCase().includes(search.toLowerCase())
            ) {
                newArray.push(item);
            }
        });
        setContentTheme(newArray.length === 0 ? null : newArray);
    };

    // RETURN
    if (!Array.isArray(content)) {
        return content;
    }
    return contentTheme === null ? (
        <NoSearchResults />
    ) : (
        <Container>
            {contentTheme.map((course) => (
                <ModalProvider key={course.id}>
                    <ContentCard content={course} key={course.id} type={type} />
                </ModalProvider>
            ))}
        </Container>
    );
};

export default GridCards;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 1.5rem;

    @media (max-width: 1440px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
`;
