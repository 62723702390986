import React from 'react';
import Text from '../../../shared/common/Text';
import { AddUserSuccessButton, FakeImage } from '../styled-components/addUser/addUserSucces.styled.components';
import { TextWrapper } from '../styled-components/users.styled.components';

const AddUserSuccess = props => {
    const { handleClose } = props;

    // RETURN
    return (
        <>
            <FakeImage src="/images/create-user-success.svg" alt="avatar" />
            <TextWrapper>
                <Text fontSize="1.5rem" fontWeight="700">
                    ¡Perfecto!
                </Text>
                <Text fontSize="1rem" fontWeight="700">
                    El usuario fue creado con éxito
                </Text>
                <Text fontSize="1rem" fontWeight="300">
                    Recuérdale que recibirá en su correo las instrucciones para terminar de crear su cuenta
                </Text>
                <AddUserSuccessButton variant="contained" color="primary" size="small" onClick={handleClose}>
                    Ok
                </AddUserSuccessButton>
            </TextWrapper>
        </>
    );
};

export default AddUserSuccess;
