import styled from "styled-components";
import { Icon } from '@iconify/react/dist/iconify.js';

const StatsSection = (props) => {

    const { data } = props;

    return (
        <Container>
            <section>
                <Icon icon={data?.icon} width="50px" height="50px" />
                <span>
                    <label>
                        {data?.number}
                    </label>
                    {
                        data?.label && <p>{data?.label}</p>
                    }
                </span>
            </section>
        </Container>
    )
}

export default StatsSection;

const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px;
    color: var(--secondary-one);

    section {
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    label {
        font-size: 40px;
        font-weight: 500;
    }

    p {
        font-size: 16px;
        font-weight: 500;
    }

    @media (max-width: 1200px) {
        min-height: 100px;
        padding: 1rem;

        section {
            gap: 0;
        }
    }
`