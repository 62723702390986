import { Checkbox } from "@material-ui/core";
import styled from "styled-components";

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;

    p {
        color: ${(props) => props.status === "success" ? "#4caf50" : "#f44336"};
        font-size: 14px;
        font-weight: 600;
        line-height: 1.25rem;
    }
`;

export const AssigmentButton = styled.button`
    cursor: pointer;
    width: 8rem;
    min-width: 192px;
    height: 40px;
    min-height: 40px;
    border-radius: 4rem;
    border: ${(props) => props.type === "primary" ? "1px solid var(--secondary-three)" : "1px solid var(--primary-one)"};
    padding: 0 1rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.type === "primary" ? "var(--secondary-one)" : "var(--primary-one)"};
    background-color: ${(props) => props.type === "primary" ? "var(--secondary-three)" : "var(--secondary-one)"};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25rem;

    :disabled {
        cursor: not-allowed;
    }
`;

export const Modal = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`

export const ModalCard = styled.div`
    width: 854px;
    height: fit-content;
    max-height: 80vh;
    min-height: 500px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: var(--secondary-one);
    padding: 1rem 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;

    h2 {
        width: 100%;
        text-align: start;
        color: #616161;
        padding-bottom: 1rem;
        border-bottom: 1px solid #616161;
    }
`

export const Check = styled.div`
    width: calc(100% - 2rem);
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    padding: 0 1rem;
`

export const CheckboxStyle = styled(Checkbox)`
    svg {
        color: var(--primary-one);
    }
`;