import React, { useState } from "react";
import styled from "styled-components";

import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
	AccountCircleRounded,
	ClassRounded,
	ExitToAppRounded,
	PersonRounded,
} from "@material-ui/icons";
import Text from "../../../common/Text";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../../../redux/actions";
import SettingsIcon from '@material-ui/icons/Settings';

const UserMenu = () => {
	let history = useHistory();
	// REDUX
	const dispatch = useDispatch();

	// STATE
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	// FUNCTIONS
	function handleOpen(e) {
		setAnchorEl(e.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	function handleAction(url) {
		history.push(url);
		handleClose();
	}

	function handleLogout() {
		dispatch(userActions.logoutUser());
	}

	// RETURN
	return (
		<>
			<FakeIconButton aria-controls="menu-appbar" onClick={handleOpen}>
				<AccountCircleRounded fontSize="large" />
				{/* <ExpandMoreRounded fontSize="small" color="secondary" /> */}
			</FakeIconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						marginTop: '4rem'
					},
				}}
			>
				<FakeMenuItem onClick={() => handleAction("/account")}>
					<PersonRounded color="error" fontSize="small" />
					<Text fontSize="1rem" fontWeight="400" color="error">
						Mi cuenta
					</Text>
				</FakeMenuItem>
				<FakeMenuItem onClick={() => handleAction("/licenses")}>
					<ClassRounded color="error" fontSize="small" />
					<Text fontSize="1rem" fontWeight="400" color="error">
						Plan de licencias
					</Text>
				</FakeMenuItem>
				<FakeMenuItem onClick={() => handleAction("/edit-layout")}>
					<SettingsIcon color="error" fontSize="small" />
					<Text fontSize="1rem" fontWeight="400" color="error">
						Configuración
					</Text>
				</FakeMenuItem>
				<Divider />
				<FakeMenuItem onClick={handleLogout}>
					<ExitToAppRounded color="error" fontSize="small" />
					<Text fontSize="1rem" fontWeight="400" color="error">
						Cerrar sesión
					</Text>
				</FakeMenuItem>
			</Menu>
		</>
	);
};

export default UserMenu;

const FakeIconButton = styled(IconButton)`
	border-radius: 5px;

	span {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;
		cursor: pointer;
	}
`;

const FakeMenuItem = styled(MenuItem)`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	padding: 1rem;

	:hover {
		svg,
		p {
			color: var(--primary-one);
		}
	}
`;
