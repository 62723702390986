import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../shared/common/Wrapper';
import Text from '../../shared/common/Text';

const UserPublicProfile = props => {
    const { public_profile } = props;

    const { street, city, country, birthday, current_job, company, profession, biography, work_experience, academic_training } = public_profile;

    const validateString = str => {
        return str && str !== 'false' && str !== 'null' && str !== 'undefined' ? str : '';
    };

    const USERDATA = [
        {
            id: 0,
            label: 'Dirección',
            value: `${validateString(street)}, 
                    ${validateString(city)}, 
                    ${validateString(country?.name)}`,
        },
        { id: 1, label: 'Cumpleaños', value: validateString(birthday) },
        {
            id: 2,
            label: 'Profesión',
            value: `${validateString(profession?.name)}, 
                    ${validateString(current_job?.name)}, 
                    ${validateString(company?.name)}`,
        },
        { id: 3, label: 'Experiencia', value: validateString(work_experience) },
        { id: 4, label: 'Formación', value: validateString(academic_training) },
        { id: 5, label: 'Biografía', value: validateString(biography) },
    ];

    return (
        <Wrapper padding="1.5rem">
            <Container>
                <SpanState>Estudiante</SpanState>
                {USERDATA.map(item => (
                    <WrapperInfo key={item.id}>
                        <Text fontSize="0.95rem" fontWeight="700" color="error">
                            {item.label}
                        </Text>
                        <DivInfo>
                            <Text fontSize="0.75rem" fontWeight="400">
                                {item.value}
                            </Text>
                        </DivInfo>
                    </WrapperInfo>
                ))}
            </Container>
        </Wrapper>
    );
};

export default UserPublicProfile;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    height: max-content;
    padding-top: 1.5rem;
`;

const WrapperInfo = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    height: 100%;
    justify-content: flex-start;
`;

const DivInfo = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    max-width: 250px;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
`;

const SpanState = styled.div`
    display: flex;
    position: absolute;
    top: 0rem;
    right: 0rem;
    font-size: 0.8rem;
    padding: 0.25rem 0.75rem;
    border-radius: 50px;
    width: max-content;
    color: #ffffff;
    background-color: #1e8065;
`;
