import React from 'react';
import styled from 'styled-components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import CheckedCourseCard from './CheckedCourseCard';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FieldArray } from 'formik';
import ModalResourceAggregation from './ModalResourceAggregation';

const DivResourceAggregationDinamic = props => {
    const { initialValues, confirmedResourceList } = props;

    //STATES
    const [open, setOpen] = useState(false);
    const [resourceChecked, setResourceChecked] = useState(initialValues);
    const [infoToGrid, setInfoToGrid] = useState(initialValues);

    //modal para agregar cursos al listado del módulo
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //FUNCTIONS
    const handleResourceAggregation = resources => {
        console.log('handleResourceAggregation resources :>> ', resources);
        setResourceChecked(resources);
        setInfoToGrid(resources);
        confirmedResourceList(resources);
        handleClose();
    };

    const handleResourceRemove = oa => {
        const filterList = infoToGrid.filter(
            item => item.id !== oa,
            //  || item.recurso_id !== oa
        );
        console.log('filterList :>> ', filterList);
        setResourceChecked(filterList);
        setInfoToGrid(filterList);
        confirmedResourceList(filterList);
    };

    return (
        <>
            <ResourcesListDefined>
                <AccordionSummaryFake expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <span>
                        {infoToGrid.length > 0 ? 'Recursos que conforman este contenido' : 'Elige los recursos que conformarán este contenido'}
                    </span>
                </AccordionSummaryFake>

                <AccordionDetailsFake>
                    <FieldArray name="list_oa">
                        <>
                            <RowButtons>
                                <AddCourseButton onClick={handleOpen}>
                                    <AddRoundedIcon />
                                    Añadir recursos
                                </AddCourseButton>
                            </RowButtons>

                            {infoToGrid ? (
                                <GridValues>
                                    {infoToGrid.map((item, index) => (
                                        <CheckedCourseCard
                                            key={index}
                                            value={item}
                                            handleItemRemove={handleResourceRemove}
                                            name={`recurso.${item.id}`}
                                            type="resource"
                                        />
                                    ))}
                                </GridValues>
                            ) : null}
                        </>
                    </FieldArray>
                </AccordionDetailsFake>
            </ResourcesListDefined>
            <ModalResourceAggregation
                open={open}
                handleClose={handleClose}
                handleResourceAggregation={handleResourceAggregation}
                changeChecked={resourceChecked}
            />
        </>
    );
};

export default DivResourceAggregationDinamic;

const ResourcesListDefined = styled(Accordion)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
    height: auto;
    margin: 1rem 0;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 25px;

    div .MuiOutlinedInput-root {
        width: 50%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    div.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
        width: 100%;
        position: relative;
        overflow: visible;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
`;

const AddCourseButton = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    gap: 0.5rem;
    width: max-content;
    background: var(--secondary-one);
    border: 1px solid var(--primary-one);
    color: var(--primary-one);
    border-radius: 3rem;
    font-size: 0.875rem;
    letter-spacing: 0.001em;
    line-height: 1.25rem;
    font-weight: 600;
`;

const GridValues = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100%-2rem);
    height: auto;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    gap: 1rem;
    padding: 1rem 0;
`;

const RowButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

const AccordionDetailsFake = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;

    .MuiFormControl-root.MuiFormControl-fullWidth.MuiInputBase-root {
        width: 50%;
        margin: auto;
    }
`;

const AccordionSummaryFake = styled(AccordionSummary)`
    &.MuiAccordionSummary-root {
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    span,
    svg {
        color: var(--primary-one);
        font-family: Montserrat;
        font-size: 0.95rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .MuiAccordionSummary-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
        box-shadow: none;
        border-radius: 20px;
        justify-content: space-between;
        align-content: center;
    }
    padding: 0;
    margin-bottom: 1rem;
`;
