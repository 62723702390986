import { Paper } from "@material-ui/core";
import styled from "styled-components";

export const ImportTableChips = styled.div`
    width: 90px;
    height: auto;
    padding: 0.5rem 0.3rem;
    border-radius: 100px;
    border: 1px solid;
    gap: 10px;
    font-size: 0.8rem;
    line-height: 1rem;
    background-color: ${p => (p.state === 200 ? '#EAFAF6' : '#FCE9E8')};
    color: ${p => (p.state === 200 ? '#1e8065' : '#CD2118')};
    border-color: ${p => (p.state === 200 ? '#1e8065' : '#CD2118')};
    justify-content: center;
    text-align: center;
`;

export const Detail = styled.div`
    font-size: 0.8rem;
    cursor: pointer;
`;

export const CustomPaper = styled(Paper)`
    border-radius: 20px !important;
`;

