import React from "react";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const Snack = (props) => {
	const { open, handleClose, severity, color } = props;

	// RETURN
	return (
		<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
			<MuiAlert
				elevation={6}
				variant="filled"
				onClose={handleClose}
				severity={severity}
				style={{ backgroundColor: color, color: "#ffffff", fontWeight: "bold" }}
			>
				{props.children}
			</MuiAlert>
		</Snackbar>
	);
};

export default Snack;
