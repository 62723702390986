import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import styled from 'styled-components';
import DefaultTabs from '../../shared/common/DefaultTabs';
import Header from '../../shared/layout/header/Header';
import Layout from '../../shared/layout/Layout';
import TabAlabRoutes from '../../components/content/TabAlabRoutes';
import TabCustomRoutes from '../../components/content-creation/TabCustomRoutes';


function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

const RoutesPage = () => {
    const user = useSelector(state => state.user.user);
    const params = useQueryParams();
    const tabQuery = params.get('tab');

    // STATE
    const [tabValue, setTabValue] = useState(1);  //por defecto rutas de acropolis no customizadas

    const tabOptions = [
        { id: 0, label: 'Rutas Empresa' },  //customizables
        { id: 1, label: 'Rutas Acrópolis' }
    ];

    //EFECTS
    useEffect(() => {
        if (tabQuery) {
            setTabValue( tabQuery === 'rutas_aden' ? 1  //TabAlabRoutes
                    : tabQuery === 'rutas_empresa' ? 0 : null  //TabCustomRoutes customizables
            );
        }
    }, [tabQuery]);

    // FUNCTIONS
    function handleTabValue(e, newValue) {
        setTabValue(newValue);
    }

    function getTabOption(option) {
        switch (option) {
            case 0:
                return <TabCustomRoutes user={user}/>;
            case 1:
                return <TabAlabRoutes user={user}/>;
            default:
                return null;
        }
    }

    return (
        <Layout header={<Header route="Rutas Formativas"></Header>}>
            <Container>
                <DefaultTabs 
                tabValue={tabValue} 
                handleTabValue={handleTabValue} 
                tabOptions={tabOptions} 
                paddingleft="2rem"/>

            </Container>
            {getTabOption(tabValue)}
        </Layout>
    );
}

export default RoutesPage; 

const Container = styled.div`
    display: flex;
    width: calc(100% - 70px - 2rem);
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 0;
    position: absolute;
    left: 70px;
    top: 140px;
    z-index: 9;
    border-top: 1px solid #f7f7f7;
    padding: 0 1rem;
`;
