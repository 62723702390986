import React from "react";
import styled from "styled-components";
import Layout from "../shared/layout/Layout";

import CurrentLicense from "../components/licenses/CurrentLicense";
import Header from "../shared/layout/header/Header";

const Licenses = () => {
	return (
		<Layout header={<Header route="Plan de licencias" />}>
			<Container>
				<CurrentLicense />
			</Container>
		</Layout>
	);
};

export default Licenses;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;
