import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";

export const AddUserImage = styled.img`
	width: 120px;
	height: auto;
	margin-top: 1rem;
`;

export const AddUserTextField = styled(TextField)`
	width: 100%;
`;

export const AddUserForm = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	width: 100%;
`;

export const FormAddUserButton = styled(Button)`
	margin-top: 0.5rem;
	width: 100%;
`;
