import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { analyticsActions} from "../../redux/actions";
import NoWrapper from "../../shared/common/NoWrapper";
import AnalyticsCoursesSkt from "../skeletons/AnalyticsCoursesSkt";
import AccordionProgress from "./common/AccordionProgress";
import CourseRadialBarChart from "./course/CourseRadialBarChart";
//import Default from "./course/Default";
import InscriptsStateCourse from "./course/InscriptsStateCourse";
import SelectCourse from "./course/SelectCourse";
//import TopStudents from "./course/TopStudents";
import UserPerCourseProgress from "./course/UserPerCourseProgress";

const Course = () => {
	//REDUX
	const dispatch = useDispatch();
	const ondemand_id = useSelector((state) => state.user.user.id);
	const {courseAdvancesByUsers, loadingCourseAdvancesByUsers} = useSelector((state) => state.analytics)
	//STATES
	const [selectedCourse, setSelectedCourse] = useState(courseAdvancesByUsers? courseAdvancesByUsers[0] : null);
	const [courseProgress, setCourseProgress] = useState(null);
	const [inscript, setInscript] = useState(null)
	//EFFECTS
	useEffect(() => {
		if (courseAdvancesByUsers === null) {
			dispatch(analyticsActions.getCourseAdvancesByUsersRequest(ondemand_id));
		}
	}, [courseAdvancesByUsers, dispatch, ondemand_id]);

	useEffect(() => {
        if(selectedCourse === undefined ){
			setSelectedCourse(false);
		}
    }, [selectedCourse]);

	useEffect(() => {
		if(selectedCourse === "all" ){
			setInscript(courseAdvancesByUsers)
		}else{
			setInscript(selectedCourse)
		}
	}, [selectedCourse, courseAdvancesByUsers]); 

	useEffect(() => {
        if(courseAdvancesByUsers!== null && selectedCourse !== null ){
            courseAdvancesByUsers.forEach(course => {
                if( course.repo_id  === selectedCourse.repo_id){
					setCourseProgress(course);
					setInscript(course);
                }
            } );
        } else{
			setCourseProgress(false);
		}
    }, [courseAdvancesByUsers, selectedCourse]);


	//FUNCTIONS
	const handleSelected = (select) => {
		setSelectedCourse(select)
	}
console.log('courseAdvancesByUsers', courseAdvancesByUsers)
	//RETURN
	return (
		<>
		{
			courseAdvancesByUsers === null ||  loadingCourseAdvancesByUsers ? 
				<AnalyticsCoursesSkt/>
			:
				<Container>
					<SelectCourse 
						courseAdvancesByUsers={courseAdvancesByUsers}
						handleSelected={handleSelected}
					/>
					{/* <Default number="40" text="20% de las licencias totales" textSize="1rem"/>
					<Default number="2560" text="horas" textSize="3rem" />
					<Default number="3.5" text="horas" textSize="3rem" /> */}
					{
						selectedCourse === null ? 
						<NoWrapper 
							title="Selecciona un curso" 
							columns="span 24"
							rows="span 12" 
							padding="15%"
						/>
						:	<>
								<InscriptsStateCourse
									selectedCourse={inscript}
									columns="span 14"
									title={selectedCourse !== "all"?
									`Estado de los inscriptos actuales en "${selectedCourse.course}"`
									: "Estado general de los inscriptos actuales"
									} 
								 />
								{/* <TopStudents selectedCourse={selectedCourse} /> */}
								<CourseRadialBarChart selectedCourse={selectedCourse}
																	/>
								{
									selectedCourse === "all" ? 
									<AccordionProgress 
										title="Progreso de cada curso, análisis por usuario"
										type="course"
										data={courseAdvancesByUsers}
										gtc="repeat(auto-fill, minmax(170px, 1%))"
									/>

									: 
									<UserPerCourseProgress 
										overflowY={true} 
										height={courseProgress ? `${courseProgress.users.length*65}px` : "100px"} 
										courseProgress={courseProgress}
									/>
								}
							</>
					}
				</Container>
		}
		</>
	);
};

export default Course;

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	grid-gap: 1.5rem;
`;