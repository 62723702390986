import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import styled from 'styled-components';
import TabCourses from '../../components/content-creation/TabCourses';
// import TabResource from '../../components/content-creation/TabResource';
// import TabEvaluations from '../../components/content-creation/TabEvaluations';
// import TabRoutes from '../../components/content-creation/TabRoutes';
import DefaultTabs from '../../shared/common/DefaultTabs';
import Header from '../../shared/layout/header/Header';
import Layout from '../../shared/layout/Layout';
import TabALabCourses from '../../components/content/TabALabCourses';

function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

const CoursesPage = () => {
    const user = useSelector(state => state.user.user);
    const params = useQueryParams();
    const tabQuery = params.get('tab');

    // STATE
    const [tabValue, setTabValue] = useState(0);

    const tabOptions = [
        { id: 0, label: 'Cursos Empresa' },
        { id: 1, label: 'Cursos Acrópolis' },
    ];

    console.log('user', user);

    const { habilita_cursos_aden, habilita_cursos_adicionales, habilita_cursos_empresa } = user;

    //EFECTS
    useEffect(() => {
        if (tabQuery) {
            setTabValue(tabQuery === 'cursos_aden' ? 1 : tabQuery === 'cursos_empresa' ? 0 : null);
        }
    }, [tabQuery]);

    // FUNCTIONS
    function handleTabValue(e, newValue) {
        setTabValue(newValue);
    }

    function getTabOption(option) {
        switch (option) {
            case 0:
                return <TabCourses user={user} />; //customizados
            case 1:
                return <TabALabCourses user={user} />; //aden
            default:
                return null;
        }
    }

    // if (!habilita_cursos_empresa) {  //solo habilita contenido adicional
    //     return (
    //         <Layout header={<Header route="Cursos Acrópolis"></Header>}>
    //             <TabALabCourses user={user} />;
    //         </Layout>
    //     );
    // }

    return (
        <Layout header={<Header route="Cursos"></Header>}>
            <Container>
                <DefaultTabs tabValue={tabValue} handleTabValue={handleTabValue} tabOptions={tabOptions} paddingleft="2rem" />
            </Container>
            {getTabOption(tabValue)}
        </Layout>
    );
};

export default CoursesPage;

const Container = styled.div`
    display: flex;
    width: calc(100% - 70px - 2rem);
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 0;
    position: absolute;
    left: 70px;
    top: 140px;
    z-index: 9;
    border-top: 1px solid #f7f7f7;
    padding: 0 1rem;
`;
