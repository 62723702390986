import React from 'react';
import { RadialBarChart, RadialBar, Legend, Tooltip } from 'recharts';
import DefaultWrapper from "../DefaultWrapper";

const CourseRadialBarChart = (props)=>{    

    const {selectedCourse} = props; 

    const data = [
        { name: selectedCourse.users[0]?.name, hours: 3, fill: '#8884d8'},
        { name: selectedCourse.users[1]?.name, hours: 3, fill: '#8dd1e1'},
        { name: selectedCourse.users[2]?.name, hours: 5,  fill: '#82ca9d'},
        { name: selectedCourse.users[3]?.name, hours: 6,fill: '#d0ed57'},
        { name: selectedCourse.users[4]?.name, hours: 8, fill: '#ffc658'},
    ];

    const style = {
        top: '80%',
        transform: 'translate(0, -50%)',
        lineHeight: '24px',
      };

    return (
        <DefaultWrapper
        // title="Top 5 alumnos más rápidos en terminar el curso"
        title="Alumnos maticulados en el curso"
        columns="span 10"
        >
            {/* <ResponsiveContainer width="500px" height="300px"> */}
                <RadialBarChart 
                    width={400}
                    height={300}
                    cx="50%" 
                    cy="50%" 
                    innerRadius="10%" 
                    outerRadius="80%" 
                    barSize={10} 
                    data={data}
                    startAngle={180} 
                    endAngle={0}
                >
                    <RadialBar
                        minAngle={15}
                        label={{ position: 'insideStart', fill: '#fffff' }}
                        background
                        clockWise
                        dataKey="hours"
                    />
                    <Legend 
                        iconSize={10} 
                        layout="vertical" 
                        verticalAlign="middle" 
                        align="center"
                        wrapperStyle={style} 
                    />
                    <Tooltip 
                  //  payload={[{ name: `${data.name}`, value: `${data.hours}`, unit: 'Horas' }] }
                    />
                </RadialBarChart>
            {/* </ResponsiveContainer> */}
      </DefaultWrapper>
    );
  }

export default CourseRadialBarChart;