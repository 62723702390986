import React from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import Text from "../../shared/common/Text";

const DefaultWrapper = (props) => {
	const { title, columns, rows, height } = props;

	// RETURN
	return (
		<Wrapper columns={columns} rows={rows} padding="1.5rem" height={height}>
			<Container>
				<HeaderWrapper>
					<Text fontSize="1rem" fontWeight="700" color="error">
						{title}
					</Text>
					{props.header}
				</HeaderWrapper>
				{props.children}
			</Container>
		</Wrapper>
	);
};

export default DefaultWrapper;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	justify-content: space-around;

`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
