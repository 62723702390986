import { MenuItem, Select } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const DefaultSorter = props => {
    const { onOrdersChange, order } = props;
    // console.log('order', order);
    function handleOrderChange(event) {
        event.preventDefault();
        onOrdersChange(event.target.value);
    }

    return (
        <Header>
            {' '}
            Ordenar Por
            <Select value={order} onChange={handleOrderChange}>
                <MenuItem value={'date-asc'}>Más Antiguo</MenuItem>
                <MenuItem value={'date-desc'}>Más Reciente</MenuItem>
            </Select>
        </Header>
    );
};

export default DefaultSorter;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: 0.8rem;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.001em;
    text-align: left;

    fieldset {
        border-color: none !important;
        border: none;
        font-size: 0.8rem;
    }

    .MuiSelect-select {
        font-size: 0.8rem;
        color: var(--primary-one);
        font-family: Montserrat;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.001em;
        text-align: left;
    }

    .MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
        background-color: red;
    }

    .MuiButtonBase-root-MuiMenuItem-root {
        font-size: 0.8rem;
        color: var(--primary-one);
        font-family: Montserrat;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.001em;
        text-align: left;
    }

    &.MuiButtonBase-root .MuiMenuItem-root .MuiMenuItem-gutters .MuiMenuItem-root {
        font-size: 0.8rem;
        color: var(--primary-one);
        font-family: Montserrat;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.001em;
        text-align: left;
    }

    &&.MuiButtonBase-root-MuiMenuItem-root {
        color: green !important;
    }
`;
