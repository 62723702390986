import React, { useState } from 'react';
import styled from 'styled-components';
import TabRouteDefinition from './TabRouteDefinition';
import TabRouteEstructure from './TabRouteEstructure';
import DefaultTabs from '../../shared/common/DefaultTabs';

const RouteForm = props => {
    const { initialValues, formik, handleSubmitFile, type, selectedImage, valueModules, addModule, deleteModule } = props;

    //STATE
    const [tabValue, setTabValue] = useState(0);

    const tabOptions = [
        { id: 0, label: '1. Sobre la ruta' },
        { id: 1, label: '2. Estructura del contenido' },
    ];


    // FUNCTIONS
    function handleTabValue(e, newValue) {
        setTabValue(newValue);
        // currentStep(newValue);
    }

    function getStepOption(option) {
        switch (option) {
            case 0:
                return (
                    <TabRouteDefinition initialValues={initialValues} type={type} handleSubmitFile={handleSubmitFile} selectedImage={selectedImage} />
                );
            case 1:
                return (
                    <TabRouteEstructure
                        initialValues={initialValues}
                        formik={formik}
                        type={type}
                        valueModules={valueModules}
                        addModule={addModule}
                        deleteModule={deleteModule}
                    />
                );
            default:
                return null;
        }
    }

    //RETURN
    return (
        <LayoutContainer>
            <Container>
                <DefaultTabs tabValue={tabValue} handleTabValue={handleTabValue} tabOptions={tabOptions} paddingleft="3rem" />
            </Container>
            {getStepOption(tabValue)}
        </LayoutContainer>
    );
};

export default RouteForm;

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    justify-content: space-between;
    align-items: start;
    position: absolute;
    left: -70px;
    top: -70px;
    /* z-index: 9; */
    border-top: 1px solid #f7f7f7;
    padding: 0 0 0 2rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    width: calc(100% + 70px + 4rem);
`;
