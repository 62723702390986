import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipantByOA, getParticipantBySubject } from '../../redux/api/participant.api';
import { participantActions } from '../../redux/actions';
import ModalForm from '../../shared/common/ModalForm';
import ModalSteps from './ModalSteps';

const EnrolledParticipants = props => {
    const {
        content,
        openModal,
        closeModal,
        type,
        success, //se llama la funcion cuando valida los estados exitosos en redux
    } = props;

    // REDUX
    const dispatch = useDispatch();
    const ondemand_id = useSelector(state => state.user.user.id);

    const [step, setStep] = useState(0);
    const [obligatory, setObligatory] = useState(false);
    const [confirmedItems, setConfirmedItems] = useState(null);
    const [participantsEnrolled, setParticipantsEnrolled] = useState(false);
    const [listEnrolled, setListEnrolled] = useState(false);

    //EFFECTS
    useEffect(() => {
        type === 'course' ? getParticipants() : type === 'subject' && getParticipantsBySubject();
        //eslint-disable-next-line
    }, [type]);

    //lista de participantes previos (aparecen con el check seleccionado)
    useEffect(() => {
        if (participantsEnrolled.length > 0) {
            let list = [];
            participantsEnrolled.map(p =>
                list.push({
                    id: p.participante_id,
                }),
            );
            setListEnrolled(list);
        } else if (participantsEnrolled.length === 0) {
            // sin participantes previos
            setListEnrolled([]);
        } else {
            setListEnrolled(false);
        }
    }, [participantsEnrolled]);

    //FUNCTIONS
    const handleSubmit = () => {
        if (type === 'course') {
            handleSubmitByCourse();
        } else {
            handleSubmitBySubject();
        }
    };

    //OA
    const handleSubmitByCourse = async () => {
        try {
            dispatch(
                participantActions.assignCourseRequest({
                    checkedItems: confirmedItems,
                    ondemand_id,
                    course_id: content.id,
                    es_obligatorio: obligatory,
                }),
            );
            await success();
            // console.log('await success()');
            closeModal();
        } catch (error) {
            return {
                error,
            };
        }
    };

    //Asignatura
    const handleSubmitBySubject = async () => {
        try {
            dispatch(
                participantActions.assignSubjectRequest({
                    checkedItems: confirmedItems,
                    ondemand_id,
                    asignatura_id: content.id,
                    es_obligatorio: obligatory,
                }),
            );
            await success();
            // console.log('await success()');
            closeModal();
        } catch (error) {
            return {
                error,
            };
        }
    };

    //check en matriculación obligatoria
    const stateObligatory = () => {
        setObligatory(!obligatory);
    };
    //lista de participantes seleccionados para el segundo paso de confirmación
    const selectedItems = items => {
        setConfirmedItems(items);
    };
    //pasos en la matriculacion
    function nextStep() {
        setStep(step + 1);
    }
    function backStep() {
        setStep(step - 1);
    }

    //Participantes ya matriculados _ CURSOS
    const getParticipants = async () => {
        // console.log('getParticipants');
        try {
            const req = await getParticipantByOA({ ondemand_id: ondemand_id, oa_id: content.id });
            // console.log('req', req);
            req && setParticipantsEnrolled(req);
        } catch (error) {
            return {
                error,
            };
        }
    };

    //Participantes ya matriculados _ Asignaturas
    const getParticipantsBySubject = async () => {
        try {
            const req = await getParticipantBySubject({ ondemand_id: ondemand_id, asignatura_id: content.id });
            req && setParticipantsEnrolled(req);
        } catch (error) {
            return {
                error,
            };
        }
    };

    return (
        <>
            <ModalForm
                isOpen={openModal}
                handleClose={closeModal}
                banner={'var(--primary-one)'}
                title={
                    step === 0 && type === 'course'
                        ? 'Matriculación de alumnos'
                        : step === 0 && type === 'subject'
                        ? 'Asignación de Ruta Formativa'
                        : step === 1 && type === 'course'
                        ? 'Resumen de matriculación de alumnos'
                        : step === 1 && type === 'subject' && 'Resumen de asignación de Ruta Formativa'
                }>
                {participantsEnrolled.length >= 0 && listEnrolled.length >= 0 && (
                    <ModalSteps
                        step={step}
                        nextStep={nextStep}
                        backStep={backStep}
                        course={content}
                        type={type}
                        handleSubmit={handleSubmit}
                        handleClose={closeModal}
                        titleAssign={'Selecciona los alumnos que podrán acceder a'}
                        titleConfirm={'Alumnos con acceso a este contenido'}
                        stateObligatory={stateObligatory}
                        selectedItems={selectedItems}
                        prevParticipantsEnrolled={listEnrolled}
                    />
                )}
            </ModalForm>
        </>
    );
};

export default EnrolledParticipants;
