import axios from "axios";

//Cantidad de inscriptos en cada curso
export function getAnalyticsCourses(ondemand_id) {
	return axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/analytics/students/${ondemand_id}/courses`);
}
// Estado de actividad de Usuario: activo, pendiente y bloqueado, no hay estado inactivo (en odoo ese campo es un select)
export function getAnalyticsStatus(ondemand_id) {
	return axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/analytics/students/${ondemand_id}/status`);
}
// Conteo de inscriptos por curso: to_start / ongoing / finished  - Progreso por usuarios en dicho curso
export function getCourseAdvancesByUsers(ondemand_id) {
	return axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/analytics/progress/${ondemand_id}/courses`);
}
//Progreso por participante en cursos en los que figura matriculado 
export function getUserAdvanceByCourses(ondemand_id) {
	return axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/analytics/students/${ondemand_id}/progress`);
}
//Progreso por GRUPO de participantes en cursos 
export function getGroupAdvanceByCourses(ondemand_id) {
	return axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/analytics/${ondemand_id}/groups`);
}