import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { groupActions, participantActions } from "../../../redux/actions";

const useStats = () => {

    const dispatch = useDispatch();
    const { numbers: numbersUsers, loading: loadingUsers } = useSelector((state) => state.participant);
    const { numbers: numbersGroups, loading: loadingGroups } = useSelector((state) => state.group);
    const { content } = useSelector((state) => state.content);
    const { id } = useSelector((state) => state.user.user);

    const [tab, setTab] = useState(0)

    const numbersCourses = content?.length;

    const sections = [
        [
            {
                icon: "mdi:person-check",
                number: numbersUsers?.activos ?? 0,
                label: "Activos"
            },
            {
                icon: "mdi:person-clock",
                number: numbersUsers?.pendientes ?? 0,
                label: "Pendientes"
            },
            {
                icon: "mdi:person-block",
                number: numbersUsers?.bloqueados ?? 0,
                label: "Inactivos"
            }
        ],
        [
            {
                icon: "mdi:person-group",
                number: numbersGroups ?? 0,
                label: ""
            }
        ],
        [
            {
                icon: "ion:book",
                number: numbersCourses ?? 0,
                label: ""
            }
        ]
    ]

    const tabs = ["Usuarios", "Grupos", "Cursos"]

    useEffect(() => {
        if (!numbersUsers) {
            dispatch(participantActions.getNumberOfParticipantsRequest(id));
        }
    }, [id, numbersUsers, dispatch]);

    useEffect(() => {
		if (!numbersGroups) {
			dispatch(groupActions.getNumberOfGroupsRequest(id));
		}
	}, [id, numbersGroups, dispatch]);

    

    return {
        sections,
        loadingUsers,
        loadingGroups,
        tabs,
        tab,
        setTab
    }

}

export default useStats;