import React from "react";
import styled from "styled-components";

import NewUnit from "./NewUnit";
import UnitCard from "./UnitCard";

const GridUnits = ({ units, id }) => {
	return (
		<Container>
			<NewUnit id={id} />
			{units.map((unit, index) => (
				<UnitCard unit={unit} key={index} index={index} />
			))}
		</Container>
	);
};

export default GridUnits;

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 1rem;
`;
