import React, { useEffect, useState } from 'react';
import Layout from '../../shared/layout/Layout';
import Header from '../../shared/layout/header/Header';
import styled from 'styled-components';
import MessageModal from '../../shared/common/MessageModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ResourceForm from './components/ResourceForm';

const NewResource = () => {
    let history = useHistory();

    const [resourceCreated, setResourceCreated] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);

    useEffect(() => {
        if (!!resourceCreated) {
            setMessageOpen(true);
        }
    }, [resourceCreated]);

    const handleLoading = (boolean) => {
        console.log('handleLoading :>> ', boolean);
        setIsLoading(boolean);
    };

    const handleState = art => {
        console.log('art :>> ', art);
        setResourceCreated(art);
    };

    function handleClose() {
        setMessageOpen(false);
        history.push(`/resources?tab=recursos_empresa`);
    }

    return (
        <>
            <Layout header={<Header route="Creación de nuevo recurso" />}>
                <HeaderWrapper>
                    <ResourceForm customResourceToEdit={null} handleState={handleState} handleLoading={handleLoading} />
                </HeaderWrapper>
            </Layout>
            <MessageModal 
                isOpen={isLoading} 
                message="" 
                image="/rediseño_2024/Loading.gif" 
                subtitle="Espera unos segundos mientras se realiza la creación del recurso..." 
                withoutButton={true}
            />
            <MessageModal 
                isOpen={messageOpen} 
                handleClose={handleClose} 
                message="¡La creación del recurso ha sido exitosa!" 
                image="/rediseño_2024/accion_exitosa.png" 
                subtitle="" 
            />
        </>
    );
};

export default NewResource;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 2rem);
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
`;
