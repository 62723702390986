import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAllCategoriesByCompany, getStrapiCompany } from '../../redux/api/blog';
import InputIcon from '../../shared/common/InputIcon';
// import ActionButton from './ActionButton';
import NoResultsFound from '../../shared/common/NoResultsFound';
import TableOfCategories from './TableOfCategories';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
// import { Button } from '@material-ui/core';
import CategoryModal from './CategoryModal';

const TabCategories = props => {
    const { user } = props;

    const headCells = [
        { id: 'id', label: 'ID' },
        { id: 'name', label: 'NOMBRE' },
        { id: 'slug', label: 'SLUG' },
        { id: 'blog_articles', label: 'ARTICULOS ASIGNADOS' },
        { id: 0, label: ' ' },
    ];

    // STATES
    const [strapiId, setStrapiId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [search, setSearch] = useState('');
    const [categoriesFiltered, setCategoriesFiltered] = useState([]);
    const [action, setAction] = useState(null);
    const [categoryToEdit, setcategoryToEdit] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [contentModal, setContentModal] = useState(null);

    // EFFECTS
    useEffect(() => {
        if (strapiId === null) {
            getStrapiId(user.id);
        }
    }, []);

    //lista de categorìas existentes asociadas a la empresa
    useEffect(() => {
        if (categories.length === 0) {
            getCategories(user.id); //las categorias las trae por user.id, pero se deben generar y editar por strapi_id
        }
    }, []);

    useEffect(() => {
        getCategories(user.id); //actualizar categorias ante cambios en el form de alta/edicion de categ
    }, [modalOpen]);

    useEffect(() => {
        if (search.length !== 0) {
            console.log('searchArticles');
            searchCategories(categories, search);
        } else {
            setCategoriesFiltered(null);
            setTimeout(() => {}, 500);
            getCategories(user.id);
        }
    }, [search]);

    // FUNCTIONS
    const getStrapiId = async id => {
        let req = await getStrapiCompany(id);
        if (!!req) {
            setStrapiId(req.data[0].id);
        }
    };

    const getCategories = async id => {
        let req = await getAllCategoriesByCompany(id);
        if (!!req) {
            setCategories(req.data);
        }
    };

    const addCategory = () => {
        setModalOpen(true);
        setAction('add');
        setContentModal({
            title: 'Crear categoría',
            category: '',
        });
        getCategories(user.id);
    };

    const editCategory = cat => {
        const categorySelected = categories.filter(category => category.id === cat);
        setcategoryToEdit(categorySelected[0]);
        setModalOpen(true);
        setAction('update');
        setContentModal({
            title: 'Editar categoría',
            category: categorySelected[0],
        });
    };

    const deleteCategory = () => {
        setModalOpen(true);
        setAction('delete');
        setContentModal({
            title: 'Eliminar categoría',
            category: '',
        });
    };

    const handleClose = () => {
        setModalOpen(false);
        getCategories(user.id);
    };

    function handleSearch(e) {
        setSearch(e.target.value);
    }

    const searchCategories = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (item.attributes?.name?.toLowerCase().includes(search.toLowerCase())) {
                newArray.push(item);
            }
        });
        setCategoriesFiltered(newArray.length === 0 ? null : newArray);
    };

    return (
        <>
            <HeaderWrapper>
                <InputIcon value={search} onChange={handleSearch} />
                <AddButton bgc="var(--primary-one)" textColor="#ffff" onClick={addCategory}>
                    <AddRoundedIcon /> Añadir Categoría
                </AddButton>
            </HeaderWrapper>
            {search.length === 0 ? (
                categories === null ? (
                    <NoResultsFound title="No hay categorias ingresadas" />
                ) : (
                    <TableOfCategories rows={categories} headCells={headCells} editCategory={editCategory} deleteCategory={deleteCategory} />
                )
            ) : categoriesFiltered === null ? (
                <NoResultsFound title="No se encuentran resultados para tu búsqueda" />
            ) : (
                <TableOfCategories rows={categoriesFiltered} headCells={headCells} editCategory={editCategory} deleteCategory={deleteCategory} />
            )}

            <CategoryModal
                isOpen={modalOpen}
                handleClose={handleClose}
                title={contentModal?.title}
                categoryToEdit={categoryToEdit}
                action={action}
                strapiId={strapiId}
            />
        </>
    );
};

export default TabCategories;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 8rem);
    padding: 1rem 4rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
`;

const AddButton = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.5rem;
    gap: 0.5rem;
    width: max-content;
    background: ${p => p.bgc};
    border-radius: 3rem;
    color: ${p => p.textColor};
    font-size: 0.875rem;
    letter-spacing: 0.001em;
    line-height: 1.25rem;
    font-weight: 600;
`;
