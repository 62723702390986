import { combineReducers } from "redux";

// REDUCERS
import { user } from "./user";
import { utils } from "./utils";
import { admin } from "./admin";
import { participant } from "./participant";
import { group } from "./group.reducer";
import { content } from "./content.reducer";
import { myContent } from "./myContent.reducer";
import { licenses } from "./licenses.reducer";
import {analytics} from "./analytics.reducer"
import { subject } from "./subject.reducer";

export const rootReducer = combineReducers({
	user: user,
	utils: utils,
	admin: admin,
	participant: participant,
	group: group,
	content: content,
	myContent: myContent,
	licenses: licenses,
	analytics: analytics,
	subject: subject
});
