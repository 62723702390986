import React from 'react';
import styled from 'styled-components';

const PreviewBanner = props => {
    const { bannerToView } = props;
    console.log('bannerToView', bannerToView);
    
    return (
        <WrapperAd
            onClick={() => {
                if (!!bannerToView?.link && !!bannerToView?.external_link) {
                    window.open(bannerToView.link);
                }
            }}
            img={
                !!bannerToView?.bg_image?.data?.url
                    ? `${process.env.REACT_APP_CMS_ADEN}${bannerToView.bg_image.data.url}`
                    : !!bannerToView?.bg_image_url
                    ? bannerToView?.bg_image_url
                    : 'https://st.depositphotos.com/1137733/2616/i/450/depositphotos_26160981-stock-photo-kid-shouting-through-megaphone.jpg'
            }>
            {bannerToView?.tag && <Tag>{bannerToView?.tag}</Tag>}
            <Body>
                <Title>{bannerToView?.title}</Title>
                <Description>
                    <p>{bannerToView?.description}</p>
                </Description>
            </Body>
        </WrapperAd>
    );
};

export default PreviewBanner;


const WrapperAd = styled.div`
    width: 250px;
    padding: 1rem;
    background-image: url(${p => p.img});
    background-position: center;
    background-size: cover;
    object-fit: content;
    height: fit-content;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    z-index: 0;
    position: relative;

    ::before {
        position: absolute;
        content: '';
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: 20px;
        left: 0;
        z-index: 1;
    }
`;

const Tag = styled.div`
    padding: 0.3rem 0.4rem;
    width: fit-content;
    color: #fff;
    background-color: var(--primary-one);
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    gap: 0.3rem;
    height: 120px;
`;

const Title = styled.h2`
    color: #fff;
    font-size: 20px;
    text-shadow: 0.1em 0.1em 0.2em black;
`;

const Description = styled.p`
    color: #fff;
    max-height: 60px;
    font-size: 15px;
    overflow: auto;
    text-shadow: 0.1em 0.1em 0.2em black;

    ::-webkit-scrollbar {
        width: 1px;
    }
`;
