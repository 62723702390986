import { Skeleton } from "@material-ui/lab";
import React from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";

const CurrentUserSkt = () => {
	return (
		<Container>
			<Wrapper width="100%">
				<Skeleton variant="rect" animation="wave" width="100%" height={95} />
			</Wrapper>
			<Wrapper width="100%">
				<Skeleton variant="rect" animation="wave" width="100%" height={275} />
			</Wrapper>
			<Wrapper width="100%">
				<Skeleton variant="rect" animation="wave" width="100%" height={275} />
			</Wrapper>
		</Container>
	);
};

export default CurrentUserSkt;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;
