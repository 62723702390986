import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReadingForm from './forms/ReadingForm';
import EvaluationForm from './forms/evaluationForms/EvaluationForm';
import RadioGroupResources from './RadioGroupResources';
import NullForm from './forms/NullForm';
import { ContainerForm, ContainerResourceForm, IndicationTitle } from '../styled-components/forms.styled.components';
import AudioVisualForm from './forms/AudioVisualForm';

const ResourceForm = props => {
    const { handleState, handleLoading } = props;
    const ondemand_id = useSelector((state) => state.user.user.id);


    const resourcesOption = [
        { id: 0, label: 'Audiovisual' },
        { id: 1, label: 'De lectura' },
        { id: 2, label: 'De evaluación' },
    ];

    const [selectedType, setSelectedType] = useState(3);

    useEffect(() => {
        getStepOption(selectedType);
        //eslint-disable-next-line
    }, [selectedType]);

    const getStepOption = option => {
        switch (option) {
            case 0:
                return <AudioVisualForm ondemand_id={ondemand_id} handleState={handleState} handleLoading={handleLoading}/>;
            case 1:
                return <ReadingForm ondemand_id={ondemand_id}  handleState={handleState} handleLoading={handleLoading} />;
            case 2:
                return <EvaluationForm ondemand_id={ondemand_id}  handleState={handleState}  handleLoading={handleLoading}/>;
            case 3:
                return <NullForm />;
            default:
                return null;
        }
    };

    function handleTypeSelected(type) {
        setSelectedType(type);
    }

    //RETURN
    return (
        <ContainerResourceForm>
            <ContainerForm>
                <IndicationTitle>¿Qué tipo de recurso deseas crear?</IndicationTitle>
            </ContainerForm>
            <RadioGroupResources label={''} list={resourcesOption} resourceType={handleTypeSelected} />
            {getStepOption(Number(selectedType))}
        </ContainerResourceForm>
    );
};

export default ResourceForm;
