import React, { useState } from 'react';
import { createParticipantList } from '../../../redux/api/participant.api';
import { Typography } from '@mui/material';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import Text from '../../../shared/common/Text';
import CsvReader from '../../../shared/common/CsvReader';
import ImportTable from './ImportTable';
import MessageModal from '../../../shared/common/MessageModal';
import { ActionButton, ButtonWrapper, ContainerModalImport, Linear, LoadingPresentation, ManualButton, Subtitle, WrapperInfo, WrapperLinear } from '../styled-components/importUsers/modalImportUser.styled.components';

const rowsInitials = [
    {
        Nombre: '  ',
        Apellido: '  ',
        Correo: '  ',
        Contraseña: ' ',
        Estado: ' ',
    },
    {
        Nombre: '  ',
        Apellido: '  ',
        Correo: '  ',
        Contraseña: ' ',
        Estado: ' ',
    },
    {
        Nombre: '  ',
        Apellido: '  ',
        Correo: '  ',
        Contraseña: ' ',
        Estado: ' ',
    },
    {
        Nombre: '  ',
        Apellido: '  ',
        Correo: '  ',
        Contraseña: ' ',
        Estado: ' ',
    },
    {
        Nombre: '  ',
        Apellido: '  ',
        Correo: '  ',
        Contraseña: ' ',
        Estado: ' ',
    },
    {
        Nombre: '  ',
        Apellido: '  ',
        Correo: '  ',
        Contraseña: ' ',
        Estado: ' ',
    },
];

const columns = [
    { id: 'Nombre', label: 'Nombre', minWidth: 150 },
    { id: 'Apellido', label: 'Apellido', minWidth: 150 },
    { id: 'Correo', label: 'Correo', minWidth: 170 },
    { id: 'Contraseña', label: 'Contraseña', minWidth: 170 },
    { id: 'Estado', label: 'Estado de usuario', minWidth: 100, align: 'center' },
];

const columnsId = ['Nombre', 'Apellido', 'Correo', 'Contraseña'];

const ModalImportUsers = props => {
    const { ondemand_id, success, error } = props;
    const csvUrl =
        'https://docs.google.com/spreadsheets/d/e/2PACX-1vRR-WbzQqE93KdsS-3SQ5rV-w3qo0dBEi0bfRI4IZtPdBPjiDyqUMmlakiA-rF6arcwxcEvX4jZ9mKK/pub?output=ods';

    const manual = 'https://drive.google.com/file/d/1Fdufw4mm1Lnxk2Ir9bqvqnat2JGivO6N/view?usp=sharing';
    //STATES
    const [nameFile, setNameFile] = useState(null);
    const [sizeFile, setSizeFile] = useState(null);
    const [fileCsv, setFileCsv] = useState(null);
    const [rows, setRows] = useState([]);
    const [usersImported, setUsersImported] = useState([]); //leidos desde el csv, impresos en la tabla del modal hasta columna
    const [openLoadingModal, setOpenLoadingModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [reason, setReason] = useState(null);
    const [finish, setFinish] = useState(false);

    //FUNCTIONS
    //upload, read , delete csv
    function nameCsv(fileName) {
        setNameFile(fileName);
    }
    function sizeCsv(fileSize) {
        setSizeFile((fileSize / 1048576).toFixed(5));
    }
    function uploadCsvCompleted(list) {
        setRows(list);
    }
    function handleDeleteCsv() {
        setRows([]);
    }

    //seteo de archivo para post createParticipantList
    const data_participantes_csv = file => {
        setFileCsv(file);
    };

    //Posibles respuestas
    // 400: El archivo proporcionado no es un csv valido
    //     *archivo no tiene el formato correcto ->  ['Nombre', 'Apellido', 'Correo', 'Contraseña'] tiene que tener ese formato
    //     *no se permiten valores nulos -> en el csv no pueden haber campos vacios.
    //     *limite de 15 contactos exedido -> esta harcodeado un limite de 15 usuarios por csv.
    //503 o 504 : error desde odoo, no pudo ejecutar el metodo, algo inesperado salio mal.
    //200 en el intento de creacion de cada uno de los usuarios: key de json 'error' con los mensajes
    // ('Se ha alcanzado el límite máximo de licencias')
    // ('Hubo un problema al crear participante')
    // ('Hubo un problema al generar el participante.')
    // ('Participante ya existe en el AcropolisLAB: #{}.') aca te pasa el id del participante que encontro

    const handleIndividualUserImport = async () => {
        setOpenLoadingModal(true);
        const formData = new FormData();
        formData.append('data_participantes_csv', fileCsv || '');
        let response = await createParticipantList(formData, ondemand_id);
        // itera cada participante y responde por cada caso
        // o un error general en el adjunto/ejecución del método
        setUsersImported(response);
        setFinish(true);
        setOpenLoadingModal(false);
    };

    //Visualizacion del error de creacion masiva
    const handleOpenViewModal = async value => {
        if (value.length > 0) {
            await messageToErrorModal(value);
            setOpenViewModal(true);
        } else {
            error();
        }
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
        setReason(null);
    };

    // const handleCloseLoadingModal = () => {
    //     setOpenLoadingModal(false);
    // };

    //Configuracion de texto del error
    const messageToErrorModal = response => {
        switch (response) {
            case response.includes('ya existe') && response:
                return setReason('Este participante ya existe en este AcropolisLAB');

            case response.includes('límite máximo de licencias') && response:
                return setReason('No es posible crear un nuevo usuario dado que se ha alcanzado el límite máximo de licencias.');

            case response.includes('problema al crear') && response:
                return setReason('Hubo un problema al crear participante, por favor contactar al Ejecutivo de cuenta.');

            case response.includes('problema al generar') && response:
                return setReason('Hubo un problema al generar el participante, por favor contactar al Ejecutivo de cuenta.');

            case response === 400 && response:
                return setReason('El archivo proporcionado no es un csv valido');

            default:
                return setReason('Por favor contactar al Ejecutivo de cuenta');
        }
    };

    //clic en finalizar _ todos los usarios fueron relacionados con el estado del ep
    //se recargará la pagina con los nuevos usuarios ya creados
    function handleFinish() {
        success();
    }

    return (
        <>
            <ContainerModalImport>
                <Subtitle>
                    <Text fontSize="1rem" fontWeight="400" fontcolor="#616161">
                        1. Descarga la{' '}
                    </Text>
                    <Text fontSize="1rem" fontWeight="700" fontcolor="#616161">
                        plantilla de importación de hasta 15 usuarios.
                    </Text>
                </Subtitle>

                <ActionButton type="button" variant="contained" color="primary" size="small" onClick={() => window.open(csvUrl)}>
                    Descargar plantilla
                </ActionButton>

                <Subtitle>
                    <Text fontSize="1rem" fontWeight="700" fontcolor="#616161">
                        2. Carga el fichero{' '}
                    </Text>
                    <Text fontSize="1rem" fontWeight="400" fontcolor="#616161">
                        seleccionando el archivo que descargaste, ya completo y en{' '}
                    </Text>
                    <Text fontSize="1rem" fontWeight="700" fontcolor="#616161">
                        formato CSV.{' '}
                    </Text>
                    <Text fontSize="1rem" fontWeight="400" fontcolor="#616161">
                        Luego de ver completa la siguiente tabla haz click en el botón inferior de{' '}
                    </Text>
                    <Text fontSize="1rem" fontWeight="700" fontcolor="#616161">
                        importación de usuarios.{' '}
                    </Text>
                </Subtitle>
                {rows.length === 0 ? (
                    <CsvReader
                        columnsId={columnsId}
                        rowsCompleted={uploadCsvCompleted}
                        nameCsv={nameCsv}
                        sizeCsv={sizeCsv}
                        data_participantes_csv={data_participantes_csv}
                    />
                ) : (
                    rows.length > 0 && (
                        <LoadingPresentation>
                            <WrapperInfo>
                                <div>
                                    <Text fontSize="0.7rem" fontWeight="600" fontcolor="#000000">
                                        {nameFile}
                                    </Text>
                                    <Text fontSize="0.7rem" fontWeight="400" fontcolor="var(--secondary-four)">{`${sizeFile} MB`}</Text>
                                </div>
                                <DeleteRoundedIcon onClick={handleDeleteCsv} />
                            </WrapperInfo>
                            <WrapperLinear scale={100}>
                                <Linear value={100} color="#35D0A5" />
                                <Typography variant="body2" color="#35D0A5">
                                    100%
                                </Typography>
                            </WrapperLinear>
                        </LoadingPresentation>
                    )
                )}

                {usersImported.length !== 0 ? (
                    <ImportTable columns={columns} rows={rows} usersImported={usersImported} handleViewError={handleOpenViewModal} />
                ) : rows.length > 0 ? (
                    <ImportTable columns={columns} rows={rows} />
                ) : (
                    <ImportTable columns={columns} rows={rowsInitials} />
                )}

                <ButtonWrapper>
                    <ManualButton type="button" variant="contained" color="primary" size="small" onClick={() => window.open(manual)}>
                        <ImportContactsRoundedIcon />
                        Ver Manual Importación de Usuarios
                    </ManualButton>
                    {rows.length > 0 && !finish ? (
                        <ActionButton
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleIndividualUserImport}
                            disabled={rows.length === 0}>
                            Importar usuarios
                        </ActionButton>
                    ) : (
                        !!finish && (
                            <ActionButton type="button" variant="contained" color="primary" size="small" onClick={handleFinish}>
                                Finalizar
                            </ActionButton>
                        )
                    )}
                    {/* definir notificacion al admin sobre errores en la importación de usuarios y respectivos motivos */}
                </ButtonWrapper>
            </ContainerModalImport>

            {openViewModal === true && (
                <MessageModal
                    isOpen={openViewModal}
                    handleClose={handleCloseViewModal}
                    message={''}
                    image={'/images/imagen_error.png'}
                    subtitle={reason}
                />
            )}

            {openLoadingModal === true && (
                <MessageModal
                    isOpen={openLoadingModal}
                    // handleClose={handleCloseLoadingModal}
                    message={'Importando alumnos'}
                    image={'/images/importando_alumnos.svg'}
                    subtitle={'Por favor espera mientras finaliza el proceso de importación de usuarios.'}
                />
            )}
        </>
    );
};

export default ModalImportUsers;