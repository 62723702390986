import React from "react";
import DefaultTable from "../../../shared/common/DefaultTable";


const headCells = [
	{ id: "nombre", label: "USUARIO" },
	{ id: "email", label: "CORREO ELECTRÓNICO" },
	{ id: "fecha_alta", label: "FECHA DE ALTA" },
	{ id: "ultimo_acceso", label: "ÚLTIMO ACCESO" },
	//{ id: "cursos_asignados", label: "CURSOS ASIGNADOS" },
	{ id: "estado", label: "ESTADO USUARIO" },
];

const TableUsers = (props) => {
	const { participants, group } = props;
	// RETURN
	return (
		<>
			<DefaultTable
				rows={participants}
				headCells={headCells}
				type="users"
				group={group}
			/>
		</>
	);
};

export default TableUsers;
