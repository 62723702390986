import { Form, Formik } from 'formik';
import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { createCustomRoute } from '../../redux/api/content-creation.api';
import Loading from '../../shared/common/Loading';
import { useSelector } from 'react-redux';
import Snack from '../../shared/common/Snack';
import RouteForm from './RouteForm';
import { useFormik } from 'formik';
import { RouteFormSchema } from '../../utils/schemas';
import MessageModal from '../blog/MessageModal';

const NewRouteForm = props => {
    const { handleState } = props;

    const ondemand_id = useSelector(state => state.user.user.id);

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            image: '',
            modules: [
                {
                    name_module: '',
                    key_module: 0,
                    list_oa: [],
                },
            ],
        },
        validationSchema: RouteFormSchema,
        onSubmit: values => {
            console.log('Formulario final:', values);
            handleSubmit();
        },
    });

    //STATES
    const [newCourse, setNewCourse] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null); //img seleccionada, para navegación entre ambos pasos del form
    const [errorOpen, setErrorOpen] = useState(false);

    //mensaje para recordar completar todos los campos del form
    const [messageOpen, setMessageOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (newCourse !== null) {
            handleState(newCourse);
        }
    }, [newCourse]);

    // FUNCTIONS

    function handleCloseError() {
        setErrorOpen(false);
    }

    //imagen adjunta y convertida a base 64
    const handleSubmitFile = async file => {
        // console.log('handleSubmitFile', file);
        if (!file) {
            console.log('no hay archivo adjunto');
        }
        setSelectedImage(file);
        const fileTransform = await toBase64(file);
        const splitFileTransform = fileTransform.split(',');
        const base64_texto = splitFileTransform[1];
        // console.log('fileTransform', fileTransform);
        if (fileTransform) {
            setFile(base64_texto);
        }
    };

    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    const handleSubmit = async values => {
        const lastIndexModule = values.modules.length ? Math.max(...values.modules.map(q => q.key_module)) : 0;
        const validateOa = values.modules[lastIndexModule].list_oa;
        if (validateOa.length === 0) {
            alert('Por favor seleccionar los Objetos de Aprendizaje que conforman el último módulo');
        } else {
            //enviar solo el id de OA de cada módulo
            const modulesList = values.modules.map(item => ({
                name_module: item.name_module,
                list_oa: item.list_oa.map(subItem => subItem.id),
            }));
            const data = {
                // published: false,
                acropolislab_id: ondemand_id,
                name: values.title,
                description: values.description,
                author: '',
                duration: 0,
                duration_unit: '',
                tags: [],
                modules: modulesList,
                image: file,
            };
            console.log('handleSubmit - data', data);
            let response = await createCustomRoute(ondemand_id, data);
            console.log('response', response);
            if (response.error) {
                setErrorOpen(true);
                <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                    El curso no pudo enviarse, favor de contactarse con el administrador.
                </Snack>;
            } else {
                setNewCourse(response);
                handleState(response);
            }
        }
    };

    //test useformik
    const addModule = formik => {
        const lastIndexModule = formik.values.modules.length ? Math.max(...formik.values.modules.map(q => q.key_module)) : 0;
        const validateOa = formik.values?.modules[lastIndexModule].list_oa;
        if (validateOa.length === 0) {
            alert('Por favor seleccionar los Objetos de Aprendizaje que conforman el módulo');
        } else {
            formik.setValues({
                ...formik.values,
                modules: [
                    ...formik.values.modules,
                    {
                        name_module: '',
                        key_module: lastIndexModule + 1,
                        list_oa: [],
                    },
                ],
            });
        }
    };

    const deleteModule = (formik, order) => {
        const nuevosModulos = formik.values.modules
            .filter(x => x.key_module !== order)
            .map((q, index) => ({
                ...q,
                key_module: index + 1,
            })); //reordena el array
        formik.setValues({
            ...formik.values,
            modules: nuevosModulos,
        });
    };

    const handleCompleted = () => {
        setMessageOpen(true);
    };

    function handleClose() {
        setMessageOpen(false);
    }

    // RETURN
    if (!formik.initialValues) {
        return <Loading />;
    }
    return (
        <>
            <Formik
                initialValues={formik.initialValues}
                onSubmit={values => {
                    console.log('values :>> ', values);
                    handleSubmit(values);
                }}
                validationSchema={RouteFormSchema}>
                {(formik, values, setValues) => (
                    <FormContainer>
                        <RouteForm
                            initialValues={formik.initialValues}
                            handleSubmitFile={handleSubmitFile}
                            valueModules={formik?.values?.modules}
                            type="new-route"
                            selectedImage={selectedImage}
                            addModule={addModule}
                            deleteModule={deleteModule}
                            formik={formik}
                        />

                        <ButtonWrapper>
                            <WrapperButtons>
                                {file === null ||
                                formik.values.name === '' ||
                                formik.values.description === '' ||
                                formik.values.modules.length === 0 ? (
                                    <ButtonToComplete variant="contained" type="button" onClick={handleCompleted}>
                                        {' '}
                                        Guardar
                                    </ButtonToComplete>
                                ) : (
                                    <ButtonToSave variant="contained" type="submit">
                                        {' '}
                                        Guardar
                                    </ButtonToSave>
                                )}
                            </WrapperButtons>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¡Ups!"
                image="/images/ups_form_ruta.svg"
                subtitle="Recuerda que debes completar
                todos los pasos, antes de guardar
                la nueva ruta formativa."
            />
        </>
    );
};

export default NewRouteForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;

const ButtonToComplete = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: #c4c4c4;
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;
