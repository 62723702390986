import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Licenses from "./general/Licenses";
import StudentsPerCourse from "./general/StudentsPerCourse";
import Users from "./general/Users";
import UsersRanking from "./general/UsersRanking";
import GroupsRanking from "./general/GroupsRanking";
import CoursesRanking from "./general/CoursesRanking";
import { useDispatch, useSelector } from "react-redux";
import AnalyticsSkt from "../skeletons/AnalyticsSkt";
import { analyticsActions, licensesActions } from "../../redux/actions";

const General = () => {

	//REDUX
	const dispatch = useDispatch()
	const ondemand_id = useSelector((state) => state.user.user.id);
	const {analyticsCourses, loadingAnalyticsCourses, analyticsStatus, loadingAnalyticsStatus} = useSelector((state) => state.analytics);
	const { licenses, loading} = useSelector((state) => state.licenses);

	//EFECTS
	useEffect(() => {
		if(analyticsCourses === null){
			dispatch(analyticsActions.getAnalyticsCoursesRequest(ondemand_id))
		}
	}, [analyticsCourses,ondemand_id,dispatch])

	useEffect(() => {
		if (licenses === null) {
			dispatch(licensesActions.getLicensesRequest(ondemand_id));
		}
	}, [ondemand_id, licenses, dispatch]);

	useEffect(() => {
		if(analyticsStatus === null){
			dispatch(analyticsActions.getAnalyticsStatusRequest(ondemand_id))
		}
	}, [analyticsStatus, ondemand_id, dispatch])


	return (
		<>
		{
			analyticsCourses === null || 
			analyticsStatus === null ||
			Licenses === null ||
			loadingAnalyticsCourses || 
			loadingAnalyticsStatus ||
			loading ?
				<AnalyticsSkt/>
			:
			<Container>
					<StudentsPerCourseFake  analyticsCourses={analyticsCourses} />
					<LicensesFake licenses={licenses} />
					<UsersFake analyticsStatus={analyticsStatus} />
					{/* <UsersRankingFake />
					<GroupsRankingFake />
					<CoursesRankingFake />  */}
			</Container>
		}
		</>
	);
};

export default General;

const Container = styled.div`
	display: grid;
	grid-template-areas:"studentspercourse 				  licenses"
						"studentspercourse   				 users"
						"usersranking groupsranking coursesranking";
	grid-template-columns: repeat(24, 1fr);
	grid-gap: 1.5rem;
	@media (max-width: 768px) {
		grid-template-columns: auto;
		grid-template-areas:" studentspercourse  "
		    				" licenses           "
							" users              "
							" usersranking       "
							" groupsranking      " 
							" coursesranking     ";
		row-gap: 2.5rem;
	}
`;

const StudentsPerCourseFake = styled(StudentsPerCourse)`
	grid-area:studentspercourse
`;

const LicensesFake = styled(Licenses)`
	grid-area:licenses
`;

const UsersFake = styled(Users)`
	grid-area:users
`;
const UsersRankingFake = styled(UsersRanking)`
	grid-area:usersranking
`;
const GroupsRankingFake = styled(GroupsRanking)`
	grid-area:groupsranking
`;

const CoursesRankingFake = styled(CoursesRanking)`
	grid-area:coursesranking
`;
