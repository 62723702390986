import React, { useEffect, useState } from 'react'
import Text from '../../../shared/common/Text';
import styled from "styled-components";
import CircularProgressBox from './CircularProgress';


const StatisticsWrapper = (props) => {
    const {data, type }= props;

    const [array, setArray] = useState([])
	const [totalValue, setTotalValue] = useState(null)
    const [totalProgress, setTotalProgress] = useState(null)


    //EFECTS
	useEffect(() => {
        if(type === "user" ){
            transformObject(data.courses)
        }if (type === "course"){
            transformObject(data.users)
        }if (type === "group"){
            transformObject(data.users)
        }if (type === "allGroups"){
            transformObject(data.users)
        // }else{
        //     console.log("no_data");
        }
    }, [data, type])

  const transformObject = async(object)=> {
    let result=[] ;
    for (const key in object) {
        let obj = {};
        obj["key"] = key;
        obj["value"] = object[key];
        result.push(obj)

    }
    setTotalProgress((result.reduce((sum, value) => (typeof value.value.progress == "number" && (value.value.progress <= 100 )? sum + value.value.progress : sum + 100), 0)));
    const totalV = result.length; //total de cursos asignados
    let finishedValue = 0;
    let toStartValue = 0;
    let onGoing=0;

    await result.forEach(value => {
        if(value.value.progress === 0){
            return toStartValue++
        } if(value.value.total !== 0 && value.value.progress >= 100){
            return finishedValue++
        }else{
            return onGoing++
        }
    })
    setTotalValue(totalV)
    setArray([
        {'key':'to_start',
        'value': toStartValue},
        {'key':'ongoing' ,
        'value': onGoing },
        {'key':'finished' ,
        'value': finishedValue},
    ])
}

    return (
        <Container>
                {
                    type === "user" ?
                    <TextWrapper>
                        <Text fontSize="0.7rem" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px">POR INICIAR 
                            <br/>
                            <NumberWrapper>
                                <Text fontSize="1rem" fontWeight="900" fontcolor="#000000"> {array.length!== 0 ? array[0].value : 0} </Text>
                            </NumberWrapper>
                        </Text>
                        <Text fontSize="0.7rem" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px" >EN CURSO
                            <br/>
                            <NumberWrapper>
                                <Text fontSize="1rem" fontWeight="900" fontcolor="#000000"> {array.length!== 0 ? array[1].value : 0} </Text>
                            </NumberWrapper>
                        </Text>
                        <Text fontSize="0.7rem" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px" >FINALIZADO
                            <br/>
                            <NumberWrapper>
                                <Text fontSize="1rem" fontWeight="900" fontcolor="#000000"> {array.length!== 0 ? array[2].value : 0} </Text>
                            </NumberWrapper>
                        </Text>
                        <CircularProgressBox percentage={totalValue!==0 ? (totalProgress/totalValue).toFixed(0) : 0}/>
                    </TextWrapper>

                    : type === "group" ?
                        <TextWrapper>
                            <Text fontSize="0.7rem" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px" >MATRICULADOS
                                <br/>
                                <NumberWrapper>
                                    <Text fontSize="1rem" fontWeight="900" fontcolor="#000000"> {totalValue ? totalValue : 0} </Text>
                                </NumberWrapper>
                            </Text>
                            <CircularProgressBox percentage={totalValue!==0 ? (totalProgress/totalValue).toFixed(0) : 0}/>
                        </TextWrapper>

                    : type === "allGroups" ?
                        <TextWrapper>
                            <Text fontSize="0.7rem" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px" >MATRICULADOS
                                <br/>
                                <NumberWrapper>
                                    <Text fontSize="1rem" fontWeight="900" fontcolor="#000000"> {totalValue ? totalValue : 0} </Text>
                                </NumberWrapper>
                            </Text>
                            <Text fontSize="0.7rem" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px" >CURSO_ID  
                                <br/>
                                <NumberWrapper>
                                    <Text fontSize="1rem" fontWeight="900" fontcolor="#000000"> {data.repo_id ? data.repo_id : 0} </Text>
                                </NumberWrapper>
                            </Text>
                            <CircularProgressBox percentage={totalValue!==0 ? (totalProgress/totalValue).toFixed(0) : 0}/>
                        </TextWrapper>

                    : type === "course" ?
                        <TextWrapper>
                            <Text fontSize="0.7rem" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px" >MATRICULADOS
                                <br/>
                                <NumberWrapper>
                                    <Text fontSize="1rem" fontWeight="900" fontcolor="#000000"> {totalValue ? totalValue : 0} </Text>
                                </NumberWrapper>
                            </Text>
                            <CircularProgressBox percentage={totalValue!==0 ? (totalProgress/totalValue).toFixed(0) : 0}/>
                        </TextWrapper>

                    : null
                }
        </Container>
    )
}

export default StatisticsWrapper;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	row-gap: 1rem;
  justify-content: end;
  text-align: end;
  justify-self: end;

`;

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;
const NumberWrapper = styled.div`
	display: flex;
	align-items: start;
	margin-right:-1px;
	column-gap: 0.5rem;
	justify-content:center; 
`;

