import * as types from "../types";

const actions = {};

// SIGN IN
actions.signInRequest = (user) => {
	return {
		type: types.SIGNIN_USER_REQUEST,
		payload: user,
	};
};

actions.signInSuccess = (user) => {
	return {
		type: types.SIGNIN_USER_SUCCESS,
		payload: user,
	};
};

actions.signInFail = (error) => {
	return {
		type: types.SIGNIN_USER_FAIL,
		payload: error,
	};
};

// GET USER
actions.getUserRequest = (user) => {
	return {
		type: types.GET_USER_REQUEST,
		payload: user,
	};
};

actions.getUserSuccess = (user) => {
	return {
		type: types.GET_USER_SUCCESS,
		payload: user,
	};
};

actions.getUserFail = (error) => {
	return {
		type: types.GET_USER_FAIL,
		payload: error,
	};
};

// LOGOUT
actions.logoutUser = () => {
	return {
		type: types.LOGOUT_USER,
	};
};

// GET COMPANY
actions.getCompanyRequest = (payload) => {
	return {
		type: types.GET_COMPANY_REQUEST,
		payload: payload,
	};
};

actions.getCompanySuccess = (company) => {
	return {
		type: types.GET_COMPANY_SUCCESS,
		payload: company,
	};
};

actions.getCompanyFail = (error) => {
	return {
		type: types.GET_COMPANY_FAIL,
		payload: error,
	};
};

// CHANGE PHOTO COMPANY
actions.photoCompanyRequest = (payload) => {
	return {
		type: types.PHOTO_COMPANY_REQUEST,
		payload: payload,
	};
};

actions.photoCompanySuccess = (photo) => {
	return {
		type: types.PHOTO_COMPANY_SUCCESS,
		payload: photo,
	};
};

actions.photoCompanyFail = (error) => {
	return {
		type: types.PHOTO_COMPANY_FAIL,
		payload: error,
	};
};

// USER SELECTED
actions.userSelected = (user) => {
	// console.log(user);
	return {
		type: types.USER_SELECTED,
		payload: user,
	};
};

// SET DATA
actions.setData = (payload) => {
	// console.log(user);
	return {
		type: types.SET_DATA,
		payload: payload,
	};
};

export { actions };
