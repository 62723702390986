import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';
import Wrapper from '../../shared/common/Wrapper';

const SubjectsSkt = () => {
    return (
        <Container>
            <HeaderWrapper>
                <Wrapper width="65%">
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={65} />
                </Wrapper>
                <Wrapper width="35%">
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={65} />
                </Wrapper>
            </HeaderWrapper>
            <HeaderWrapper>
                <Wrapper width="45%">
                    {' '}
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={300} />
                </Wrapper>
                <Wrapper width="45%">
                    {' '}
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={300} />
                </Wrapper>
            </HeaderWrapper>
			<HeaderWrapper>
                <Wrapper width="45%">
                    {' '}
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={300} />
                </Wrapper>
                <Wrapper width="45%">
                    {' '}
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={300} />
                </Wrapper>
            </HeaderWrapper>
        </Container>
    );
};

export default SubjectsSkt;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
	padding: 0;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1.5rem;
    background-color: transparent !important;
	padding: 0;
    div {
        background-color: transparent !important;
    }
`;
