import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LicensesSkt from "../../skeletons/LicensesSkt";
import Text from "../../../shared/common/Text";
import DefaultWrapper from "../DefaultWrapper";
import { PieChart, Pie, Cell } from 'recharts';
import {renderCustomizedLabel} from "../../../helpers/recharts.helper"

const Licenses = (props) => {
	const {licenses}=props;
	
	//STATES
	const [array, setArray] = useState([])

	//FUNCTIONS
	const COLORS = ['var(--primary-one)', '#BDBDBD'];
	const transformObject = (object)=> {
		let result=[] ;
			for (const key in object) {
				let obj = {};
				obj["key"] = key;
				obj["value"] = object[key];
				result.push(obj)
		}
		setArray(result.slice(1, 3))
	}

	//EFFECTS
	useEffect(() => {
		if (licenses!== null) {
			transformObject(licenses)
		}
	}, [licenses]);
	

	if (!licenses) {
		return <LicensesSkt />;
	}
	return (
		<DefaultWrapper title="Licencias" columns="span 10">
			<Container>
				<PieChartFake width={250} height={200}>
					<Pie
						data={array}
						startAngle={180}
						endAngle={0}
						cx={100}
						cy={100}
						innerRadius={50}
						outerRadius={60}
						fill="#8884d8"
						dataKey="value"
						label={renderCustomizedLabel}
						labelLine={false}
					>
						{array.map((entry, index) => ( 
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} /> 
						))} 
					</Pie>
				</PieChartFake>
				<TextWrapper>
					<LineWrapper>
						<Text fontSize="1rem" fontWeight="700">	{licenses.total}</Text>
						<Text fontSize="1rem" fontWeight="400">	Licencias totales</Text>
					</LineWrapper>
					<LineWrapper>
						<Text fontSize="1rem" fontWeight="700">	{licenses.usadas}</Text>
						<Text fontSize="1rem" fontWeight="400">	Licencias en uso </Text>
					</LineWrapper>
					<LineWrapper>
						<Text fontSize="1rem" fontWeight="700">{licenses.restantes}</Text>
						<Text fontSize="1rem" fontWeight="400">Licencias disponibles</Text>
					</LineWrapper>
				</TextWrapper>
			</Container>
		</DefaultWrapper>
	);
};

export default Licenses;

const Container = styled.div`
	display: grid;
	grid-template-areas: "piechart info";
	/* grid-template-columns: repeat(auto-fill, minmax(200px, 1%)); */
	grid-template-columns:1fr 3fr;;

	align-items: center;
	justify-content: inherit;
	@media (max-width: 1550px) {
		grid-template-areas:" piechart  "
		    				" info      ";
		gap: 1rem;
		grid-template-columns:1fr;
	}
`;

const TextWrapper = styled.div`
	display: grid;
	grid-area:info;
	width:90%;
	justify-content: center;
	@media (max-width: 1500px) {
		align-self: center;
	}
`;

const LineWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
`;
const PieChartFake = styled(PieChart)`
	grid-area:piechart;
	width:90%;
	margin-bottom:-20%;
	justify-self: center;
	@media (max-width: 1500px) {
		align-self: center;
		margin-bottom:-20%;

	}
`;
