import { call, put, takeEvery } from "redux-saga/effects";
import {
	SIGNIN_USER_REQUEST,
	GET_USER_REQUEST,
	GET_COMPANY_REQUEST,
	PHOTO_COMPANY_REQUEST,
} from "../types";
import {
	changePhotoCompany,
	getCompany,
	signInFA,
	signInUser,
} from "../api/user";
import { userActions } from "../actions";

function* signInRequest(action) {
	try {
		const token = yield call(signInFA, action.payload);
		console.log('token', token)
		console.log('data.response_data.token', token.data.response_data.token)
		yield put(userActions.signInSuccess(token.data));
	} catch (error) {
		yield put(userActions.signInFail(error));
	}
}

function* getUserRequest(action) {
	try {
		const user = yield call(signInUser, action.payload);
		yield put(userActions.getUserSuccess(user.data[0]));
	} catch (error) {
		yield put(userActions.getUserFail(error));
	}
}

function* getCompanyRequest(action) {
	try {
		const company = yield call(getCompany, action.payload);
		yield put(userActions.getCompanySuccess(company.data));
	} catch (error) {
		yield put(userActions.getCompanyFail(error));
	}
}

function* photoCompanyRequest(action) {
	try {
		yield call(changePhotoCompany, action.payload);
		yield put(userActions.photoCompanySuccess(action.payload.imagen));
	} catch (error) {
		yield put(userActions.photoCompanyFail(error));
	}
}

function* userWatcher() {
	yield takeEvery(SIGNIN_USER_REQUEST, signInRequest);
	yield takeEvery(GET_USER_REQUEST, getUserRequest);
	yield takeEvery(GET_COMPANY_REQUEST, getCompanyRequest);
	yield takeEvery(PHOTO_COMPANY_REQUEST, photoCompanyRequest);
}

export { userWatcher };
