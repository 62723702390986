import React from "react";
import CsvCreator from 'react-csv-creator';
import { GetAppRounded } from "@material-ui/icons";
import { ExportUsersButton } from "../styled-components/users.styled.components";

const ExportUsers = (props) => {

	const { participants } = props

	const headers = [{
		id: 'nombre',
		display: 'Nombre'
	}, {
		id: 'email',
		display: 'Correo electrónico'
	}, {
		id: 'fecha_alta',
		display: 'Fecha de alta'
	}, {
		id: 'ultimo_login',
		display: 'Ultimo acceso'
	},{
		id: 'estado',
		display: 'Estado'
	}];


	return (
		<>
			<CsvCreator
				filename='participantes_csv'
				headers={headers}
				rows={participants}
			>
				<ExportUsersButton
				variant="outlined"
				color="primary"
				size="small"
				startIcon={<GetAppRounded />}
				>
				Exportar
				</ExportUsersButton>
			</CsvCreator>
		</>
	);
};

export default ExportUsers;



