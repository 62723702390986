import React, { useState } from 'react';

// Material UI
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { CheckboxStyle, UnassignButton, WrapperList } from './styled-components/table.styled.components';
import Loading from '../../../../shared/common/Loading';
import { patchAssignResource } from '../../services/resources.services';

const CoursesList = props => {
    const { list, resourceId, listEliminated, setListEliminated } = props;

    const [listUnchecked, setListUnchecked] = useState([]);
    const [loading, setLoading] = useState(false);

    //la desasignacion se debe seleccionar, una vez marcado el check se confirma...corroborar el EP , se actualiza lista sin el cehck solicitado
    const handleCheckboxChange = (index, event) => {
        if (event.target.checked) {
            setListUnchecked(prev => [...prev, list[index]?.id]);
        } else {
            setListUnchecked(prev => prev.filter(c => c !== list[index]?.id));
        }
    };

    // Función para desasignar
    const handleClick = async () => {
        setLoading(true);
        const body = {
            instructions: listUnchecked.map(c => {
                return {
                    curse_id: c,
                    resource_id: resourceId,
                    area_id: 2,
                    action: 'unassign',
                };
            }),
        };
        try {
            await patchAssignResource(body);
            setLoading(false);
            setListEliminated([...listEliminated, ...listUnchecked]);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <WrapperList>
            <FormGroup>
                {list
                    .filter(element => {
                        return !listEliminated.includes(element?.id);
                    })
                    .map((c, index) => (
                        <FormControlLabel
                            onChange={e => handleCheckboxChange(index, e)}
                            control={
                                <CheckboxStyle
                                // defaultChecked
                                />
                            }
                            label={c?.publication_name}
                        />
                    ))}
            </FormGroup>
            <UnassignButton disabled={listUnchecked.length === 0} onClick={handleClick}>
                {loading ? <Loading /> : 'Desasignar'}
            </UnassignButton>
        </WrapperList>
    );
};

export default CoursesList;
