import React from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import Text from "../../shared/common/Text";
import { Avatar } from "@material-ui/core";

const CardGroup = ({ group }) => {
	const { nombre, id } = group;

	return (
		<Wrapper>
			<Container>
				<Avatar>{nombre.charAt(0)}</Avatar>
				<TextWrapper>
					<Text fontSize="0.6rem" fontWeight="400" color="error">
						GRUPO
					</Text>
					<Text fontSize="1rem" fontWeight="400">
						{nombre}
					</Text>
				</TextWrapper>
			</Container>
		</Wrapper>
	);
};

export default CardGroup;

const Container = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	padding: 1rem;
	border-radius: 5px;
	border: 1px solid #e5e5e5;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
