import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Preview from '../components/blog/Preview';
import { getOneArticle, getStrapiCompany } from '../redux/api/blog';
import Loading from '../shared/common/Loading';
import Header from '../shared/layout/header/Header';
import Layout from '../shared/layout/Layout';

const ViewPost = () => {
    const { id } = useParams();

    //STATES
    const [articleToView, setArticleToView] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getArticle(id);
        }
    }, []);

    //FUNCTIONS
    const getArticle = async id => {
        let req = await getOneArticle(id);
        if (!!req) {
            setArticleToView(req.data.attributes);
        }
    };

    // console.log('articleToView', articleToView);

    if (!articleToView) {
        return <Loading />;
    }
    return (
        <Layout header={<Header route={articleToView.title} />}>
            <Preview articleToView={articleToView} />
        </Layout>
    );
};

export default ViewPost;
