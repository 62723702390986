import React from "react";
import styled from "styled-components";

const ScrollFixed = (props) => {
	const { height , overflowY } = props;

	// RETURN
	return (
		<OverflowWrapper height={height}>
			<Overflow overflowY = {overflowY} >{props.children}</Overflow>
		</OverflowWrapper>
	);
};

export default ScrollFixed;

const OverflowWrapper = styled.div`
	position: relative;
	width: 100%;
	height: ${(p) => p.height};
`;

const Overflow = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow-y:${(p) => p.overflowY !== false ? "scroll" : "hidden" };
	overflow-x: hidden;
`;
