import React from 'react';
import { Body, ContainerAnswer, Title } from '../../../../styled-components/forms.styled.components';
import Text from '../../../../../../shared/common/Text';
import AnswersListOneOption from './AnswersListOneOption';

const OnlyAnswer = props => {
    const { formik, index} = props;

    return (
        <ContainerAnswer>
            <Title>
                <Text textalign={'left'} fontWeight={500} fontcolor={'#616161'} lineheight={'1.3rem'}>
                    Ingresa la respuesta verdadera y las falsas para la pregunta anterior.{' '}
                </Text>
                <Text textalign={'left'} fontWeight={700} fontcolor={'#616161'} lineheight={'1.3rem'}>
                    {' '}
                    No olvides seleccionar la respuesta correcta.
                </Text>
            </Title>
            <Body>
                <AnswersListOneOption  formik={formik} index={index} />
            </Body>
        </ContainerAnswer>
    );
};

export default OnlyAnswer;
