import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { createPopUpSlide } from '../../redux/api/announcements';
import Snack from '../../shared/common/Snack';
import PopUpForm from './PopUpForm';
import { PopUpFormSchema } from '../../utils/schemas';

const NewPopUpForm = props => {
    const { handleState, strapiId } = props;

    //STATES 
    const [popUp, setPopUp] = useState(null);
    const [file, setFile] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (popUp !== null) {
            handleState(popUp);
        }
    }, []);

    // STATE INICIAL
    const initialValues = {
        title: '',
        description: '',
        tag: '',
        image_url: '',
        image: '',
    };

    // FUNCTIONS
    const handleSubmitFile = file => {
        setFile(file);
    };


    const handleSubmit = async values => {
        const data = {
            title: values.title,
            description: values.description,
            tag: values.tag,
            image_url: values.image_url,
            company: strapiId,
            user_roles: ['4'],
            published: false,
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(data) || '');
        formData.append('files.image', file || '');
        let response = await createPopUpSlide(formData);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El Pop Up no pudo generarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setPopUp(response);
            handleState(response);
        }
    };

    function handleCloseError() {
        setErrorOpen(false);
    }

    // RETURN
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={PopUpFormSchema}
            >
                {({ values }) => (
                    <FormContainer>
                        <PopUpForm
                            initialValues={initialValues}
                            handleSubmitFile={handleSubmitFile}
                            type="new"
                        />

                        <ButtonWrapper>
                            <WrapperButtons>
                                <ButtonToSave variant="contained" type="submit" disabled={file === null}>
                                    Guardar cambios
                                </ButtonToSave>
                            </WrapperButtons>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default NewPopUpForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;
