import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../shared/layout/Layout';
import Header from '../shared/layout/header/Header';
import { getStrapiCompany } from '../redux/api/blog';
import MessageModal from '../components/blog/MessageModal';
import NewBannerForm from '../components/announcementsAndNews/NewBannerForm';

const NewBanner = () => {

    let history = useHistory();
    const ondemandId = useSelector(state => state.user.user.id);

    const [bannerCreated, setBannerCreated] = useState(null);
    const [strapiId, setStrapiId] = useState(null); //la generación de artículos se vincula por id de strapi del ondemand, el cual se consulta por repo_id
    const [messageOpen, setMessageOpen] = useState(false);

    useEffect(() => {
        if (strapiId === null) {
            getStrapiId(ondemandId);
        }
    }, []);

    useEffect(() => {
        if (!!bannerCreated) {
            setMessageOpen(true);
            // history.push(`/blog`);

        }
    }, [bannerCreated]);

    const getStrapiId = async id => {
        let req = await getStrapiCompany(id);
        if (!!req) {
            setStrapiId(req.data[0].id);
        }
    };

    const handleState = art => {
        setBannerCreated(art);
    };

    function handleClose() {
        setMessageOpen(false);
        history.push(`/anuncios?tab=banner`);
    }

    return (
        <>
            <Layout header={<Header route="Nuevo Banner" />}>
                <HeaderWrapper>
                    <NewBannerForm handleState={handleState} strapiId={strapiId} />
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¡Banner creado!"
                image="/images/saved_article.svg"
                subtitle="El banner se guardó como borrador"
            />
        </>
    );
};

export default NewBanner;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
