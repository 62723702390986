import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#B31D15",
		},
		secondary: {
			main: "#a8a8a8",
		},
		error: {
			main: "#777777",
		},
	},
	typography: {
		fontFamily: ["Lato", "sans-serif"].join(","),
	},
});
