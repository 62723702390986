import React, { useState } from "react";
import styled from "styled-components";

import { Button, IconButton } from "@material-ui/core";
import { AddAPhotoRounded } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions";

const CompanyPhoto = (props) => {
	const { photo, ondemand_id } = props;
	// REDUX
	const dispatch = useDispatch();

	// STATE
	const [imagen, setImagen] = useState(`data:image/png;base64, ${photo}`);
	const [form, setForm] = useState(null);

	// FUNCTIONS
	function handlePhoto(e) {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setImagen(reader.result);
			}
		};
		reader.readAsDataURL(e.target.files[0]);
		const form = new FormData();
		form.append("img", e.target.files[0]);
		setForm(form);
	}

	function handleSubmit(e) {
		e.preventDefault();
		const caracter = imagen.indexOf(",", 0);
		const newImage = imagen.substr(caracter + 1);
		dispatch(
			userActions.photoCompanyRequest({
				imagen: newImage,
				ondemand_id,
			})
		);
		setForm(null);
	}

	// RETURN
	return (
		<Container onSubmit={handleSubmit}>
			<PhotoWrapper>
				<Photo src={imagen} alt="logo" />
				<input
					accept="image/*"
					name="imagen"
					type="file"
					id="photoCompany"
					hidden
					onChange={handlePhoto}
				/>
				<FakeLabel htmlFor="photoCompany">
					<FakeIconButton color="primary" component="span">
						<AddAPhotoRounded />
					</FakeIconButton>
				</FakeLabel>
			</PhotoWrapper>
			<Button
				variant="contained"
				color="primary"
				size="small"
				type="submit"
				disabled={form === null}
				style={{ width: "max-content" }}
			>
				Guardar logo
			</Button>
		</Container>
	);
};

export default CompanyPhoto;

const Container = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	row-gap: 1rem;
`;

const PhotoWrapper = styled.div`
	position: relative;
`;

const Photo = styled.img`
	border: 1px solid #a8a8a8;
	border-radius: 5px;
	width: 100%;
	height: auto;
	max-height: 126px;
	object-fit: cover;
	object-position: center;
`;

const FakeLabel = styled.label`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const FakeIconButton = styled(IconButton)`
	background-color: #f6f6f6;

	&:hover {
		background-color: #f6f6f690;
	}
`;
