import React, { useState } from "react";
import styled from "styled-components";

import { Button, IconButton, TextField } from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
import { userActions } from "../../redux/actions";
import { useDispatch } from "react-redux";

const AdminFields = (props) => {
	const { admin } = props;
	// REDUX
	const dispatch = useDispatch();

	// STATE
	const [disabledEmail, setDisabledEmail] = useState(true);
	const [disabledPassword, setDisabledPassword] = useState(true);
	const [values, setValues] = useState({
		nombre: admin.nombre,
		email: admin.email,
		password: 12345678,
	});

	// FUNCTIONS
	function toggleDisabledEmail() {
		setDisabledEmail(!disabledEmail);
	}

	function toggleDisabledPassword() {
		setDisabledPassword(!disabledPassword);
	}

	function handleChange(e) {
		setValues({ ...values, [e.target.name]: e.target.value });
	}

	function handleSubmit(e) {
		e.preventDefault();
		dispatch(userActions.putAdminRequest(values));
	}

	// RETURN
	return (
		<Container onSubmit={handleSubmit}>
			{/** Nombre completo */}
			<TextField
				name="nombre"
				value={values.nombre}
				onChange={handleChange}
				type="text"
				label="Nombre completo"
				variant="outlined"
				disabled
			/>
			{/** Correo electronico */}
			{/*<InputDisableWrapper>*/}
			<TextField
				name="email"
				value={values.email}
				onChange={handleChange}
				type="email"
				label="Correo electrónico"
				variant="outlined"
				style={{ width: "100%" }}
				disabled={disabledEmail}
			/>
			{/*<FakeIconButton onClick={toggleDisabledEmail}>
					<EditRounded />
				</FakeIconButton>
	</InputDisableWrapper>*/}
			{/** Password */}
			{/*<InputDisableWrapper>
				<TextField
					name="password"
					value={values.password}
					onChange={handleChange}
					type="password"
					label="Contraseña"
					variant="outlined"
					style={{ width: "100%" }}
					disabled={disabledPassword}
				/>
				<FakeIconButton onClick={toggleDisabledPassword}>
					<EditRounded />
				</FakeIconButton>
			</InputDisableWrapper>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				size="small"
				style={{ width: "max-content" }}
			>
				Guardar datos
			</Button>*/}
		</Container>
	);
};

export default AdminFields;

const Container = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

const InputDisableWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;

const FakeIconButton = styled(IconButton)`
	background-color: #fafafa;
	border-radius: 5px;
`;
