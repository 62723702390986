export const GET_MY_CONTENT_REQUEST = "GET_MY_CONTENT_REQUEST";
export const GET_MY_CONTENT_SUCCESS = "GET_MY_CONTENT_SUCCESS";
export const GET_MY_CONTENT_FAIL = "GET_MY_CONTENT_FAIL";

export const CREATE_CONTENT_REQUEST = "CREATE_CONTENT_REQUEST";
export const CREATE_CONTENT_SUCCESS = "CREATE_CONTENT_SUCCESS";
export const CREATE_CONTENT_FAIL = "CREATE_CONTENT_FAIL";

export const DELETE_CONTENT_REQUEST = "DELETE_CONTENT_REQUEST";

export const CREATE_UNIT_REQUEST = "CREATE_UNIT_REQUEST";
export const CREATE_UNIT_SUCCESS = "CREATE_UNIT_SUCCESS";
export const CREATE_UNIT_FAIL = "CREATE_UNIT_FAIL";
