import React from 'react';
import styled from 'styled-components';

const AnalyticsDataStudio= (props) => {

    const {url} = props;
   
    return (
        <IframeWrapper>
            <Iframe 
                src={url}
                frameBorder="0" 
                allowFullScreen 
            />
        </IframeWrapper>    
    );
};

export default AnalyticsDataStudio;

const IframeWrapper = styled.div`
    position: relative;
    padding-bottom: 80%;
    padding-top: 1%;
    height: 0;
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
`;