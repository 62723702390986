import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { theme } from './assets/theme/MaterialTheme';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import { ModalProvider } from './hooks/ModalContext';
import { GlobalStyles } from './providers/GlobalStyles';

function App() {
    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ModalProvider>
                    <BrowserRouter>
                        <GlobalStyles />
                        <ScrollToTop />
                        <Routes />
                    </BrowserRouter>
                </ModalProvider>
            </ThemeProvider>
        </StylesProvider>
    );
}

export default App;
