import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ModalForm from '../../shared/common/ModalForm';
import ModalSteps from './ModalSteps';
import { dienrrolParticipants, dienrrolParticipantsBySubject, getParticipantByOA, getParticipantBySubject } from '../../redux/api/participant.api';
import Loading from '../../shared/common/Loading';

const RemoveUsers = props => {
    const { course, openModal, closeModal, type, removeSucces, 
        // handleCloseMenu 
    } = props;

    const { id } = useSelector(state => state.user.user);

    // STATE
    const [isOpen, setIsOpen] = useState(openModal);
    const [step, setStep] = useState(0);
    const [checkedItems, setCheckedItems] = useState(false);
    const [participants, setParticipants] = useState(null);

    //EFFECTS
    useEffect(() => {
        type === 'course' 
        ? getParticipants() 
        : getParticipantsBySubject();
    }, [type]);


    // FUNCTIONS
    const selectedItems = items => {
        setCheckedItems(items);
    };

    const handleSubmit = () => {
        if (type === 'course') {
            handleSubmitByCourse();
        } else {
            handleSubmitBySubject();
        }
    };

    function handleClose() {
        setIsOpen(false);
        // handleCloseMenu()
        closeModal();
    }

    //course
    const getParticipants = async () => {
        try {
            const req = await getParticipantByOA({ ondemand_id: id, oa_id: course.id });
            req && setParticipants(req);
        } catch (error) {
            return {
                error,
            };
        }
    };

    //subject
    const getParticipantsBySubject = async () => {
        try {
            const req = await getParticipantBySubject({ ondemand_id: id, asignatura_id: course.id });
            req && setParticipants(req);
        } catch (error) {
            return {
                error,
            };
        }
    };

    //course
    const handleSubmitByCourse = async () => {
        const formatArray = checkedItems.map(par => ({ id: par.id }));
        try{
            const req = await dienrrolParticipants(formatArray, id, course.id);
            req.status === 200 && 
                removeSucces();
                setParticipants(null);
                setCheckedItems([]);
        }
        catch(error) {
            return {
                error,
            };
        }
    };

    //subject
    const handleSubmitBySubject = async () => {
        const formatArray = checkedItems.map(par => ({ id: par.id }));
        try {
            const req = await dienrrolParticipantsBySubject(formatArray, id, course.id);
            req.status === 200 && 
            removeSucces();
            setParticipants(null);
            setCheckedItems([]);
        } catch(error) {
            return {
                error,
            };
        }
    };

    function nextStep() {
        setStep(step + 1);
    }
    function backStep() {
        setStep(step - 1);
    }

    // RETURN
    if (checkedItems.length === undefined || participants === null) {
        <Loading />;
    }
    return (
        <>
            <ModalForm
                isOpen={isOpen}
                handleClose={handleClose}
                banner={'var(--primary-one)'}
                title={(step === 0 && 'Desmatriculación de alumnos') || (step === 1 && 'Resumen de desmatriculación de alumnos')}>
                    <ModalSteps
                        step={step}
                        nextStep={nextStep}
                        backStep={backStep}
                        course={course}
                        type={'course'}
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        titleAssign={'Selecciona los alumnos que ya no podrán acceder a'}
                        titleConfirm={'Alumnos sin acceso'}
                        participantsToRemove={participants}
                        selectedItems={selectedItems}
                        prevParticipantsEnrolled={[]}
                    />
            </ModalForm>
        </>
    );
};

export default RemoveUsers;
