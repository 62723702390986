import React, { useState } from 'react';
import styled from 'styled-components';
import { RadioGroupStyle, RadioStyle } from '../../../../styled-components/forms.styled.components';
import { FormControlLabel } from '@mui/material';
import Text from '../../../../../../shared/common/Text';

const TrueOrFalseAnswer = props => {
    const { formik, index } = props;
    const initialTrueAnswers = formik.values.questions[index].data_question_options.filter(option => option.selected === true);

    const [answerSelected, setAnswerSelected] = useState(initialTrueAnswers?.length !== 0 ? initialTrueAnswers[0].indexanswers : null);
    const list = [
        { id: 0, label: 'Verdadero' },
        { id: 1, label: 'Falso' },
    ];

    const handleChange = event => {
        setAnswerSelected(event.target.value);
        const trueOrFalse = Number(event.target.value);
        const updatedOptions = [{
            name: trueOrFalse === 0 ? 'Verdadero' : 'Falso',
            es_opcion_correcta: trueOrFalse === 0 ? 'True' : 'False',
            comentario: trueOrFalse === 0 ? 'Correcto' : 'Incorrecto',
            indexanswers: trueOrFalse,
            selected: trueOrFalse === 0 ? true : false,
        }];
        formik.setFieldValue(`questions[${index}].data_question_options`, updatedOptions);
    };

    return (
        <ContainerAnswer>
            <Title>
                {/* <Text textalign={'left'} fontWeight={500} fontcolor={'#616161'} lineheight={'1.3rem'}>
                    Ingresa la respuesta verdadera y falsa para la pregunta anterior.{' '}
                </Text> */}
                <Text textalign={'left'} fontWeight={700} fontcolor={'#616161'} lineheight={'1.3rem'}>
                    {' '}
                    No olvides seleccionar la respuesta correcta.
                </Text>
            </Title>
            <Body>
                <RadioGroupStyle
                    aria-labelledby="TrueOrFalseAnswer_controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={answerSelected}
                    onChange={handleChange}>
                    {list.map(l => (
                        <FormControlLabel key={l.id} value={l.id} control={<RadioStyle />} label={l.label} />
                    ))}
                </RadioGroupStyle>
            </Body>
        </ContainerAnswer>
    );
};

export default TrueOrFalseAnswer;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

const ContainerAnswer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-shadow: 0px 12px 24px -15px #0000001a;
    box-shadow: 0px 0px 10px -6px #00000040;
`;

const Body = styled.div``;
