import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Autocomplete } from "@material-ui/lab";
import { Button, TextField } from "@material-ui/core";
import Wrapper from "../../../shared/common/Wrapper";
import Loading from "../../../shared/common/Loading";

const SelectGroup = (props) => {
	const { handleSelected, groupAdvanceByCourses, groupQuery} = props;
	
	//STATES
	const [value, setValue] = useState( groupAdvanceByCourses ?
											groupQuery!==null ? groupAdvanceByCourses.find(group => group.repo_id === groupQuery) 
											: groupAdvanceByCourses[0]
										: null 
										);
	const [inputValue, setInputValue] = useState(null);

  // EFFECTS
	useEffect(() => {
			if (inputValue === null) {
				handleSelected(value)
			}
	}, [inputValue]);

	useEffect(() => {
		if (inputValue !== null) {
			handleSelected(value)
		}
	}, [value,inputValue]); 

// console.log(`value`, value)	
// console.log(`groupAdvanceByCourses`, groupAdvanceByCourses)
// console.log(`groupQuery`, groupQuery)
	//RETURN
	return (
		<Wrapper padding="1.5rem" columns="span 24">
			<Container>
			{groupAdvanceByCourses === null?
				<Loading padding="4rem" />
			: 
				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
					setValue(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
					}}
					id="autocomplete-groups"
					options={groupAdvanceByCourses}
					getOptionLabel={(option) => option.group}
					style={{ width: "100%" }}
					renderInput={(params) => 
						<TextField {...params} 
						label="Selecciona un grupo" 
						variant="outlined" />}
				/>

			}
				<Button
					variant="contained"
					color="primary"
					onClick={(event, newValue) => {
						setValue("all");
						}}
				>
					Todos
				</Button>
			</Container>
		</Wrapper>
	);
};

export default SelectGroup;

const Container = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1.5rem;
`;
