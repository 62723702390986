export const GET_NUMBER_OF_PARTICIPANTS_REQUEST = "GET_NUMBER_OF_PARTICIPANTS_REQUEST";
export const GET_NUMBER_OF_PARTICIPANTS_SUCCESS = "GET_NUMBER_OF_PARTICIPANTS_SUCCESS";
export const GET_NUMBER_OF_PARTICIPANTS_FAIL = "GET_NUMBER_OF_PARTICIPANTS_FAIL";

export const GET_PARTICIPANTS_REQUEST = "GET_PARTICIPANTS_REQUEST";
export const GET_PARTICIPANTS_SUCCESS = "GET_PARTICIPANTS_SUCCESS";
export const GET_PARTICIPANTS_FAIL = "GET_PARTICIPANTS_FAIL";

export const CREATE_PARTICIPANT_REQUEST = "CREATE_PARTICIPANT_REQUEST";
export const CREATE_PARTICIPANT_SUCCESS = "CREATE_PARTICIPANT_SUCCESS";
export const CREATE_PARTICIPANT_FAIL = "CREATE_PARTICIPANT_FAIL";
export const CONFIRM_CREATE_PARTICIPANT = "CONFIRM_CREATE_PARTICIPANT";

export const REMOVE_PARTICIPANT_REQUEST = "REMOVE_PARTICIPANT_REQUEST";
export const REMOVE_PARTICIPANT_SUCCESS = "REMOVE_PARTICIPANT_SUCCESS";
export const REMOVE_PARTICIPANT_FAIL = "REMOVE_PARTICIPANT_FAIL";

export const DEACTIVATE_PARTICIPANT_REQUEST = "DEACTIVATE_PARTICIPANT_REQUEST";
export const DEACTIVATE_PARTICIPANT_SUCCESS = "DEACTIVATE_PARTICIPANT_SUCCESS";
export const DEACTIVATE_PARTICIPANT_FAIL = "DEACTIVATE_PARTICIPANT_FAIL";

export const ASSIGN_COURSE_REQUEST = "ASSIGN_COURSE_REQUEST";
export const ASSIGN_COURSE_SUCCESS = "ASSIGN_COURSE_SUCCESS";
export const ASSIGN_COURSE_FAIL = "ASSIGN_COURSE_FAIL";
export const CONFIRM_ASSIGN_COURSE = "CONFIRM_ASSIGN_COURSE";

export const GET_PARTICIPANT_REQUEST = "GET_PARTICIPANT_REQUEST";
export const GET_PARTICIPANT_SUCCESS = "GET_PARTICIPANT_SUCCESS";
export const GET_PARTICIPANT_FAIL = "GET_PARTICIPANT_FAIL";

export const ASSIGN_SUBJECT_REQUEST = "ASSIGN_SUBJECT_REQUEST";
export const ASSIGN_SUBJECT_SUCCESS = "ASSIGN_SUBJECT_SUCCESS";
export const ASSIGN_SUBJECT_FAIL = "ASSIGN_SUBJECT_FAIL";
export const CONFIRM_ASSIGN_SUBJECT = "CONFIRM_ASSIGN_SUBJECT";
