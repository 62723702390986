import { Form, Formik } from 'formik';
import React from 'react';
import { useEffect, useState } from 'react';
import CourseForm from './CourseForm';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { createCustomOA } from '../../redux/api/content-creation.api';
import Loading from '../../shared/common/Loading';
import { useSelector } from 'react-redux';
import Snack from '../../shared/common/Snack';
import { CourseFormSchema } from '../../utils/schemas';

const NewCourseForm = props => {
    const { handleState } = props;
    const ondemand_id = useSelector(state => state.user.user.id);

    //STATES
    const [newCourse, setNewCourse] = useState(null);
    const [file, setFile] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [tagsListSelected, setTagsListSelected] = useState([]);
    const [themesListSelected, setThemesListSelected] = useState([]);

    // EFFECTS
    useEffect(() => {
        if (newCourse !== null) {
            handleState(newCourse);
        }
    }, [newCourse]);

    const listTimeUnities = [
        { id: 0, name: 'meses' },
        { id: 1, name: 'semanas' },
        { id: 2, name: 'días' },
        { id: 3, name: 'horas' },
        { id: 4, name: 'selecciona una opción' },
    ];

    const typesOfCourse = [
        { id: 1, name: 'Bloque BIenvenida' },
        { id: 3, name: 'Bloque Contenido' },
        { id: 4, name: 'Bloque Integrador' },
        { id: 5, name: 'Encuesta de Satisfacción' },
        { id: 6, name: 'Bloque Bienvenida' },
    ];

    //Default Autocomplete
    const formatUnityTime = () => {
        return listTimeUnities.filter(values => values.name === listTimeUnities[4].name)[0];
    };
    const filterTypes = () => {
        return typesOfCourse?.filter(values => values.name === typesOfCourse[4].name)[0]; // { id: 6, name: 'Bloque Bienvenida' },
    };

    // STATE INICIAL
    const initialValues = {
        title: '',
        description: '',
        author: '',
        duration: '',
        timeunity: formatUnityTime(),
        type: filterTypes(),
        image: '',
        theme: '',
        tag: '',
    };
    // console.log('initialValues', initialValues)
    // FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    const handleSubmitFile = async file => {
        // console.log('handleSubmitFile', file);

        if (!file) {
            console.log('no hay archivo adjunto');
        }
        const fileTransform = await toBase64(file);
        const splitFileTransform = fileTransform.split(',');
        const base64_texto = splitFileTransform[1];
        if (fileTransform) {
            setFile(base64_texto);
        }
    };

    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    const listTagsValuesSelected = list => {
        console.log('list_ listValuesSelected', list);
        let idList = [];
        list?.forEach(element => {
            idList.push(element.id);
        });
        setTagsListSelected(idList);
    };

    const listThemesValuesSelected = list => {
        console.log('list_ listThemesValuesSelected', list);
        let idList = [];
        list?.forEach(element => {
            idList.push(element.id);
        });
        setThemesListSelected(idList);
    };

    const handleSubmit = async values => {
        // console.log('values', values);
        const a = listTimeUnities?.filter(x => x.id === values.timeunity.id);
        const b = typesOfCourse?.filter(x => x.id === values.type.id);
        const data = {
            titulo: values.title,
            descripcion: values.description,
            autor: values.author,
            duracion: values.duration,
            duracion_unidad_tiempo: a[0].name,
            tipo_oa_id: b[0].id,
            eje_ids: themesListSelected,
            tag_ids: tagsListSelected,
            imagen: file,
            published: false,
        };
        // console.log('data', data);
        let response = await createCustomOA(ondemand_id, data);
        // console.log('response', response);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El curso no pudo enviarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setNewCourse(response);
            handleState(response);
        }
    };

    // RETURN
    if (!initialValues) {
        return <Loading />;
    }
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CourseFormSchema}>
                {({ values, setFieldValue }) => (
                    <FormContainer>
                        <CourseForm
                            initialValues={initialValues}
                            handleSubmitFile={handleSubmitFile}
                            type="new"
                            listTagsValuesSelected={listTagsValuesSelected}
                            listThemesValuesSelected={listThemesValuesSelected}
                            setFieldValue={setFieldValue}
                            timeUnityComplete={values.timeunity}
                            typeComplete={values.type}
                        />

                        <ButtonWrapper>
                            <WrapperButtons>
                                <ButtonToSave variant="contained" type="submit" disabled={file === null}>
                                    Guardar cambios
                                </ButtonToSave>
                            </WrapperButtons>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default NewCourseForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;
