import React, { useEffect, useState } from 'react';
import DefaultField from '../../../../shared/common/DefaultField';
import { AlertText, Label, Second } from '../../styled-components/forms.styled.components';
import RadioGroupResources from '../RadioGroupResources';

const MaterialPlanoForm = props => {
    const { formik, materialPlanoSelectedType } = props;


    const typeMaterialPlanoToEdit =
        formik?.values?.subtype === 'Página Web'
            ? '6'
            : formik?.values?.subtype === 'PDF'
            ? '4'
            : formik?.values?.subtype === 'PPT'
            ? '9'
            : formik?.values?.subtype === 'HTML'
            ? '24'
            : null;

    //STATES
    const [selectedType, setSelectedType] = useState(typeMaterialPlanoToEdit);

    //EFFECTS
    useEffect(() => {
        if (selectedType !== null) {
            materialPlanoSelectedType(selectedType);
        }
    }, [selectedType]);

    const resourcesOption = [
        { id: 4, label: 'Archivo PDF (guardado en Google Drive o iCloud)' },
        // { id: 1, label: 'Archivo Word (guardado en Google Drive o iCloud)' },
        { id: 9, label: 'Archivo Power Point (guardado en Google Drive o iCloud)' },
        // { id: 2, label: 'Archivo Excel (guardado en Google Drive o iCloud)' },
        { id: 6, label: 'Enlace de página web' },
        { id: 24, label: 'Material plano HTML' },
    ];

    function handleTypeSelected(type) {
        setSelectedType(type);
        materialPlanoSelectedType(type);
    }

    return (
        <>
            {/* manejar el estado de subtype de value de formik.values.subtype con la seleccion de tipo de recurso */}
            <RadioGroupResources
                label={'Tipo de archivo que se encontrará en el enlace'}
                list={resourcesOption}
                resourceType={handleTypeSelected}
                preSelected={selectedType}
                formik={formik}
            />
            {selectedType === '24' ? (
                <Second>
                    <Label>
                        Nombre del contenido (así se verá en la plataforma)
                        <DefaultField
                            name="publication_name"
                            type="text"
                            label=""
                            required
                            variant="outlined"
                            placeholder=""
                            border="1px solid #616161"
                            borderradius="8px"
                        />
                    </Label>

                    <Label>
                        Código HTML
                        <DefaultField
                            name="description_html"
                            type="text"
                            variant="outlined"
                            label=""
                            required
                            placeholder=""
                            border="1px solid #616161"
                            borderradius="8px"
                            multiline
                        />
                    </Label>
                </Second>
            ) : (
                <Second>
                    <Label>
                        Nombre de la lectura (así se verá en la plataforma)
                        <DefaultField
                            name="publication_name"
                            type="text"
                            label=""
                            required
                            variant="outlined"
                            placeholder=""
                            border="1px solid #616161"
                            borderradius="8px"
                        />
                    </Label>

                    <Label>
                        Enlace de la lectura
                        <DefaultField
                            name="link_storage"
                            type="url"
                            variant="outlined"
                            label=""
                            required
                            placeholder=""
                            border="1px solid #616161"
                            borderradius="8px"
                        />
                    </Label>
                    <AlertText>Recuerda que el enlace debe ser público para que todos los estudiantes puedan verlo.</AlertText>
                </Second>
            )}
        </>
    );
};

export default MaterialPlanoForm;
