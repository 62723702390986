import React, { useEffect, useState } from "react";
import styled from "styled-components";

import SearchName from "./SearchName";
import GridMembers from "../../shared/common/GridMembers";
import SearchMember from "./SearchMember";
import { Button, Checkbox, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { groupActions, participantActions } from "../../redux/actions";
import Text from "../../shared/common/Text";

const FormAddGroup = () => {
	// REDUX
	const dispatch = useDispatch();
	const ondemand_id = useSelector((state) => state.user.user.id);
	const loadingNewGroup = useSelector((state) => state.group.loadingNewGroup);
	const participants = useSelector((state) => state.participant.participants);

	//EFECT
	useEffect(() => {
		if (participants === null ) {
			dispatch(
				participantActions.getParticipantsRequest({ ondemand_id })
			);
		}
	}, []); 

	//STATE
	const [search, setSearch] = useState("");
	const [nombre, setNombre] = useState("");
	const [checkedItems, setCheckedItems] = useState([]);

	// FUNCTIONS
	function handleSearch(e) {
		setSearch(e.target.value);
	}

	function handleName(e) {
		setNombre(e.target.value);
	}

	function handleChecked(e) {
		if (e.target.checked) {
			setCheckedItems([...checkedItems, { id: Number(e.target.name) }]);
		} else {
			setCheckedItems(
				checkedItems.filter((x) => x.id !== Number(e.target.name))
			);
		}
	}

	function handleAllChecked(e){
		if (e.target.checked) {
			setCheckedItems(participants.map((p)=>({ id: p.participante_id })))
		} else {
			setCheckedItems([]);
		}
	}

	function handleSubmit(e) {
		e.preventDefault();
		dispatch(
			groupActions.createGroupRequest({
				newGroup: {
					data_grupo: {
						nombre,
					},
					data_participantes: checkedItems,
				},
				ondemand_id,
			})
		);
	}

	// RETURN
	return (
		<FakeForm onSubmit={handleSubmit}>
			<SearchName nombre={nombre} handleName={handleName} />
			<Wrapper>
				<SearchMember search={search} handleSearch={handleSearch} />
				<WrapperCheck>
				<Text fontSize="1rem" fontWeight="700" color="primary">
					Seleccionar <br/> Todos </Text>
					<Checkbox
							onChange={handleAllChecked}
							color="primary"
					/>
				</WrapperCheck>
			</Wrapper>
			

			<GridMembers
				height="200px"
				search={search}
				handleChecked={handleChecked}
				checkedItems={checkedItems}
			/>
			<Divider />
			<ButtonWrapper>
				<Button
					variant="contained"
					color="primary"
					size="small"
					type="submit"
					disabled={loadingNewGroup}
				>
					Crear
				</Button>
			</ButtonWrapper>
		</FakeForm>
	);
};

export default FormAddGroup;

const FakeForm = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
`;

const Wrapper = styled.div`
	display: grid;
	columns: span 24;
	grid-template-columns: 3fr 1fr;
	align-items: center;
	justify-content: space-around;

`;
const WrapperCheck = styled.div`
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;

    box-shadow: 0px 5px 10px rgba(0,0,0,0.06);
	margin: 1.5rem 0.5rem 0rem 0rem;
	padding: 0.5rem;
`;