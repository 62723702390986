import React from 'react';
import {ContainerForm, ImageStyle, IndicationSubtitle, IndicationTitle } from '../../styled-components/forms.styled.components';

const NullForm = () => {
    return (
        <ContainerForm>
            <IndicationTitle>Información sobre el recurso</IndicationTitle>
            <IndicationSubtitle>Por favor selecciona qué tipo de recurso deseas crear para poder continuar.</IndicationSubtitle>
            <ImageStyle src={'./rediseño_2024/seleccion_tipo_recurso.png'} />
        </ContainerForm>
    );
};

export default NullForm;
