import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../shared/common/Loading';
import Header from '../shared/layout/header/Header';
import Layout from '../shared/layout/Layout';
import { getOneBanner } from '../redux/api/announcements';
import PreviewBanner from '../components/announcementsAndNews/PreviewBanner';


const ViewBanner = () => {
    const { id } = useParams();

    //STATES
    const [bannerToView, setBannerToView] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getArticle(id);
        }
    }, []);

    //FUNCTIONS
    const getArticle = async id => {
        let req = await getOneBanner(id);
        if (!!req) {
            setBannerToView(req.data.attributes);
        }
    };

    // console.log('bannerToView', bannerToView);

    if (!bannerToView) {
        return <Loading />;
    }
    return (
        <Layout header={<Header route={`Previsualización Banner: ${bannerToView.title}`} />}>
            {/* <Preview bannerToView={bannerToView} /> */}
            <PreviewBanner bannerToView={bannerToView}/>
        </Layout>
    );
}

export default ViewBanner

