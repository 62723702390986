import React from 'react'
import styled from 'styled-components';
import Header from '../../shared/layout/header/Header';
import Layout from '../../shared/layout/Layout';
// import NewCourseForm from '../components/content-creation/NewCourseForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';
import { useEffect } from 'react';
import NewRouteForm from '../../components/content-creation/NewRouteForm';
import MessageModal from '../../shared/common/MessageModal';

const NewRoute = () => {
    let history = useHistory();

    const [routeCreated, setRouteCreated] = useState(null);
    const [messageOpen, setMessageOpen] = useState(false);

    useEffect(() => {
        if (!!routeCreated) {
            setMessageOpen(true);
        }
    }, [routeCreated]);

    const handleState = art => {
        setRouteCreated(art);
    };

    function handleClose() {
        setMessageOpen(false);
        history.push(`/subjects?tab=rutas_empresa`);
    }

    return (
        <>
            <Layout header={<Header route="Nueva ruta formativa" />}>
                <HeaderWrapper>
                    <NewRouteForm
                        handleState={handleState}
                    />
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="Ruta formativa creada!"
                image="/images/saved_article.svg"
                subtitle="La ruta se guardó como borrador"
            /> 
        </>
    );
}

export default NewRoute; 
const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 2rem);
    height: 100vw;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
