import * as types from "../types/analytics.types";

const actions = {};

//COURSES
actions.getAnalyticsCoursesRequest = (payload) => {
	return {
		type: types.GET_ANALYTICS_COURSES_REQUEST,
		payload: payload,
	};
};
actions.getAnalyticsCoursesSuccess = (payload) => {
	return {
		type: types.GET_ANALYTICS_COURSES_SUCCESS,
		payload: payload,
	};
};
actions.getAnalyticsCoursesFail = (error) => {
	return {
		type: types.GET_ANALYTICS_COURSES_FAIL,
		payload: error,
	};
};

//STATUS
actions.getAnalyticsStatusRequest = (payload) => {
	return {
		type: types.GET_ANALYTICS_STATUS_REQUEST,
		payload: payload,
	};
};
actions.getAnalyticsStatusSuccess = (payload) => {
	return {
		type: types.GET_ANALYTICS_STATUS_SUCCESS,
		payload: payload,
	};
};
actions.getAnalyticsStatusFail = (error) => {
	return {
		type: types.GET_ANALYTICS_STATUS_FAIL,
		payload: error,
	};
};

//Participantes por curso, estado de avance
actions.getCourseAdvancesByUsersRequest = (payload) => {
	return {
		type: types.GET_COURSE_ADVANCES_BY_USERS_REQUEST,
		payload: payload,
	};
};
actions.getCourseAdvancesByUsersSuccess = (payload) => {
	return {
		type: types.GET_COURSE_ADVANCES_BY_USERS_SUCCESS,
		payload: payload,
	};
};
actions.getCourseAdvancesByUsersFail = (error) => {
	return {
		type: types.GET_COURSE_ADVANCES_BY_USERS_FAIL,
		payload: error,
	};
};

//Cursos por participante, estado de avance
actions.getUserAdvanceByCoursesRequest = (payload) => {
	return {
		type: types.GET_USER_ADVANCE_BY_COURSES_REQUEST,
		payload: payload,
	};
};
actions.getUserAdvanceByCoursesSuccess = (payload) => {
	return {
		type: types.GET_USER_ADVANCE_BY_COURSES_SUCCESS,
		payload: payload,
	};
};
actions.getUserAdvanceByCoursesFail = (error) => {
	return {
		type: types.GET_USER_ADVANCE_BY_COURSES_FAIL,
		payload: error,
	};
};

//Cursos por GRUPOS de participante, estados de avance
actions.getGroupAdvanceByCoursesRequest = (payload) => {
	return {
		type: types.GET_GROUP_ADVANCE_BY_COURSES_REQUEST,
		payload: payload,
	};
};
actions.getGroupAdvanceByCoursesSuccess = (payload) => {
	return {
		type: types.GET_GROUP_ADVANCE_BY_COURSES_SUCCESS,
		payload: payload,
	};
};
actions.getGroupAdvanceByCoursesFail = (error) => {
	return {
		type: types.GET_GROUP_ADVANCE_BY_COURSES_FAIL,
		payload: error,
	};
};

export { actions };