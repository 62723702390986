import { call, put, takeEvery } from "redux-saga/effects";
import { GET_ADMIN_REQUEST, PHOTO_ADMIN_REQUEST } from "../types";
import { getAdmin, changePhotoAdmin } from "../api/admin";
import { adminActions } from "../actions";

function* getAdminRequest(action) {
	try {
		const admin = yield call(getAdmin, action.payload);
		yield put(adminActions.getAdminSuccess(admin.data));
	} catch (error) {
		yield put(adminActions.getAdminFail(error));
	}
}

function* photoAdminRequest(action) {
	try {
		yield call(changePhotoAdmin, action.payload);
		yield put(adminActions.photoAdminSuccess(action.payload.imagen));
	} catch (error) {
		yield put(adminActions.photoAdminFail(error));
	}
}

function* adminWatcher() {
	yield takeEvery(GET_ADMIN_REQUEST, getAdminRequest);
	yield takeEvery(PHOTO_ADMIN_REQUEST, photoAdminRequest);
}

export { adminWatcher };
