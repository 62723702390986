import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Text from "../../shared/common/Text";
import Wrapper from "../../shared/common/Wrapper";
import { Button } from "@material-ui/core";
import CardGroup from "./CardGroup";

const UserGroups = ({ groups }) => {
	return (
		<Wrapper padding="1.5rem">
			<Container>
				<HeaderWrapper>
					<Text fontSize="1.25rem" fontWeight="700" color="error">
						Grupos donde participa
					</Text>
					<Link to="/groups">
						<Button variant="contained" size="small" color="primary">
							Gestionar grupos
						</Button>
					</Link>
				</HeaderWrapper>
				{groups.length === 0 ? (
					<NotFoundWrapper>
						<Text fontSize="1.25rem" fontWeight="400" color="primary">
							El usuario no tiene grupos asignados.
						</Text>
					</NotFoundWrapper>
				) : (
					<GroupsGrid>
						{groups.map((group, index) => (
							<CardGroup group={group} key={index} />
						))}
					</GroupsGrid>
				)}
			</Container>
		</Wrapper>
	);
};

export default UserGroups;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const GroupsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 1rem;
`;

const NotFoundWrapper = styled.div`
	width: 100%;
	text-align: center;
	padding: 3rem 0;
`;
