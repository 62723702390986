import axios from "axios";

export function getNumberOfGroups(ondemand_id) {
	return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/cantidad_grupos/${ondemand_id}`);
}

export function getGroups({ ondemand_id }) {
	return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/grupos/${ondemand_id}`);
}

export function createGroup({ newGroup, ondemand_id }) {
	return axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/crear_grupo/${ondemand_id}`, newGroup);
}

export function editGroup({ editGroup, ondemand_id, id }) {
	return axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/modificar_grupo/${ondemand_id}/${id}`, editGroup);
}

export function removeGroup({ id, ondemand_id }) {
	return axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/eliminar_grupo/${ondemand_id}/${id}`);
}
