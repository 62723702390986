import React, { useState } from 'react';
import styled from 'styled-components';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { DeleteOutlineRounded, MoreVertRounded } from '@material-ui/icons';
import Text from '../../shared/common/Text';
import { useDispatch, useSelector } from 'react-redux';
import { groupActions } from '../../redux/actions';

const TableMenu = props => {
    const { id } = props;
    // REDUX
    const dispatch = useDispatch();

    const ondemand_id = useSelector(state => state.user.user.id);

    // STATE
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // FUNCTIONS
    function handleOpen(e) {
        setAnchorEl(e.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function removeUser() {
        dispatch(groupActions.removeGroupRequest({ id, ondemand_id }));
        handleClose();
    }

    // RETURN
    return (
        <>
            <IconButton size="small" aria-controls="menu-appbar" onClick={handleOpen}>
                <MoreVertRounded fontSize="small" />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}>


                <FakeMenuItem onClick={removeUser}>
                    <DeleteOutlineRounded color="error" fontSize="small" />
                    <Text fontSize="0.75rem" fontWeight="400" color="error">
                        Eliminar
                    </Text>
                </FakeMenuItem>
            </Menu>
        </>
    );
};

export default TableMenu;

const FakeMenuItem = styled(MenuItem)`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
