import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import Snack from '../../shared/common/Snack';
import { createBanner } from '../../redux/api/announcements';
import BannerForm from './BannerForm';
import { BannerFormSchema } from '../../utils/schemas';

const NewBannerForm = props => {
    const { handleState, strapiId } = props;

    //STATES 
    const [banner, setBanner] = useState(null);
    const [file, setFile] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [externalLink, setExternalLink] = useState(true)

    // EFFECTS
    useEffect(() => {
        if (banner !== null) {
            handleState(banner);
        }
    }, []);

    // STATE INICIAL
    const initialValues = {
        title: '',
        description: '',
        link: '',
        tag: '',
        bg_image_url: '',
        bg_image: '',
    };

    // FUNCTIONS
    const handleSubmitFile = file => {
        setFile(file);
    };

    const handleChange = (event) => {
        setExternalLink(event.target.checked);
      };

    const handleSubmit = async values => {
        const data = {
            title: values.title,
            description: values.description,
            link: values.link,
            tag: values.tag,
            bg_image_url: values.bg_image_url,
            company: strapiId,
            user_roles: ['4'],
            external_link: externalLink,
            published: false,
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(data) || '');
        formData.append('files.bg_image', file || '');
        let response = await createBanner(formData);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El banner no pudo enviarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setBanner(response);
            handleState(response);
        }
    };

    function handleCloseError() {
        setErrorOpen(false);
    }

    // RETURN
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={BannerFormSchema}
            >
                {({ values }) => (
                    <FormContainer>
                        <BannerForm
                            externalLink={externalLink}
                            handleChange={handleChange}
                            initialValues={initialValues}
                            handleSubmitFile={handleSubmitFile}
                            type="new"
                        />

                        <ButtonWrapper>
                            <WrapperButtons>
                                <ButtonToSave variant="contained" type="submit" disabled={file === null}>
                                    Guardar cambios
                                </ButtonToSave>
                            </WrapperButtons>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default NewBannerForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;
