import React from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import { Skeleton } from "@material-ui/lab";

const AnalyticsSkt = () => {
	return (
		<Wrapper width="100%">
			<Container>
				<Skeleton1 variant="rect" animation="wave" height={720} />
				<Skeleton2 variant="rect" animation="wave" height={350} />
				<Skeleton3 variant="rect" animation="wave" height={350} />
				<Skeleton4 variant="rect" animation="wave" height={300} />
				<Skeleton5 variant="rect" animation="wave" height={300} />
				<Skeleton6 variant="rect" animation="wave" height={300} />
			</Container>
		</Wrapper>
	);
};

export default AnalyticsSkt;

const Container = styled.div`
	display: grid;
	grid-template-areas: "student  student  licenses"
						 "student  student     users"
						 "ranking1 ranking2 ranking3";
	flex-direction: column;
	grid-gap: 1rem;
	padding: 1rem;
`;

const Skeleton1 = styled(Skeleton)`
	display: grid;
	grid-area:student;
	flex-direction: column;
`;

const Skeleton2 = styled(Skeleton)`
	display: grid;
	grid-area:licenses;
	flex-direction: column;
`;

const Skeleton3 = styled(Skeleton)`
	display: grid;
	grid-area: users;
	flex-direction: column;
`;

const Skeleton4 = styled(Skeleton)`
	display: grid;
	grid-area: ranking1;
	flex-direction: column;
`;

const Skeleton5 = styled(Skeleton)`
	display: grid;
	grid-area: ranking2;
	flex-direction: column;
`;

const Skeleton6 = styled(Skeleton)`
	display: grid;
	grid-area: ranking3;
	flex-direction: column;
`;