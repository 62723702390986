import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DefaultWrapper from "../DefaultWrapper";
import Loading from "../../../shared/common/Loading";
import AccordionProgress from "../common/AccordionProgress";

const CourseProgress = (props) => {
	const {selectedGroup} = props;
	//STATES
	const [arrayCourses, setArrayCourses] = useState([])
	//EFECTS
	useEffect(() => {
		if(selectedGroup !== null && selectedGroup !== false){
			transformObject(selectedGroup)
		}
	}, [selectedGroup])

	const transformObject = (object)=> {
		let result=[] ;
		for (const key in object) {
			let obj = {};
			obj["key"] = key;
			obj["value"] = object[key];
			result.push(obj)
		}
		//result: repo_id/group/courses
		let arrayCoursess=result.slice(2)
		setArrayCourses(arrayCoursess[0].value)
	}
	//RETURN
	if(selectedGroup === null){ 
		return <Loading/>
	}
	return (
		<DefaultWrapper columns="span 16">
			<Container>
				<AccordionProgress 
					title={`Avance por curso y usuario - Grupo ${selectedGroup.group}`}
					type="group"
					data={arrayCourses}
					print={selectedGroup} //se pasa como objeto para la logica de pdf-xls
					gtc="repeat(auto-fill, minmax(120px, 1%))"
				/>
			</Container>
		</DefaultWrapper>
	);
};

export default CourseProgress;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
`;