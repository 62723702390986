import React from 'react'
import styled from 'styled-components'
import Text from '../../shared/common/Text'
import { ReactComponent as ReactLogo } from '../../assets/acropolis_lab_horizontal_color.svg'
import { Image } from '../../shared/common/Image'

const LoginAcropolis = (props) => {

    const { layout } = props
    // console.log('layout', layout)
    // console.log('layout?.font', layout?.font)

    const font =  layout?.font ? (
        layout?.font?.split("base64,").length > 1 
        ? layout?.font?.split("base64,")[1] 
        : layout?.font?.split("base64,")[0]
    ) : null
    // console.log('layout?.font', font)

    return (
        <Wrapper 
        position={layout?.inverted} 
        bgcolor={layout?.secondaryColor}
        >
            <Banner left_image={layout?.imageLeft} color={layout?.primaryColor}/>
            <Form font={font} imageRight={layout?.imageRight}>
                <Image width={`${layout?.width}px`} src={layout?.logoAlab}/>
                <ReactLogoFake color={layout?.logoAlabColor}/>
                <Text fontcolor={layout?.primaryColor}>Iniciar sesion</Text>
                <Content>
                    <Input error={layout?.textError}>Iniciar Sesion</Input>
                    <Input error={layout?.textError}>Contraseña</Input>
                    <Button color={layout?.primaryColor}>Ingresar</Button>
                    <BtnPassword>Olvidé mi contraseña</BtnPassword>
                </Content>
            </Form>
            <Crisp bgcolor={layout?.primaryColor}>C</Crisp>
        </Wrapper>
    )
}

export default LoginAcropolis

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${p => p.position === "true" && "row-reverse"};
    background-color: ${p => p.bgcolor};
    position: relative;
`

const Crisp = styled.div`
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${p => p.bgcolor};
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Banner = styled.div`
    width: 50%;
    height: 100%;
    background-color: ${p => p.color};
    background-image: url(${p => p.left_image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

const Form = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: ${p => p.color}; */
    background-image: url(${p => p.imageRight});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @font-face {
        font-family: 'Alab';
        src: url(${p => p.font && `data:application/octet-stream;base64,${p.font}`}) format("opentype");
    }
    ${p => p.font && `*
    {
        font-family: Alab !important;
    }`}
`

const Input = styled.div`
    width: 60%;
    background-color:#efefef;
    border-radius: 20px;
    height: 30px;
    color: #aaa;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    position: relative;
    margin-bottom: 10px;
    ::before{
        content: "Error en input";
        position: absolute;
        bottom: -15px;
        color: ${p => p.error};
        font-size: 10px;

    }
`

const Content = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: column;
    width: 100%;
    margin: 12px 0;
    justify-content: center;
    align-items: center;
`

const Button = styled.div`
    width: 60%;
    background-color: ${p => p.color};
    border-radius: 5px;
    height: 30px;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
`

const BtnPassword = styled.div`
    width: 60%;
    background-color: #ffffff00;
    border-radius: 5px;
    height: 30px;
    color: #bebebe;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
`

const ReactLogoFake = styled(ReactLogo)`
    width: 200px;
    fill: ${p => p.color} !important;
	.st0{
        fill: ${p => p.color} !important;
    }
	.st1{
        fill: ${p => p.color} !important;
    }
    margin: 18px 0;
`