const useAnswers = () => {
    const handleInputChange = (formik, index, optionIndex, newValue) => {
        const updatedOptions = formik.values.questions[index].data_question_options.map((option, i) => {
            if (i === optionIndex) {
                return { ...option, name: newValue };
            }
            return option;
        });
        formik.setFieldValue(`questions[${index}].data_question_options`, updatedOptions);
    };

    const handleAddAnswer = (formik, index) => {
        const lastAnswer = formik.values.questions[index].data_question_options.length - 1;
        const lastAnswerIndex = formik.values.questions[index].data_question_options[lastAnswer].indexanswers;
        const newAnswer = {
            indexanswers: lastAnswerIndex + 1,
            name: '',
            es_opcion_correcta: 'False',
            selected: false,
            comentario: '',
        };
        const updatedOptions = [...formik.values.questions[index].data_question_options, newAnswer];
        formik.setFieldValue(`questions[${index}].data_question_options`, updatedOptions);
    };

    const handleDeleteAnswer = (formik, index, indexAnswer) => {
        console.log('indexAnswer :>> ', indexAnswer);
        const updatedOptions = formik.values.questions[index].data_question_options.filter(x => x.indexanswers !== indexAnswer);
        console.log('updatedOptions :>> ', updatedOptions);
        formik.setFieldValue(`questions[${index}].data_question_options`, updatedOptions);
    };

    return {
        handleAddAnswer,
        handleDeleteAnswer,
        handleInputChange,
    };
};

export default useAnswers;
