import { call, put, takeEvery } from "redux-saga/effects";
import { subjectActions } from "../actions";
import { getSubject } from "../api/subjects";
import { GET_SUBJECT_REQUEST } from "../types/subjects.types";

function* getSubjectRequest(action) {
	try {
		const res = yield call(getSubject, action.payload);
		yield put(subjectActions.getSubjectSuccess(res));
	} catch (error) {
		yield put(subjectActions.getSubjectFail(error));
	}
}


function* subjectWatcher() {
	yield takeEvery(GET_SUBJECT_REQUEST, getSubjectRequest);
}

export { subjectWatcher };
