import * as types from "../types/content.types";

const actions = {};

// GET CONTENT
actions.getContentRequest = (payload) => {
	return {
		type: types.GET_CONTENT_REQUEST,
		payload: payload,
	};
};

actions.getContentSuccess = (content) => {
	return {
		type: types.GET_CONTENT_SUCCESS,
		payload: content,
	};
};

actions.getContentFail = (error) => {
	return {
		type: types.GET_CONTENT_FAIL,
		payload: error,
	};
};

// GET CATEGORIES
actions.getCategoriesRequest = (payload) => {
	return {
		type: types.GET_CATEGORIES_REQUEST,
		payload: payload,
	};
};

actions.getCategoriesSuccess = (payload) => {
	return {
		type: types.GET_CATEGORIES_SUCCESS,
		payload: payload,
	};
};

actions.getCategoriesFail = (error) => {
	return {
		type: types.GET_CATEGORIES_FAIL,
		payload: error,
	};
};

export { actions };
