import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";
//import { UpdateRounded } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Wrapper from "../../../shared/common/Wrapper";
import Loading from "../../../shared/common/Loading";

const SelectCourse = (props) => {
	const { handleSelected, courseAdvancesByUsers } = props;

	//STATES
	const [value, setValue] = useState(courseAdvancesByUsers ? courseAdvancesByUsers[0] : null);
	const [inputValue, setInputValue] = useState(null);

	// EFFECTS
	useEffect(() => {
		if (inputValue === null) {
			handleSelected(value)
		}
	}, []); 

	useEffect(() => {
		if (inputValue !== null) {
			handleSelected(value)
		}
	}, [value, inputValue, handleSelected]); 
	
	//RETURN
	return (
		<Wrapper padding="1.5rem" columns="span 24">
			<Container>
			{	courseAdvancesByUsers === null  ? <Loading/>

			:
				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
						}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
						}}
					id="autocomplete-course"
					options={courseAdvancesByUsers}
					getOptionLabel={(option) => option.course !== false? option.course : `REVISAR - Curso sin "nombre"` }
					style={{ width: "100%" }}
					renderInput={(params) => 
						<TextField {...params} 
						label="Selecciona un curso" 
						variant="outlined" />}
				/>
			}
				<Button
					variant="contained"
					color="primary"
					onClick={(event, newValue) => {
						setValue("all");
						}}
				>
					Todos
				</Button>
			</Container>
		</Wrapper>
	);
};

export default SelectCourse;

const Container = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1.5rem;
`;
