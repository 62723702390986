import React, { useState } from "react";

import AssignMembers from "./AssignMembers";
import ConfirmMembers from "./ConfirmMembers";
import { useEffect } from "react";

const ModalSteps = (props) => {
	const { step, 
		nextStep, 
		backStep, 
		course, 
		type, 
		handleSubmit,
		handleClose, 
		titleAssign, 
		titleConfirm,
		participantsToRemove, 
		stateObligatory, 
		selectedItems, 
		prevParticipantsEnrolled } = props;

	// STATE
	const [checkedItems, setCheckedItems] = useState(prevParticipantsEnrolled);
	const [select, setSelect] = useState(null);

	//EFECTS
	useEffect(() => {
	  if ( checkedItems.length !== 0) {
		selectedItems(checkedItems)
	  }
	}, [checkedItems])
	

	// FUNCTIONS
	function handleChecked(e) {
		if (e.target.checked) {
			setCheckedItems([...checkedItems, { id: Number(e.target.name) }]);
		} else {
			setCheckedItems(
				checkedItems.filter((x) => x.id !== Number(e.target.name))
			);
		}
	}

	const handleSelect = (select) => {
		setSelect(select)
	}

	const handleForceChecked = select => {
		setCheckedItems(select.map(item => {
			return { id: item.participante_id }
		}));
	}

	const handleCleanChecked = () => {
		setCheckedItems([])
	}

	// RETURN
	if (step === 0) {
		return (
			<AssignMembers
				nextStep={nextStep}
				checkedItems={checkedItems}
				select={select}
				handleSelect={handleSelect}
				handleChecked={handleChecked}
				course={course}
				handleForceChecked={handleForceChecked}
				handleCleanChecked={handleCleanChecked}
				handleClose={handleClose}
				titleAssign={titleAssign}
				participantsToRemove={participantsToRemove}
			/>
		);
	}

	if (step === 1) {
		return (
			<ConfirmMembers
				backStep={backStep}
				checkedItems={checkedItems}
				handleChecked={handleChecked}
				course={course}
				handleSelect={handleSelect}
				select={select}
				handleForceChecked={handleForceChecked}
				handleCleanChecked={handleCleanChecked}
				type={type}
				titleConfirm={titleConfirm}
				handleSubmit={handleSubmit}
				stateObligatory={stateObligatory}
				handleClose={handleClose}
			/>
		);
	}

	return null;
};

export default ModalSteps;
