import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DefaultWrapper from "../DefaultWrapper";
import Loading from "../../../shared/common/Loading";
import CardAvance from "../common/CardAvance";
import DownloadUser from "./Download";

const UserProgress = (props) => {
	const {userProgress, title} = props;

	//STATES
	const [arrayCourses, setArrayCourses] = useState([])

	//EFECTS
	useEffect(() => {
		if(userProgress !== null && userProgress !== false){
			transformObject(userProgress)
		}
	}, [userProgress])

	const transformObject = (object)=> {
		let result=[] ;
		for (const key in object) {
			let obj = {};
			obj["key"] = key;
			obj["value"] = object[key];
			result.push(obj)
		}
		//result: name/repo_id/courses
		let arrayCoursess=result.slice(2)
		setArrayCourses(arrayCoursess[0].value)
	}
	//RETURN
	if (userProgress === null) {
		return <Loading/>
	}if(userProgress === false){
		return 	<DefaultWrapper title="Seleccione un usuario" columns="span 24"/>
	}return (
		<>
		{
			userProgress.courses.length === 0 ?	<DefaultWrapper title={userProgress? `El usuario "${userProgress.name}" no tiene cursos asignados`  : "Avance por curso"} columns="span 24"/>
			:
			<DefaultWrapper columns="span 24">
				<DownloadUser 
				title={`Avance por curso - Usuario ${userProgress.name}`}
				print={userProgress}/>
				<Container>
					{arrayCourses.map((item, index) => (
						<CardAvance
							key={item.repo_id}
							item={item}
							type="user"
						/>
					))}
				</Container>
			</DefaultWrapper>
		}
		</>

	);
};

export default UserProgress;

const Container = styled.div`
	display:grid;
  	grid-template-columns: repeat(auto-fill, minmax(170px, 1%));
	row-gap: 0.5rem;
	column-gap:1.5rem;
  	padding: 1.5rem;
	background-color: #F2F2F2;
	justify-content: center;
`;