import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { participantActions } from '../../redux/actions';
import styled from 'styled-components';
import Wrapper from '../common/Wrapper';
import Text from '../common/Text';
import ViewCourse from '../../components/content/ViewCourse';
import RemoveUsers from '../../components/content/RemoveUsers';
import { useModal } from '../../hooks/ModalContext';
import MessageModal from '../common/MessageModal';
import EnrolledParticipants from '../../components/content/EnrolledParticipants';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import { DeleteOutlineRounded, EditRounded } from '@material-ui/icons';

const ContentCard = props => {
    const { content, customCourse, type } = props;

    // REDUX
    const dispatch = useDispatch();

    //STATES
    const [assignSuccess, setAssignSuccess] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [contentMessage, setContentMessage] = useState(null);

    //manejo de modales
    const { state, openModal, closeModal } = useModal();
    const handleOpenModal = modalType => {
        openModal(modalType);
    };
    const handleCloseModal = () => {
        closeModal();
    };

    //EFFECTS
    //matriculacion exitosa
    useEffect(() => {
        if (!!assignSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Matriculación exitosa!',
                image: '/images/matriculacion_usuario.svg',
                subtitle: `${
                    type === 'course' ? 'El curso fue asignado' : 'La Ruta Formativa fue asignada'
                } a los alumnos seleccionados de manera correcta.`,
            });
            setTimeout(() => {
                if (type === 'course') {
                    dispatch(participantActions.confirmAssignCourse());
                } else {
                    dispatch(participantActions.confirmAssignSubject());
                    //cambia a false el estado de assign de la asignatura/curso
                }
                setAssignSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [assignSuccess, dispatch]);

    //desmatriculacion exitosa
    useEffect(() => {
        if (!!removeSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Desmatriculación exitosa!',
                image: '/images/desmatriculacion_usuario.svg',
                subtitle: `${
                    type === 'course' ? 'El curso fue desasignado' : 'La Ruta Formativa fue desasignada'
                } a los alumnos seleccionados de manera correcta.`,
            });

            setTimeout(() => {
                setRemoveSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [removeSuccess, dispatch]);

    // let history = useHistory();

    // function redirectTo() {
    //     history.push(`./mi-curso/${content.id}`);
    // }

    return (
        <>
            <Wrapper>
                <Container>
                    <Photo
                        src={content?.imagen ? content?.imagen : content?.img}
                        alt="content"
                        cursor={customCourse ? 'pointer' : 'default'}
                        // onClick={customCourse && redirectTo}
                    />
                    {/* <Tag> {customCourse ? OPTIONS[content.categoria].name : "Workshop"}</Tag> */}
                    <InfoWrapper
                        cursor={customCourse ? 'pointer' : 'default'}
                        // onClick={customCourse && redirectTo}
                    >
                        <Text fontSize="1rem" fontWeight="700" component="h1">
                            {content?.nombre_publicacion
                                ? content?.nombre_publicacion
                                : content?.publication_name
                                ? content?.publication_name
                                : content?.name}
                        </Text>
                        {type !== 'viewSubject' && (
                            <Text fontSize="0.8rem" fontWeight="400" color="error" component="p">
                                {content?.descripcion ? content?.descripcion : content.description}
                            </Text>
                        )}
                    </InfoWrapper>
                    <FooterWrapper>
                        <TimeWrapper columnGap={customCourse ? '1rem' : '0.25rem'}>
                            {customCourse && (
                                <>
                                    <DeleteOutlineRounded color="error" style={{ cursor: 'pointer' }} />
                                    <EditRounded color="error" style={{ cursor: 'pointer' }} />
                                </>
                            )}
                        </TimeWrapper>
                        <ActionsWrapper>
                            {
                                type === 'course' || type === 'viewSubject' ? (
                                    <MenuButton variant="contained" color="primary" onClick={() => handleOpenModal('previsualizar')}>
                                        <RemoveRedEyeIcon />
                                    </MenuButton>
                                ) : null
                                //se bloquea la opcion de previsualizar asignatura desde esta card
                                //cuando se convoca desde las matriculaciones de un alumno
                                //ya que no viene la info de los OA que componen dicha asignatura, hasta desarrollo del EP
                            }

                            <MenuButton variant="contained" color="primary" onClick={() => handleOpenModal('matricular')}>
                                <GroupAddIcon />
                            </MenuButton>
                            <MenuButton variant="contained" color="primary" onClick={() => handleOpenModal('desmatricular')}>
                                <GroupRemoveRoundedIcon />
                            </MenuButton>
                        </ActionsWrapper>
                    </FooterWrapper>
                </Container>
            </Wrapper>

            {state.isOpen &&
                state.modalType === 'entendido' && ( //mensaje entendido
                    <MessageModal
                        isOpen={state.isOpen}
                        handleClose={handleCloseModal}
                        message={contentMessage?.message}
                        image={contentMessage?.image}
                        subtitle={contentMessage?.subtitle}
                    />
                )}
            {state.isOpen &&
                state.modalType === 'matricular' && ( //modal de matriculación
                    <EnrolledParticipants
                        content={content}
                        type={`${type === 'course' ? 'course' : 'subject'}`}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        success={() => setAssignSuccess(true)}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'previsualizar' && ( //modal de previsualización
                    <ViewCourse course={content} openModal={state.isOpen} closeModal={handleCloseModal} />
                )}
            {state.isOpen &&
                state.modalType === 'desmatricular' && ( //modal de desmatriculación
                    <RemoveUsers
                        course={content}
                        type={`${type === 'course' ? 'course' : 'subject'}`}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        removeSucces={() => setRemoveSuccess(true)}
                    />
                )}
        </>
    );
};

export default ContentCard;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
`;

const Photo = styled.img`
    width: 100%;

    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 25px 25px 0 0;
    cursor: ${p => p.cursor};
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 100%;
    cursor: ${p => p.cursor};
`;

const FooterWrapper = styled.div`
    padding: 1rem;
    gap: 5px;
    border-top: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
`;

const TimeWrapper = styled.div`
    grid-area: top;
    display: flex;
    align-items: center;
    column-gap: ${p => p.columnGap};
`;

const ActionsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 5px;
    color: var(--primary-one);
    justify-content: space-evenly;
    svg {
        fill: var(--primary-one);
    }
`;

const MenuButton = styled.button`
    background-color: var(--primary-one);
    border-radius: 3rem;
    padding: 0.5rem;
    color:var(--secondary-one);
    gap: 1rem;
    border: none;
    width: calc(100%-1rem);
    background-color: transparent;
    align-items: center;
    display: flex;
    justify-content: center;

    :hover {
        background-color:var(--secondary-three);
        /* svg,
        span {
            color: var(--primary-one) !important;
            fill: var(--primary-one) !important;
        } */
    }
`;
