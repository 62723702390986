import * as types from "../types/myContent.types";

const actions = {};

// GET MY CONTENT
actions.getMyContentRequest = (id) => {
	return {
		type: types.GET_MY_CONTENT_REQUEST,
		payload: id,
	};
};

actions.getMyContentSuccess = (content) => {
	return {
		type: types.GET_MY_CONTENT_SUCCESS,
		payload: content,
	};
};

actions.getMyContentError = (error) => {
	return {
		type: types.GET_MY_CONTENT_FAIL,
		payload: error,
	};
};

// CREATE CONTENT
actions.createContentRequest = (content) => {
	return {
		type: types.CREATE_CONTENT_REQUEST,
		payload: content,
	};
};

actions.createContentSuccess = (payload) => {
	return {
		type: types.CREATE_CONTENT_SUCCESS,
		payload: payload,
	};
};

actions.createContentFail = (error) => {
	return {
		type: types.CREATE_CONTENT_FAIL,
		payload: error,
	};
};

// DELETE CONTENT
actions.deleteContentRequest = (id) => {
	// console.log(id);
	return {
		type: types.DELETE_CONTENT_REQUEST,
		payload: id,
	};
};

// CREATE UNIT
actions.createUnitRequest = (unit) => {
	// console.log(unit);
	return {
		type: types.CREATE_UNIT_REQUEST,
		payload: unit,
	};
};

actions.createUnitSuccess = (unit) => {
	return {
		type: types.CREATE_UNIT_SUCCESS,
		payload: unit,
	};
};

actions.createUnitFail = (error) => {
	return {
		type: types.CREATE_UNIT_FAIL,
		payload: error,
	};
};

export { actions };
