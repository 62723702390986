import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import ResourceTypeChip from './ResourceTypeChip';
import MenuAction from './MenuAction';
import CellAsigned from './CellAsigned';

// Material UI
import {
    CheckboxStyle,
    Container,
    HeaderCell,
    HeaderRow,
    ResourceCell,
    ResourceContainer,
    ResourceRow,
    ResourceSpan,
} from './styled-components/table.styled.components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function TableResource({ resources, setSelectedResources, selectedResources, type }) {
    let history = useHistory();
    const [expanded, setExpanded] = useState(false);

    const handleClick = (event, id) => {
        const selectedIndex = selectedResources.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedResources, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedResources.slice(1));
        } else if (selectedIndex === selectedResources.length - 1) {
            newSelected = newSelected.concat(selectedResources.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedResources.slice(0, selectedIndex), selectedResources.slice(selectedIndex + 1));
        }
        setSelectedResources(newSelected);
    };

    const isSelected = id => selectedResources.indexOf(id) !== -1;

    const visibleRows = resources;

    const archivedStateChanged = () => {
        history.push(`/resources?tab=recursos_empresa`);
    };

    const handleChange = (panel) => {
      setExpanded(expanded === panel ? false : panel);
    };
  

    return (
        <Container>
            <ResourceContainer>
                <HeaderRow>
                    <HeaderCell width="3%"></HeaderCell>
                    <HeaderCell width="15%">TIPO DE RECURSO</HeaderCell>
                    <HeaderCell width="35%">NOMBRE</HeaderCell>
                    <HeaderCell width="30%">CURSOS ASIGNADOS</HeaderCell>
                    <HeaderCell width="15%"></HeaderCell>
                </HeaderRow>
                {visibleRows.map(row => {
                    const isItemSelected = isSelected(row);
                    return (
                        <ResourceRow key={row?.id} selected={isItemSelected}>
                            <ResourceCell width="3%" /* bg="orange" */>
                                <CheckboxStyle checked={isItemSelected} onClick={event => handleClick(event, row)} />
                            </ResourceCell>
                            <ResourceCell width="15%" /* bg="yellow" */>
                                <ResourceTypeChip type={row?.type} />
                            </ResourceCell>
                            <ResourceCell width="35%" /* bg="green" */>
                                <ResourceSpan>{row?.title}</ResourceSpan>
                            </ResourceCell>
                            <ResourceCell width="30%" /* bg="blue" */>
                                <CellAsigned 
                                list={row?.courses} 
                                id={row?.id} 
                                expanded={expanded}
                                handleChange={handleChange}
                                />
                            </ResourceCell>
                            <ResourceCell width="15%" /* bg="red" */ justifyContent="end">
                                    <MenuAction resource={row} type={type} archivedStateChanged={archivedStateChanged} />
                            </ResourceCell>
                        </ResourceRow>
                    );
                })}
            </ResourceContainer>
        </Container>
    );
}

TableResource.propTypes = {
    resources: PropTypes.array.isRequired,
};
