import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../../shared/layout/Layout';
import Header from '../../shared/layout/header/Header';
import EditRouteForm from '../../components/content-creation/EditRouteForm';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { getRoute } from '../../redux/api/content-creation.api';
import { ModalProvider } from '../../hooks/ModalContext';

const EditRoute = () => {
    const { id } = useParams();
    let history = useHistory();
    const ondemandId = useSelector(state => state.user.user.id);

    //STATES
    const [customRouteToEdit, setCustomRouteToEdit] = useState(null);
    const [routeEdited, setRouteEdited] = useState(null);
    const [publishedCustomRoute, setPublishedCustomRoute] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getCustomRoute(id);
        }
    }, [id]);

    useEffect(() => {
        if (!!publishedCustomRoute || !!routeEdited) {
            getCustomRoute(id);
            history.push(`/subjects?tab=rutas_empresa`);
        }
    }, [publishedCustomRoute, routeEdited]);

    //FUNCTIONS
    const getCustomRoute = async id => {
        let req = await getRoute(id, ondemandId);
        console.log('req getCustomCourse', req);
        if (!!req) {
            setCustomRouteToEdit(req);
        }
    };

    const handleState = route => {
        setRouteEdited(route);
    };

    const handlePublished = route => {
        setRouteEdited(null);
        setPublishedCustomRoute(route);
    };

    return (
        <Layout header={<Header route="Edición de ruta formativa" />}>
            <HeaderWrapper>
                {!!customRouteToEdit && (
                    <ModalProvider>
                        <EditRouteForm
                            customRouteToEdit={customRouteToEdit[0]}
                            routeId={id}
                            handleState={handleState}
                            handlePublished={handlePublished}
                            ondemand_id={ondemandId}
                        />
                    </ModalProvider>
                )}
            </HeaderWrapper>
        </Layout>
    );
};

export default EditRoute;
const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 2rem);
    height: 100vw;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
