import * as types from "../types/licenses.types";

const actions = {};

actions.getLicensesRequest = (id) => {
	return {
		type: types.GET_LICENSES_REQUEST,
		payload: id,
	};
};

actions.getLicensesSuccess = (payload) => {
	return {
		type: types.GET_LICENSES_SUCCESS,
		payload: payload,
	};
};

actions.getLicensesFail = (error) => {
	return {
		type: types.GET_LICENSES_FAIL,
		payload: error,
	};
};

export { actions };
