import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

import DefaultTabs from '../../shared/common/DefaultTabs';
import Header from '../../shared/layout/header/Header';
import Layout from '../../shared/layout/Layout';
import TabEvents from '../../components/events/TabEvents';
import TabBanner from '../../components/announcementsAndNews/TabBanner';
import TabPopUp from '../../components/announcementsAndNews/TabPopUp';
import Blog from '../Blog';


function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

const XtraContent = () => {
    const user = useSelector(state => state.user.user);
    const params = useQueryParams();
    const tabQuery = params.get('tab');

    // STATE
    const [tabValue, setTabValue] = useState( 0 );

    const tabOptions = [
        // { id: 0, label: 'Eventos' },
        { id: 0, label: 'Artículos' },
        { id: 1, label: 'Banner' },
        { id: 2, label: 'Pop-Up' }
    ];


    //EFECTS
    useEffect(() => {
        if (tabQuery) {
            setTabValue(
                // tabQuery === 'eventos' ? 0
                //     : 
                    tabQuery === 'blog-de-articulos' ? 0
                        : tabQuery === 'banner' ? 1
                            : tabQuery === 'pop-up' ? 2 : null
            );
        }
    }, [tabQuery]);

    // FUNCTIONS
    function handleTabValue(e, newValue) {
        setTabValue(newValue);
    }

    function getTabOption(option) {
        switch (option) {
            // case 0:
            //     return <TabEvents user={user}/>;
            case 0:
                // return <TabArticles user={user}/>;
                return <Blog user={user}/>;
            case 1:
                return <TabBanner user={user}/>;
            case 2:
                return <TabPopUp user={user}/>;
            default:
                return null;
        }
    }


    console.log('tabValue', tabValue)
    return (
        <Layout header={<Header route="Contenido Extra"></Header>}>
            <Container>
                <DefaultTabs 
                tabValue={tabValue} 
                handleTabValue={handleTabValue} 
                tabOptions={tabOptions} 
                paddingleft="2rem"/>
            </Container>
            {getTabOption(tabValue)}
        </Layout>
    );
}

export default XtraContent; 

const Container = styled.div`
    display: flex;
    width: calc(100% - 70px - 6rem);
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 0;
    position: absolute;
    left: 70px;
    top: 140px;
    z-index: 9;
    border-top: 1px solid #f7f7f7;
    padding: 0 3rem;
`;
