export const GET_ANALYTICS_STATUS_REQUEST = "GET_ANALYTICS_STATUS_REQUEST";
export const GET_ANALYTICS_STATUS_SUCCESS = "GET_ANALYTICS_STATUS_SUCCESS";
export const GET_ANALYTICS_STATUS_FAIL = "GET_ANALYTICS_STATUS_FAIL";

export const GET_ANALYTICS_COURSES_REQUEST = "GET_ANALYTICS_COURSES_REQUEST";
export const GET_ANALYTICS_COURSES_SUCCESS = "GET_ANALYTICS_COURSES_SUCCESS";
export const GET_ANALYTICS_COURSES_FAIL = "GET_ANALYTICS_COURSES_FAIL";

export const GET_COURSE_ADVANCES_BY_USERS_REQUEST = "GET_COURSE_ADVANCES_BY_USERS_REQUEST";
export const GET_COURSE_ADVANCES_BY_USERS_SUCCESS = "GET_COURSE_ADVANCES_BY_USERS_SUCCESS";
export const GET_COURSE_ADVANCES_BY_USERS_FAIL = "GET_COURSE_ADVANCES_BY_USERS_FAIL";

export const GET_USER_ADVANCE_BY_COURSES_REQUEST = "GET_USER_ADVANCE_BY_COURSES_REQUEST";
export const GET_USER_ADVANCE_BY_COURSES_SUCCESS = "GET_USER_ADVANCE_BY_COURSES_SUCCESS";
export const GET_USER_ADVANCE_BY_COURSES_FAIL = "GET_USER_ADVANCE_BY_COURSES_FAIL";

export const GET_GROUP_ADVANCE_BY_COURSES_REQUEST = "GET_GROUP_ADVANCE_BY_COURSES_REQUEST";
export const GET_GROUP_ADVANCE_BY_COURSES_SUCCESS = "GET_GROUP_ADVANCE_BY_COURSES_SUCCESS";
export const GET_GROUP_ADVANCE_BY_COURSES_FAIL = "GET_GROUP_ADVANCE_BY_COURSES_FAIL";