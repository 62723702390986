import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useModal } from '../../hooks/ModalContext';
import { participantActions } from '../../redux/actions';
import { deleteCustomRoute, updateCustomRoute } from '../../redux/api/content-creation.api';
import ViewSubject from '../subjects/ViewSubject';
import RemoveUsers from '../content/RemoveUsers';
import Snack from '../../shared/common/Snack';
import MessageModal from '../../shared/common/MessageModal';
import EnrolledParticipants from '../content/EnrolledParticipants';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Menu, MenuItem } from '@material-ui/core';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const CustomSubjectAlabCard = props => {
    const { subject, ondemand_id, deleted, publishedStateChanged } = props;
    // REDUX
    const dispatch = useDispatch();

    //STATES
    const [contentMessage, setContentMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    const [assignSuccess, setAssignSuccess] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [deletedSuccess, setDeletedSuccess] = useState(false);
    const [changePublishedState, setChangePublishedState] = useState(null);

    //menu izq desplegable
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    //manejo de modales
    const { state, openModal, closeModal } = useModal();
    const handleOpenModal = modalType => {
        openModal(modalType);
    };
    const handleCloseModal = () => {
        closeModal();
    };

    const publishedState = subject?.can_be_published;
    // console.log('publishedState', publishedState)

    //EFFECTS
    //publicacion
    useEffect(() => {
        if (!!changePublishedState) {
            handleOpenModal('entendido');
            setContentMessage({
                message: `${publishedState === false ? 'Publicada' : 'Despublicada'}`,
                image: '/images/published_article.svg',
                subtitle: `La Ruta Formativa fue ${publishedState === false ? ' publicada ' : ' despublicada '}con éxito`,
            });

            setTimeout(() => {
                publishedStateChanged();
                setChangePublishedState(null);
            }, 2000);
        }
        //eslint-disable-next-line
    }, [changePublishedState]);

    //matriculacion exitosa
    useEffect(() => {
        if (!!assignSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Matriculación exitosa!',
                image: '/images/matriculacion_usuario.svg',
                subtitle: 'La Ruta Formativa fue asignada a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                dispatch(participantActions.confirmAssignSubject());
                setAssignSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [assignSuccess, dispatch]);

    //desmatriculacion exitosa
    useEffect(() => {
        if (!!removeSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Desmatriculación exitosa!',
                image: '/images/desmatriculacion_usuario.svg',
                subtitle: 'La Ruta Formativa fue desasignada a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                setRemoveSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [removeSuccess, dispatch]);

    //eliminacion de ruta exitosa
    useEffect(() => {
        if (!!deletedSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: 'Eliminada',
                image: '/images/delete_article.png',
                subtitle: 'La Ruta Formativa fue eliminada con éxito.',
            });
            deleted(subject.id);

            setTimeout(() => {
                setDeletedSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [deletedSuccess, dispatch]);

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    //Publicación o despublicación de Ruta Formativa
    const handlePublishState = async () => {
        //estado publicado: accion "Despublicar"
        if (!!publishedState) {
            await handleUpdatePublish(false, subject.id);
            console.log('Despublicar', publishedState);
        } else {
            //estado despublicado: accion "Publicar"
            await handleUpdatePublish(true, subject.id);
            console.log('Publicar', publishedState);
        }
    };

    //Publicación o despubicaciòn desde boton _ action debe ser tru o false
    const handleUpdatePublish = async (action, routeId) => {
        // console.log('action', action);
        const data = {
            id: Number(routeId),
            can_be_published: action,
        };
        let response = await updateCustomRoute(ondemand_id, data);
        // console.log('response - handleUpdatePublish', response);

        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                La ruta personalizada no pudo publicarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setChangePublishedState(true);
        }
    };

    //eliminación confirmada por modal _ publicacion del resultado del ep
    const handleConfirmedDeleteRoute = () => {
        handleDeleteSubject();
        setTimeout(() => {
            // setDeletedSuccess(true);
            console.log('setDeletedSuccess', setDeletedSuccess);
        }, 500);
    };

    const handleDeleteSubject = async () => {
        let response = await deleteCustomRoute(ondemand_id, subject.id);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                La ruta personalizada no pudo eliminarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setDeletedSuccess(true);
            handleCloseModal();
        }
    };

    //edición de ruta
    const handleConfirmedEditRoute = () => {
        handleCloseModal();
        window.location.href = `/edit-custom-route/${subject.id}`;
        // console.log('handleConfirmedEditRoute');
    };

    function getValidPublicationName(value) {
        const properties = ['name', 'nombre', 'nombre_publicacion', 'publication_name', 'title'];
        for (let prop of properties) {
            if (value[prop] && value[prop].trim() !== '') {
                return value[prop];
            }
        }
        return '';
    }

    function getValidDescription(value) {
        const properties = ['description', 'descripcion'];
        for (let prop of properties) {
            if (value[prop] && value[prop].trim() !== '') {
                return value[prop];
            }
        }
        return '';
    }

    // console.log('setDeletedSuccess', setDeletedSuccess);
    return (
        <>
            <CardFake>
                <ButtonState bgc={publishedState}>
                    {publishedState === true ? (
                        <>
                            {' '}
                            <CheckRoundedIcon /> Publicado{' '}
                        </>
                    ) : (
                        <>
                            {' '}
                            <EditRoundedIcon /> Borrador{' '}
                        </>
                    )}
                </ButtonState>
                <Header image={subject?.img}>{subject?.tag_id ? <TagSpan>{subject.tag_id[0]}</TagSpan> : null}</Header>

                <BodyCard>
                    <h2>{getValidPublicationName(subject)}</h2>
                    <Span>{getValidDescription(subject)}</Span>
                </BodyCard>

                <Footer>
                    <>
                        <MoreVertRoundedIcon
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        />
                        <MenuFake
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                            <MenuItem onClick={() => handleOpenModal('desea_editar')}>
                                <EditRoundedIcon />
                                Editar
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('previsualizar')}>
                                <RemoveRedEyeIcon />
                                Vista previa
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('matricular')}>
                                <GroupAddIcon />
                                Matricular alumnos
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('desmatricular')}>
                                <GroupRemoveRoundedIcon />
                                Desmatricular alumnos
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('desea_eliminar')}>
                                <GroupRemoveRoundedIcon />
                                Eliminar
                            </MenuItem>
                        </MenuFake>
                    </>
                    <ButtonToPublish bgc={publishedState} onClick={handlePublishState}>
                        {!!publishedState ? 'Despublicar' : 'Publicar'}
                    </ButtonToPublish>
                </Footer>
            </CardFake>
            {state.isOpen &&
                state.modalType === 'entendido' && ( //mensaje entendido
                    <MessageModal
                        isOpen={state.isOpen}
                        handleClose={handleCloseModal}
                        message={contentMessage?.message}
                        image={contentMessage?.image}
                        subtitle={contentMessage?.subtitle}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'matricular' && ( //modal de matriculación
                    <EnrolledParticipants
                        content={subject}
                        type={'subject'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        success={() => setAssignSuccess(true)}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'previsualizar' && ( //modal de previsualización
                    <ViewSubject subject={subject} openModal={state.isOpen} closeModal={handleCloseModal} />
                )}

            {state.isOpen &&
                state.modalType === 'desmatricular' && ( //modal de desmatriculación
                    <RemoveUsers
                        course={subject}
                        type={'subject'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        removeSucces={() => setRemoveSuccess(true)}
                    />
                )}

            {state.isOpen && state.modalType === 'desea_editar' && (
                <MessageModal
                    isOpen={state.isOpen}
                    handleClose={handleCloseModal}
                    message={'¿Deseas editar esta ruta formativa?'}
                    image={'/images/alerta_edicion.svg'}
                    subtitle={
                        'Ten en cuenta que si modificas la estructura o el contenido de esta ruta, los alumnos que se encuentran matriculados, verán su progreso de avance afectado.'
                    }
                    handleClickConfirm={handleConfirmedEditRoute}
                />
            )}

            {state.isOpen && state.modalType === 'desea_eliminar' && (
                <MessageModal
                    isOpen={state.isOpen}
                    handleClose={handleCloseModal}
                    message="¿Deseas eliminar?"
                    image="/images/eliminacion_modulo.svg"
                    subtitle="Estás a punto de eliminar una ruta formativa"
                    handleClickConfirm={handleConfirmedDeleteRoute}
                />
            )}
        </>
    );
};

export default CustomSubjectAlabCard;

const CardFake = styled.div`
    min-width: 350px;
    height: 340px;
    padding: 0;
    max-width: 320px;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;
    background-color: #fafafa;
`;

const Header = styled.div`
    height: 100px;
    padding: 1.5rem;
    border-radius: 20px;
    width: calc(100% - 3rem);
    background-image: url(${p => `${p.image}`});
    background-size: cover;
    background-position: center center;
    position: relative;
`;

const BodyCard = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
    width: 290px;
    overflow: hidden;
    white-space: nowrap;
    h2 {
        color: #222222;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        padding: 0.5rem;
    }
`;

const Span = styled.span`
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 140%;
    color: #616161;
    display: flex;
    align-items: flex-end;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-overflow: ellipsis;

    svg {
        font-weight: 400;
        font-size: 1.5rem;
        color: #616161;
        opacity: 50%;
        padding: 0 1rem 0 0;
    }
`;

const ButtonState = styled.div`
    background-color: ${p => (!!p.bgc ? '#35D0A5' : '#616161')};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0.3rem 0.5rem;
    gap: 0.25rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ffffff;

    position: absolute;
    top: 2.5%;
    right: 2.5%;
    z-index: 5;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100%-4rem);
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: calc(100%-2rem);
    gap: 0.8rem;
    position: absolute;
    bottom: 1%;
    left: 1%;
    right: 1%;

    svg {
        fill: var(--secondary-four);
        cursor: pointer;
    }
`;

const TagSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 10px;
    position: absolute;
    width: 50%;
    left: 0px;
    bottom: 0px;
    background: #ffffff;
    border-radius: 0px 10px 0px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
    color: #616161;
`;

const MenuFake = styled(Menu)`
    ul li span {
        color: var(--secondary-four);
        font-size: 0.8rem;
        font-weight: 400;
        font-family: 'Montserrat';
        font-style: normal;
    }

    .MuiList-root-MuiMenu-list {
        color: var(--secondary-four);
    }

    .MuiSvgIcon-root {
        fill: var(--secondary-four);
        margin-right: 1rem;
    }
    .MuiIconButton-colorPrimary:hover {
        background-color: transparent;
    }

    .MuiIconButton-root:hover {
        background-color: transparent;
    }

    .MuiButtonBase-root {
        width: 100%;
        justify-content: flex-start;
        color: #222222;
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
    }
`;

const ButtonToPublish = styled.div`
    background-color: ${p => (p.bgc === true ? 'var(--tertiary-three)' : 'var(--primary-one)')};
    border-radius: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0.5rem 1.5rem;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1.5rem;
    letter-spacing: 0.001em;
    color: ${p => (p.bgc === true ? 'var(--secondary-three)' : '#FFFFFF')};
`;
