import { Checkbox, TableRow } from '@material-ui/core';
import styled from 'styled-components';

//contenedores
export const ContainerResources = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 0;
    z-index: 1;
    padding: 0 1rem;
`;

export const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const SecondRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ThirdRow = styled.div`
    min-height: 50vh;
`

export const ResourcesNotFound = styled.div`
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: #616161;
`

export const ResourceCreate = styled.div`
    cursor: pointer;
    height: 40px;
    border-radius: 4rem;
    color: white;
    padding: 0 1rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-one);
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.25rem;
`;

//botones
export const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.5rem;
`;

export const TableRowStyle = styled(TableRow)`
    &&.MuiTableRow-root.Mui-selected {
        background-color: transparent;
    }
    &&.MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: transparent !important;
    }
`;

export const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 0.25fr 1fr 2fr 2fr 1fr;
    grid-column-gap: 0.5rem;
    justify-items: start;
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    margin: 1rem;
    padding: 0.25rem 0 0.25rem 1rem;
    height: calc(60px - 0.5rem);
    :hover {
        background-color: #f9f9f9;
        border: 1px solid #616161;
    }
`;
export const ResourceSpan = styled.span`
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
`;

export const CheckboxStyle = styled(Checkbox)`
    svg {
        color: var(--primary-one);
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;

    b {
        color: #b31d15;
    }

    button{
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;
