import { Icon } from '@iconify/react/dist/iconify.js';
import Text from "../../../../shared/common/Text";
import { Container, Header, Info, InfoCard, InfoCardIcon, InfoCardDescription, Description, Questions, Question, QuestionHeader, QuestionDescription } from "./styled-components/preview.styled.components";


const EvaluationPreview = (props) => {

    const { evaluation } = props;

    return (
        <Container>
            <Header>
                {evaluation?.name}
            </Header>
            <Info>
                <InfoCard>
                    <InfoCardIcon>
                        <Icon icon="fontisto:spinner-rotate-forward" width="1.2em" height="1.2em" />
                    </InfoCardIcon>
                    <InfoCardDescription>
                        <Text
                            fontSize="10px"
                            fontWeight="700"
                            fontcolor="#616161"
                        >
                            INTENTOS
                        </Text>
                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            fontcolor="#222"
                        >
                            {evaluation?.attempts_quantity}
                        </Text>
                    </InfoCardDescription>
                </InfoCard>
                <InfoCard>
                    <InfoCardIcon>
                        <Icon icon="fontisto:spinner-rotate-forward" width="1.2em" height="1.2em" />
                    </InfoCardIcon>
                    <InfoCardDescription>
                        <Text
                            fontSize="10px"
                            fontWeight="700"
                            fontcolor="#616161"
                        >
                            TIEMPO
                        </Text>
                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            fontcolor="#222"
                        >
                            {evaluation?.attempts}
                        </Text>
                    </InfoCardDescription>
                </InfoCard>
            </Info>
            <Text
                style={{ marginLeft: "1rem" }}
                fontSize="18px"
                fontWeight="700"
                fontcolor="#b31d15"
            >
                Instrucciones
            </Text>
            <Description>
                <div className="content" dangerouslySetInnerHTML={{ __html: evaluation?.description }}></div>
            </Description>
            <Questions>
                {
                    evaluation?.preview_options?.map((question, index) => {
                        return (
                            <Question key={index}>
                                <QuestionHeader>
                                    <p>Pregunta {question?.order}</p>
                                    <p>{question?.score} pts</p>
                                </QuestionHeader>
                                <QuestionDescription>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: question?.description }}></div>
                                </QuestionDescription>
                            </Question>
                        )
                    })
                }
            </Questions>
        </Container>
    )
}

export default EvaluationPreview;