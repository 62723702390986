import React, { useEffect, useState } from 'react';
import { Modal, ModalCard, Check, CheckboxStyle, ButtonsContainer, AssigmentButton } from './styled-components/assignment.styled.components';
import { getPersonalizedCourses, patchAssignResource } from '../../services/resources.services';
import { useSelector } from 'react-redux';
import MessageModal from '../../../../shared/common/MessageModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AssignmentResourceButton = ({ selectedResources }) => {
    let history = useHistory();

    //STATES
    const [openModal, setOpenModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [coursesAssigned, setCoursesAssigned] = useState([]);
    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState('');
    const [messageSuccesOpen, setMessageSuccesOpen] = useState(false);
    const [messageErrorOpen, setMessageErrorOpen] = useState(false);

    const ondemand_id = useSelector(state => state?.user?.user?.id);

    //FUNCTIONS
    const handleClick = id => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(item => item !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const getCourses = async () => {
        try {
            const courses = await getPersonalizedCourses(ondemand_id);
            setCoursesAssigned(courses);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAssingment = async () => {
        setLoading(true);

        const instructions = [];

        // Se verifica que el recurso no esté previamente asignado a un curso asignado
        selectedResources.forEach(resource => {
            selectedItems.forEach(courseId => {
                // Verifica si el curso actual está en los cursos del recurso
                const courseFound = resource?.courses?.some(course => course?.id === courseId);
                // Si no se encuentra el curso, agrega las instrucciones
                if (!courseFound) {
                    instructions.push({
                        curse_id: courseId,
                        resource_id: resource?.id,
                        area_id: '188', // El área se hardcodea en 188 por pedido de Juan Francisco Indelicato
                        action: 'assign',
                    });
                }
            });
        });

        const body = {
            instructions: instructions,
        };

        try {
            await patchAssignResource(body);
            setLoading(false);
            setStatus('success');
        } catch (error) {
            console.error(error);
            setLoading(false);
            setStatus('error');
        }
        return null;
    };

    //EFFECTS
    useEffect(() => {
        getCourses();
        setLoading(false);
        setStatus('');
        setSelectedItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);

    useEffect(() => {
        if (status === 'success') {
            setOpenModal(false);
            setMessageSuccesOpen(true);
        } else if (status === 'error') {
            setOpenModal(false);
            setMessageErrorOpen(true);
        }
    }, [status]);

    function handleClose() {
        setMessageSuccesOpen(false);
        setMessageErrorOpen(false);
        history.push(`/resources`);
    }

    return (
        <>
            {openModal && (
                <Modal>
                    <ModalCard>
                        <h2>Cursos donde puedes realizar la asignación de recursos:</h2>
                        {coursesAssigned?.map((course, index) => (
                            <Check key={index}>
                                <label htmlFor={index}>{course?.name}</label>
                                <CheckboxStyle checked={selectedItems.includes(course?.id) || false} onClick={event => handleClick(course?.id)} />
                            </Check>
                        ))}
                        <ButtonsContainer status={status}>
                            <AssigmentButton onClick={() => setOpenModal(false)}>Cancelar</AssigmentButton>
                            <AssigmentButton disabled={loading} onClick={handleAssingment} type="primary">
                                {loading ? 'Cargando...' : 'Asignar a curso'}
                            </AssigmentButton>
                        </ButtonsContainer>
                    </ModalCard>
                </Modal>
            )}
            <AssigmentButton onClick={() => setOpenModal(true)}>Asignar a curso</AssigmentButton>
            {/* status === "error" */}
            <MessageModal
                isOpen={messageErrorOpen}
                handleClose={handleClose}
                message="¡Ups!"
                image="/rediseño_2024/fallo_accion.png"
                subtitle="Hubo un error al asignar los recursos"
            />
            {/* status === "success" */}
            <MessageModal
                isOpen={messageSuccesOpen}
                handleClose={handleClose}
                message="¡La asignación del recurso ha sido exitosa!"
                image="/rediseño_2024/accion_exitosa.png"
                subtitle=""
            />
        </>
    );
};

export default AssignmentResourceButton;
