import React from 'react'
import styled from 'styled-components'
import Text from '../../../../shared/common/Text'

const CardService = (props) => {

  const { values, onClick } = props

  return (
    <CardServiceWrapper>
      <Text textalign="center" fontSize="16px" fontWeight="bold" fontcolor={"#000"}>
        {values.name}
      </Text>
      <SwitchLabel className="switch" onClick={() => onClick(values.key)}>
        <CheckboxInput type="checkbox" checked={values.state} onChange={() => onClick(values.key)} />
        <Slider className="slider" />
      </SwitchLabel>
    </CardServiceWrapper>
  )
}

export default CardService

const CardServiceWrapper = styled.div`
    width: calc(100% - 4rem);
    margin: 0 1rem;
    padding: 0 1rem;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    overflow: hidden;
    border-bottom: 1px solid #a8a8a8;
`

const SwitchLabel = styled.label`
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #79747E;
  transition: 0.4s;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.25em;
    background-color: #79747E;
    transition: 0.4s;
  }
`;

const CheckboxInput = styled.input`
    visibility: hidden;

  &:checked + ${Slider} {
    background-color: var(--primary-one);
    border: 1px solid var(--primary-one);
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px var(--primary-one);
  }

  &:checked + ${Slider}:before {
    transform: translateX(1.4em);
    background-color: #fff;
  }
`;

