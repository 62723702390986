import React from 'react';
import {
    ButtonWithBackground,
    ButtonWithBorder,
    FormContainer,
    WrapperButtons,
} from '../../../../styled-components/forms.styled.components';
import { FieldArray, Formik, useFormik } from 'formik';
import { secondSchemaEvaluation } from '../../../../../../utils/schemas';
import DivQuestion from './DivQuestion';
// import MessageModal from '../../../../../../shared/common/MessageModal';

const SecondStepEvaluation = props => {
    const secondStateEvaluation = JSON.parse(localStorage.getItem('2evaluation'));

    const { handleSubmit, handlePrev, handleCompleted, handleDismiss } = props;
//  const [messageOpen, setMessageOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            questions: [
                {
                    description: '',
                    typeId: 0,
                    type: '', //unica_opcion / multiple_opcion / verdadero_falso
                    order: 0,
                    score: 0,
                    data_question_options: [
                        { indexanswers: 0, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                        { indexanswers: 1, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                        { indexanswers: 2, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                    ],
                },
            ],
        },
    });

    const addQuestion = formik => {
        const lastOrderQuestion = formik.values.questions?.length ? Math.max(...formik.values.questions.map(q => q.order)) : 0;
        console.log('lastOrderQuestion :>> ', lastOrderQuestion);
        const validateAnswer = formik.values.questions[formik.values.questions?.length - 1].data_question_options; //valida 1 objeto literal de respuestas completas  para la ultima poregunta existente

        if (validateAnswer.length === 0) {
            alert('Por favor seleccionar la/s respuesta/s correcta/s de la pregunta anterios');
        } else {
            formik.setValues({
                ...formik.values,
                questions: [
                    ...formik.values.questions,
                    {
                        description: '',
                        order: lastOrderQuestion + 1,
                        score: 0,
                        typeId: 0,
                        type: 0, //unica_opcion / multiple_opcion / verdadero_falso
                        data_question_options: [
                            { indexanswers: 0, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                            { indexanswers: 1, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                            { indexanswers: 2, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                        ],
                    },
                ],
            });
        }
    };

    const deleteQuestion = (formik, order) => {
        const updatedQuestions = formik.values.questions
            .filter(x => x.order !== order)
            .map((q, index) => ({
                ...q,
                order: index + 1, //reordena el array
            }));
        formik.setValues({ ...formik.values, questions: updatedQuestions });
    };

    const handleClearQuestion = () => {
        console.log('handleClearQuestion :>> ');
    };

    // const handleClose = () => {
    //     setMessageOpen(false);
    // };


    return (
        <>
            <Formik
                initialValues={!!secondStateEvaluation ? secondStateEvaluation : formik.initialValues}
                onSubmit={values => {
                    localStorage.setItem('2evaluation', JSON.stringify(values));
                    alert('Datos del Paso 2 guardados');
                    handleSubmit();
                }}
                validationSchema={secondSchemaEvaluation}>
                {(formik, values) => (
                    <FormContainer>
                        <FieldArray name="questions">
                            <>
                                {formik.values.questions.length > 0 &&
                                    formik.values.questions.map(
                                        (question, index) =>
                                            question.order >= 0 && (
                                                <DivQuestion
                                                    key={question.order}
                                                    index={index}
                                                    question={question}
                                                    formik={formik}
                                                    remove={deleteQuestion}
                                                    handleClearQuestion={handleClearQuestion}
                                                />
                                            ),
                                    )}
                                <ButtonWithBorder bordercolor="var(--primary-one)" type="button" onClick={() => addQuestion(formik)}>
                                    Añadir Pregunta
                                </ButtonWithBorder>
                            </>
                        </FieldArray>
{/*                         
                        {//modal por error de sumatoria de score
                        formik.errors.questions && 
                        typeof formik.errors.questions === 'string' 
                        ? setMessageOpen(true)
                        : null
                        } */}

                        <WrapperButtons>
                            <ButtonWithBorder bordercolor="var(--primary-one)" type="button" onClick={handleDismiss}>
                                Descartar Cambios
                            </ButtonWithBorder>
                            <ButtonWithBackground
                                bgcolor={!(formik.isValid && formik.dirty) ? '#A8A8A8' : 'var(--primary-one)'}
                                variant="contained"
                                type={!(formik.isValid && formik.dirty) ? 'button' : 'submit'}
                                onClick={!(formik.isValid && formik.dirty) ? handleCompleted : null}>
                                Crear Evaluación
                            </ButtonWithBackground>
                        </WrapperButtons>
                    </FormContainer>
                )}
            </Formik>
            {/* <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¡Ups!"
                image="./rediseño_2024/fallo_accion.png"
                subtitle={`${formik.errors.questions}`}
            /> */}
        </>
    );
};

export default SecondStepEvaluation;
