import * as types from "../types/participant.types";

const initialState = {
	// PARTICIPANTS
	participants: null,
	loadingParticipants: false,
	errorParticipants: null,
	// NEW PARTICIPANT
	newParticipant: false,
	loadingNewParticipant: false,
	errorNewParticipant: null,
	// REMOVE PARTICIPANT
	loadingRemoveParticipant: false,
	errorRemoveParticipant: false,
	// DEACTIVATE PARTICIPANT
	loadingDeactivateParticipant: false,
	errorDeactivateParticipant: false,
	// ASSIGN COURSE
	assign: false,
	loadingAssign: false,
	errorAssign: null,
	// ASSIGN SUBJECT
	assignSubject: false,
	loadingAssignSubject: false,
	errorAssignSubject: null,
	// CURRENT PARTICIPANT
	currentParticipant: null,
	loading: false,
	error: null,
	// NUMBERS OF PARTICIPANTS
	numbers: null,
	errorNumbers: null,
};

export const participant = (state = initialState, action) => {
	switch (action.type) {
		// GET PARTICIPANTS
		case types.GET_PARTICIPANTS_REQUEST:
			return {
				...state,
				loadingParticipants: true,
				errorParticipants: null,
				participants: [],
			};
		case types.GET_PARTICIPANTS_SUCCESS:
			return {
				...state,
				loadingParticipants: false,
				participants: action.payload,
			};
		case types.GET_PARTICIPANTS_FAIL:
			return {
				...state,
				loadingParticipants: false,
				errorParticipants: action.payload,
			};
		// CREATE PARTICIPANT
		case types.CREATE_PARTICIPANT_REQUEST:
			return {
				...state,
				loadingNewParticipant: true,
				errorNewParticipant: null,
			};
		case types.CREATE_PARTICIPANT_SUCCESS:
			return {
				...state,
				loadingNewParticipant: false,
				newParticipant: true,
				participants:
					state.participants === null
						? [
								{
									participante_id: action.payload.participante_id,
									repositorio_id: action.payload.repositorio_id,
									nombre: action.payload.name,
									email: action.payload.email,
								},
						  ]
						: [
								...state.participants,
								{
									participante_id: action.payload.participante_id,
									repositorio_id: action.payload.repositorio_id,
									nombre: action.payload.name,
									email: action.payload.email,
								},
						  ],
			};
		case types.CREATE_PARTICIPANT_FAIL:
			return {
				...state,
				loadingNewParticipant: false,
				errorNewParticipant: action.payload,
			};
		case types.CONFIRM_CREATE_PARTICIPANT:
			return {
				...state,
				newParticipant: false,
			};
		// REMOVE PARTICIPANT
		case types.REMOVE_PARTICIPANT_REQUEST:
			return {
				...state,
				loadingRemoveParticipant: true,
				errorRemoveParticipant: null,
			};
		case types.REMOVE_PARTICIPANT_SUCCESS:
			return {
				...state,
				loadingRemoveParticipant: false,
				participants: state.participants.filter((x) => x.participante_id !== action.payload),
			};
		case types.REMOVE_PARTICIPANT_FAIL:
			return {
				...state,
				loadingRemoveParticipant: false,
				errorRemoveParticipant: action.payload,
			};
		// DEACTIVATE PARTICIPANT
		case types.DEACTIVATE_PARTICIPANT_REQUEST:
			return {
				...state,
				loadingDeactivateParticipant: true,
				errorDeactivateParticipant: null,
			};
		case types.DEACTIVATE_PARTICIPANT_SUCCESS:
			return {
				...state,
				loadingDeactivateParticipant: false,
				participants: state.participants.filter((x) => x.participante_id !== action.payload),
			};
		case types.DEACTIVATE_PARTICIPANT_FAIL:
			return {
				...state,
				loadingDeactivateParticipant: false,
				errorDeactivateParticipant: action.payload,
			};
		// ASSIGN COURSE
		case types.ASSIGN_COURSE_REQUEST:
			return {
				...state,
				loadingAssign: true,
				errorAssign: null,
			};
		case types.ASSIGN_COURSE_SUCCESS:
			return {
				...state,
				loadingAssign: false,
				assign: true,
			};
		case types.ASSIGN_COURSE_FAIL:
			return {
				...state,
				loadingAssign: false,
				errorAssign: action.payload,
			};
		case types.CONFIRM_ASSIGN_COURSE:
			return {
				...state,
				assign: false,
			};
		// ASSIGN SUBJECT
		case types.ASSIGN_SUBJECT_REQUEST:
			return {
				...state,
				loadingAssignSubject: true,
				errorAssignSubject: null,
			};
		case types.ASSIGN_SUBJECT_SUCCESS:
			return {
				...state,
				loadingAssignSubject: false,
				assignSubject: true,
			};
		case types.ASSIGN_SUBJECT_FAIL:
			return {
				...state,
				loadingAssignSubject: false,
				errorAssignSubject: action.payload,
			};
		case types.CONFIRM_ASSIGN_SUBJECT:
			return {
				...state,
				assignSubject: false,
			};

		// GET PARTICIPANT
		case types.GET_PARTICIPANT_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case types.GET_PARTICIPANT_SUCCESS:
			return {
				...state,
				loading: false,
				currentParticipant: action.payload,
			};
		case types.GET_PARTICIPANT_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		// GET NUMBERS OF PARTICIPANTS
		case types.GET_NUMBER_OF_PARTICIPANTS_REQUEST:
			return {
				...state,
				loading: true,
				errorNumbers: null,
			};
		case types.GET_NUMBER_OF_PARTICIPANTS_SUCCESS:
			return {
				...state,
				loading: false,
				numbers: action.payload,
			};
		case types.GET_NUMBER_OF_PARTICIPANTS_FAIL:
			return {
				...state,
				loading: false,
				errorNumbers: action.payload,
			};
		default:
			return state;
	}
};
