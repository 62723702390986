import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import styled from 'styled-components';
// import { useHistory } from 'react-router-dom';

const MenuActionBlog = props => {
    const { content, idCategory, handleAction } = props;
    // let history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MoreVertRoundedIcon
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            />
            <MenuFake id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                {content.map((m, index) => (
                    <MenuItem
                    onClick={() => {
                         if (idCategory !== null && m.action !== 'deleteArticle') {
                            handleAction(m.action, idCategory);
                            handleClose();
                        } 
                    }}
                        // onClick={() => {
                        //     if (m.component !== null) {
                        //         return m.component;
                        //     } else if (m.to !== '') {
                        //         history.push(m.to);
                        //         handleClose();
                        //     } else if (idCategory !== null && m.action !== 'deleteArticle') {
                        //         handleAction(m.action, idCategory);
                        //         handleClose();
                        //     } else {
                        //         handleAction(m.action);
                        //         handleClose();
                        //     }
                        // }}
                        key={index}>
                        {m.component}
                        {m.icon}{m.title}
                    </MenuItem>
                ))}
            </MenuFake>
        </>
    );
};

export default MenuActionBlog;

const MenuFake = styled(Menu)`
    ul li span {
        color: var(--secondary-four);
        font-size: 0.8rem;
        font-weight: 400;
        font-family: 'Montserrat';
        font-style: normal;
    }

    .MuiList-root-MuiMenu-list {
        color: var(--secondary-four);
    }

    .MuiSvgIcon-root {
        fill: var(--secondary-four);
        margin-right: 1rem;
    }
    .MuiIconButton-colorPrimary:hover {
        background-color: transparent;
    }

    .MuiIconButton-root:hover {
        background-color: transparent;
    }

    .MuiButtonBase-root {
        width: 100%;
        justify-content: flex-start;
        color: #222222;
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
    }
`;
