import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Cell, Pie, PieChart,ResponsiveContainer } from "recharts";
import DefaultWrapper from "../DefaultWrapper";
import Text from "../../../shared/common/Text";


const COLORS = ["#e74a70", "#f2851f", "#00adb1"];

const InscriptsStateCourse = (props) => {
	const {selectedCourse, columns, title } = props;

	//STATES
	const [array, setArray] = useState([])
	const [totalValue, setTotalValue] = useState(null)

	//EFECTS
	useEffect(() => {
			if( selectedCourse !== null 
				&& selectedCourse !== undefined){
					selectedCourse.length === undefined ? transformObject(selectedCourse.users)	//1 COURSE SELECTED
					:sumValues(selectedCourse)           											//ALL COURSES
			}
		}, [selectedCourse])

	//FUNCTION
	const percentage = (partialValue, totalValue) =>{
		return (100 * partialValue) / totalValue;
	}

	const transformObject = async(object)=> {
		let result=[] ;
		for (const key in object) {
			let obj = {};
			obj["key"] = key;
			obj["value"] = object[key];
			result.push(obj)
		
		}
		const totalV = result.length;
		let finishedValue = 0;
		let toStartValue = 0;
		let onGoing=0;
		
		await result.forEach(value => {
			if(value.value.progress === 0){ 
				return toStartValue++ 
			} if(value.value.total !== 0 && value.value.progress >= 100){
				return finishedValue++
			}else{
				return onGoing++
			}
		});	
		setTotalValue(totalV)
		setArray([
			{'key':'to_start',
			'value': toStartValue},
			{'key':'ongoing' ,
			'value': onGoing },
			{'key':'finished' ,
			'value': finishedValue}
		])	
	}
	const sumValues= async (array)=>{
		let finishedValue = 0;
		let toStartValue = 0;
		let ongoing = 0
		const totalV = ( array.reduce((sum, value) => (typeof value.users.length == "number" ? sum + value.users.length : sum), 0));
		const filters = await array.map(course=>course.users.forEach(value => {
			if(value.progress === 0){return toStartValue++ }
			if(value.progress >= 100){return finishedValue++}
			else { ongoing++}
		})
		);
		
		setTotalValue(totalV)
		setArray([
			{'key':'to_start',
			'value': toStartValue},
			{'key':'ongoing' ,
			'value': ongoing},
			{'key':'finished' ,
			'value': finishedValue}
		])
	}
	
	//RETURNS
	if(array.length===0 ){
		<DefaultWrapper
			title = "Estado de inscripciones en cursos actuales"
			columns={columns}>
		</DefaultWrapper>
	}
	return (
		<>
		{
			selectedCourse === undefined  ?
				<DefaultWrapper
				title={selectedCourse && `El curso "${selectedCourse.course}" no posee inscriptos` }
				columns={columns}/>
			:
				<DefaultWrapper
					title = {title}
					columns={columns}>
					<Container>
						<ResponsiveContainer width={250} height={250}>
							<PieChartFake>
								<Pie
									data={array}
									innerRadius={60}
									outerRadius={110}
									fill="#8884d8"
									paddingAngle={1}
									dataKey="value"
								>
								{array.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
								))}
								</Pie>
							</PieChartFake>
						</ResponsiveContainer>
						<StatisticsWrapper>
							{array.map((item, index) => (
								<StatisticsWrapper key={index}>
									<TextWrapper>
										<Point color={COLORS[index % COLORS.length]} />
										<Text
											fontSize="1rem"
											fontWeight="700"
											fontcolor={COLORS[index % COLORS.length]}
										>
										{
											item.key === "to_start" ? "Por iniciar" :
											item.key === "ongoing" ? "En curso" : "Completo"
										}
										</Text>
									
										<NumberWrapper>
											<Text
											fontSize="1.25rem"
											fontWeight="700"
											fontcolor={COLORS[index % COLORS.length]}
											>
												{item.value}
											</Text>
										</NumberWrapper>
										<Text fontSize="1.25rem" fontWeight="700" color="error">
											({ totalValue !==0 ?`${percentage(item.value, totalValue).toFixed(0)}%` : ""})
											
										</Text>
									</TextWrapper>
								</StatisticsWrapper>
							))}
						</StatisticsWrapper>
					</Container>
				</DefaultWrapper>
		}
		</>
	);
};

export default InscriptsStateCourse;

const Container = styled.div`
	display: grid;
	grid-template-areas:" piechart statisticswrapper ";
	grid-template-columns:50%;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	column-gap: 0rem;
	@media (max-width: 768px) {
		grid-template-areas:" piechart  "
		    				" statisticswrapper ";
		row-gap: 2.5rem;
		grid-template-columns:95%;
	}
`;

const PieChartFake = styled(PieChart)`
	grid-area:piechart;
	margin: auto;
	@media (max-width: 768px) {
		max-height: 50vh;
		align-self: center;
		margin-bottom: 5%;
	}
`;

const StatisticsWrapper = styled.div`
	grid-area:statisticswrapper;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	@media (max-width: 768px) {
		max-height: 50vh;
	}
`;

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;

const Point = styled.div`
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 100%;
	background-color: ${(p) => p.color};
`;

const NumberWrapper = styled.div`
	display: flex;
	align-items: start;
	margin-right:-1px;
	column-gap: 0.5rem;
	justify-content:center; 
`;
