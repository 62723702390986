import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
// import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
// import { deleteArticle, publishArticle } from '../../redux/api/blog';
import Snack from '../../shared/common/Snack';
import MessageModal from '../blog/MessageModal';
import { publishBanner, publishPopUpSlide } from '../../redux/api/announcements';
import { Menu, MenuItem } from '@material-ui/core';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

const AnnouncementsCard = props => {
    const { value, artId, type } = props;
    const [publishedState, setPublishedState] = useState(value?.published);
    const [messageOpen, setMessageOpen] = useState(false);
    const [contentMessage, setContentMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    let history = useHistory();

    //menu izq desplegable
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // FUNCTIONS
    //edición
    const handleEditCourse = () => {
        history.push(`/edit-${type}/${artId}`);
    };

    //previsualizacion
    const handleViewCourse = () => {
        history.push(`/${type}/${artId}`);
    };

    function handleCloseError() {
        setErrorOpen(false);
    }

    const handlePublishState = async () => {
        //estado publicado: accion "Despublicar"
        if (!!publishedState) {
            let response = await (type === 'popUp'
                ? publishPopUpSlide(artId, { publish: false })
                : type === 'banner'
                ? publishBanner(artId, { publish: false })
                : null);
            if (response.error) {
                setErrorOpen(true);
                <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                    El {type} no pudo despublicarse, favor de contactarse con el administrador.
                </Snack>;
            } else {
                setPublishedState(false);
                setMessageOpen(true);
                setContentMessage({
                    message: `¡${type === 'popUp' ? 'Pop Up' : 'Banner'} despublicado!`,
                    image: '/images/published_article.svg',
                    subtitle: `El ${type} fue despublicado con éxito`,
                });
            }
        } else {
            //estado despublicado: accion "Publicar"
            let response = await (type === 'popUp'
                ? publishPopUpSlide(artId, { publish: true })
                : type === 'banner'
                ? publishBanner(artId, { publish: true })
                : null);
            if (response.error) {
                setErrorOpen(true);
                <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                    El {type} no pudo publicarse, favor de contactarse con el administrador.
                </Snack>;
            } else {
                setPublishedState(true);
                setMessageOpen(true);
                setContentMessage({
                    message: `¡${type === 'popUp' ? 'Pop Up' : 'Banner'} publicado!`,
                    image: '/images/published_article.svg',
                    subtitle: `El ${type} fue publicado con éxito`,
                });
            }
        }
    };

    const handleClose = () => {
        setMessageOpen(false);
        setContentMessage(null);
    };

    const toDay = new Date().getTime();
    const updatedDay = new Date(value?.updatedAt).getTime();
    const publishedDay = value?.publication_date ? new Date(value?.publication_date).getTime() : null;
    const dateDiff = publishedDay ? (toDay - publishedDay) / (1000 * 60 * 60 * 24) : (toDay - updatedDay) / (1000 * 60 * 60 * 24);
    const dateDiffRounded = Math.round(dateDiff);

    return (
        <>
            <Container>
                <CardFake>
                    <ButtonState bgc={publishedState}>
                        <CheckRoundedIcon />
                        {publishedState === true ? 'Publicado' : 'Borrador'}
                    </ButtonState>
                    <Header
                        image={
                            value?.bg_image?.data?.attributes?.url
                                ? `${process.env.REACT_APP_CMS}${value?.bg_image?.data?.attributes?.url}`
                                : value?.image?.data?.attributes?.url
                                ? `${process.env.REACT_APP_CMS}${value?.image?.data?.attributes?.url}`
                                : `${value?.image_url}`
                        }>
                        {value?.tag && <TagSpan>{value.tag}</TagSpan>}
                    </Header>

                    <Body>
                        <h2>{value?.title}</h2>
                        <Span>
                            <ScheduleRoundedIcon />
                            {dateDiffRounded === 0 ? 'Actualizado hoy' : `Hace ${dateDiffRounded} días`}
                        </Span>
                    </Body>

                    <Footer>
                        {' '}
                        <>
                            <MoreVertRoundedIcon
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            />
                            <MenuFake
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                                MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                                <MenuItem onClick={handleEditCourse}>
                                    <EditRoundedIcon />
                                    Editar
                                </MenuItem>
                                <MenuItem onClick={handleViewCourse}>
                                    <VisibilityRoundedIcon />
                                    Vista previa
                                </MenuItem>
                            </MenuFake>
                        </>
                        <ButtonToPublish bgc={publishedState} onClick={handlePublishState}>
                            {!!publishedState ? 'Despublicar' : 'Publicar'}
                        </ButtonToPublish>
                    </Footer>
                </CardFake>
            </Container>
            {/* confirmar artículo publicado/despublicado/eliminado */}
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message={contentMessage?.message}
                image={contentMessage?.image}
                subtitle={contentMessage?.subtitle}
            />
            {/* solicitar confirmación previo a eliminar artículo */}
            {/* <DeleteConfirm
                isOpen={messageDeleteOpen}
                handleClose={handleClose}
                handleDelete={handleDelete}
                message={contentDeleteMessage?.message}
            /> */}
        </>
    );
};

export default AnnouncementsCard;

const Container = styled.div`
    /* position: relative; */
`;

const CardFake = styled.div`
    width: 340px;
    height: 375px;
    padding: 0;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;
`;

const Header = styled.div`
    height: 100px;
    padding: 1.5rem;
    border-radius: 20px;
    width: calc(100% - 3rem);
    background-image: url(${p => `${p.image}`});
    background-size: cover;
    background-position: center center;
    position: relative;
`;

const Body = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
    h2 {
        color: #222222;
        font-size: 1.2rem;
    }
`;

const Span = styled.span`
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 140%;
    color: #616161;

    svg {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 140%;
        color: #616161;
        padding-right: 1rem;
    }
`;

const ButtonState = styled.div`
    background-color: ${p => (!!p.bgc ? '#35D0A5' : '#616161')};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0.3rem 0.5rem;
    gap: 0.25rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ffffff;

    position: absolute;
    top: 2.5%;
    right: 2.5%;
    z-index: 5;
`;

const ButtonToPublish = styled.div`
    background-color: ${p => (p.bgc === true ? 'var(--tertiary-three)' : 'var(--primary-one)')};
    border-radius: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0.5rem 1.5rem;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1.5rem;
    letter-spacing: 0.001em;
    color: ${p => (p.bgc === true ? 'var(--secondary-three)' : '#FFFFFF')};
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100%-4rem);
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: calc(100%-2rem);
    gap: 0.8rem;
    position: absolute;
    bottom: 1%;
    left: 1%;
    right: 1%;
    background-color: #fafafa;

    svg {
        fill: var(--secondary-four);
        cursor: pointer;
    }
`;

const TagSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 10px;
    position: absolute;
    width: 50%;
    left: 0px;
    bottom: 0px;
    background: #ffffff;
    border-radius: 0px 10px 0px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 12px;
    text-align: right;
    color: #616161;
`;

const MenuFake = styled(Menu)`
    ul li span {
        color: var(--secondary-four);
        font-size: 0.8rem;
        font-weight: 400;
        font-family: 'Montserrat';
        font-style: normal;
    }

    .MuiList-root-MuiMenu-list {
        color: var(--secondary-four);
    }

    .MuiSvgIcon-root {
        fill: var(--secondary-four);
        margin-right: 1rem;
    }
    .MuiIconButton-colorPrimary:hover {
        background-color: transparent;
    }

    .MuiIconButton-root:hover {
        background-color: transparent;
    }

    .MuiButtonBase-root {
        width: 100%;
        justify-content: flex-start;
        color: #222222;
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
    }
`;
