import React, { createContext, useContext, useReducer } from 'react';

const ModalContext = createContext();

const initialState = {
    isOpen: false,
    modalType: null,
};

const modalReducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_MODAL':
            return {
                isOpen: true,
                modalType: action.payload.modalType,
            };
        case 'CLOSE_MODAL':
            return initialState;
        default:
            return state;
    }
};

export const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(modalReducer, initialState);

    const openModal = modalType => {
        dispatch({ type: 'OPEN_MODAL', payload: { modalType } });
    };

    const closeModal = () => {
        dispatch({ type: 'CLOSE_MODAL' });
    };

    return <ModalContext.Provider value={{ state, openModal, closeModal }}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal debe usarse dentro de un ModalProvider');
    }
    return context;
};
