import * as types from "../types/subjects.types";

const initialState = {
	subject: null,
	loadingSubject: false,
	errorSubject: null,
};

export const subject = (state = initialState, action) => {
	switch (action.type) {
		// GET Subject
		case types.GET_SUBJECT_REQUEST:
			return {
				...state,
				loadingSubject: true,
				errorSubject: null,
			};
		case types.GET_SUBJECT_SUCCESS:
			return {
				...state,
				loadingSubject: false,
				subject: action.payload,
			};
		case types.GET_SUBJECT_FAIL:
			return {
				...state,
				loadingSubject: false,
				errorSubject: action.payload,
			};
		default:
			return state;
	}
};
