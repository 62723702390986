import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, Field } from 'formik';
import { Autocomplete, TextField } from '@mui/material';

const DefaultComplete = props => {
    const { name, value, setFieldValue, options = [] } = props;

    // RETURN
    return (
        <FieldFake
            as={Autocomplete}
            name={name}
            value={value?.id}
            onChange={(e, newValue) => {
                if (newValue !== null) {
                    console.log('newValue', newValue);
                    setFieldValue(name, newValue);
                }
            }}
            options={options}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} variant="standard" helperText={<ErrorMessage name={name} component={CustomError} />} />}
            {...props}
        />
    );
};

export default DefaultComplete;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #f8423f;
`;

const FieldFake = styled(Field)`
    width: 100%;

    .MuiInput-root .MuiInput-input {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #616161;
    }
`;
