import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/participant.types";
import {
	createParticipant,
	getParticipants,
	removeParticipant,
	deactivateParticipant,
	assignCourse,
	getParticipant,
	getNumberOfParticipants,
	assignSubject,
} from "../api/participant.api";
import { participantActions } from "../actions";

function* getNumberOfParticipantsRequest(action) {
	try {
		const response = yield call(getNumberOfParticipants, action.payload);
		yield put(participantActions.getNumberOfParticipantsSuccess(response.data));
	} catch (error) {
		yield put(participantActions.getNumberOfParticipantsFail(error));
	}
}

function* getParticipantsRequest(action) {
	try {
		const res = yield call(getParticipants, action.payload);
		yield put(participantActions.getParticipantsSuccess(res.data.participantes));
	} catch (error) {
		yield put(participantActions.getParticipantsFail(error));
	}
}

function* createParticipantRequest(action) {
	try {
		const res = yield call(createParticipant, action.payload);
		yield put(participantActions.createParticipantSuccess(res.data.data));
	} catch (error) {
		yield put(participantActions.createParticipantFail(error));
	}
}

function* removeParticipantRequest(action) {
	try {
		yield call(removeParticipant, action.payload);
		yield put(participantActions.removeParticipantSuccess(action.payload.id));
	} catch (error) {
		yield put(participantActions.removeParticipantFail(error));
	}
}

function* deactivateParticipantRequest(action) {
	try {
		yield call(deactivateParticipant, action.payload);
		yield put(participantActions.deactivateParticipantSuccess(action.payload.id));
	} catch (error) {
		yield put(participantActions.deactivateParticipantFail(error));
	}
}

function* assignCourseRequest(action) {
	try {
		const res = yield call(assignCourse, action.payload);
		yield put(participantActions.assignCourseSuccess(res.data));
	} catch (error) {
		yield put(participantActions.assignCourseFail(error));
	}
}

function* assignSubjectRequest(action) {
	try {
		const res = yield call(assignSubject, action.payload);
		yield put(participantActions.assignSubjectSuccess(res.data));
	} catch (error) {
		yield put(participantActions.assignSubjectFail(error));
	}
}

function* getParticipantRequest(action) {
	try {
		const res = yield call(getParticipant, action.payload);
		yield put(participantActions.getParticipantSuccess(res.data.response_data));
	} catch (error) {
		yield put(participantActions.getParticipantFail(error));
	}
}

function* participantWatcher() {
	yield takeEvery(types.GET_NUMBER_OF_PARTICIPANTS_REQUEST, getNumberOfParticipantsRequest);
	yield takeEvery(types.GET_PARTICIPANTS_REQUEST, getParticipantsRequest);
	yield takeEvery(types.CREATE_PARTICIPANT_REQUEST, createParticipantRequest);
	yield takeEvery(types.REMOVE_PARTICIPANT_REQUEST, removeParticipantRequest);
	yield takeEvery(types.DEACTIVATE_PARTICIPANT_REQUEST, deactivateParticipantRequest);
	yield takeEvery(types.ASSIGN_COURSE_REQUEST, assignCourseRequest);
	yield takeEvery(types.ASSIGN_SUBJECT_REQUEST, assignSubjectRequest);
	yield takeEvery(types.GET_PARTICIPANT_REQUEST, getParticipantRequest);
}

export { participantWatcher };
