import React, { useEffect, useState } from 'react';
import DefaultField from '../../shared/common/DefaultField';
import styled from 'styled-components';
import Loading from '../../shared/common/Loading';
import MultipleSelect from '../../shared/common/MultipleSelect';
import { getAllTags, getAllThemes, getTypeOfCourse } from '../../redux/api/content-creation.api';
import DefaultComplete from '../../shared/common/DefaultComplete';
import InputFile from '../../shared/common/InputFile';
import DivResourceAggregationDinamic from './DivResourceAggregationDinamic';

const CourseForm = props => {
    const {
        initialValues,
        timeUnityComplete,
        typeComplete,
        handleSubmitFile,
        type,
        listTagsValuesSelected,
        listThemesValuesSelected,
        setFieldValue,
        selectedImage,
        confirmedResourceList = null,
    } = props;


    //STATES
    const [tagsList, setTagsList] = useState([]);
    const [typesOfCourse, setTypesOfCourse] = useState([]);
    const [themes, setThemes] = useState([]);
    const [tagsListSelected, setTagsListSelected] = useState(initialValues?.tag ? initialValues?.tag?.map(item => [item]) : []);
    const [themesSelected, setThemesSelected] = useState(initialValues?.theme ? [initialValues.theme] : []);

    //EFFECTS
    //etiquetas
    useEffect(() => {
        if (tagsList.length === 0) {
            handleTags();
        }
    }, [tagsList]);

    //tipos de cursos
    useEffect(() => {
        if (typesOfCourse.length === 0) {
            handleTypes();
        }
    }, [typesOfCourse]);

    //ejes temáticos
    useEffect(() => {
        if (themes.length === 0) {
            handleThemes();
        }
    }, [themes]);

    //FUNCTIONS
    const handleTags = async () => {
        let req = await getAllTags();
        if (!!req) {
            setTagsList(req);
        }
    };

    const handleTypes = async () => {
        let req = await getTypeOfCourse();
        if (!!req) {
            setTypesOfCourse(req);
        }
    };

    const handleThemes = async () => {
        let req = await getAllThemes();
        if (!!req) {
            setThemes(req);
        }
    };

    const timeUnities = [
        { id: 0, name: 'meses' },
        { id: 1, name: 'semanas' },
        { id: 2, name: 'días' },
        { id: 3, name: 'horas' },
        { id: 4, name: 'selecciona una opción' },
    ];

    const clasesArray = initialValues?.unidades?.flatMap(item => item.clases);

    if (tagsList.length === 0 || typesOfCourse.length === 0 || themes.length === 0) {
        return <Loading />;
    }
    return (
        <Content>
            <First>
                <Label>
                    Título
                    <Subtitle>Elige un título claro pero llamativo, para captar la atención de los alumnos.</Subtitle>
                    <DefaultField
                        name="title"
                        type="text"
                        label=""
                        required
                        variant="outlined"
                        placeholder="Ingresa el título"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Descripción
                    <Subtitle>Cuéntale a tus alumnos de qué se trata o qué aprenderán en este curso.</Subtitle>
                    <DefaultField
                        name="description"
                        type="text"
                        variant="outlined"
                        label=""
                        required
                        placeholder="¿De qué trata este curso?"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Autor
                    <Subtitle>Carga el nombre del autor, persona o entidad a cargo de este curso.</Subtitle>
                    <DefaultField
                        name="author"
                        type="text"
                        variant="outlined"
                        label=""
                        required
                        placeholder="Ingresa el o los nombres"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Duración
                    <Subtitle>Indica a tus alumnos el tiempo promedio de duración de este curso</Subtitle>
                    <DefaultField
                        name="duration"
                        type="number"
                        variant="outlined"
                        label=""
                        required
                        placeholder="Ingresa una cantidad"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                    <DefaultComplete name="timeunity" options={timeUnities} value={timeUnityComplete} setFieldValue={setFieldValue} />
                </Label>

                <Label>
                    Tipo de curso
                    <Subtitle>Elige de que tipo de curso se trata. Esta opción será de valor cuando agregues este curso a una asignatura.</Subtitle>
                    <DefaultComplete name="type" options={typesOfCourse} value={typeComplete} setFieldValue={setFieldValue} />
                </Label>
            </First>

            <Second>
                <Label>
                    Portada
                    <Subtitle>Elige una imagen que represente a este recurso.</Subtitle>
                    <InputFile initialContent={initialValues.image} handleSubmitFile={handleSubmitFile} type={type} selectedImage={selectedImage} />
                </Label>
                <Label>
                    Ejes temáticos
                    <Subtitle>Elige el o los ejes temáticos a los que pertenece este curso.</Subtitle>
                    <MultipleSelect options={themes} presetValues={themesSelected} listValuesSelected={listThemesValuesSelected} />
                </Label>
                <Label>
                    Etiquetas
                    <Subtitle>Elige palabras claves que ayuden a los usuarios a encontrar este contenido.</Subtitle>
                    <MultipleSelect options={tagsList} presetValues={tagsListSelected} listValuesSelected={listTagsValuesSelected} />
                </Label>
                {type === 'edit-course' ? (
                    <Label>
                        Recursos
                        <DivResourceAggregationDinamic initialValues={clasesArray} confirmedResourceList={confirmedResourceList} />
                    </Label>
                ) : null}
            </Second>
        </Content>
    );
};

export default CourseForm;

const Content = styled.div`
    display: grid;
    grid-template-areas: 'first second';
    gap: 2rem;
    margin-top: 25px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Label = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--primary-one);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0 1rem 0;
`;

const First = styled.div`
    width: calc(100%-64px);
    grid-area: first;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Second = styled.div`
    grid-area: second;
    width: calc(100%-64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Subtitle = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color: #616161;
`;
