import axios from "axios";

export async function getLicenses(id) {
	return await axios
		.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/licencias/${id}`)
		.then((response) => {
			return {
				status: true,
				data: response.data,
			};
		})
		.catch((error) => {
			return {
				status: false,
				data: error,
			};
		});
}
