import React from 'react';
import styled from 'styled-components';
import { InputAdornment } from '@mui/material';
import DefaultField from '../../shared/common/DefaultField';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import InputFile from '../blog/InputFile';

const PopUpForm = props => {
    const {initialValues, handleSubmitFile, type } = props;

    return (
        <Content>
            <First>
                <Label>
                    Temática
                    <Subtitle>Una palabra o frase corta que resuma la temática del mensaje</Subtitle>
                    <DefaultField
                        name="tag"
                        type="text"
                        label=""
                        required
                        variant="outlined"
                        placeholder="Ingresa un tag"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Título
                    <Subtitle>Elige un nombre claro pero llamativo para captar la atención de los estudiantes</Subtitle>
                    <DefaultField
                        name="title"
                        type="text"
                        label=""
                        required
                        variant="outlined"
                        placeholder="Ingresa el título"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Descripción
                    <Subtitle>Utiliza un mensaje conciso para transmitir el mensaje a tus alumnos de manera clara</Subtitle>
                    <DefaultField
                        name="description"
                        type="text"
                        variant="outlined"
                        label=""
                        required
                        placeholder="¿Cuál es la novedad?"
                        border="1px solid #616161"
                        borderradius="8px"
                        multiline
                        rows={4}
                    />
                </Label>
            </First>

            <Second>
                <Label>
                    Imágen
                    <Subtitle>Elige una imágen que acompañe al mensaje</Subtitle>
                    <InputFile 
                        initialContent={initialValues.image} 
                        handleSubmitFile={handleSubmitFile} 
                        type={type}
                        required 
                    />
                </Label>

                <Label>
                    Link de imágen
                    <Subtitle>Si tienes el enlace directo de una imagen puedes utilizarlo en lugar de cargar una imagen de tu ordenador.</Subtitle>
                    <DefaultField
                        name="image_url"
                        type="text"
                        variant="outlined"
                        label=""
                        placeholder="Ingresa el link"
                        border="1px solid #616161"
                        borderradius="8px"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InsertLinkRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Label>
            </Second>
        </Content>
    );
};

export default PopUpForm;

const Content = styled.div`
    display: grid;
    grid-template-areas:'first second';
    gap: 2rem;
    margin-top: 25px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Label = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--primary-one);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0 1rem 0;
`;

const First = styled.div`
    width: calc(100%-64px);
    grid-area: first;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Second = styled.div`
    grid-area: second;
    width: calc(100%-64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Subtitle = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color: #616161;
`;

