import { TextField } from "@material-ui/core";
import { SearchRounded } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";

import Text from "../../shared/common/Text";
import Wrapper from "../../shared/common/Wrapper";

const SearchMember = (props) => {
	const { search, handleSearch } = props;

	// RETURN
	return (
		<Container>
			<Text fontSize="1rem" fontWeight="700" color="primary">
				Integrantes
			</Text>
			<Wrapper>
				<Center>
					<TextField
						value={search}
						onChange={handleSearch}
						type="text"
						placeholder="Buscar"
						InputProps={{
							endAdornment: <SearchRounded color="secondary" />,
						}}
						variant="outlined"
						style={{ width: "100%" }}
					/>
				</Center>
			</Wrapper>
		</Container>
	);
};

export default SearchMember;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
`;

const Center = styled.div`
	display: flex;
	justify-content: center;
	padding: 5px;
`