import React from "react";
import styled from "styled-components";

import Text from "../../shared/common/Text";
import { TextField } from "@material-ui/core";

const SearchName = (props) => {
	const { nombre, handleName } = props;

	// RETURN
	return (
		<Container>
			<Text fontSize="1rem" fontWeight="700" color="primary">
				Nombre del grupo
			</Text>
			<TextField
				value={nombre}
				onChange={handleName}
				required
				type="text"
				variant="outlined"
				color="primary"
				placeholder="Nombre de grupo"
			/>
		</Container>
	);
};

export default SearchName;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
`;
