import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from './Loading';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 500,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, values, theme) {
    return {
        fontWeight: values && values.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

const MultipleSelect = props => {
    const { options, presetValues, listValuesSelected } = props;
    const filteredArray = options.length > 0 ? options?.filter(op => presetValues[0] && presetValues[0]?.includes(op.name)) : [];
    const classes = useStyles();
    const theme = useTheme();

    const [valueSelected, setValueSelected] = useState(filteredArray);

    useEffect(() => {
        if (valueSelected?.length !== 0) {
            listValuesSelected(valueSelected);
        } else {
            listValuesSelected([]);
        }
    }, [valueSelected]);

    const handleChangeMultipleSelect = event => {
        setValueSelected(event.target.value);
        listValuesSelected(event.target.value);
    };

    if (!options) {
        return <Loading />;
    }
    return (
        <div>
            <FormControl className={classes.formControl}>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={valueSelected}
                    onChange={handleChangeMultipleSelect}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes?.chips}>
                            {selected?.length > 0 &&
                                selected?.map((value, index) => <Chip key={index} label={value?.name} className={classes.chip} />)}
                        </div>
                    )}
                    MenuProps={MenuProps}>
                    {!!options?.length > 0 &&
                        options?.map((value, index) => (
                            <MenuItem key={index} value={value} style={getStyles(value, valueSelected, theme)}>
                                {value.name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultipleSelect;
