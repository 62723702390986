import React from "react";
import styled from "styled-components";

import Text from "../../shared/common/Text";
import { Button } from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";

const OPTIONS = [
	{ id: 0, name: "Marketing" },
	{ id: 1, name: "Economía" },
	{ id: 2, name: "Negocios" },
];

const AboutContent = ({ course }) => {
	return (
		<Container>
			<Text fontSize="1.5rem" fontWeight="700" color="error" component="h1">
				Sobre el curso
			</Text>
			<Text fontSize="1rem" color="error" component="h1">
				{course.description}
			</Text>
			<RowWrapper>
				<ColumnWrapper>
					<Text fontWeight="700" color="error">
						DURACIÓN
					</Text>
					<Text color="error">{course.duration} semanas</Text>
				</ColumnWrapper>
				<ColumnWrapper>
					<Text fontWeight="700" color="error">
						CATEGORÍA
					</Text>
					<Text color="primary">{OPTIONS[course.category].name}</Text>
				</ColumnWrapper>
				<Button
					variant="contained"
					color="primary"
					size="small"
					startIcon={<EditRounded />}
					style={{ marginLeft: "auto" }}
				>
					Editar
				</Button>
			</RowWrapper>
		</Container>
	);
};

export default AboutContent;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	padding: 2rem;
	background-color: #ffffff;
`;

const RowWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 3rem;
`;

const ColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;
