import React, { useState } from 'react';

import styled from 'styled-components';
import TrueOrFalseAnswer from './TrueOrFalseAnswer';
import MultipleAnswer from './MultipleAnswer';
import OnlyAnswer from './OnlyAnswer';
import Text from '../../../../../../shared/common/Text';
import {
    ButtonWithBackground,
    ButtonWithBorder,
    Label,
    PatternFormatStyle,
    ReactQuillContainer,
    ReactQuillStyled,
    WrapperButtons,
} from '../../../../styled-components/forms.styled.components';
import RadioGroupResources from '../../../RadioGroupResources';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';

const DivQuestion = props => {
    const { index, formik, remove, handleClearQuestion } = props;

    //STATES
    const [selectedType, setSelectedType] = useState(formik.values.questions[index].typeId);
    const [expanded, setExpanded] = useState(false);

    const questionOption = [
        { id: 3, value: 'unica_opcion', label: 'Unica opción (una sola respuesta correcta)' },
        { id: 2, value: 'multi_opcion', label: 'Multiple choise (al menos 2 respuestas correctas)' },
        { id: 1, value: 'verdadero_falso', label: 'Verdadero o falso' },
    ];

    //FUNCTIONS
    function handleTypeSelected(type) {
        setSelectedType(Number(type));
        const stringType = Number(type) === 1 ? 'verdadero_falso' : Number(type) === 2 ? 'multi_opcion' : Number(type) === 3 && 'unica_opcion';
        formik.values.questions[index].typeId = Number(type);
        formik.values.questions[index].type = stringType;
        if(Number(type)!==1){
            formik.values.questions[index].data_question_options = [
                { indexanswers: 0, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                { indexanswers: 1, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
                { indexanswers: 2, name: '', es_opcion_correcta: 'False', selected: false, comentario: '' },
            ];
        } 
    }

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const saveQuestion = (panel, nuevoObjeto) => {
        let items;
        try {
            items = JSON.parse(localStorage.getItem('2evaluation')) || {};
        } catch (e) {
            items = {};
        }
        // Asegurar que items tenga una propiedad questions que sea un array para inicializar el esquema de preguntas en el localStorage
        if (!Array.isArray(items.questions)) {
            items.questions = [];
        }
        const existingIndex = items.questions.findIndex(item => item.order === nuevoObjeto.order);
        if (existingIndex !== -1) {
            items.questions[existingIndex] = nuevoObjeto;
        } else {
            items.questions.push(nuevoObjeto);
        }
        localStorage.setItem('2evaluation', JSON.stringify(items));
        setExpanded(prevExpanded => (prevExpanded === panel ? false : panel));
    };

    function getQuestionOption(option) {
        switch (option) {
            case 1:
                return <TrueOrFalseAnswer formik={formik} index={index} />;
            case 2:
                return <MultipleAnswer formik={formik} index={index} />;
            case 3:
                return <OnlyAnswer formik={formik} index={index} />;
            default:
                return null;
        }
    }

    const handleNumericChange = e => {
        const { name, value } = e.target;
        // Convertir el valor a número si es posible, si no, volver a cadena vacía o un valor predeterminado
        const numericValue = value === '' ? '' : Number(value) || 0;
        formik.setFieldValue(name, numericValue);
    };

    function validarCondiciones(formik, index) {
        if (!formik.isValid) return false;
        //validacion pregunta
        const question = formik.values.questions[index];
        if (question.score <= 0) return false;
        if (!question.data_question_options || question.description.length === 0) return false;
        //validacion respuestas correctas
        const answers = question.data_question_options;
        const opcionesDetalladas = answers.filter(option => option.name.length >= 1);
        if (opcionesDetalladas < question.data_question_options.length) return false;
        const answersChecked = answers.filter(x => x.selected === true);
        const answersNotChecked = answers.filter(x => x.selected !== true);
        if (question.typeId === 1 && answers.length < 1) return false;
        if (question.typeId === 2 && answersChecked < 3 && answersNotChecked < 2) return false;
        if (question.typeId === 3 && answersChecked < 2 && answersNotChecked < 3) return false;
        return true;
    }

    return (
        <ContainerDivQuestion>
            {index >= 0 && (
                <>
                    <AccordionStyle expanded={expanded === `panel_${index}`} onChange={handleChange(`panel_${index}`)}>
                        <AccordionSummaryStyle
                            expandIcon={<ExpandMoreStyle />}
                            aria-controls={`panel_${index}-content`}
                            id={`panel_${index}-header`}
                            defaultExpanded //por defecto expandido hasta guardar pregunta
                        >
                            <ScoreButtonDiv>
                                <ScoreButtonDivLeft>
                                    <Text textalign={'start'} fontWeight={700} fontcolor={'#616161'} lineheight={'1.3rem'}>
                                        Pregunta {index + 1}
                                    </Text>
                                </ScoreButtonDivLeft>
                                <ScoreButtonDivRight>
                                    <Text textalign={'end'} fontWeight={500} fontcolor={'#616161'} lineheight={'1.2rem'}>
                                        Puntaje para esta pregunta: {''}
                                    </Text>
                                    <PatternFormatStyle
                                        value={formik.values.questions[index]?.score}
                                        onChange={handleNumericChange}
                                        onBlur={formik.handleBlur}
                                        valueIsNumericString
                                        format="##"
                                        name={`questions[${index}].score`}
                                    />
                                </ScoreButtonDivRight>
                            </ScoreButtonDiv>

                            {formik.values.questions.length > 1 && (
                                <ButtonWithBorderRigth
                                    bordercolor="var(--primary-one)"
                                    variant="contained"
                                    type="button"
                                    InputProps={{
                                        startAdornment: <DeleteRoundedIcon />,
                                    }}
                                    onClick={() => remove(formik, index)}>
                                    Eliminar Pregunta
                                </ButtonWithBorderRigth>
                            )}

                            {formik.values.questions[index]?.description.length < 3 ? <Label>Ingrese la pregunta </Label> : null}

                            <ReactQuillContainer height={'130px'}>
                                <ReactQuillStyled
                                    value={formik.values.questions[index]?.description}
                                    name={`questions[${index}].description`}
                                    onChange={value => formik.setFieldValue(`questions[${index}].description`, value)}
                                    onBlur={() => formik.setFieldTouched(`questions[${index}].description`, true)}
                                    modules={{
                                        toolbar: {
                                            container: [
                                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                ['bold', 'italic', 'underline'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                [{ align: [] }],
                                                ['link'],
                                                ['clean'],
                                                [{ color: [] }],
                                            ],
                                        },
                                    }}
                                />
                            </ReactQuillContainer>
                        </AccordionSummaryStyle>
                        <AccordionDetailsStyle>
                            <RadioGroupResources
                                label={'Selecciona qué tipo de respuestas tendrá esta pregunta.'}
                                list={questionOption}
                                resourceType={handleTypeSelected}
                                preSelected={Number(selectedType)}
                            />
                            {getQuestionOption(Number(selectedType))}

                            <WrapperButtons>
                                {/* <ButtonWithBorder bordercolor="var(--primary-one)" type="button" onClick={handleClearQuestion}>
                            Cancelar
                        </ButtonWithBorder> */}
                                <ButtonWithBackground
                                    type="button"
                                    disabled={!validarCondiciones(formik, index)}
                                    onClick={() => saveQuestion(`panel_${index}`, formik.values.questions[index])}
                                    bgcolor={!validarCondiciones(formik, index) ? '#A8A8A8' : 'var(--primary-one)'}
                                    variant="contained">
                                    Guardar Pregunta
                                </ButtonWithBackground>
                            </WrapperButtons>
                        </AccordionDetailsStyle>
                    </AccordionStyle>
                </>
            )}
        </ContainerDivQuestion>
    );
};

export default DivQuestion;

const ContainerDivQuestion = styled.div`
    box-shadow: 0px 12px 24px -15px #0000001a;
    box-shadow: 0px 0px 10px -6px #00000040;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
    gap: 3rem;
    border-radius: 20px;
    background-color: #ffffff;
`;

const ScoreButtonDiv = styled.div`
    width: calc(100% - 1rem);
    height: calc(50px - 1rem);
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 20px;
    margin-bottom: 1rem;
`;

const ScoreButtonDivLeft = styled.div`
    padding-left: 1rem;
`;

const ScoreButtonDivRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    align-items: center;
    gap: 0.5rem;
`;

const ButtonWithBorderRigth = styled(ButtonWithBorder)`
    align-self: flex-end;
`;

const AccordionStyle = styled(Accordion)`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    &.MuiPaper-root {
        border-radius: none;
        box-shadow: none;
        border: none;
        width: 100%;
        padding: 0;
        background-color: none !important;

        &.MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible {
            background-color: none !important;
        }
    }

    &.MuiPaper-root-MuiAccordion-root {
        background-color: none !important;
    }

    &.MuiPaper-root-MuiAccordion-root:last-of-type {
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
    }
    &.MuiPaper-root-MuiAccordion-root:first-of-type {
        border-bottom-left-radius: none;
        border-bottom-right-radius: none;
    }
    &.MuiPaper-root.MuiAccordion-root:before {
        background-color: #fafafa;
    }
`;

const AccordionSummaryStyle = styled(AccordionSummary)`
    /* color: var(--secondary-four); */

    &.MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible {
        background-color: none !important;
    }

    &.MuiAccordionSummary-root {
        border: none;
        background-color: none;
        box-shadow: none;
        border-radius: 0;
        &.Mui-focusVisible {
            background-color: none !important;
        }
    }

    &.Mui-expanded {
        padding: 0;
        :hover {
            background: none;
        }
    }

    .MuiAccordionSummary-expandIconWrapper {
        /* padding: 1rem 0; */
        align-self: self-start;
    }

    :hover {
        background-color: none;
        .MuiAccordionSummary-expandIconWrapper {
            display: block;
        }
    }

    .MuiAccordionSummary-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        /* display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
        box-shadow: none;
        border-radius: 20px; */

        /* svg {
            width: 70px;
            color: var(--secondary-four);
        } */
        :hover {
            background-color: none;
        }

        /* &.active {
            border-radius: 20px;
            background-color: #f9f9f9;

            svg,
            span {
                color: var(--primary-one);
            }
        } */

        &.Mui-expanded {
            background: none;
            :hover {
                background: none;
            }
        }
    }
`;

const ExpandMoreStyle = styled(ExpandMore)`
    color: var(--primary-one);
`;

const AccordionDetailsStyle = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    padding: 0;

    /* &.MuiAccordionDetails-root {
        padding: 0;
    }
    &.active {
        background-color: #fafafa;
        border-right: 2px solid var(--primary-one);
        border-radius: 20px;
    } */
`;
