import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    background-color: #fff;
`

export const Header = styled.div`
    width: 100%;
    height: 90px;
    background-color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
`

export const Info = styled.div`
    width: calc(100% - 1rem);
    height: 90px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    padding-left: 1rem;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 1rem;
`

export const InfoCard = styled.div`
    width: 150px;
    height: 4rem;
    display: flex;
    flex-direction: row;
    gap: 8px;
    border-radius: 10px;
    background-color: #f1f1f1;
`

export const InfoCardIcon = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b31d15;
    border-radius: 10px 0 0 10px;
    color: #fff;
`

export const InfoCardDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
`

export const Description = styled.div`
    padding: 1rem;
`

export const Questions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 1rem;
    padding: 1rem;
`

export const Question = styled.div`
    width: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 20px;
    padding: 8px;
`

export const QuestionHeader = styled.div`
    width: calc(100% - 2rem);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    color: #616161;
    font-size: 15px;
    font-weight: 700;
    padding: 0 1rem;
    border-radius: 50px;
`

export const QuestionDescription = styled.div`
    width: 95%;
    font-size: 16px;
    font-weight: 400;
    color: #222;
`