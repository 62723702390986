import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { PieChart, Pie, Cell } from "recharts";
import Text from "../../../shared/common/Text";
import { PersonRounded } from "@material-ui/icons";
import DefaultWrapper from "../DefaultWrapper";
// import { useDispatch, useSelector } from "react-redux";
// import { actions } from "../../../redux/actions/analytics";
import Loading from "../../../shared/common/Loading";

const COLORS = ["#63c2a8", "#f2c060", "#e04f69"];

const Users = (props) => {
	const {analyticsStatus} = props;
	
	// //FUNCTIONS
	const percentage = (partialValue, totalValue) =>{
		return (100 * partialValue) / totalValue;
	}

	const transformObject = (object)=> {
		let result=[] ;
		for (const key in object) {
			let obj = {};
			obj["key"] = key;
			obj["value"] = object[key];
			result.push(obj)
		}
		setArray(result)
		setTotalValue(result.reduce((sum, value) => (typeof value.value == "number" ? sum + value.value : sum), 0))
	}

	//STATES
	const [array, setArray] = useState([])
	const [totalValue, setTotalValue] = useState(null)

	//EFECTS
	useEffect(() => {
		if(analyticsStatus !== null){
			transformObject(analyticsStatus)
		}
	}, [analyticsStatus])

	if (analyticsStatus === null) {
		return <Loading/>
	}
	return (
		<DefaultWrapper title="Usuarios" columns="span 10">
			<Container>
				<PieChartFake width={200} height={200}>
					<Pie
						data={array}
						labelLine={false}
						outerRadius={70}
						fill="#8884d8"
						dataKey="value"
					>
						{array.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))}
					</Pie>
				</PieChartFake>
				<UserStatus>
				{array.map((item, index) => (
					<TextWrapper key={index}>
						{/** TITULO */}
						<TitleWrapper>
							<CoursePoint color={COLORS[index % COLORS.length]} />
							<Text
								fontSize="0.7rem"
								fontWeight="900"
								fontcolor="#616161"
								style={{ textTransform: "uppercase" }}
							>
								{item.key}
							</Text>
						</TitleWrapper>
						{/** CANTIDAD DE USUARIOS */}
						<NumberWrapper>
							<Text fontSize="1rem" fontWeight="700">
								{item.value}
							</Text>
							<PersonRounded color="secondary" style={{ fontSize: "1rem" }} />
						</NumberWrapper>
						{/** PORCENTAJE DE USUARIOS */}
						<NumberWrapper>
							<Text fontSize="1rem" fontWeight="700">
								{
									item.value !== 0 ? percentage(item.value,totalValue).toFixed(0) : 0
								}
								
							</Text>
							<Text fontSize="1rem" fontWeight="700" color="secondary">
								%
							</Text>
						</NumberWrapper>
					</TextWrapper>
				))}
				</UserStatus>
			</Container>
		</DefaultWrapper>
	);
};

export default Users;

const Container = styled.div`
	/* display: flex;
	justify-content: space-between; */
	display: grid;
	grid-template-areas:" piechart userstatus ";
	grid-template-columns:1fr 2fr;
	align-self: inherit;
	/* overflow-y: auto; */
	@media (max-width: 1900px) {
		grid-template-areas:" piechart  "
		    				" userstatus ";
		grid-template-columns: auto;
		row-gap: 1.5rem;
	}
`;
const PieChartFake = styled(PieChart)`
	grid-area:piechart;
	display: flex;
	align-content:center;
	justify-self: center;
	@media (max-width: 1900px) {
		max-height: 40vh;
	}
`;
const UserStatus = styled.div`
	grid-area:userstatus;
	display: flex;
	flex-direction:row;
	flex-wrap: nowrap;
	justify-content: space-around;
	/* padding: 0.5rem 1rem; */
	@media (max-width: 1900px) {
		max-height: 60vh;
	}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
	column-gap:2rem;
	justify-content: center;
	text-align: center;
	align-items:center;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
`;

const CoursePoint = styled.div`
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	background-color: ${(p) => p.color};
`;

const NumberWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	column-gap: 0.25rem;

	svg {
		padding-bottom: 0.1rem;
	}
`;
