import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, InputAdornment, Switch } from '@mui/material';
import DefaultField from '../../shared/common/DefaultField';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import InputFile from '../blog/InputFile';

const BannerForm = props => {
    const {externalLink, handleChange, initialValues, handleSubmitFile, type } = props;

    return (
        <Content>
            <First>
                <Label>
                    Temática
                    <Subtitle>Una palabra o frase corta que resuma la temática del mensaje</Subtitle>
                    <DefaultField
                        name="tag"
                        type="text"
                        label=""
                        required
                        variant="outlined"
                        placeholder="Ingresa un tag"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Título
                    <Subtitle>Elige un nombre claro pero llamativo para captar la atención de los estudiantes</Subtitle>
                    <DefaultField
                        name="title"
                        type="text"
                        label=""
                        required
                        variant="outlined"
                        placeholder="Ingresa el título"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Descripción
                    <Subtitle>Utiliza un mensaje conciso para transmitir el mensaje a tus alumnos de manera clara</Subtitle>
                    <DefaultField
                        name="description"
                        type="text"
                        variant="outlined"
                        label=""
                        required
                        placeholder="Da más detalles"
                        border="1px solid #616161"
                        borderradius="8px"
                    />
                </Label>

                <Label>
                    Enlace de redirección
                    <Subtitle>Cuando el usuario haga click sobre el banner, será redirigido a este enlace</Subtitle>
                    <DefaultField
                        name="link"
                        type="text"
                        variant="outlined"
                        label=""
                        required
                        placeholder="Ingresa el link"
                        border="1px solid #616161"
                        borderradius="8px"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InsertLinkRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControlLabelSwitch
                        control={
                            <Switch
                                checked={externalLink}
                                onChange={handleChange}
                                //  defaultChecked
                            />
                        }
                        label="Abrir en nueva ventana"
                    />
                </Label>
            </First>

            <Second>
                <Label>
                    Nombre del recurso
                    <Subtitle>Elige un nombre claro pero llamativo para captar la atención de los estudiantes</Subtitle>
                    <InputFile 
                    initialContent={initialValues.bg_image} 
                    handleSubmitFile={handleSubmitFile} 
                    type={type}
                    required 
                    />
                </Label>

                <Label>
                    Link de imágen
                    <Subtitle>Si tienes el enlace directo de una imagen puedes utilizarlo en lugar de cargar una imagen de tu ordenador.</Subtitle>
                    <DefaultField
                        name="bg_image_url"
                        type="text"
                        variant="outlined"
                        label=""
                        placeholder="Ingresa el link"
                        border="1px solid #616161"
                        borderradius="8px"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InsertLinkRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Label>
            </Second>
        </Content>
    );
};

export default BannerForm;

const Content = styled.div`
    display: grid;
    grid-template-areas:'first second';
    gap: 2rem;
    margin-top: 25px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Label = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--primary-one);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0 1rem 0;
`;

const First = styled.div`
    width: calc(100%-64px);
    grid-area: first;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Second = styled.div`
    grid-area: second;
    width: calc(100%-64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Subtitle = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color: #616161;
`;

const FormControlLabelSwitch = styled(FormControlLabel)`
    span .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
        color: var(--primary-one);
    }
    span .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: var(--secondary-one);
    }
    &.MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #616161;
    }
`;
