import React from 'react';
import styled from 'styled-components';

import AsideLogin from '../components/login/AsideLogin';
import Text from '../shared/common/Text';

export default function Login() {
    return (
        <LoginContainer className="bg-login">
            <ImageWrapper></ImageWrapper>
            <AsideLogin />
        </LoginContainer>
    );
}

const LoginContainer = styled.main`
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
`;

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('/images/Login-Acropolis-lab.png');
    position: relative;
    background-size: cover;
    background-position: center center;
`;

const FakeImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #3e403f;
    opacity: 30%;
`;

const TextWrapper = styled.div`
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;

    h1 {
        text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
    }

    h1:before {
        content: '';
        animation: animate infinite 10s;
    }

    @keyframes animate {
        0% {
            content: '9 unidades temáticas y más de 15 cursos de capacitación';
        }

        50% {
            content: 'Lleva el control de las capacitaciones a través de analíticas precisas';
        }

        100% {
            content: 'Crea equipos y gestiónalos desde un mismo lugar';
        }
    }
`;
