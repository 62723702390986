import React, { useState } from "react";
import styled from "styled-components";

import Text from "../../shared/common/Text";
import { Button } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";
import ModalForm from "../../shared/common/ModalForm";
import NewUnitContentForm from "./NewUnitContentForm";

const NewUnitContent = ({ addContent }) => {
	const [isOpen, setIsOpen] = useState(false);

	function handleOpen() {
		setIsOpen(true);
	}

	function handleClose() {
		setIsOpen(false);
	}

	return (
		<>
			<Container>
				{/** Boton de abrir modal para agregar un nuevo conteido a la unidad */}
				<Text fontWeight="700" color="primary" component="span">
					Contenido de la unidad
				</Text>
				<Button
					variant="contained"
					size="small"
					color="primary"
					startIcon={<AddRounded />}
					onClick={handleOpen}
				>
					Añadir nuevo
				</Button>
				{/** Modal para crear el nuevo contenido */}
				<ModalForm isOpen={isOpen} handleClose={handleClose} title="Añadir contenido">
					<NewUnitContentForm addContent={addContent} handleClose={handleClose} />
				</ModalForm>
			</Container>
		</>
	);
};

export default NewUnitContent;

const Container = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1.5rem;
`;
