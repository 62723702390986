import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { analyticsActions, participantActions } from "../../redux/actions";
import SelectGroup from "./group/SelectGroup";
//import Activity from "./group/Activity";
import CourseProgress from "./group/CourseProgress";
import UsersRanking from "./general/UsersRanking";
import NoWrapper from "../../shared/common/NoWrapper";
import AllGroups from "./group/AllGroups";
import AnalyticsGroupsSkt from "../skeletons/AnalyticsGroupsSkt";
import { getParticipants } from "../../redux/api/participant.api";

const Group = (props) => {
	const {groupQuery} = props;

	//REDUX
	const dispatch = useDispatch();
	const ondemand_id = useSelector((state) => state.user.user.id);
	const participants = useSelector((state) => state.participant.participants);
	const loadingParticipants = useSelector((state) => state.participant.loadingParticipants);
	const {groupAdvanceByCourses, loadingGroupAdvanceByCourses} = useSelector((state) => state.analytics);

	//STATES
	const [selectedGroup, setSelectedGroup] = useState( groupAdvanceByCourses ?
															groupQuery!==null ? groupAdvanceByCourses.find(group => group.repo_id === groupQuery) 
															: groupAdvanceByCourses[0]
														: null 
														);
	// EFFECTS
	useEffect(() => {
		if (participants === null) {
			dispatch(participantActions.getParticipantsRequest({ ondemand_id }));
		}
	}, []);

	useEffect(() => {
		if (groupAdvanceByCourses === null) {
			dispatch(analyticsActions.getGroupAdvanceByCoursesRequest(ondemand_id));
		}
	}, [groupAdvanceByCourses, selectedGroup]); 

	useEffect(() => {
		if(selectedGroup === undefined ){
			setSelectedGroup(false);
		}
	}, [selectedGroup]);

	//FUNCTIONS
	const handleSelected = (select) => {
		setSelectedGroup(select)
	}

	return (
		<>
		{	groupAdvanceByCourses === null || loadingGroupAdvanceByCourses || loadingParticipants  ?
				<AnalyticsGroupsSkt/>
			: 

			groupAdvanceByCourses.length === 0 ? 
				<NoWrapper
					title="Aún no has creado un grupo" 
					columns="span 24"
					rows="span 12" 
					padding="15%"
				/>
			:
			
			<Container>
					<SelectGroup 
					groupAdvanceByCourses={groupAdvanceByCourses}
					handleSelected={handleSelected}
					groupQuery={groupQuery}/>
					{/* <Activity /> */}
					{
						selectedGroup==="all" ?
							<AllGroups groupAdvanceByCourses={groupAdvanceByCourses}/>
						:
						selectedGroup === null || selectedGroup === false || selectedGroup === undefined ? 
							<NoWrapper
								title="Selecciona un grupo" 
								columns="span 24"
								rows="span 12" 
								padding="15%"
							/>
						:
						
						selectedGroup.courses && selectedGroup.courses.length === 0 ?
						<NoWrapper
								title="Aún no has asignado un curso al grupo"
								columns="span 24"
								rows="span 12" 
								padding="15%"
							/>	
						
						:
							<>
								<UsersRanking selectedGroup={selectedGroup} participants={participants} />
								<CourseProgress selectedGroup={ selectedGroup }  
								/>
							</>
					}
					
				</Container>
		}
		</>
	);
};

export default Group;

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	grid-gap: 1.5rem;
`;
