import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from '@mui/material';

export const WrapperCellAsigned = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const AccordionEdited = styled(Accordion)`
    position: absolute !important;
    height: 100%;
    width: 100%;
    top: 0;
    margin: 0;
    z-index: ${props => (props.expanded ? '99' : '4')};
    border-radius: 10px !important;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
`;

export const AccordionSummaryFake = styled(AccordionSummary)`
    margin: 0;
    background-color: #fff !important;
    border-radius: 10px 10px 0 0 !important;
`;

export const AccordionDetailsFake = styled(AccordionDetails)`
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;

    label {
        width: 100% !important;
        display: flex !important;
        flex-direction: row-reverse !important;
        justify-content: space-between !important;
        padding-left: 1rem;
    }
`;

export const WrapperList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export const CheckboxStyle = styled(Checkbox)`
    svg {
        color: var(--primary-one);
    }
`;

export const UnassignButton = styled.button`
    margin: auto;
    width: fit-content;
    height: 40px;
    padding: 0 1rem;
    border: 1px solid #b31d15;
    border-radius: 48px;
    font-size: 14px;
    color: #b31d15;
    background-color: #fff;

    :hover {
        background-color: #f1f1f1;
    }

    :disabled {
        cursor: not-allowed;
        border: 1px solid #a8a8a8;
        color: #a8a8a8;

        :hover {
            background-color: #fff;
        }
    }
`;

export const WrapperHeadTable = styled.div`
    display: grid;
    width: 100%;
    height: 50px;
    padding: 0.2rem 0 0 2rem;
    grid-template-columns: 1.5fr 1fr 3fr 1fr;
    justify-items: center;
    align-content: center;
    border-bottom: 2px solid #c4c4c4;

    p {
        text-transform: uppercase;
        color: #000000;
        font-weight: 700;
        font-size: 0.8rem;
    }
`;

export const ActionButton = styled.div`
    cursor: pointer;
    color: var(--primary-two);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
`;

export const Modal = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;
`;

export const ResourceChip = styled.div`
    width: calc(max-content - 2rem);
    height: calc(45px - 0.5prem);
    color: var(--primary-one);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 100px;
    svg {
        background-color: var(--primary-one);
        color: white;
        border-radius: 50%;
        width: 24px;
        padding: 0.25rem;
    }

    span {
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1rem;
        text-transform: capitalize;
    }
`;

export const Container = styled.div`
    width: 100%;
    margin-bottom: 2rem;
`;

export const ResourceContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderRow = styled.div`
    display: flex;
    border-bottom: 2px solid #ddd;
    padding: 10px 0;
`;

export const HeaderCell = styled.div`
    width: ${props => props.width};
    padding: 10px;
    text-align: center;
    font-weight: bold;
`;

export const ResourceRow = styled.div`
    display: flex;
    width: 100%;
    margin: 10px 0;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-color: ${({ selected }) => (selected ? '#e0e0e0' : 'white')};
    &:hover {
        background-color: #f9f9f9;
        border: 1px solid #616161;
    }
`;

export const ResourceCell = styled.div`
    width: ${props => props.width};
    padding: 10px;
    display: flex;
    justify-content: ${props => props.justifyContent || 'start'};
    align-items: center;
    background-color: ${({ bg }) => bg};
    max-height: 54px;
`;

export const ResourceSpan = styled.span`
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
`;

export const Toolbar = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
`;

export const Typography = styled.span`
    flex: 1;
    color: var(--primary-one);
    font-size: 1rem;
`;

export const Tooltip = styled.div``;

export const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;

export const MenuActionContainer = styled.div`
    color: var(--primary-two);
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: space-evenly;
    width: 100%;

`;
