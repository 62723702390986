import React from "react";
import styled from "styled-components";

const Tabs = (props) => {
    const { tabValue, handleTabValue, tabOptions } = props;

    // RETURN
    return (
        <Container>
            {tabOptions.map((option) => (
                <Tab
                    onClick={() => handleTabValue(option.id)}
                    tabValue={tabValue}
                    key={option.id}
                    active={tabValue === option.id}
                >
                    {option.label}
                </Tab>
            ))}
        </Container>
    );
};

export default Tabs;

const Container = styled.div`
	width:100%;
    margin-bottom: 1rem;
`;

const Tab = styled.button`
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    color: ${props => (props.active ? '#b3261e' : '#A8A8A8')};
    font-weight: ${props => (props.active ? '700' : '400')};
    transition: color 0.3s;
    background-color: #fff;

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        border-radius: 10px;
        height: 4px;
        background-color: ${props => (props.active ? '#b3261e' : 'transparent')};
        
    }
`;