import React, { useEffect, useState } from "react";
import RankingCard from "../../../shared/cards/RankingCard";
import ScrollFixed from "../../../shared/common/ScrollFixed";
import DefaultWrapper from "../DefaultWrapper";
import axios from 'axios';


const UsersRanking = (props) => {
 	const {selectedGroup, participants} = props;
// 	const [dracmaPoints, setDracmaPoints] = useState(null);

// 	const getData = async () => {
//         const URL = "https://sisapi.aden.org/api/v1/acropolis/dracmacoins/ranking?top=100&ondemand=true"
//         try {
//             const res = await axios.get(URL);
//             const data = res.data;
//             const postData = data.response_data;
//             setDracmaPoints(Object.values(postData));
//         } catch (error) {
//             return {
//                 error
//             };
//         }
//     };

//     useEffect(() => {
//         getData();
//     }, []);

// console.log(`dracmaPoints`, dracmaPoints)

	//RETURN
	return (
		<DefaultWrapper
			title={selectedGroup ? `Ranking de usuarios por actividad - Grupo: ${selectedGroup.group}`  : "Ranking de usuarios por actividad"}
			columns="span 8"
		>
			<ScrollFixed height="500px">{
				participants.map((row) => {return (<RankingCard row={row}/>)
				})
			}
				{/* <RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard /> */}
			</ScrollFixed>

			{/* <ScrollFixed height="300px">
				{
					selectedGroup.participantes.length !== 0 &&
					selectedGroup.participantes.map((g,i)=>(
						<RankingCard 
						key={i}
						nombre={g.nombre} />
					))
					
				}
				
			</ScrollFixed> */}
		</DefaultWrapper>
	);
};

export default UsersRanking;
