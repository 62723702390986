import React, { useEffect } from 'react';
import styled from 'styled-components';

import Text from '../../shared/common/Text';
import { Modal } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';

import { useState } from 'react';
import DefaultField from '../../shared/common/DefaultField';
import { createCategory, updateCategory } from '../../redux/api/blog';
import Snack from '../../shared/common/Snack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const CategoryModal = props => {
    const { isOpen, handleClose, title, categoryToEdit, action, strapiId } = props;
    const [errorOpen, setErrorOpen] = useState(false);
    const [category, setCategory] = useState(null);

    // console.log('categoryToEdit', categoryToEdit);

    //FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    const handleSubmit = async values => {
        const data = {
            data: {
                name: values.category,
                company: strapiId,
            }
        };
        let response = await action === 'add'
        ? createCategory(data)
        : updateCategory(categoryToEdit.id, data);

        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                La categorìa no pudo actualizarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setCategory(response);
            handleClose(true);
        }

        
    };

  

    // const handleDelete = async () => {
    //     let response = await deleteCategory(categoryToEdit.id);

    // };

    // RETURN
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Container>
                <Row>
                    <Text fontSize="1.2rem" fontWeight="700" fontcolor="#222222">
                        {title}
                    </Text>
                    <CloseRoundedIcon onClick={handleClose} />
                </Row>

                <Formik 
                initialValues={{category: categoryToEdit?.attributes?.name}} 
                onSubmit={(value) => {handleSubmit(value)}}>
                    {() => (
                        <FormContainer>
                            <DefaultField
                                name="category"
                                type="text"
                                label=""
                                required
                                variant="outlined"
                                placeholder="Nombre de la categoría"
                                border="1px solid #616161"
                                borderradius="8px"
                            />
                            <FakeButton 
                            variant="contained" 
                            type="submit" 
                            size="small" 
                            >
                                Guardar
                            </FakeButton>
                        </FormContainer>
                    )}
                </Formik>
            </Container>
        </Modal>
    );
};

export default CategoryModal;

const Container = styled.div`
    max-width: 400px;
    height: 150px;
    padding: 3rem;
    row-gap: 1.5rem;
    text-align: center;
    align-items: start;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    outline: none;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    position: relative;
`;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    svg {
        fill: var(--secondary-four);
    }
`;

const FakeButton = styled.button`
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 5%;
    bottom: 5%;
    justify-content: center;
    align-items: center;
    align-content: end;
    width: 150px;
    height: 48px;
    gap: 8px;
    background: var(--primary-one);
    border-radius: 48px;
    cursor: pointer;
    z-index: 2;

    font-size: 0.8rem;
    font-weight: 600;
    color: #ffff;

    /* span {
        font-size: 0.8rem;
        font-weight: 600;
        color: #ffff;
    }

    &.MuiButton-contained:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), var(--primary-one);
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    } */
`;
