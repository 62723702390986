import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { PersonRounded } from '@material-ui/icons';
import styled from 'styled-components';

const StudentsPerCourseTable = (props) => {
const {rows, colors}= props;    
const classes = useStyles();
//STATES
const [totalValue, setTotalValue] = useState(null)

//EFECTS
useEffect(() => {
    if(rows.length !== 0){
        setTotalValue(rows.reduce((sum, value) => (typeof value.counter === "number" ? sum + value.counter : sum), 0))
    }
}, [rows])

    return (
      <Table className={classes.table} sx={{ width: "100%" }} 	overflow-x="scroll"  size="small">
        <TableBody  >
          {rows.map((row, index) => (
            <TableRow  key={row.repo_id}>
                <TableCell component="th" scope="row">
                    <CoursePoint color={colors[index % colors.length]} />
                </TableCell>
                <TableCell align="left" >
                    {row.name}
                </TableCell>
                <TableCell  align="center" size="medium"> {row.counter} <PersonRounded color="secondary" style={{ fontSize: "1rem" }} />                 </TableCell>
                {
                  rows.length > 10 &&
                    <TableCell align="right">
                      { row.counter !== 0 ? percentage(row.counter,totalValue).toFixed(0) : 0 }%
                    </TableCell>
                }
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
}
export default StudentsPerCourseTable;

const useStyles = makeStyles({
    table: {
      minWidth: 200,
    },
  
  });
  
  // //FUNCTIONS
  const percentage = (partialValue, TotalValue) =>{
      return (100 * partialValue) / TotalValue;
  }
  
  const CoursePoint = styled.div`
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	background-color: ${(p) => p.color};
`;