import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhotoRoundedIcon from '@mui/icons-material/PhotoRounded';
import Text from '../../shared/common/Text';

const InputFile = props => {
    const { handleSubmitFile, initialContent, type } = props;
    const { name, size, ext } = initialContent; //imagenes desde strappi se pueden consumir sin inconvenientes

    const [file, setFile] = useState(type === 'edit' ? initialContent : null);

    const [infoFile, setInfoFile] = useState({
        name: name,
        size: (size / 1048576).toFixed(2) + ' MB',
        type: ext,
    });
    const [fileError, setFileError] = useState(false);
    const [sizeFile, setSizeFile] = useState(false);

    useEffect(() => {
        if (file !== null) {
            handleSubmitFile(file);
        }
    }, [file]);

    const handleChange = e => {
        setFileError(false);
        setSizeFile(false);
        const type = e.target.files[0].type.split('/')[1];
        // console.log('e.target.files[0]', e.target.files[0]);
        if (true) {
            const size = (e.target.files[0].size / 1048576).toFixed(2);
            if (size <= 15) {
                setFile(e.target.files[0]);
                setInfoFile({
                    name: e.target.files[0].name,
                    size: size + ' MB',
                    type: type,
                });
            } else {
                setSizeFile(true);
            }
        } else {
            setFileError(true);
            setFile(null);
        }
    };

    return (
        <>
            <Container>
                <Label for="fileInput">
                    <PhotoRoundedIcon />
                    {type === 'edit' ? (
                        <InfoFileDiv>
                            <TitleFile>{infoFile.name}</TitleFile>
                            <Size>{infoFile.size}</Size>
                        </InfoFileDiv>
                    ) : !fileError ? (
                        !sizeFile ? (
                            file === null ? (
                                <Gap>
                                    <Text fontSize="0.875rem" fontWeight="700" fontcolor="#616161">
                                        Arrastre y suelte la imágen aquí
                                    </Text>
                                    <Text fontSize="0.875rem" fontWeight="400" fontcolor="#616161">
                                        {' '}
                                        o{' '}
                                    </Text>
                                    <ButtonFile>Seleccione un archivo</ButtonFile>
                                    <Text fontSize="0.625rem" fontWeight="400" fontcolor="var(--secondary-four)">
                                        Formatos admitidos: JPEG, PNG, GIF
                                    </Text>
                                </Gap>
                            ) : (
                                <InfoFileDiv>
                                    <TitleFile>{infoFile.name}</TitleFile>
                                    <Size>{infoFile.size}</Size>
                                </InfoFileDiv>
                            )
                        ) : (
                            <InfoFileDiv>
                                <TitleFile>Peso máximo permitido</TitleFile>
                                <Size>15 MB</Size>
                            </InfoFileDiv>
                        )
                    ) : (
                        <InfoFileDiv>
                            <TitleFile>Solo se permiten los siguentes formatos</TitleFile>
                            <Size>JPEG, PNG, GIF</Size>
                        </InfoFileDiv>
                    )}
                </Label>

                <Input multiple={false} onChange={handleChange} id="fileInput" type="file" />
            </Container>
        </>
    );
};

export default InputFile;

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 0.25rem;
    /* background: #f1f1f1;
    border: 1px dashed #a8a8a8;
    border-radius: 20px; */
`;

const Label = styled.label`
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    gap: 0.25rem;
    background: #f1f1f1;
    border: 1px dashed #a8a8a8;
    border-radius: 20px;
    overflow: hidden;
    svg {
        color: var(--secondary-four);
        font-size: 2.25rem;
    }
`;

const Input = styled.input`
    display: none;
`;

const ButtonFile = styled.div`
    cursor: pointer;
    width: 100%;
    padding: 0.8rem 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--primary-one);
    border-radius: 48px;
    color: white;
    font-size: 0.875rem;
    font-weight: 600;
`;

const InfoFileDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    overflow: hidden;
    white-space: initial;
    width: calc(100%-2rem);
    padding: 1rem;
`;

const TitleFile = styled.div`
    text-transform: uppercase;
    color: var(--primary-one);
    font-weight: 600;
    font-size: 0.8rem;
    text-overflow: ellipsis;
`;

const Size = styled.div`
    text-transform: uppercase;
    color: #555555;
    font-size: 0.7rem;
`;

const Gap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 0.25rem;
`;
