import React, { useState } from 'react';
import { ButtonWithBorder, LabelAnswers, RadioGroupStyle, RadioStyle, TitleOpction } from '../../../../styled-components/forms.styled.components';
import { FormControlLabel } from '@material-ui/core';
import DefaultField from '../../../../../../shared/common/DefaultField';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import useAnswers from '../../../../hooks/useAnswers';

const AnswersListOneOption = props => {
    const { formik, index } = props;

    const { handleInputChange, handleDeleteAnswer, handleAddAnswer } = useAnswers();

    
    const formikValues = formik.values.questions[index].data_question_options;

    const initialTrueAnswers = formikValues.filter(option => option.selected === true);

    const [correctAnswer, setCorrectAnswer] = useState( initialTrueAnswers?.length !== 0 ? initialTrueAnswers[0].indexanswers : null); //indice de respuesta marcado como correcto

    //es_opcion_correcta
    const handleCorrectAnswer = event => {
        setCorrectAnswer(event.target.value); //inidice de respuesta
        const optionIndex = formik.values.questions[index].data_question_options.findIndex(
            option => option.indexanswers === Number(event.target.value),
        );
        const updatedOptions = formik.values.questions[index].data_question_options.map((option, i) => {
            if (option.indexanswers === optionIndex) {
                return { ...option, selected: true, es_opcion_correcta: 'True' };
            } else {
                return { ...option, selected: false, es_opcion_correcta: 'False' };
            }
        });
        formik.setFieldValue(`questions[${index}].data_question_options`, updatedOptions);
    };

    return (
        <>
            <RadioGroupStyle
                aria-labelledby="OnlyAnswer_controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={correctAnswer}
                onChange={handleCorrectAnswer}>
                {formikValues.map(
                    (
                        option,
                        optionIndex, //posibles respuestas
                    ) => (
                        <LabelAnswers key={optionIndex}>
                            <TitleOpction>
                                Opcion {optionIndex + 1}
                                {formikValues.length > 2 && <DeleteRoundedIcon onClick={() => handleDeleteAnswer(formik, index, option.indexanswers)} />}
                            </TitleOpction>
                            <DefaultField
                                name={`option[${optionIndex}].name`}
                                value={option.name}
                                onChange={e => handleInputChange(formik, index, optionIndex, e.target.value)}
                                type="text"
                                label=""
                                required
                                variant="outlined"
                                placeholder=""
                                border="1px solid #616161"
                                borderradius="10px"
                                InputProps={{
                                    startAdornment: <FormControlLabel key={optionIndex} control={<RadioStyle />} value={option.indexanswers} />,
                                }}></DefaultField>
                        </LabelAnswers>
                    ),
                )}
                <ButtonWithBorder onClick={() => handleAddAnswer(formik, index)} bordercolor="var(--primary-one)" variant="contained" type="button">
                    {''} +{''} Agregar Respuesta
                </ButtonWithBorder>
            </RadioGroupStyle>
        </>
    );
};

export default AnswersListOneOption;
