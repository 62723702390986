import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import LoginAcropolis from '../components/loginPreview/LoginAcropolis'
import useLayout from '../hooks/useLayout'
import DefaultFile from '../shared/common/DefaultFile'
import Loading from '../shared/common/Loading'
import Text from '../shared/common/Text'
import Header from '../shared/layout/header/Header'
import Layout from '../shared/layout/Layout'

const EditLayout = () => {

    const { loading, handleSubmit, layoutLoad, ...styles } = useLayout()
    const [layout, setLayout] = useState(null)
    const { subdominio_login } = useSelector(state => state.user.user)

    useEffect(() => {
        !layout && layoutLoad && setLayout(styles)
    }, [layoutLoad, layout])
    

    const handleFile = (hash, name) => {
        setLayout({...layout, [name]: hash})
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setLayout({...layout, [name]: value})
    }

    return (
        <Layout header={<Header route="Editar Layout" />}>
            <Container>
                {!loading ? <Form>
                    <Text fontWeight="bold" fontSize="1.3rem">Editar página de incio de sesión</Text>
                    <FormContainer>
                        {/* Subdominio */}
                        <Text fontcolor="rgba(0, 0, 0, 0.54)">Dominio del sitio</Text>
                        <Text>{subdominio_login}.acropolislab.com</Text>
                        {/* Orientacion */}
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Orientación del formulario</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="inverted"
                                onClick={handleChange}
                                value={layout?.inverted}
                            >
                                <FormControlLabel value={"true"} control={<Radio />} label="Izquierda" />
                                <FormControlLabel value={"false"} control={<Radio />} label="Derecha" />
                            </RadioGroup>
                        </FormControl>
                        {/* Colores */}
                        <Text fontcolor="rgba(0, 0, 0, 0.54)">Colores principales</Text>
                        <Col col={2}>
                            <TextField onChange={handleChange} name="primaryColor" value={layout?.primaryColor} variant='outlined' label="Color primario" fullWidth type='color' />
                            <TextField variant='outlined' onChange={handleChange} name="secondaryColor" value={layout?.secondaryColor} label="Color secundario" fullWidth type='color' />
                        </Col>
                        <Col col={2}>
                            <TextField value="#507780" variant='outlined' label="Color de texto" fullWidth type='color' />
                            <TextField value={layout?.logoAlabColor} onChange={handleChange} name="logoAlabColor" variant='outlined' label="Color logo (Alab SVG)" fullWidth type='color' />
                        </Col>
                        <Text fontcolor="rgba(0, 0, 0, 0.54)">Logo y tipografía de la empresa</Text>
                        {/* Logo empresa */}
                        <Col col={2}>
                            <DefaultFile name="logoAlab" title="Logo empresa" handleB64File={handleFile} toPdf={false} />
                            <TextField value={String(layout?.width)} onChange={handleChange} name="width" variant='outlined' label="Ancho logo (.px)" fullWidth type='number' />
                        </Col>
                        {/* Tipografia */}
                        <Col col={2}>
                            <DefaultFile name="font" title="Tipografia (.otf)" handleB64File={handleFile} toPdf={false} />
                            <TextField variant='outlined' label="Color texto" fullWidth type='color' />
                        </Col>
                        <Col col={2}>
                            {/* <DefaultFile title="Tipografia (.otf)" handleB64File={handleLogo} toPdf={false} /> */}
                            <TextField onChange={handleChange} name="textError" value={layout?.textError} variant='outlined' label="Color texto (error)" fullWidth type='color' />
                        </Col>
                        {/* Banner, imagenes */}
                        <Text fontcolor="rgba(0, 0, 0, 0.54)">Imagenes de fondo</Text>
                        <Col col={2}>
                            <DefaultFile name="imageLeft" title="Imagen izquierda" handleB64File={handleFile} toPdf={false} />
                            <DefaultFile name="imageRight" title="Imagen derecha" handleB64File={handleFile} toPdf={false} />
                        </Col>
                        <Button variant='contained' color="primary" onClick={() => handleSubmit(layout)}>Guardar cambios</Button>
                    </FormContainer>
                </Form> : <LoadingWrapper>
                    <Loading/>
                </LoadingWrapper>}
                <Preview>
                    <LoginAcropolis layout={layout}/>
                </Preview>
            </Container>
        </Layout>
    )
}

export default EditLayout

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
`


const Form = styled.div`
    background-color: white;
    border-radius: 20px;
    padding: 1rem;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.06);
`

const Preview = styled.div`
    background-color: white;
    border-radius: 20px;
    padding: .5rem;
    height: 500px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.06);
`

const FormContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Col = styled.div`
    display: grid;
    grid-template-columns: repeat(${p => p.col}, 1fr);
    gap: 12px;
`

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.06);
    width: 100%;
    background-color: white;
    border-radius: 20px;
`