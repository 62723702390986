import React from 'react';
import {
    ButtonWithBackground,
    EvaluationEstructureContainer,
    FormContainer,
    Label,
    LabelCheckbox,
    PatternFormatStyle,
    ReactQuillContainer,
    ReactQuillStyled,
    WrapperButtons,
} from '../../../../styled-components/forms.styled.components';
import Text from '../../../../../../shared/common/Text';
import { Formik, useFormik } from 'formik';
import DefaultField from '../../../../../../shared/common/DefaultField';
import { FirstSchemaEvaluation } from '../../../../../../utils/schemas';
import { Checkbox } from '@mui/material';

const FirstStepEvaluation = props => {
    const firstStateEvaluation = JSON.parse(localStorage.getItem('1evaluation'));

    const { handleNext, handleCompleted } = props;

    const formik = useFormik({
        initialValues: {
            publication_name: '',
            description: '',
            permitted_attempts: false,
            number_attempts: 0,
            time_defined: false,
            time: 0,
        },
    });


    return (
        <>
            <Formik initialValues={!!firstStateEvaluation ? firstStateEvaluation : formik.initialValues} 
            onSubmit={values => {
                console.log('values :>> ', values);
                localStorage.setItem('1evaluation', JSON.stringify(values));
                handleNext()
              }}
            validationSchema={FirstSchemaEvaluation}>
                {(formik, values) => (
                    <FormContainer>
                        <EvaluationEstructureContainer>
                            <Label extension={'60%'}>
                                Nombre de la evaluación
                                <DefaultField
                                    name="publication_name" //se enviia en el post como "name" dentro de evaluation_data y demas
                                    type="text"
                                    label=""
                                    required
                                    variant="outlined"
                                    placeholder=""
                                    border="1px solid #A8A8A8"
                                    borderradius="12px"
                                />
                            </Label>
                            <Label>Instrucciones de la evaluación (Así se verá en la plataforma)</Label>
                            <ReactQuillContainer height={'40vh'}>
                                <ReactQuillStyled
                                    value={formik.values.description}
                                    name="description"
                                    onChange={value => formik.setFieldValue('description', value)}
                                    onBlur={() => formik.setFieldTouched('description', true)}
                                    modules={{
                                        toolbar: {
                                            container: [
                                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                ['bold', 'italic', 'underline'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                [{ align: [] }],
                                                ['link'],
                                                ['clean'],
                                                [{ color: [] }],
                                            ],
                                        },
                                    }}
                                />
                            </ReactQuillContainer>

                            <Label>Opciones (estos ítems no son obligatorios)</Label>

                            <LabelCheckbox>
                                <Checkbox 
                                checked={formik.values.permitted_attempts} 
                                name="permitted_attempts" 
                                onChange={formik.handleChange} 
                                sx={{
                                    color: '#535353',
                                    '&.Mui-checked': {
                                      color: 'var(--primary-one)',
                                    },
                                  }}
                                />
                                <Text textalign={'left'} fontWeight={500} fontcolor={'#616161'} lineheight={'1.3rem'}>
                                    Cantidad de intentos permitidos
                                </Text>
                                <PatternFormatStyle
                                    value={formik.values.number_attempts}
                                    onChange={formik.handleChange}
                                    valueIsNumericString
                                    format="#"
                                    name="number_attempts"
                                />
                            </LabelCheckbox>

                            <LabelCheckbox>
                                <Checkbox 
                                checked={formik.values.time_defined} 
                                name="time_defined" 
                                onChange={formik.handleChange}
                                sx={{
                                    color: '#535353',
                                    '&.Mui-checked': {
                                      color: 'var(--primary-one)',
                                    },
                                  }}
                                
                                />
                                <Text textalign={'left'} fontWeight={500} fontcolor={'#616161'} lineheight={'1.3rem'}>
                                    Límite de tiempo
                                </Text>
                                <PatternFormatStyle
                                    value={formik.values.time}
                                    onChange={formik.handleChange}
                                    valueIsNumericString
                                    format="##:##"
                                    name="time"
                                />
                            </LabelCheckbox>
                        </EvaluationEstructureContainer>

                        <WrapperButtons>
                            <ButtonWithBackground
                                bgcolor={!(formik.isValid && formik.dirty) ? '#A8A8A8' : 'var(--primary-one)'}
                                variant="contained"
                                type={!(formik.isValid && formik.dirty) ? 'button' : 'submit'}
                                onClick={!(formik.isValid && formik.dirty) ? handleCompleted : null}
                                >
                                Continuar
                            </ButtonWithBackground>
                        </WrapperButtons>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default FirstStepEvaluation;
