import React from "react";
import styled from "styled-components";

import Text from "../../../shared/common/Text";
import Wrapper from "../../../shared/common/Wrapper";
import CircularProgressBox from "./CircularProgress";

const CardAvance = (props) => {
	const {item, type, gtc} = props;
	return (
		<Wrapper 
            columns="span 2"
            padding= "0.5rem"
            height= "auto"
            width={type === "group" ? "230px" : "330px"}
            >
            <Container gtc={gtc}>
                <div>
                    {
                        type === "course" ?

                            <Text fontSize="1rem" fontWeight="700" lineheight="19px">
                            {item.name? item.name :"" }
                            </Text>

                        : type === "user" ?

                            <Text fontSize="1rem" fontWeight="700" lineheight="19px">
                            {item.course? item.course :"" }
                            </Text>

                        : type === "group" ?

                            <Text fontSize="1rem" fontWeight="700" lineheight="19px">
                            {item.name? item.name :"" }
                            </Text>

                        : ""
                    }
                    <br/>
                    {
                        type === "course" ? ""

                        : type === "user" ?

                            <Text fontSize="0.75rem" fontWeight="700" lineheight="20px" fontcolor="#616161">
                                {
                                    item && item.total !==0 && (item.viewed <= item.total) ? `${item.viewed}/${item.total} Recursos cursados`  
                                    :   `${item.total}/${item.total} Recursos cursados` 
                                }
                            </Text>

                        : type === "group" ? ""

                        : ""
                    }
                </div>
                <CircularProgressBox percentage={item.progress > 100 ? 100 : item.progress}/>
            </Container>
        </Wrapper>
	);
};

export default CardAvance;

const Container = styled.div`
	display: grid;
    grid-template-columns: ${(p) => p.gtc ? p.gtc  : "270px 1fr"};
	justify-content: space-between;
	align-items: center;
    vertical-align: middle;
    width: 70%;
`;