import React from "react";
//import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Button } from "@material-ui/core";
import { GetAppRounded } from "@material-ui/icons";


const ExportGroups = (handleExport) => {
	return (
		<>
			<Button
				variant="outlined"
				color="primary"
				size="small"
				startIcon={<GetAppRounded />}
			> Exportar
				{/* <ReactHTMLTableToExcel
                    id="table-xls-button"
                    // className=""
                    table="table-groups"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Exportar a XLS"/> */}
			</Button>
		</>
	);
};

export default ExportGroups;
