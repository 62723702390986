// AUTH
export const SIGNIN_USER_REQUEST = "SIGNIN_USER_REQUEST";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_FAIL = "SIGNIN_USER_FAIL";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const LOGOUT_USER = "LOGOUT_USER";

// COMPANY
export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";

export const PHOTO_COMPANY_REQUEST = "PHOTO_COMPANY_REQUEST";
export const PHOTO_COMPANY_SUCCESS = "PHOTO_COMPANY_SUCCESS";
export const PHOTO_COMPANY_FAIL = "PHOTO_COMPANY_FAIL";

// ADMIN
export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL";

export const PHOTO_ADMIN_REQUEST = "PHOTO_ADMIN_REQUEST";
export const PHOTO_ADMIN_SUCCESS = "PHOTO_ADMIN_SUCCESS";
export const PHOTO_ADMIN_FAIL = "PHOTO_ADMIN_FAIL";

// CONTRIES
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

// THEMATICS
export const GET_THEMATICS_REQUEST = "GET_THEMATICS_REQUEST";
export const GET_THEMATICS_SUCCESS = "GET_THEMATICS_SUCCESS";
export const GET_THEMATICS_FAIL = "GET_THEMATICS_FAIL";

// USER SELECTED
export const USER_SELECTED = "USER_SELECTED";

// SET DATA
export const SET_DATA = "SET_DATA";