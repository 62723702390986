import React, { useState } from 'react';
import styled from 'styled-components';
import ViewCourse from '../content/ViewCourse';
import { MenuItem } from '@mui/material';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Text from '../../shared/common/Text';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Loading from '../../shared/common/Loading';
import { ViewResource } from '../content/ViewResource';

const CheckedCourseCard = props => {
    const { value, handleItemRemove, type = null } = props;

    //Modales de vista previa
    const [openViewModal, setOpenViewModal] = useState(false);

    //vista previa
    const handleOpenViewModal = () => {
        setOpenViewModal(true);
    };
    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };

    const handleRemove = () => {
        handleItemRemove(value.id);
    };

    const TagToPrint = value?.tag_id?.length > 0 ? value?.tag_id[0] : value?.tags?.length > 0 ? value?.tags[0].name : null;
    //value?.tag_id es un array de textos con los nombres de los tags para la incorporacion de OA en el listado de cáda mòdulo
    //value?.tags es un array de Objetos con numero y nombre de cada tag, viene desde el valor inicial del modulo a editar

    function getValidPublicationNameResource(value) {
        const properties = ['nombre', 'nombre_publicacion', 'publication_name', 'title'];
        for (let prop of properties) {
            if (value[prop] && value[prop].trim() !== '') {
                return value[prop];
            }
        }
        return '';
    }

    if (value.length === 0) {
        return <Loading />;
    }
    return (
        <>
            <RowCard key={value.id}>
                <CardFake>
                    <DragIndicatorRoundedIcon />
                    <Body>
                        <Text fontSize="1rem" fontWeight="600" lineheight="1.5rem" fontcolor="#000000">
                            {getValidPublicationNameResource(value)}{' '}
                        </Text>
                        <Text fontSize="0.8rem" fontWeight="400" lineheight="1.5rem" fontcolor="#616161">
                            {TagToPrint}
                        </Text>
                    </Body>
                    <ActionsWrapper>
                        <MenuItemFake onClick={handleOpenViewModal}>
                            <RemoveRedEyeIcon />
                        </MenuItemFake>
                        <MenuItemFake onClick={handleRemove}>
                            <DeleteRoundedIcon />
                        </MenuItemFake>
                    </ActionsWrapper>
                </CardFake>
            </RowCard>
            {openViewModal &&
                (type === 'resource' ? (
                    <Modal>
                        <ViewResource handleClose={handleCloseViewModal} selectedClass={value} title={getValidPublicationNameResource(value)} />
                    </Modal>
                ) : (
                    type === 'oa' && <ViewCourse course={value} openModal={openViewModal} closeModal={handleCloseViewModal} />
                ))}
        </>
    );
};

export default CheckedCourseCard;

const CardFake = styled.div`
    width: 100%;
    height: 70px;
    padding: 0 2rem;
    background-color: #f1f1f1;
    /* cursor: pointer; */
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;
    display: grid;
    grid-template-columns: 0.5fr 4fr 0.5fr;
    align-items: center;
    align-content: center;
    justify-items: stretch;
    justify-content: space-between;
    overflow: hidden;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const MenuItemFake = styled(MenuItem)`
    /* li .MuiButtonBase-root
.MuiButtonBase-root-MuiMenuItem-root
.MuiButtonBase-root
.MuiMenuItem-root 
.MuiMenuItem-gutters 
.MuiMenuItem-root 
.MuiMenuItem-gutters{
    border: var(--primary-one);
    border-radius: 50px
} */
    li span .MuiTouchRipple-root {
        border-radius: 50px;
        :hover {
            background-color: transparent !important;
        }
    }

    .MuiButtonBase-root-MuiMenuItem-root:hover {
        background-color: transparent !important;
    }

    svg {
        color: var(--primary-one);
        width: 1rem;
    }
`;

const RowCard = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    align-items: center;
    align-content: center;
    justify-items: stretch;
    justify-content: space-between;

    span .Mui-checked .MuiButtonBase-root-MuiCheckbox-root .MuiCheckbox-indeterminate {
        color: var(--primary-one);
    }

    span {
        color: var(--primary-one);
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    color: var(--primary-one);
    svg {
        fill: var(--primary-one);
    }
`;

export const Modal = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;
`;
