import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from 'file-saver'
import jsPDF  from "jspdf";
import autoTable from 'jspdf-autotable';
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import Text from '../../../shared/common/Text';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const DownloadGroup = (props) => {
    const {print, imagenCompany, title} = props;

    //DATE
    const d = new Date();
    const date = d.toISOString().slice(0, 10);

    //STATE
    const [groups, setGroups] = useState([])
    const [allGroupDate, setAllGroupsDate] = useState(null)
    const [groupData, setGroupData] = useState(null)
    
    //EFFECTS
    useEffect(() => {
        if(print.length !== undefined){
            setGroups(print) 
            //PRINT se utilizará como objeto si es solo 1 usuario, GROUPS como array si se seleccionó el boton "todos"
        }else{
            dataToPdf(print)
        }
    }, [print])

    useEffect(() => {
        if(groups.length !== 0){
            dataAllGroupsToPdf(groups) 
        }
    }, [groups])

    //FUNCTIONS
    const dataToPdf=(group)=>{
        var body = []
            group.courses.map((course)=>(
                course.users.map((user)=>(
                        body.push({
                            "id": user.repo_id,
                            "group": group.group,
                            "course":course.course,                           
                            "user": user.name,
                            "progress": user.progress,
                        })
                ))
            ))
        setGroupData(body)
    }
    const dataAllGroupsToPdf=(groups)=>{
        var body = []
        groups.map((group)=>(
            group.courses.map((course)=>(
                course.users.map((user)=>(
                        body.push({
                            "id": user.repo_id,
                            "group": group.group,
                            "course":course.course,                           
                            "user": user.name,
                            "progress": user.progress,
                        })
                ))
            ))
        ))
        setAllGroupsDate(body)
    }

    function bodyRows(rowCount, data) {
        rowCount = rowCount || 1
        var body = []
        for (var j = 1; j <= rowCount; j++) {
            body.push({
                    "id": data[j-1] ? data[j-1].repo_id : j,
                    "group": data[j-1] ? data[j-1].group : "",
                    "course": data[j-1] ? data[j-1].course : "",
                    "user": data[j-1] ? data[j-1].user : "",
                    "progress":data[j-1] && (data[j-1].progress<=100) ? `${data[j-1].progress} %` : "100 %",
                  })
           }
        return body
    }

    const generatePDF = async () => {
    try{
        const doc = new jsPDF('p', 'mm', 'a4');
        // IMG
        let img = new Image();
        img.src = `data:image/png;base64,${imagenCompany}`;
        doc.addImage(img, 'png', 20, 10, 30, 10);
        //PAGES
        var totalPagesExp = '{total_pages_count_string}'
        // FONT
        doc.setTextColor('#757575');
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        // TITLE
        doc.text(`Avance de cursado`, 90, 17);
        doc.text(`Grupo ${print.group}`, 90, 22);
        //DATE
        doc.text(date, 170, 17);
        //GROUP
        let finalY = doc.lastAutoTable.finalY || 20;
        doc.setTextColor('#757575');
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        //TABLE
        await autoTable(doc, {
                theme:'grid',
                startY: finalY + 15,
                margin: { top: 30, bottom:20 },
                horizontalPageBreak: false,
                pageBreak: "auto",
                rowPageBreak: 'avoid',
                tableWidth: '80%',
                head: [{
                    course:'Nombre del Curso',
                    user:'Nombre del Usuario',
                    progress:'Progreso'
                      }],
                headStyles: {
                    fillColor: 'var(--primary-one)',
                    fontStyle: 'bold',
                    textColor: '#FFFFFF',
                    cellPadding: 2,
                    halign: 'center',
                    valign: 'middle',
                },
                columnStyles: {
                    0: {halign: 'left'},
                    1: {halign: 'center'},
                    2: {halign: 'center'},
                },
                body: bodyRows(groupData.length, groupData),
                styles: { 
                    lineWidth: 0.1, 
                    cellPadding: 1, 
                    fontSize: 10, 
                    overflow: 'linebreak', 
                    cellWidth: 'auto'
                },
                //DRAW PAGES
                didDrawPage: function () {
                    // Footer
                    let str = 'Página ' + doc.internal.getNumberOfPages()
                        if (typeof doc.putTotalPages === 'function') {
                          str = str + ' de ' + totalPagesExp
                        }
                    var pageSize = doc.internal.pageSize
                    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                    doc.text(str, 14, pageHeight - 10)
                },
            })

        finalY = doc.lastAutoTable.finalY;
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp)
        }
        doc.text(`Generado por Acrópolis Lab con fecha ${date}`, 14, finalY + 10);
        doc.save(`grupo_${print.group}.pdf`)
        }
        catch (error){
            console.log(error);
        }
    }

    //ALL GROUPS
    const generateGlobalPDF = async () => {
    try{
        const doc = new jsPDF('p', 'mm', 'a4');
        // IMG
        let img = new Image();
        img.src = `data:image/png;base64,${imagenCompany}`;
        doc.addImage(img, 'png', 20, 10, 30, 10);
        //PAGES
        var totalPagesExp = '{total_pages_count_string}'
        // FONT
        doc.setTextColor('#757575');
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        // TITLE
        doc.text('Análisis grupal por curso y usuario', 65, 17);
        //DATE
        doc.text(date, 170, 17);
        let finalY = doc.lastAutoTable.finalY || 15

            await autoTable(doc, {
                    theme:'grid',
                    startY: finalY + 15,
                    margin: { top: 30, bottom:20 },
                    horizontalPageBreak: false,
                    pageBreak: "auto",
                    rowPageBreak: 'avoid',
                    tableWidth: '80%',
                    //HEAD
                    head: [{
                        group: "Grupo",
                        course:'Nombre del Curso',
                        user:'Nombre del Usuario',
                        progress:'Progreso'
                          }],
                    headStyles: {
                        fillColor: 'var(--primary-one)',
                        fontStyle: 'bold',
                        textColor: '#FFFFFF',
                        cellPadding: 2,
                        halign: 'center',
                        valign: 'middle',
                    },
                    columnStyles: {
                        0: {halign: 'left'},
                        1: {halign: 'center'},
                        2: {halign: 'center'},
                        3: {halign: 'center'},
                    },
                    //BODY
                    body: bodyRows(allGroupDate.length, allGroupDate),
                    styles: {
                        lineWidth: 0.1, 
                        cellPadding: 1, 
                        fontSize: 10, 
                        overflow: 'linebreak', 
                        cellWidth: 'auto' },
                    //DRAW PAGES
                    didDrawPage: function () {
                        // Footer
                        let str = 'Página ' + doc.internal.getNumberOfPages()
                            if (typeof doc.putTotalPages === 'function') {
                              str = str + ' de ' + totalPagesExp
                            }
                        var pageSize = doc.internal.pageSize
                        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                        doc.text(str, 14, pageHeight - 10)
                    },
            })
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp)
        }

        finalY = doc.lastAutoTable.finalY; 
        doc.text(`Generado por Acrópolis Lab con fecha ${date}`, 14, finalY + 10);
        doc.save('analítica_grupos.pdf')
        } catch (error){
            console.log(error);
        }
    };

    const saveAsExcel = async() => {
        try{
             // Create a workbook with a worksheet
            const wb = new ExcelJS.Workbook()
            wb.views = [
                {
                    x: 0, y: 0, width: 10000, height: 20000,
                    firstSheet: 0, activeTab: 1, visibility: 'visible',
                    showGridLines: true
                }
            ]
            const ws = wb.addWorksheet('coursesSheet', {
                pageSetup:{
                    paperSize: 9,
                    orientation:'portrait',
                    margins: {
                        left: 0.7, right: 0.7,
                        top: 0.75, bottom: 0.75,
                        header: 0.3, footer: 0.3
                      }
                },
            });
            //LOGO
            var myBase64Image = `data:image/png;base64,${imagenCompany}`;
            var logo = wb.addImage({
            base64: myBase64Image,
            extension: 'png',
            });
            ws.addImage(logo, 'F1:I2');

            //COLUMNS
            ws.columns = [
                { header: 'Grupo', key: 'group', width: 20 },
                { header: 'Nombre del Curso', key: 'course', width: 40 },
                { header: 'Usuario', key: 'user', width: 30 },
                { header: 'Progreso', key: 'progress', width: 15 }
            ];

            //1 GROUP SELECTED
            print.length === undefined
            ?   print.courses.forEach(courseData => {
                    ws.addRow({
                        course:courseData.course,
                    })
                    courseData.users.forEach(usersData =>{
                        ws.addRow({
                            user: usersData.name,
                            progress: usersData.progress <= 100 ? usersData.progress/100 : 1,
                        })
                    })
                ws.getCell('A2').value=print.group
                })
            :  //ALL GROUPS
                print.forEach(groupData => {
                    ws.addRow({
                        group:groupData.group, 
                    })
                    groupData.courses.forEach(courseData=>{
                        ws.addRow({
                            course:courseData.course,   
                        })
                        courseData.users.forEach(usersData=>{
                            ws.addRow({
                                user: usersData.name,
                                progress: usersData.progress <= 100 ? usersData.progress/100 : 1,    
                            })
                        })
                    })
                });
            
            let bodyFill ={
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'F8F9F9'},
            };
            let bodyBorder ={
                left: { style: 'thin' },
                right: { style: 'thin' }
            };
            let alignmentTex ={ vertical: 'middle', horizontal: 'left', indent:1 };
            let alignmentNumber ={ vertical: 'middle', horizontal: 'center' };

            ws.eachRow({ includeEmpty: false }, function(row) {
                row.eachCell({ includeEmpty: false }, function() {
                    //FILL BODY
                row.getCell('A').fill=bodyFill;
                row.getCell('B').fill=bodyFill;
                row.getCell('C').fill=bodyFill;
                row.getCell('D').fill=bodyFill;
                //PERCENTAGE
                row.getCell('D').numFmt = '0.00%';
                //BORDERS
                row.getCell('A').border = bodyBorder;
                row.getCell('B').border = bodyBorder;
                row.getCell('C').border = bodyBorder;
                row.getCell('D').border = bodyBorder;
                })
            });
            //styles
            ['A1','B1','C1','D1'].map((key) => ( 
                ws.getCell(key).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'b31d15'},
                }
            ));
            ['A1','B1','C1','D1'].map((key) => ( 
                ws.getCell(key).font = {
                    name: 'helvetica',
                    size: 12,
                    bold: true,
                    color: { argb: 'FFFFFF' },
                }
            ));
            ['A1','B1','C1','D1'].map((key) => ( 
                ws.getCell(key).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            ));
            ws.getRow(1).height = 30;
            ws.getRow(1).alignment = { vertical: 'middle' };
            ws.getColumn(1).alignment = alignmentTex;
            ws.getColumn(2).alignment = alignmentTex;
            ws.getColumn(3).alignment = alignmentTex;
            ws.getColumn(4).alignment = alignmentNumber;

            // Generate & Save Excel File
            const buf = await wb.xlsx.writeBuffer()
            saveAs(new Blob([buf]), 'analítica_grupos.xlsx')
        }
        catch (error){
            console.log(error);
        }
    }

    return (
        <Container>
            <Text fontSize="1rem" fontWeight="700" color="error">
                {title}
                {/* {print && print.length !== undefined ? "Avance por curso" : `Avance por curso - Grupo ${print.group}`} */}
            </Text>
            <ButtonList>
                <PdfButton
                    size="small"
                    endIcon={<GetAppRoundedIcon />}
                    variant='contained'
                    color='primary'
                    onClick={groups.length !== 0
                            ? generateGlobalPDF
                            : generatePDF }
                >
                    Exportar PDF 
                </PdfButton>
                <ExcelButton
                    size="small"
                    variant='contained'
                    color='primary'
                    endIcon={<GetAppRoundedIcon />}  
                    onClick={saveAsExcel}
                >
                    Exportar XLS
                </ExcelButton>
            </ButtonList>
        </Container>
    )
}
const mapStateToProps = (state) => ({
	imagenCompany: state.user.user.imagen,
});

export default connect(mapStateToProps)(DownloadGroup);

const Container  = styled.div`
	display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    gap: 0.5rem;
`;

const ButtonList = styled.div`
	display: flex;
	align-items: center;
    justify-content: end;
	flex-wrap: wrap;
	gap: 1rem;
	padding: 0rem;
    font-size: 1rem;
    margin-top: 0;
`;

const PdfButton = styled(Button)`
	background-color: var(--primary-one);
	color: #f4f4f4;
;
`;
const ExcelButton = styled(Button)`
	background-color: #1D6F42;
	color: #f4f4f4;
`;