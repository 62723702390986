import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InputIcon from '../../shared/common/InputIcon';
import NoResultsFound from '../../shared/common/NoResultsFound';
import ActionButton from '../blog/ActionButton';
import { getAllSlidesByCompany } from '../../redux/api/announcements';
import AnnouncementsCard from './AnnouncementsCard';

const TabBanner = props => {
    const { user } = props;

    // STATE
    const [search, setSearch] = useState('');
    const [banners, setBanners] = useState([]);
    const [bannersFiltered, setBannersFiltered] = useState([])

    // EFFECTS
    useEffect(() => {
        if (banners.length === 0) {
            getSlides(user.id);
        }
    }, []);

    useEffect(() => {
        if (search.length !== 0) {
            searchbanners(banners, search)
        } else {
            getSlides(user.id);
        }
    }, [search]);

    // FUNCTIONS

    const getSlides = async (id) => {
        let req = await getAllSlidesByCompany(id);
        if (!!req) {
            setBanners(req.data);
        }
        // if(req.error){
        // 	alert("Ocurrió un error")
        // }else{
        // 	setBanners(req)
        // }
    };

    function handleSearch(e) {
        setSearch(e.target.value);
    }


    const searchbanners = (array, search) => {
		let newArray = []
		array.forEach(item => {
			if( item.attributes?.title?.toLowerCase().includes(search.toLowerCase()) 
			){ 
				newArray.push(item)
			}
		}
	);
    setBannersFiltered(newArray.length === 0 ? [] : newArray)
	}

    return (
        <>
            <HeaderWrapper>
                <InputIcon value={search} onChange={handleSearch} />
                <ActionButton title="Añadir Banner" action="/newbanner" icon={<AddRoundedIcon />} bgc="var(--primary-one)" textColor="#ffff" />
            </HeaderWrapper>
            {
                search.length === 0
                ?   ( banners.length === 0 
                    ?   ( <NoResultsFound message="No existe contenido del blog, añade un artículo." /> ) 
                    :   (
                            <GridValues>
                                { banners.map(value => value.id !== undefined &&
                                    <AnnouncementsCard value={value.attributes} key={value.id} artId={value.id} type='banner' />)
                                }
                            </GridValues>
                        )
                    )
                :   ( bannersFiltered.length === 0
                    ?   ( <NoResultsFound message="No se encuentran resultados para tu búsqueda" /> ) 
                    :   (
                            <GridValues>
                                { bannersFiltered.map(value => value.id !== undefined &&
                                    <AnnouncementsCard 
                                    value={value.attributes} 
                                    key={value.id} 
                                    artId={value.id}
                                    type='banner'
                                    // aDeletedArticle={aDeletedArticle}
                                    />)
                                }
                            </GridValues>
                        )
                    )
            }
        </>
    );
};

export default TabBanner;

const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    height: auto;
    grid-gap: 1.5rem;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 4rem);
    padding: 1rem  2rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
`;
