import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

// COMPONENTS
import Navegation from './navegation/Navegation';
import Aside from './aside/Aside';
import TicketPopUp from './ticketLayout/components/TicketPopUp';

import BackgroundHome from "../../assets/dashboard-background.png"

export default function Layout(props) {
    const { header } = props;

    const history = useHistory();

    const location = history?.location.pathname.match(/\/([^/]*)\/?/);

    const appLocation = location ? location[1] : null;

    const BackgroundSelected = () => {
        if (!!appLocation) {
            return "none";
        } else {
            return BackgroundHome;
        }
    };

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function handleToggle() {
        setIsOpen(!isOpen);
    }

    function closeMenu() {
        setIsOpen(false);
    }

    // RETURN
    return (
        <>
            <Navegation handleToggle={handleToggle} />
            <Aside handleToggle={handleToggle} isOpen={isOpen} closeMenu={closeMenu} />
            {header}
            <CustomMain backgroundImage={BackgroundSelected} header={header}>{props.children}</CustomMain>
            <TicketPopUp />
        </>
    );
}

const CustomMain = styled.main`
    padding: ${p => (p.header ? '4rem 4rem 4rem 8rem' : 'calc(70px + 2rem) 4rem 4rem calc(70px + 4rem)')};
    background-image: url(${(p) => p.backgroundImage});
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 100% 80%;
    background-color: #f1f1f1;
`;
