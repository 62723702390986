import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import BodyPopup from './BodyPopup';

const MainPopup = props => {
    const { popUpToView } = props;
    let history = useHistory();
    const [open, setOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        setOpen(true);
    }, []);

    const URL = process.env.REACT_APP_CMS_ADEN || 'https://cms.aden.org';

    const image = popUpToView?.image?.data?.attributes?.url ? URL + popUpToView?.image?.data?.attributes?.url : popUpToView?.image_url;

    // FUNCTIONS
    const handleClose = () => {
        setOpen(false);
        history.push(`/anuncios?tab=popup`);
    };

    return (
        <ModalFake open={open} disableEnforceFocus aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
            <Card>
                <ImageWrapper>
                    {' '}
                    <img src={image} />{' '}
                </ImageWrapper>
                <BodyWrapper>
                    <BodyPopup currentSlide={popUpToView} handleClose={handleClose} />
                </BodyWrapper>
            </Card>
        </ModalFake>
    );
};

export default MainPopup;

const ModalFake = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Card = styled.div`
    width: 800px;
    background-color: white;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    height: auto;
    overflow: hidden;
    outline: none;

    @media (max-width: 768px) {
        width: 85%;
    }
`;

const ImageWrapper = styled.div`
    height: 100%;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const BodyWrapper = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
