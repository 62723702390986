import React from 'react'
import styled from 'styled-components';
import Header from '../../shared/layout/header/Header';
import Layout from '../../shared/layout/Layout';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';
import { useEffect } from 'react';
import NewCourseForm from '../../components/content-creation/NewCourseForm';
import MessageModal from '../../shared/common/MessageModal';

const NewCourse = () => {
    let history = useHistory();

    const [courseCreated, setCourseCreated] = useState(null);
    const [messageOpen, setMessageOpen] = useState(false);

    useEffect(() => {
        if (!!courseCreated) {
            setMessageOpen(true);
            // history.push(`/blog`);

        }
    }, [courseCreated]);


    const handleState = art => {
        setCourseCreated(art);
    };

    function handleClose() {
        setMessageOpen(false);
        history.push(`/creacion-de-contenido?tab=cursos_empresa`);
    }

    return (
        <>
            <Layout header={<Header route="Nuevo Curso" />}>
                <HeaderWrapper>
                    <NewCourseForm
                        handleState={handleState}
                    />
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="Curso creado!"
                image="/images/saved_article.svg"
                subtitle="El curso se guardó como borrador"
            /> 
        </>
    );
}

export default NewCourse; 
const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
