import React from 'react';
import styled from 'styled-components';

import Text from '../../shared/common/Text';
import { Button } from '@material-ui/core';
import { Modal } from '@mui/material';

const MessageModal = props => {
    const { isOpen, handleClose, message, image, subtitle, handleClickConfirm = false, withoutButton = false } = props;

    // RETURN
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Container>
                <FakeImage src={image} />
                <Text fontSize="2rem" fontWeight="700" fontcolor="#222222" textalign={'center'}>
                    {message}
                </Text>
                <Text fontSize="1.125rem" fontWeight="700" fontcolor="#777777" textalign={'center'}>
                    {subtitle}
                </Text>
                {withoutButton === false ? (
                    handleClickConfirm !== false ? (
                        <>
                            <FakeButton variant="contained" size="small" onClick={handleClickConfirm}>
                                Si, confirmo
                            </FakeButton>
                            <FakeButton variant="contained" size="small" onClick={handleClose}>
                                No, cancelo
                            </FakeButton>
                        </>
                    ) : (
                        <FakeButton variant="contained" size="small" onClick={handleClose}>
                            Entendido
                        </FakeButton>
                    )
                ) : null}
            </Container>
        </Modal>
    );
};

export default MessageModal;

const Container = styled.div`
    max-width: 400px;
    height: max-content;
    padding: 3rem;
    row-gap: 1.5rem;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    outline: none;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
`;

const FakeImage = styled.img`
    width: 250px;
    height: auto;
    margin-top: 1rem;
`;

const FakeButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 48px;
    gap: 8px;
    background: var(--primary-one);
    border-radius: 48px;
    /* cursor: pointer;
    z-index: 2; */

    font-size: 0.8rem;
    font-weight: 600;
    color: #ffff;

    span {
        font-size: 0.8rem;
        font-weight: 600;
        color: #ffff;
    }

    &.MuiButton-contained:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), var(--primary-one);
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    }
`;
