import React from 'react';
import styled from 'styled-components';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

const PublicationInfo = props => {
    const { author, creation, update, published } = props;
    return (
        <Container>
            <Row>
                <h2>Creado</h2>
                <p>{creation}</p>
            </Row>
            <Row>
                <h2>Por</h2>
                <p>{author}</p>
            </Row>
            <Row>
                <h2>Última actualización</h2>
                <p>{update}</p>
            </Row>
            <Row>
                <h2>Estado</h2>
                {!!published ? (
                    <StateButton bgc="var(--primary-one)">Publicado</StateButton>
                ) : (
                    <StateButton bgc="#616161">
                        <EditRoundedIcon />
                        Borrador
                    </StateButton>
                )}
            </Row>
        </Container>
    );
};

export default PublicationInfo;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    gap: 2rem;
    padding: 0.25rem;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
`;

const StateButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0px !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px 8px;
    max-width: 115px;
    overflow: hidden;
    background: ${p => p.bgc};
    border-radius: 5px;
    color: #ffff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
`;
