import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PieChart, Pie, Cell } from "recharts";
import ScrollFixed from "../../../shared/common/ScrollFixed";
import DefaultWrapper from "../DefaultWrapper";
import Loading from "../../../shared/common/Loading";
import {renderCustomizedLabel} from "../../../helpers/recharts.helper"
import StudentsPerCourseTable from "./StudentsPerCourseTable";

const COLORS = [
	"#f65f5f",
	"#fcb765",
	"#fbff6a",
	"#aafc99",
	"#71f2ff",
	"#72a6fc",
	"#bdb2ff",
	"#ff92ff",
	"#ff948e",
	"#07BACC",
	"#0A57D6",
	"#142459",
	"#176BA0",
	"#19AADE",
	"#1AC9E6",
	"#1BD4D4",
	"#1CE5F8",
	"#1DE4BD",
	"#29066B",
	"#3780F7",
	"#5338F6",
	"#54DA39",
	"#6DF0D2",
	"#71F2FF",
	"#72A6FC",
	"#79EF60",
	"#7D3AC1",
	"#820401",
	"#8674F4",
	"#9FFFE9",
	"#AAFC99",
	"#AD3535",
	"#AF4BCE",
	"#B9F9FF",
	"#BCD5FF",
	"#BD0ABD",
	"#BDB2FF",
	"#C02323",
	"#C17111",
	"#CB2118",
	"#CEFFF4",
	"#D4FFCC",
	"#DAD4FF",
	"#DB4CB2",
	"#DC3636",
	"#DE542C",
	"#DEE40D",
	"#E237E2",
	"#E49435",
	"#E7E34E",
	"#EA7369",
	"#EABD3B",
	"#EB548C",
	"#EE9A3A",
	"#EF7E32",
	"#F0A58F",
	"#F19E9E",
	"#F3F192",
	"#F4CDC2",
	"#F7F4BF",
	"#F8FD43",
	"#FABC72",
	"#FCEAE6",
	"#FCFFA4",
	"#FEFFD8",
	"#FF392E",
	"#FF92FF",
	"#FF948E",
	"#FFC5C2",
	"#FFCAFF",
	"#FFD8AA",
];

const StudentsPerCourse = (props) => {
	const {analyticsCourses} = props;

	//FUNCTION
	const transformObject = (object)=> {
		let result=[] ;
		for (const key in object) {
			result.push(object[key])
		}
		setArray(result)
	}

	//STATES
	const [array, setArray] = useState([])

	// //EFECTS
	useEffect(() => {
		if(analyticsCourses !== null || array.length === 0){
			transformObject(analyticsCourses)
		}
	}, [analyticsCourses])


	//RETURN
	if (analyticsCourses === null && array.length === 0) {
		return <Loading/>
	}
	return (
		<DefaultWrapperFake
			title="Porcentaje de alumnos por curso"
			columns="span 14"
			rows="span 2"
		>
			<Container>
			{
				array.length > 10 ?
					<PieChartFake width={200} height={200} >
						<Pie
							data={array}
							innerRadius={50}
							outerRadius={80}
							fill="#8884d8"
							paddingAngle={1}
							dataKey="counter"
							labelLine={false}
						>
							{array.map((element, index) => (
								<Cell
									key={`cell-${index}`}
									fill={COLORS[index % COLORS.length]}
								/>
							))};
						</Pie>
					</PieChartFake>
				:
					<PieChartFake width={300} height={200} >
						<Pie
							data={array}
							innerRadius={50}
							outerRadius={80}
							fill="#8884d8"
							paddingAngle={1}
							dataKey="counter"
							label={renderCustomizedLabel}
							labelLine={false}
						>
							{array.map((element, index) => (
								<Cell
									key={`cell-${index}`}
									fill={COLORS[index % COLORS.length]}
								/>
							))};
						</Pie>
					</PieChartFake>
			}
				<ScrollFixed 
				overflow-x="scroll"
				height="500px">
					<StudentsPerCourseTable 
					colors={COLORS}
					rows={array} />
				</ScrollFixed>
			</Container>
		</DefaultWrapperFake>
	);
};

export default StudentsPerCourse;

const DefaultWrapperFake = styled(DefaultWrapper)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content:center;
	column-gap: 1rem;
`;

const Container = styled.div`
	display: grid;
	grid-template-areas:" piechart scrollfixed ";
	grid-template-columns:max-content;
	align-self: center;
	align-items: center;
	//justify-content: center; NO USAR
	width: 100%;
	height: 550px;
	overflow-y: auto;
	overflow-x: auto;
	column-gap: 2rem;
	@media (max-width: 2000px) {
		grid-template-areas:" piechart  "
		    				" scrollfixed ";
		row-gap: 2.5rem;
		grid-template-columns:95%;
	}
`;
const PieChartFake = styled(PieChart)`
	grid-area:piechart;
	margin-bottom: -10%;
	justify-self: center;

	@media (max-width:2000px) {
		max-height: 50vh;
		align-self: center;
		margin-bottom: 5%;

	}
`;
