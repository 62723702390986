import styled from "styled-components";

export const ContainerAddUser = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    padding: 1.5rem;
    min-width: 320px;
    max-width: 320px;
`;
