import React from 'react';
import styled from 'styled-components';

const CourseAddedCard = props => {
    const { course, swictch } = props;
    return (
        <CardFake>
            <Header image={course?.imagen ? course?.imagen : course?.img}>
                {
                    course?.tag_id
                    ? <TagSpan>{course.tag_id[0]}</TagSpan> 
                    : null
                }
            </Header>
            <Body>
                <h2>{course?.nombre_publicacion ? course?.nombre_publicacion : course?.name}</h2>
                <Span>
                    {course.descripcion !== '' && course.descripcion !== undefined && course.descripcion !== null && course.descripcion !== false
                        ? course.descripcion
                        : course.description ? course.description : null }
                </Span>
            </Body>
            <Footer>{swictch}</Footer>
        </CardFake>
    );
};

export default CourseAddedCard;

const CardFake = styled.div`
    min-width: 350px;
    min-height: 280px;
    padding: 0;
    max-width: 320px;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;
`;

const Header = styled.div`
    height: 80px;
    padding: 1.5rem;
    border-radius: 20px;
    width: calc(100% - 3rem);
    background-image: url(${p => `${p.image}`});
    background-size: cover;
    background-position: center center;
    position: relative;
`;

const Body = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
    height: 150px;
    overflow: hidden;
    white-space: wrap;
    margin: 0;
    h2 {
        color: #222222;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        padding: 0.5rem;
    }
    span {
        font-size: 0.7rem;
        overflow: scroll;
        padding: 1rem;
        margin-bottom: 1rem;
    }
`;

const Span = styled.span`
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 140%;
    color: #616161;
    display: flex;
    align-items: flex-end;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-overflow: ellipsis;

    svg {
        font-weight: 400;
        font-size: 1.5rem;
        color: #616161;
        opacity: 50%;
        padding: 0 1rem 0 0;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100%-4rem);
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 10px;
    overflow: hidden;
    gap: 0.8rem;
    position: absolute;
    bottom: 1%;
    left: 1%;
    right: 1%;
    background-color: #fafafa;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 0rem 0rem 1.25rem 1.25rem;
    background: #f9f9f9;

    svg {
        fill: var(--secondary-four);
        cursor: pointer;
    }
`;

const TagSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 10px;
    position: absolute;
    width: 50%;
    left: 0px;
    bottom: 0px;
    background: #ffffff;
    border-radius: 0px 10px 0px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
    color: #616161;
`;
