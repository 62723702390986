import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/groups.types";
import { getGroups, createGroup, editGroup, removeGroup, getNumberOfGroups } from "../api/group.api";
import { groupActions } from "../actions";

function* getNumberOfGroupsRequest(action) {
	try {
		const response = yield call(getNumberOfGroups, action.payload);
		yield put(groupActions.getNumberOfGroupsSuccess(response.data.cantidad_grupos));
	} catch (error) {
		yield put(groupActions.getNumberOfGroupsFail(error));
	}
}

function* getGroupsRequest(action) {
	try {
		const res = yield call(getGroups, action.payload);
		yield put(groupActions.getGroupsSuccess(res.data));
	} catch (error) {
		yield put(groupActions.getGroupsFail(error));
	}
}

function* createGroupRequest(action) {
	try {
		const res = yield call(createGroup, action.payload);
		yield put(
			groupActions.createGroupSuccess({
				nombre: res.data.data.name,
				id: res.data.data.grupo_id,
				participantes: res.data.participantes,
			})
		);
	} catch (error) {
		yield put(groupActions.createGroupFail(error));
	}
}

function* editGroupRequest(action) {
	try {
		const res = yield call(editGroup, action.payload);
		yield put(
			groupActions.editGroupSuccess({
				nombre: action.payload.editGroup.data_grupo.nombre,
				id: action.payload.id,
				participantes: action.payload.editGroup.data_participantes,
			})
		);
	} catch (error) {
		yield put(groupActions.editGroupFail(error));
	}
}

function* removeGroupRequest(action) {
	try {
		yield call(removeGroup, action.payload);
		yield put(groupActions.removeGroupSuccess(action.payload.id));
	} catch (error) {
		yield put(groupActions.removeGroupFail(error));
	}
}

function* groupWatcher() {
	yield takeEvery(types.GET_NUMBER_OF_GROUPS_REQUEST, getNumberOfGroupsRequest);
	yield takeEvery(types.GET_GROUPS_REQUEST, getGroupsRequest);
	yield takeEvery(types.CREATE_GROUP_REQUEST, createGroupRequest);
	yield takeEvery(types.EDIT_GROUP_REQUEST, editGroupRequest);
	yield takeEvery(types.REMOVE_GROUP_REQUEST, removeGroupRequest);
}

export { groupWatcher };
