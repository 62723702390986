import { MenuItem } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components';
import { participantActions } from '../../redux/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { BlockRounded } from "@material-ui/icons";
import Text from '../../shared/common/Text';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

const DeactivateButton = (props) => {
    const { repositorio_id, status } = props;

    let history = useHistory();
	const dispatch = useDispatch();

    function deactivate() {
		if( status === "activo" ){
			//desactivar en false
			dispatch(
				participantActions.deactivateParticipantRequest({ repositorio_id, status:false })
			);
		}else if ( status === "bloqueado" ){
			//desactivar en true
			dispatch(
				participantActions.deactivateParticipantRequest({ repositorio_id, status:true })
			);
		}
		history.go(0);
	}


  return (
    <FakeMenuItem 
				onClick={deactivate}
				>
					{
					status === "activo" 
						? (	<>
								<BlockRounded color="error" fontSize="small" />
								<Text fontSize="0.75rem" fontWeight="400" color="error">Desactivar</Text>
							</>)
						: status === "bloqueado"
							?(	<>
									<CheckCircleOutlineRoundedIcon color="error" fontSize="small" />
									<Text fontSize="0.75rem" fontWeight="400" color="error">Activar</Text>
								</>)
							: status === "pendiente" || status === "vencido" ? (<></>) : null
					}
				</FakeMenuItem>
  )
}

export default DeactivateButton; 

const FakeMenuItem = styled(MenuItem)`
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
`;
