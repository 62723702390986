import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getStrapiCompany } from '../redux/api/announcements';
import Header from '../shared/layout/header/Header';
import styled from 'styled-components';
import Layout from '../shared/layout/Layout';
import MessageModal from '../components/blog/MessageModal';
import NewPopUpForm from '../components/announcementsAndNews/NewPopUpForm';

const NewPopUp = () => {
 
  let history = useHistory();
  const ondemandId = useSelector(state => state.user.user.id);

  const [popUpCreated, setPopUpCreated] = useState(null);
  const [strapiId, setStrapiId] = useState(null); //la generación de artículos se vincula por id de strapi del ondemand, el cual se consulta por repo_id
  const [messageOpen, setMessageOpen] = useState(false);

  useEffect(() => {
      if (strapiId === null) {
          getStrapiId(ondemandId);
      }
  }, []);

  useEffect(() => {
      if (!!popUpCreated) {
          setMessageOpen(true);
      }
  }, [popUpCreated]);

  const getStrapiId = async id => {
      let req = await getStrapiCompany(id);
      if (!!req) {
          setStrapiId(req.data[0].id);
      }
  };

  const handleState = art => {
      setPopUpCreated(art);
  };

  function handleClose() {
      setMessageOpen(false);
      history.push(`/anuncios?tab=popup`);
  }

  return (
      <>
          <Layout header={<Header route="Nuevo Pop Up" />}>
              <HeaderWrapper>
                  <NewPopUpForm handleState={handleState} strapiId={strapiId} />
              </HeaderWrapper>
          </Layout>
          <MessageModal
              isOpen={messageOpen}
              handleClose={handleClose}
              message="¡Pop Up creado!"
              image="/images/saved_article.svg"
              subtitle="El Pop Up se guardó como borrador"
          />
      </>
  );
};

export default NewPopUp;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;