import React from 'react';
import styled from 'styled-components';
import NewPostForm from '../components/blog/NewPostForm';
import Layout from '../shared/layout/Layout';
import Header from '../shared/layout/header/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { getStrapiCompany } from '../redux/api/blog';
import { useSelector } from 'react-redux';
import MessageModal from '../components/blog/MessageModal';
import { useHistory } from 'react-router-dom';

const NewPost = () => {

    let history = useHistory();
    const ondemandId = useSelector(state => state.user.user.id);

    const [articleCreated, setArticleCreated] = useState(null);
    const [strapiId, setStrapiId] = useState(null); //la generación de artículos se vincula por id de strapi del ondemand, el cual se consulta por repo_id
    const [messageOpen, setMessageOpen] = useState(false);

    useEffect(() => {
        if (strapiId === null) {
            getStrapiId(ondemandId);
        }
    }, []);

    useEffect(() => {
        if (!!articleCreated) {
            setMessageOpen(true);
            // history.push(`/blog`);

        }
    }, [articleCreated]);

    const getStrapiId = async id => {
        let req = await getStrapiCompany(id);
        if (!!req) {
            setStrapiId(req.data[0].id);
        }
    };

    const handleState = art => {
        setArticleCreated(art);
    };

    function handleClose() {
        setMessageOpen(false);
        history.push(`/blog`);
    }


    return (
        <>
            <Layout header={<Header route="Nuevo Artículo" />}>
                <HeaderWrapper>
                    <NewPostForm handleState={handleState} strapiId={strapiId} />
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¡Artículo guardado!"
                image="/images/saved_article.svg"
                subtitle="El artículo se guardó como borrador"
            />
        </>
    );
};

export default NewPost;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
