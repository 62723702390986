import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../shared/layout/Layout';
//import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import AddGroup from '../components/groups/AddGroup';
import ExportGroups from '../components/groups/ExportGroups';
import InputIcon from '../shared/common/InputIcon';
import Wrapper from '../shared/common/Wrapper';
import Header from '../shared/layout/header/Header';
import TableGroups from '../components/groups/TableGroups';
import { useDispatch, useSelector } from 'react-redux';
import { groupActions } from '../redux/actions';
import Loading from '../shared/common/Loading';
import UsersSkt from '../components/skeletons/UsersSkt';
import DefaultWrapper from '../components/analytics/DefaultWrapper';

const Groups = () => {
    // REDUX
    const dispatch = useDispatch();

    const ondemand_id = useSelector(state => state.user.user.id);

    const groups = useSelector(state => state.group.groups);
    const loadingGroups = useSelector(state => state.group.loadingGroups);

    // STATE
    const [search, setSearch] = useState('');
    // const [exported, setExported] = useState(null)

    // EFFECTS
    useEffect(() => {
        if (groups === null) {
            dispatch(groupActions.getGroupsRequest({ ondemand_id }));
        }
    }, []); // eslint-disable-line

    // FUNCTIONS
    function handleSearch(e) {
        setSearch(e.target.value);
    }

    // function handleExport(e) {
    // 	setExported(e.target.value);
    // }

    // RETURN
    return (
        <Layout header={<Header route="Grupos" />}>
            <Container>
                <HeaderWrapper>
                    <InputIcon value={search} onChange={handleSearch} />
                    <Wrapper height="4rem">
                        <ButtonsWrapper>
                            <AddGroup />
                            <ExportGroups
                            // onClick={handleExport}

                            // onClick={()=>
                            // 	<ReactHTMLTableToExcel
                            // 	id="table-xls-button"
                            // 	// className=""
                            // 	table="table-groups"
                            // 	filename="tablexls"
                            // 	sheet="tablexls"
                            // 	buttonText="Exportar a XLS"/>
                            // }
                            />
                        </ButtonsWrapper>
                    </Wrapper>
                </HeaderWrapper>
                {loadingGroups ? (
                    <Loading padding="4rem" />
                ) : groups === null ? (
                    <DefaultWrapper title="No hay grupos ingresados" columns="span 8" />
                ) : (
                    <TableGroups id="table-groups" groups={groups.filter(x => x.nombre.toLowerCase().includes(search.toLowerCase()))} />
                )}
            </Container>
        </Layout>
    );
};

export default Groups;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.5rem;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0 1rem;
    height: 100%;
    width: max-content;
`;
