import * as types from "../types";

const actions = {};

// COUNTRIES
actions.getCountriesRequest = () => {
	return {
		type: types.GET_COUNTRIES_REQUEST,
	};
};

actions.getCountriesSuccess = (countries) => {
	return {
		type: types.GET_COUNTRIES_SUCCESS,
		payload: countries,
	};
};

actions.getCountriesFail = (error) => {
	return {
		type: types.GET_COUNTRIES_FAIL,
		payload: error,
	};
};

// THEMATICS
actions.getThematicsRequest = () => {
	return {
		type: types.GET_THEMATICS_REQUEST,
	};
};

actions.getThematicsSuccess = (countries) => {
	return {
		type: types.GET_THEMATICS_SUCCESS,
		payload: countries,
	};
};

actions.getThematicsFail = (error) => {
	return {
		type: types.GET_THEMATICS_FAIL,
		payload: error,
	};
};

export { actions };
