import React, { useEffect, useState } from 'react'
import Layout from '../shared/layout/Layout';
import styled from "styled-components";
import Header from '../shared/layout/header/Header';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import { groupActions } from '../redux/actions';
import HeaderButtons from '../components/user/HeaderButtons';
import Loading from '../shared/common/Loading';
import TableUsers from './Users/components/TableUsers';
//import UserContent from '../components/user/UserContent';

const Group = () => {
  
    const { id } = useParams();
   
    // REDUX
	const dispatch = useDispatch();
    const { groups, editGroup } = useSelector((state) => state.group);
    const ondemand_id = useSelector(state => state.user.user.id)

    //STATE
    const [currentGroup, setCurrentGroup] = useState(null) 

    // EFFECTS
    useEffect(() => {
        if(groups === null){
            dispatch(groupActions.getGroupsRequest({ondemand_id}));
        }
    }, [groups, id])

    useEffect(() => {
        if(id !== null && groups !== null){
            groups.forEach(group => {
                if( group.id === parseInt( id, 10)){
                    setCurrentGroup(group)
                }
            } );
        } 
    }, [id, groups, editGroup]); 

    if(currentGroup === null){
        return <Loading/>
    }
	return (
		<>
			<Layout
			header={
				<Header group={true}  route={`${currentGroup.nombre ? currentGroup.nombre : ""}`}>
					<HeaderButtons tab="1" id={parseInt(id)} group={true}  rol="group" />
				</Header>
				}
			>
				<Container>
					<TableUsers participants={currentGroup.participantes} group={true} />
                    {/*<UserContent contents={currentGroup.objetos_aprendizaje} /> */}
				</Container>
			</Layout>
	    </>
	);
};

export default Group;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;