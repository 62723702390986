import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DivModuleAggregationDinamic from './DivModuleAggregationDinamic';
import MessageModal from '../../shared/common/MessageModal';

const TabRouteEstructure = props => {
    const { valueModules, formik,  addModule, deleteModule } = props;

    //mensaje de confirmacion de eliminacion de modulo
    const [messageOpen, setMessageOpen] = useState(false);
    const [modToDelete, setModToDelete] = useState(null);
    const [confirm, setConfirm] = useState(false);

    //EFFECTS
    useEffect(() => {
        if (!!modToDelete && confirm === true) {
            deleteModule(formik, modToDelete);
        }
    }, [modToDelete, confirm]);



    //FUNCTIONS
    //mensaje de confirmacion de eliminacion de modulo
    const deleteThisModule = index => {
        setMessageOpen(true);
        setModToDelete(index);
    };
    const handleConfirmedDeleteModule = () => {
        setConfirm(true);
        setTimeout(() => {}, 500);
        handleClose();
    };
    function handleClose() {
        setMessageOpen(false);
    }
    return (
        <Content>
            {formik.values.modules && (
                <GridModules>
                    {formik.values.modules?.map((mod, index) => (
                        <DivModuleAggregationDinamic
                            key={mod.key_module}
                            index={index}
                            valueModule={mod}
                            formik={formik}
                            initialValues={mod.initialValues}
                            deleteThisModule={deleteThisModule}
                            legthModulesListSelected={formik.values.modules.length}
                        />
                    ))}
                </GridModules>
            )}
            <FooterButtons>
                <AddModuleButton
                    onClick={() => addModule(formik)}
                    disabled={valueModules.length > 0 && valueModules[valueModules.length - 1].list_oa?.length === 0}
                    >
                    <AddRoundedIcon />
                    Añadir módulo
                </AddModuleButton>
            </FooterButtons>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¿Deseas eliminar?"
                image="/images/eliminacion_modulo.svg"
                subtitle="Estás a punto de eliminar un módulo
                perteneciente a nueva ruta formativa"
                handleClickConfirm={handleConfirmedDeleteModule}
            />
        </Content>
    );
};

export default TabRouteEstructure;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 2rem 3rem;
    gap: 2rem;
    margin-top: 25px;
`;

const GridModules = styled.div`
    height: auto;
    width: 100%;
`;
const AddModuleButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    gap: 0.5rem;
    width: max-content;
    background: var(--primary-one);
    border-radius: 3rem;
    color: #fafafa;
    font-size: 0.875rem;
    letter-spacing: 0.001em;
    line-height: 1.25rem;
    font-weight: 600;
    justify-self: end;
    place-self: end;
`;

const FooterButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 2rem;
`;
