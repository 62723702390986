import * as types from "../types";

const actions = {};

// GET ADMIN
actions.getAdminRequest = (payload) => {
	return {
		type: types.GET_ADMIN_REQUEST,
		payload: payload,
	};
};

actions.getAdminSuccess = (admin) => {
	return {
		type: types.GET_ADMIN_SUCCESS,
		payload: admin,
	};
};

actions.getAdminFail = (error) => {
	return {
		type: types.GET_ADMIN_FAIL,
		payload: error,
	};
};

// CHANGE PHOTO COMPANY
actions.photoAdminRequest = (payload) => {
	return {
		type: types.PHOTO_ADMIN_REQUEST,
		payload: payload,
	};
};

actions.photoAdminSuccess = (photo) => {
	return {
		type: types.PHOTO_ADMIN_SUCCESS,
		payload: photo,
	};
};

actions.photoAdminFail = (error) => {
	return {
		type: types.PHOTO_ADMIN_FAIL,
		payload: error,
	};
};

export { actions };
