import { useEffect, useState } from 'react';

const useFilterParticipants = (participants, currentState) => {
    const [filteredFinalData, setFilteredData] = useState([]);

    const currentDate = new Date();
    const thirtyOneDaysAgo = new Date().setDate(currentDate.getDate() - 31);
    const thisWeek = new Date().setDate(currentDate.getDate() - 7);
    
    useEffect(() => {
        let filteredList = [];
        switch (currentState.days) {
            case 1:
                // Todos los participantes
                filteredList = participants;
                break;
            case 2:
                // Sin Acceso
                filteredList = participants.filter(item => item.ultimo_login === '');
                break;
            case 3:
                // Accedieron esta ultima semana
                filteredList = participants.filter(item => {
                    const itemDate = new Date(item?.ultimo_login !== '' && item?.ultimo_login);
                    return itemDate >= new Date(thisWeek);
                });
                break;
            case 4:
                // Accedieron despues de esta ultima semana, dias 8 a 30
                filteredList = participants.filter(item => {
                    const itemDate = new Date(item?.ultimo_login !== '' && item?.ultimo_login);
                    return itemDate < new Date(thisWeek) && itemDate > new Date(thirtyOneDaysAgo);
                });
                break;
            case 5:
                // Accedieron por ultima vez hace mas de 31 días
                filteredList = participants.filter(item => {
                    const itemDate = new Date(item?.ultimo_login !== '' && item?.ultimo_login);
                    return itemDate < new Date(thirtyOneDaysAgo);
                });
                break;
            default:
                filteredList = participants;
                break;
        }
        setFilteredData(filteredList);
        // eslint-disable-next-line
    }, [currentState]);
    return { filteredFinalData };
};

export default useFilterParticipants;
