import React from "react";
import styled from "styled-components";

const Wrapper = (props) => {
	const { columns, rows, padding, height, width } = props;

	return (
		<Container columns={columns} rows={rows} padding={padding} height={height} width={width}>
			{props.children}
		</Container>
	);
};

export default Wrapper;

const Container = styled.div`
	padding: ${(p) => p.padding};
	border-radius: 25px;
	background-color: #ffffff;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
	grid-column: ${(p) => p.columns};
	grid-row: ${(p) => p.rows};
	height: ${(p) => p.height};
	width: ${(p) => p.width};
	margin: 0.5rem;
`;
