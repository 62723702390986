import React from 'react';
import styled from 'styled-components';
import Layout from '../shared/layout/Layout';
import Header from '../shared/layout/header/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { getOneArticle, getStrapiCompany } from '../redux/api/blog';
import { useSelector } from 'react-redux';
import MessageModal from '../components/blog/MessageModal';
import EditPostForm from '../components/blog/EditPostForm';
import { useParams } from 'react-router-dom';

const EditPost = () => {
    const { id } = useParams();
    const user = useSelector(state => state.user.user);

    //STATES
    const [articleToEdit, setArticleToEdit] = useState(null);
    const [editedArticle, setEditedArticle] = useState(null); // el artìculo editado llega con "published" false
    const [publishedArticle, setPublishedArticle] = useState(null);
    const [strapiId, setStrapiId] = useState(null); //la generación de artículos se vincula por id de strapi del ondemand, el cual se consulta por repo_id

    const [messageOpen, setMessageOpen] = useState(false);
    const [contentMessage, setContentMessage] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getArticle(id);
        }
    }, [id]);

    useEffect(() => {
        if (strapiId === null) {
            getStrapiId(user.id);
        }
    }, []);

    useEffect(() => {
        if (!!editedArticle) {
            setMessageOpen(true);
            setContentMessage({
                message: '¡Artículo guardado!',
                image: '/images/saved_article.svg',
                subtitle: 'El artículo se guardó como borrador',
            });
            getArticle(id);
        }
    }, [editedArticle]);

    useEffect(() => {
        if (!!publishedArticle) {
            setMessageOpen(true);
            setContentMessage({
                message: '¡Artículo publicado!',
                image: '/images/published_article.svg',
                subtitle: 'El artículo fue publicado con éxito',
            });
            getArticle(id);
        }
    }, [publishedArticle]);

    //FUNCTIONS
    const getArticle = async id => {
        let req = await getOneArticle(id);
        // console.log('req', req);
        if (!!req) {
            setArticleToEdit(req.data.attributes);
        }
    };

    const getStrapiId = async id => {
        let req = await getStrapiCompany(id);
        if (!!req) {
            setStrapiId(req.data[0].id);
        }
    };

    const handlePublished = article => {
        setEditedArticle(null);
        setPublishedArticle(article);
    };

    const handleUpdate = article => {
        setEditedArticle(article);
    };

    const handleClose = () => {
        console.log('handleClose');
        setMessageOpen(false);
        setContentMessage(null);
    };

    // console.log('articleToEdit', articleToEdit);
    // console.log('editedArticle', editedArticle);
    // console.log('publishedArticle', publishedArticle);
    console.log('messageOpen', messageOpen)

    //RETURN
    return (
        <>
            <Layout header={<Header route="Edición de Artículo" />}>
                <HeaderWrapper>
                    {!!articleToEdit && (
                        <EditPostForm
                            articleToEdit={articleToEdit}
                            strapiId={strapiId}
                            artId={id}
                            handleUpdate={handleUpdate}
                            handlePublished={handlePublished}
                        />
                    )}
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message={contentMessage?.message}
                image={contentMessage?.image}
                subtitle={contentMessage?.subtitle}
            />
        </>
    );
};

export default EditPost;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
