import * as types from "../types/content.types";

const initialState = {
	content: null,
	loadingContent: false,
	errorContent: null,
	categories: null,
	loadingCategories: false,
	errorCategories: null,
};

export const content = (state = initialState, action) => {
	switch (action.type) {
		// GET CONTENT
		case types.GET_CONTENT_REQUEST:
			return {
				...state,
				loadingContent: true,
				errorContent: null,
			};
		case types.GET_CONTENT_SUCCESS:
			return {
				...state,
				loadingContent: false,
				content: action.payload,
			};
		case types.GET_CONTENT_FAIL:
			return {
				...state,
				loadingContent: false,
				errorContent: action.payload,
			};
		// GET CATEGORIES
		case types.GET_CATEGORIES_REQUEST:
			return {
				...state,
				loadingCategories: true,
				errorCategories: null,
			};
		case types.GET_CATEGORIES_SUCCESS:
			return {
				...state,
				loadingCategories: false,
				categories: action.payload,
			};
		case types.GET_CATEGORIES_FAIL:
			return {
				...state,
				loadingCategories: false,
				errorCategories: action.payload,
			};
		default:
			return state;
	}
};
