import React from "react";
import styled from "styled-components";

import { Tab, Tabs } from "@material-ui/core";

const DefaultTabs = (props) => {
	const { tabValue, handleTabValue, tabOptions, paddingleft } = props;
	console.log('tabOptions', tabOptions)
	// RETURN
	return (
		<Container
			value={tabValue}
			onChange={handleTabValue}
			textColor="primary"
			indicatorColor="primary"
			paddingleft={paddingleft}
		>
			{tabOptions.map((option) => (
				<Tab key={option.id} label={option.label} />
			))}
		</Container>
	);
};

export default DefaultTabs;

const Container = styled(Tabs)`
	width:100%;
	padding-left: ${(p) => p.paddingleft ? p.paddingleft : "2rem" };
`;
