import React, { useState } from "react";
import styled from "styled-components";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
} from "@material-ui/core";
import Wrapper from "../../shared/common/Wrapper";
import { DeleteRounded } from "@material-ui/icons";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort, headCells } = props;

	const createSortHandler = (property) => (e) => {
		onRequestSort(e, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						padding="default"
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<CustomTableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<SortHidden>{order === "desc" ? "sorted descending" : "sorted ascending"}</SortHidden>
							) : null}
						</CustomTableSortLabel>
					</TableCell>
				))}
				<TableCell padding="checkbox" style={{ fontSize: "0.75rem", fontWeight: "700", color: "#616161" }}>
					ACCIONES
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

function MoreMenu({ deleteContent }) {
	return <DeleteRounded color="secondary" onClick={deleteContent} />;
}

const OPTIONS = [
	{ id: 0, name: "PDF" },
	{ id: 1, name: "Video" },
];

const UnitsTable = (props) => {
	const { rows, headCells, deleteContent } = props;

	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("nombre");
	const [page, setPage] = useState(0);

	const handleRequestSort = (e, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const emptyRows = 3 - Math.min(3, rows.length - page * 3);

	return (
		<Wrapper>
			<TableContainer>
				<Table aria-labelledby="tableTitle" aria-label="enhanced table" size="small">
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						headCells={headCells}
					/>
					<TableBody>
						{stableSort(rows, getComparator(order, orderBy))
							.slice(page * 3, page * 3 + 3)
							.map((row, index) => {
								const labelId = `enhanced-table-checkbox-${index}`;
								return (
									<TableRow
										hover
										tabIndex={-1}
										key={index}
										style={{
											cursor: "pointer",
										}}
									>
										<TableCell component="th" id={labelId} scope="row" padding="default">
											{row.name}
										</TableCell>
										<TableCell>{OPTIONS[row.type].name}</TableCell>
										<TableCell>{row.link || "-"}</TableCell>
										<TableCell>
											{<MoreMenu deleteContent={() => deleteContent(row.id)} />}
										</TableCell>
									</TableRow>
								);
							})}
						{emptyRows > 0 && (
							<TableRow
								style={{
									height: 53 * emptyRows,
								}}
							>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				size="small"
				rowsPerPageOptions={[]}
				component="div"
				count={rows.length}
				rowsPerPage={3}
				page={page}
				onChangePage={handleChangePage}
			/>
		</Wrapper>
	);
};

export default UnitsTable;

const CustomTableSortLabel = styled(TableSortLabel)`
	font-size: 0.75rem;
	font-weight: 700;
	color: #616161;
`;

const SortHidden = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1;
	margin: -1;
	overflow: hidden;
	padding: 0;
	position: absolute;
	top: 20;
	width: 1;
`;

const SpanState = styled.span`
	font-size: 0.8rem;
	text-transform: capitalize;
	padding: 0.25rem 0.75rem;
	border-radius: 50px;
	width: max-content;
	color: #ffffff;
	background-color: ${(p) =>
		p.background === "activo" ? "#1e8065" : p.background === "pendiente" ? "#f2ab1f" : "#b31531"};
`;
