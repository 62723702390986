import React from 'react';
import { CourseFiltersContainer, Span, SpanWrapper } from '../../styled-components/filters.styled.component';

const DefaultStateFilter = props => {
    const { currentState, handleCurrentState, estados } = props;

    // RETURN
    return (
        <CourseFiltersContainer>
            {estados.map(estado => (
                <SpanWrapper
                    key={estado.id}
                    currentState={estado.days ? currentState.value === estado.days : currentState.value === estado.value}
                    onClick={() => handleCurrentState(estado.days ? estado.days : estado.name)}>
                    <Span currentState={estado.days ? currentState.value === estado.days : currentState.value === estado.value}>
                        {estado.name === 'Confirmado' ? 'En curso' : estado.name}
                    </Span>
                </SpanWrapper>
            ))}
        </CourseFiltersContainer>
    );
};

export default DefaultStateFilter;
