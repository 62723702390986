import React from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import { Skeleton } from "@material-ui/lab";

const AnalyticsCoursesSkt = () => {
	return (
		<Wrapper width="100%">
			<Container>
				<SkeletonText variant="text" animation="wave" height={80}/>
				<Skeleton1 variant="rect" animation="wave" height={300} />
				<Skeleton2 variant="rect" animation="wave" height={300} />
				<Skeleton3 variant="rect" animation="wave" height={300} />
			</Container>
		</Wrapper>
	);
};

export default AnalyticsCoursesSkt;

const Container = styled.div`
	display: grid;
	grid-template-areas: "selectcourse         			    selectcourse"
						 "inscriptsstate  				     topstudents"
						 "userpercourseprogress    userpercourseprogress";
	flex-direction: column;
	grid-gap: 1rem;
	padding: 1rem;
`;
const SkeletonText = styled(Skeleton)`
	display: grid;
	grid-area:selectcourse;
	flex-direction: column;
`;
const Skeleton1 = styled(Skeleton)`
	display: grid;
	grid-area:inscriptsstate;
	flex-direction: column;
`;
const Skeleton2 = styled(Skeleton)`
	display: grid;
	grid-area:topstudents;
	flex-direction: column;
`;
const Skeleton3 = styled(Skeleton)`
	display: grid;
	grid-area: userpercourseprogress;
	flex-direction: column;
`;