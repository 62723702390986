import axios from "axios";

export async function getSubject(id) { //no customizables
	try {
        const response = await axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/acropolis/assignments/${id}`,);
        const filteredRoutes = response.data.response_data.filter(x => x.personalized === false)
        return filteredRoutes;
    } catch (error) {
        return {
            error,
        };
    }
}
