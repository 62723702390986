import React from "react";

import RankingCard from "../../../shared/cards/RankingCard";
import ScrollFixed from "../../../shared/common/ScrollFixed";
import DefaultWrapper from "../DefaultWrapper";

const GroupsRanking = () => {
	return (
		<DefaultWrapper
			title="Ranking de grupos por actividad"
			columns="span 8"
		>
			<ScrollFixed height="500px">
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
				<RankingCard />
			</ScrollFixed>
		</DefaultWrapper>
	);
};

export default GroupsRanking;
