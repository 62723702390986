import React from "react";
import styled from "styled-components";
import moment from 'moment';


import Text from "../../shared/common/Text";
import Wrapper from "../../shared/common/Wrapper";
import { getLocalUTC } from "../../helpers/time.helper";

const UserData = ({ currentParticipant }) => {
	const { repositorio_id, nombre, email, estado, fecha_alta, app_last_login } = currentParticipant;
	const utc = getLocalUTC();
	const ultimo_acceso = app_last_login ? (moment(new Date(app_last_login?.replaceAll('-', '/') + `${utc} UTC`)).format('DD/MM/YY HH:mm'))
	:(null);

	const USERDATA = [
		{ id: 0, label: "ID", value: repositorio_id },
		{ id: 1, label: "NOMBRE", value: nombre },
		{ id: 2, label: "EMAIL", value: email },
		{ id: 3, label: "ESTADO", value: estado },
		{ id: 4, label: "FECHA DE ALTA", value: fecha_alta },
		{ id: 5, label: "ÚLTIMA SESIÓN", value: ultimo_acceso },
	];

	return (
		<Wrapper padding="1.5rem">
			<Container>
				{USERDATA.map((item) => (
					<WrapperInfo key={item.id}>
						<Text fontSize="0.75rem" fontWeight="700" color="error">
							{item.label}
						</Text>
						{item.id === 3 ? (
							<SpanState background={item.value?.toLowerCase()}>{item.value}</SpanState>
						) : (
							<Text fontSize="1rem" fontWeight="400">
								{item.value}
							</Text>
						)}
					</WrapperInfo>
				))}
			</Container>
		</Wrapper>
	);
};

export default UserData;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const WrapperInfo = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
`;

const SpanState = styled.span`
	font-size: 0.8rem;
	text-transform: capitalize;
	padding: 0.25rem 0.75rem;
	border-radius: 50px;
	width: max-content;
	color: #ffffff;
	background-color: ${(p) =>
		p.background === "activo" ? "#1e8065" : p.background === "pendiente" ? "#f2ab1f" : "#b31531"};
`;
