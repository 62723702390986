import React, { useState } from 'react';
import styled from 'styled-components';

const TooltipImage = ({ imageUrl }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const handleClick = () => {
        window.open(imageUrl, '_blank');
    };

    return (
        <InfoFileDiv
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}>
            <TitleFile>{imageUrl}</TitleFile>
            {showTooltip && <img src={imageUrl} alt="Imagen" />}
        </InfoFileDiv>
    );
};

export default TooltipImage;

const TitleFile = styled.div`
    text-transform: uppercase;
    color: var(--primary-one);
    font-weight: 600;
    font-size: 0.6rem;
    text-overflow: ellipsis;
    padding: 1rem;
`;

const InfoFileDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    padding: 1rem;
    overflow: hidden;
    white-space: initial;
    width: calc(100%-2rem);
    img {
        min-height: 200px;
        max-width: 300px;
        padding: 1.5rem;
        border-radius: 20px;
        background-size: cover;
        background-position: center center;
        position: relative;
        margin: auto;
    }
`;
