import React from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import Text from "../../shared/common/Text";
import { DeleteRounded, EditRounded } from "@material-ui/icons";

const UnitCard = ({ unit, index }) => {
	function getPdf() {
		let pdf = unit.contents.filter((i) => i.type === 0);
		return pdf.length;
	}

	function getVideo() {
		let video = unit.contents.filter((i) => i.type === 1);
		return video.length;
	}

	return (
		<Wrapper padding="1.5rem">
			<Container>
				<Number>{index + 1}</Number>
				<Text fontSize="1rem" fontWeight="700" color="primary" component="h2">
					{unit.name}
				</Text>
				<Text fontSize="0.7rem" fontWeight="700" color="error" component="span">
					CONTENIDO
				</Text>
				<Text fontSize="0.75rem" color="error" component="p">
					{getPdf()} x PDF
				</Text>
				<Text fontSize="0.75rem" color="error" component="p">
					{getVideo()} x Video
				</Text>
				{/*<ButtonsWrapper>
					<DeleteRounded color="error" />
					<EditRounded color="error" />
                </ButtonsWrapper>*/}
			</Container>
		</Wrapper>
	);
};

export default UnitCard;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 0.5rem;
`;

const Number = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--primary-one);
	color: #ffffff;
	width: 25px;
	height: 25px;
	font-size: 0.7rem;
	font-weight: 700;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 1rem;
	margin-top: 1rem;
`;
