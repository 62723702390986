import React from "react";
import styled from "styled-components";
import Layout from "../shared/layout/Layout";

import Company from "../components/account/Company";
import Admin from "../components/account/Admin";
import Header from "../shared/layout/header/Header";
import Services from "./Dashboard/components/services/Services";

const Account = () => {
	return (
		<Layout header={<Header route="Mi cuenta" />}>
			<Container>
				<Services/>
				<Company />
				<Admin />
			</Container>
		</Layout>
	);
};

export default Account;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;
