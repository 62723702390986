import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import Snack from '../../shared/common/Snack';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ActionButton from '../blog/ActionButton';
import { publishBanner, updateBanner } from '../../redux/api/announcements';
import BannerForm from './BannerForm';
import { BannerFormSchema } from '../../utils/schemas';

const EditBannerForm = props => {
    const { strapiId, bannerToEdit, artId, handleUpdate, handlePublished } = props;
    const [bannerUpdate, setBannerUpdate] = useState(null);
    const [bannerPublished, setBannerPublished] = useState(null);
    const [file, setFile] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [externalLink, setExternalLink] = useState(bannerToEdit?.external_link);

    // EFFECTS
    useEffect(() => {
        if (bannerToEdit.bg_image?.data?.attributes !== undefined) {
            setFile(bannerToEdit.bg_image?.data?.attributes);
        } else {
            setFile(null);
        }
    }, []);

    useEffect(() => {
        if (bannerUpdate !== null) {
            handleUpdate(bannerUpdate);
        }
        if (bannerPublished !== null) {
            handlePublished(bannerPublished);
        }
    }, [bannerPublished, bannerUpdate]);

    // STATE INICIAL
    const initialValues = {
        title: bannerToEdit.title || '',
        description: bannerToEdit.description || '',
        link: bannerToEdit.link || '',
        tag: bannerToEdit.tag || '',
        published: bannerToEdit.published || '',
        bg_image_url: bannerToEdit.bg_image_url || '',
        bg_image: bannerToEdit.bg_image?.data?.attributes || '',
    };

    // FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }
    const handleSubmitFile = file => {
        setFile(file);
    };
    const handleChange = event => {
        setExternalLink(event.target.checked);
    };

    const handleSubmit = async values => {
        const data = {
            title: values.title,
            description: values.description,
            link: values.link,
            tag: values.tag,
            bg_image_url: values.bg_image_url,
            company: strapiId,
            user_roles: ['4'],
            external_link: externalLink,
            published: false,
            //la edicion del banner fuerza su estado de publicado a borrador,
            //si estaba publicado necesariamente debe publicarlo nuevamente tras el update de modificaciones
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(data) || '');
        formData.append('files.bg_image', file || '');

        let response = await updateBanner(artId, formData);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El banner no pudo actualizarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setBannerUpdate(response);
        }
    };

    const handlePublish = async () => {
        let response = await publishBanner(artId, { publish: true });
        // console.log('response-publishbanner', response);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El banner no pudo publicarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setBannerPublished(response);
        }
    };

    // RETURN
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={BannerFormSchema}>
                {({ values }) => (
                    <FormContainer>
                        <BannerForm
                            externalLink={externalLink}
                            handleChange={handleChange}
                            initialValues={initialValues}
                            handleSubmitFile={handleSubmitFile}
                            type="edit"
                        />
                        <ButtonWrapper>
                            {
                                <WrapperButtons>
                                    <ButtonToPublish variant="contained" type="submit" disabled={initialValues.published} onClick={handlePublish}>
                                        Publicar
                                    </ButtonToPublish>
                                    <ButtonToSave variant="contained" type="submit" disabled={file === null} onClick={handleSubmit}>
                                        Guardar cambios
                                    </ButtonToSave>
                                    <ActionButton
                                        title="Vista previa"
                                        type="submit"
                                        icon={<VisibilityRoundedIcon />}
                                        bgc="var(--secondary-one)"
                                        textColor="var(--primary-one)"
                                        border={"1px solid var(--primary-one)"}
                                        action={`/banner/${artId}`}
                                    />
                                </WrapperButtons>
                            }
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default EditBannerForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;

const ButtonToPublish = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background: var(--secondary-one);
        border: 1px solid var(--primary-one);
        color: var(--primary-one);
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }

        &.Mui-disabled {
            background-color: rgba(0, 0, 0, 0.12) !important;
            color: rgba(0, 0, 0, 0.26);
        }
    }
`;

const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }

    &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26);
    }
`;
