import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

// Components
import { ViewResource } from '../../../../components/content/ViewResource';
import { ActionButton, MenuActionContainer, Modal } from './styled-components/table.styled.components';
import MessageModal from '../../../../shared/common/MessageModal';
import { archiveCustomResource } from '../../../Resource/services/resource.services';
import Snack from '../../../../shared/common/Snack';

// Material UI
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Tooltip } from '@mui/material';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

const MenuAction = props => {
    const { resource, type, archivedStateChanged } = props;

    let history = useHistory();
    const ondemand_id = useSelector(state => state?.user?.user?.id);

    //suponemos archivedState false, como recurso activo
    // const archivedState = resource?.can_be_archived ? resource?.can_be_archived : false;
    const archivedState = false;

    function handleCloseError() {
        setErrorOpen(false);
    }

    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openSuccesModal, setOpenSuccesModal] = useState(false);
    const [changeArchivedState, setChangeArchivedState] = useState(null);
    const [contentMessage, setContentMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    //EFFECTS
    //publicacion
    useEffect(() => {
        if (!!changeArchivedState) {
            setOpenSuccesModal(true);
            setContentMessage({
                message: `${archivedState === false ? 'Eliminar' : 'Desarchivar'}`,
                image: './rediseño_2024/accion_exitosa.png',
                subtitle: `El Recurso fue ${archivedState === false ? ' eliminado ' : ' desarchivado '}con éxito`,
            });

            setTimeout(() => {
                archivedStateChanged(); //se recarga la pag  de la tabla
                setChangeArchivedState(null);
            }, 2000);
        }
        //eslint-disable-next-line
    }, [changeArchivedState]);

    // FUNCTIONS
    //previsualización
    const handleViewResource = () => {
        setOpenPreviewModal(true);
    };

    //edición
    const handleEditCourse = () => {
        history.push(`/edit-custom-resource/${resource.id}`);
    };

    //archivo
    //archivo confirmado por modal
    const handleConfirmedArchivedResource = () => {
        handleCloseConfirmModal();
        handleUpdateArchive();
    };
    const handleUpdateArchive = async archivedState => {
        let action = '';
        //archivedState true : archivado, se solicita desarchivar y activar el recurso
        if (!!archivedState) {
            action = false;
            console.log('Desarchivar', archivedState);
        } else {
            //archivedState false : activo, se solicita archivar y desactivar el recurso
            action = true;
            console.log('Archivar', archivedState);
        }
        let response = await archiveCustomResource(ondemand_id, resource.id, action);
        console.log('response - handleUpdateArchive', response);
        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El curso personalizado no pudo publicarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setChangeArchivedState(true);
        }
    };

    //cierre de modales
    const handleCloseSuccesModal = () => {
        setOpenSuccesModal(false);
    };

    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    return (
        <>
            <MenuActionContainer>
                {openPreviewModal && (
                    <Modal>
                        <ViewResource
                            selectedClass={resource}
                            title={resource?.title}
                            handleClose={() => {
                                setOpenPreviewModal(false);
                            }}
                        />
                    </Modal>
                )}
                <ActionButton onClick={handleViewResource}>
                    <Tooltip title="Previsualizar">
                        <VisibilityRoundedIcon />
                    </Tooltip>
                </ActionButton>
                {type === 'company' && resource.type !== 'evaluacion' && (
                    <>
                        <ActionButton onClick={handleEditCourse}>
                            <Tooltip title="Editar">
                                <EditRoundedIcon />
                            </Tooltip>
                        </ActionButton>
                        <ActionButton onClick={() => setOpenConfirmModal(true)}>
                            {archivedState === true ? (
                                <Tooltip title="Desarchivar">
                                    <PublishRoundedIcon />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Eliminar">
                                    <DeleteRoundedIcon />
                                </Tooltip>
                            )}
                        </ActionButton>
                    </>
                )}
            </MenuActionContainer>

            <MessageModal
                isOpen={openConfirmModal}
                handleClose={handleCloseConfirmModal}
                message="¿Deseas eliminar este recurso?"
                image="/images/eliminacion_modulo.svg"
                subtitle="¿Estás seguro de realizar esta acción?"
                // Ten en cuenta que con esto modificas el contenido del cursado
                // los alumnos que se encuentran matriculados,
                // verán su progreso de avance afectado.'
                handleClickConfirm={handleConfirmedArchivedResource}
            />

            <MessageModal
                isOpen={openSuccesModal}
                handleClose={handleCloseSuccesModal}
                message={contentMessage?.message}
                image={contentMessage?.image}
                subtitle={contentMessage?.subtitle}
            />
        </>
    );
};

export default MenuAction;
