import * as types from "../types/licenses.types";

const initialState = {
	licenses: null,
	loading: false,
	error: null,
};

export const licenses = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_LICENSES_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case types.GET_LICENSES_SUCCESS:
			return {
				...state,
				loading: false,
				licenses: action.payload,
			};
		case types.GET_LICENSES_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
