import axios from 'axios';

//Tipos de Objetos de Aprendizaje
export async function getTypeOfCourse() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/get_tipo_oa`);
        return response.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Tags
export async function getAllTags() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/get_tags`);
        // console.log('response getAllTags', response)
        return response.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Ejes Temáticos
export async function getAllThemes() {
    // const URL = `${window.location.origin}/mock/ejes.json`;

    try {
        const response = await axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/acropolis/ejes_tematicos/0`);
        // const response = await axios.get(URL);
        // console.log('response getAllThemes', response)
        return response.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Personalizacion Objetos de Aprendizaje
export async function getAllCustomOA(ic_ondemand_id) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/get_oa_personalizados/${ic_ondemand_id}`);
        // console.log('response getAllCustomOA', response)
        return response.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Objeto de Aprendizaje por id
export async function getOA(oa_id) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/${oa_id}`);
        console.log('response getOA', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Creación de Objeto de Aprendizaje
export async function createCustomOA(ic_ondemand_id, body) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/crear_oa_personalizado/${ic_ondemand_id}`,
            body,
        );
        // console.log('response createCustomOA', response)
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

//Actualización oa_id
export async function updateCustomOA(oa_id, body) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/actualizar_oa_personalizado/${oa_id}`,
            body,
        );
        console.log('response updateCustomOA', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}


//Eliminación de oa_id
export async function deleteCustomOA(oa_id, body) {
    // try {
    //     const response = await axios.delete(
    //         `${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/eliminacion_oa_personalizado/${oa_id}`,
    //         body,
    //     );
    //     console.log('response deleteCustomOA', response);
    //     return response.data;
    // } catch (error) {
    //     return {
    //         error,
    //     };
    // }
}


//RUTAS FORMATIVAS
//Visualización Rutas Formativas personalizadas (propiedad "personalized" === true)
export async function getAllCustomRoute(ic_ondemand_id) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/ondemand/acropolis/assignments/${ic_ondemand_id}`,);
        const filteredRoutes = response.data.response_data.filter(x => x.personalized ===true)
        return filteredRoutes;
    } catch (error) {
        return {
            error,
        };
    }
}

//Creación de una Ruta Formativa Personalizada
export async function createCustomRoute(ic_ondemand_id, body) {
    console.log('createCustomRoute')
console.log('body', body)
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_REPOURL}/v1/repo_aden/ondemand/acropolis/create_assignment/${ic_ondemand_id}`,
            body,
        );
        console.log('response createCustomRoute', response)
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}


//Ruta Formativa por id
export async function getRoute(route_id, ic_ondemand_id) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/ondemand/acropolis/assignments/${ic_ondemand_id}`,);
        const filteredRoute = response.data.response_data.filter(x => x.id === Number(route_id))
        return filteredRoute;
    } catch (error) {
        return {
            error,
        };
    }
}


//Actualización de una Ruta Formativa Personalizada
export async function updateCustomRoute(ic_ondemand_id, body) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_REPOURL}/v1/repo_aden/ondemand/acropolis/edit_assignment/${ic_ondemand_id}`,
            body,
        );
        console.log('response updateCustomRoute', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}


//Eliminación de una Ruta Formativa Personalizada
export async function deleteCustomRoute(ic_ondemand_id, assignment_id) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_REPOURL}/v1/repo_aden/ondemand/acropolis/delete_assignment/${ic_ondemand_id}/${assignment_id}`,
        );
        console.log('response deleteCustomRoute', response);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}

