import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Layout from '../shared/layout/Layout';
import Header from '../shared/layout/header/Header';
import DefaultTabs from '../shared/common/DefaultTabs';
import Wrapper from '../shared/common/Wrapper';
import General from '../components/analytics/General';
import Group from '../components/analytics/Group';
import User from '../components/analytics/User';
import Course from '../components/analytics/Course';
import AnalyticsDataStudio from '../components/analytics/AnalyticsDataStudio';

const tabOptions = [
    { id: 0, label: 'General' },
    { id: 1, label: 'Por Grupo' },
    { id: 2, label: 'Por Usuario' },
    { id: 3, label: 'Por Curso' },
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Analytics = () => {
    //REDUX
    const dataStudio = useSelector(state => state.user.user.tablero_analitica_admin);

    // STATE
    const [tabValue, setTabValue] = useState(0);
    const [userQuery, setUserQuery] = useState(null);
    const [groupQuery, setGroupQuery] = useState(null);

    const params = useQuery();
    const tabQuery = parseInt(params.get('tab'), 10);
    const uQuery = parseInt(params.get('user'), 10);
    const gQuery = parseInt(params.get('group'), 10);

    useEffect(() => {
        if (tabQuery || uQuery || gQuery) {
            setTabValue(tabQuery);
            setUserQuery(uQuery);
            setGroupQuery(gQuery);
            window.history.pushState(null, '', '/analytics');
        }
    }, [tabQuery, uQuery, gQuery]);

    // FUNCTIONS
    function handleTabValue(e, newValue) {
        setTabValue(newValue);
        setUserQuery(null);
        setGroupQuery(null);
    }

    function getTabOption(option) {
        switch (option) {
            case 0:
                return <General />;
            case 1:
                return <Group groupQuery={groupQuery} />;
            case 2:
                return <User userQuery={userQuery} />;
            case 3:
                return <Course />;
            default:
                return null;
        }
    }

    // RETURN
    return (
        <Layout header={<Header route="Analítica" />}>
            {dataStudio === false ? (
                <Container>
                    <Wrapper>
                        <Center>
                            <DefaultTabs tabValue={tabValue} handleTabValue={handleTabValue} tabOptions={tabOptions} />
                        </Center>
                    </Wrapper>
                    {getTabOption(tabValue)}
                </Container>
            ) : (
                <Container>
                    <AnalyticsDataStudio url={dataStudio} />
                </Container>
            )}
        </Layout>
    );
};

export default Analytics;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Center = styled.div`
    div{     
        display: flex;
        justify-content: center;
    }
`