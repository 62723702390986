import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { Form, Formik } from "formik";
import { Button } from "@material-ui/core";
import DefaultFieldWithText from "../../shared/common/DefaultFieldWithText";
import DefaultField from "../../shared/common/DefaultField";
import DefaultSelect from "../../shared/common/DefaultSelect";
import Text from "../../shared/common/Text";

const OPTIONS = [
	{ id: 0, name: "PDF" },
	{ id: 1, name: "Video" },
];

const NewUnitContentForm = ({ addContent, handleClose }) => {
	const initialValues = {
		name: "",
		type: 0,
		link: "",
	};

	function handleSubmit(values) {
		addContent({ ...values, id: uuidv4() });
		handleClose();
	}

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{() => (
				<Container>
					<FormWrapper>
						<DefaultFieldWithText
							name="name"
							type="text"
							title="Nombre contenido"
							placeholder="Nombre del contenido"
						/>
						<ColumnWrapper>
							<Text Text fontWeight="700" color="primary" component="span">
								Carga de contenido
							</Text>
							<GridWrapper>
								<DefaultSelect name="type" options={OPTIONS} />
								<DefaultField
									name="link"
									type="text"
									placeholder="Link público del material"
									variant="outlined"
									size="medium"
									required
								/>
							</GridWrapper>
						</ColumnWrapper>
					</FormWrapper>
					<FooterWrapper>
						<Button variant="contained" color="primary" type="submit">
							Cargar contenido
						</Button>
					</FooterWrapper>
				</Container>
			)}
		</Formik>
	);
};

export default NewUnitContentForm;

const Container = styled(Form)`
	display: flex;
	flex-direction: column;
	width: 700px;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding: 1.5rem 2rem;
`;

const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: 3fr 7fr;
	align-items: center;
	grid-gap: 1rem;
`;

const ColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding: 1rem 1.5rem;
	border-top: 1px solid #dadada;
`;
