import React, { useState } from "react";
import styled from "styled-components";

import Text from "../../shared/common/Text";
import { IconButton } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";
import ModalForm from "../../shared/common/ModalForm";
import NewUnitForm from "./NewUnitForm";

const NewUnit = ({ id }) => {
	const [isOpen, setIsOpen] = useState(false);

	function handleOpen() {
		setIsOpen(true);
	}

	function handleClose() {
		setIsOpen(false);
	}

	return (
		<>
			{/** Boton de abrir modal para agregar nueva unidad al curso */}
			<Container>
				<CustomIconButton onClick={handleOpen}>
					<AddRounded htmlColor="#ffffff" />
				</CustomIconButton>
				<Text fontSize="1rem" fontWeight="700" color="error" component="h2">
					Añadir Unidad
				</Text>
			</Container>
			{/** Modal para crear la nueva unidad */}
			<ModalForm isOpen={isOpen} handleClose={handleClose} title="Añadir unidad">
				<NewUnitForm id={id} handleClose={handleClose} />
			</ModalForm>
		</>
	);
};

export default NewUnit;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 2rem;
	border: 2px dashed #e5e5e5;
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
	min-height: 200px;
`;

const CustomIconButton = styled(IconButton)`
	background-color: var(--primary-one);

	:hover {
		background-color: var(--primary-one);
	}
`;
