import React, { useState } from 'react';
import styled from 'styled-components';
import Text from '../../../../shared/common/Text';
import ViewCourse from '../../../../components/content/ViewCourse';
import RemoveUsers from '../../../../components/content/RemoveUsers';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useDispatch } from 'react-redux';
import { useModal } from '../../../../hooks/ModalContext';
import { useEffect } from 'react';
import { participantActions } from '../../../../redux/actions';
import MessageModal from '../../../../components/blog/MessageModal';
import EnrolledParticipants from '../../../../components/content/EnrolledParticipants';

const ContentCardSmall = props => {
    const { course, customCourse } = props;

    // REDUX
    const dispatch = useDispatch();

    //STATES
    const [assignSuccess, setAssignSuccess] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [contentMessage, setContentMessage] = useState(null);

    //manejo de modales
    const { state, openModal, closeModal } = useModal();
    const handleOpenModal = modalType => {
        openModal(modalType);
    };
    const handleCloseModal = () => {
        closeModal();
    };

    //matriculacion exitosa
    useEffect(() => {
        if (!!assignSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Matriculación exitosa!',
                image: '/images/matriculacion_usuario.svg',
                subtitle: 'El curso fue asignado a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                dispatch(participantActions.confirmAssignCourse()); //cambia a false el estado de assign del course
                setAssignSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [assignSuccess, dispatch]);

    //desmatriculacion exitosa
    useEffect(() => {
        if (!!removeSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Desmatriculación exitosa!',
                image: '/images/desmatriculacion_usuario.svg',
                subtitle: 'El curso fue desasignado a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                setRemoveSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [removeSuccess, dispatch]);

    return (
        <>
            <Wrapper>
                <Container>
                    <NameWrapper columnGap={customCourse ? '1rem' : '0.25rem'}>
                        <Text fontSize="16px" fontWeight="400" fontcolor="#222222" component="h1">
                            {course.nombre_publicacion ? course.nombre_publicacion : course.name}
                        </Text>
                    </NameWrapper>
                    <ActionsWrapper>
                        <MenuButton variant="contained" color="primary" onClick={() => handleOpenModal('previsualizar')}>
                            <RemoveRedEyeIcon />
                        </MenuButton>
                        <MenuButton variant="contained" color="primary" onClick={() => handleOpenModal('matricular')}>
                            <GroupAddIcon />
                        </MenuButton>
                        <MenuButton variant="contained" color="primary" onClick={() => handleOpenModal('desmatricular')}>
                            <GroupRemoveRoundedIcon />
                        </MenuButton>
                    </ActionsWrapper>
                </Container>
            </Wrapper>

            {state.isOpen &&
                state.modalType === 'entendido' && ( //mensaje entendido
                    <MessageModal
                        isOpen={state.isOpen}
                        handleClose={handleCloseModal}
                        message={contentMessage?.message}
                        image={contentMessage?.image}
                        subtitle={contentMessage?.subtitle}
                    />
                )}
            {state.isOpen &&
                state.modalType === 'matricular' && ( //modal de matriculación
                    <EnrolledParticipants
                        content={course}
                        type={'course'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        success={() => setAssignSuccess(true)}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'previsualizar' && ( //modal de previsualización
                    <ViewCourse course={course} openModal={state.isOpen} closeModal={handleCloseModal} />
                )}

            {state.isOpen &&
                state.modalType === 'desmatricular' && ( //modal de desmatriculación
                    <RemoveUsers
                        course={course}
                        type={'course'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        removeSucces={() => setRemoveSuccess(true)}
                    />
                )}
        </>
    );
};

export default ContentCardSmall;

const Wrapper = styled.div`
    border-radius: 10px;
    border: 1px solid #c4c4c4;
    height: 60px;
    width: 100%;

    :hover {
        border: 1px solid #535353;
        background-color: #f9f9f9;
    }
`

const Container = styled.div`
    height: 60px;
    gap: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    @media (max-width: 900px) {
        display: flex;
        align-items: flex-start;
        width: calc(100% - 2rem);
        padding: 1rem;
    }
`;

const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${p => p.columnGap};
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    color: #222;
    svg {
        fill: #222;
    }
`;

const MenuButton = styled.button`
    background-color: #dcdcdc;
    border-radius: 3rem;
    padding: 0.5rem;
    color: var(--primary-one);
    gap: 1rem;
    border: none;
    width: calc(100%-1rem);
    height: calc(100%-1rem);
    background-color: transparent;
    align-items: center;
    display: flex;

    :hover {
        background-color: #cdcdcd;
        svg,
        span {
            color: #222 !important;
            fill: #222 !important;
        }
    }
`;
