import React, { useState } from 'react';

// Components
import CoursesList from './CoursesList';
import Text from '../../../../shared/common/Text';

// Material UI
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WrapperCellAsigned, AccordionEdited, AccordionSummaryFake, AccordionDetailsFake } from './styled-components/table.styled.components';

const CellAsigned = props => {
    const { list, id, expanded, handleChange } = props;
    const [listEliminated, setListEliminated] = useState([]);

    return (
        <WrapperCellAsigned>
            {list?.filter((element) => {
                return !listEliminated.includes(element?.id)
            })?.length > 0 ? (
                <AccordionEdited 
                    expanded={expanded === id} 
                    onChange={() => handleChange(id)}
                >
                    <AccordionSummaryFake expandIcon={<ExpandMoreIcon />} aria-controls={`panel${id}-content`} id={`panel${id}-header`}>
                        <p>Asignado a <b> {list?.filter((element) => {
                            return !listEliminated.includes(element?.id)
                        })?.length ?? 0} </b> cursos</p>
                    </AccordionSummaryFake>
                    <AccordionDetailsFake>
                        <CoursesList list={list} resourceId={id} listEliminated={listEliminated} setListEliminated={setListEliminated} />
                    </AccordionDetailsFake>
                </AccordionEdited>
            ) : (
                <Text fontSize="1rem" fontWeight="600" fontcolor="var(--primary-one)">
                    {' '}
                    No asignado
                </Text>
            )}
        </WrapperCellAsigned>
    );
};

export default CellAsigned;