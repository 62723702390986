import React from 'react';
import styled from 'styled-components';

import { OutlinedInput } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';

const InputIcon = props => {
    const { value, onChange } = props;

    // RETURN
    return <FakeInput value={value} onChange={onChange} placeholder="Buscar..." startAdornment={<SearchRounded color="secondary" />} />;
};

export default InputIcon;

const FakeInput = styled(OutlinedInput)`
    height: calc(80px - 1.6rem);
    width: calc(350px - 2rem);
    background-color: #f9f9f9;
    box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    -webkit-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    -moz-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    display: flex;
    flex-direction: row;
    padding: 0.8rem 1rem;
    gap: 1rem;
    border-radius: 8px;
    /* color: var(--secondary-four); */

    * {
        border: none;
    }
`;
