import * as types from "../types/groups.types";

const initialState = {
	loading: false,
	// GROUPs
	groups: null,
	loadingGroups: false,
	errorGroups: null,
	// NEW GROUP
	newGroup: false,
	loadingNewGroup: false,
	errorNewGroup: null,
	// EDIT GROUP
	editGroup:false,
	loadingEditGroup: false,
	errorEditGroup: false,
	// REMOVE GROUP
	loadingRemoveGroup: false,
	errorRemoveGroup: false,
	// NUMBER OF GROUPS
	numbers: null,
	errorNumbers: null,
};

export const group = (state = initialState, action) => {
	switch (action.type) {
		// GET NUMBER OF GROUPS
		case types.GET_NUMBER_OF_GROUPS_REQUEST:
			return {
				...state,
				loading: true,
				errorNumbers: null,
			};
		case types.GET_NUMBER_OF_GROUPS_SUCCESS:
			return {
				...state,
				loading: false,
				numbers: action.payload,
			};
		case types.GET_NUMBER_OF_GROUPS_FAIL:
			return {
				...state,
				loading: false,
				errorNumbers: action.payload,
			};
		// GET GROUPS
		case types.GET_GROUPS_REQUEST:
			return {
				...state,
				loadingGroups: true,
				errorGroups: null,
				groups: [],
			};
		case types.GET_GROUPS_SUCCESS:
			return {
				...state,
				loadingGroups: false,
				groups: action.payload,
			};
		case types.GET_GROUPS_FAIL:
			return {
				...state,
				loadingGroups: false,
				errorGroups: action.payload,
			};
		// CREATE GROUP
		case types.CREATE_GROUP_REQUEST:
			return {
				...state,
				loadingNewGroup: true,
				errorNewGroup: null,
			};
		case types.CREATE_GROUP_SUCCESS:
			return {
				...state,
				loadingNewGroup: false,
				newGroup: true,
				groups: [...state.groups, action.payload],
			};
		case types.CREATE_GROUP_FAIL:
			return {
				...state,
				loadingNewGroup: false,
				errorNewGroup: action.payload,
			};
		case types.CONFIRM_CREATE_GROUP:
			return {
				...state,
				newGroup: false,
			};
			// EDIT GROUP
		case types.EDIT_GROUP_REQUEST:
			return {
				...state,
				loadingEditGroup: true,
				errorEditGroup: null,
			};
		case types.EDIT_GROUP_SUCCESS:
			return {
				...state,
				loadingEditGroup: false,
				editGroup: true,
				groups:	null,
			};
		case types.EDIT_GROUP_FAIL:
			return {
				...state,
				loadingEditGroup: false,
				errorEditGroup: action.payload,
			};
		case types.CONFIRM_EDIT_GROUP:
			return {
				...state,
				editGroup: false,
			};
		// REMOVE GROUP
		case types.REMOVE_GROUP_REQUEST:
			return {
				...state,
				loadingRemoveGroup: true,
				errorRemoveGroup: null,
			};
		case types.REMOVE_GROUP_SUCCESS:
			return {
				...state,
				loadingRemoveGroup: false,
				groups: state.groups.filter((x) => x.id !== action.payload),
			};
		case types.REMOVE_GROUP_FAIL:
			return {
				...state,
				loadingRemoveGroup: false,
				errorRemoveGroup: action.payload,
			};

		default:
			return state;
	}
};
