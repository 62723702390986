import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    column-gap: 1.5rem;
`;

export const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0 1rem;
    height: 100%;
    width: max-content;
`;

export const ButtonUsersDark = styled(Button)`
    &.MuiButton-containedSizeSmall {
        border-radius: 3rem;
    }
    span,
    svg {
        font-size: 0.8rem;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        padding: 0.15rem;
    }
`;

//ExportUsers
export const ExportUsersButton = styled(Button)`
    &.MuiButton-outlinedSizeSmall {
        border-radius: 3rem;
    }
    span,
    svg {
        font-size: 0.8rem;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        padding: 0.15rem;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;
