import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { userActions } from "../../redux/actions";
import { getUsersIC } from "../../redux/api/user";
import Loading from "../../shared/common/Loading";
import PersonIcon from '@material-ui/icons/Person';

const ListUserAvaliable = props => {

    const { user } = props

    const [users, setUsers] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        getUsers(user)
    }, [])

    const getUsers = async user => {
        const req = await getUsersIC(user)
        // console.log('req', req)
        if (req.error) {
            dispatch(userActions.logoutUser())
            return
        }
        setUsers(req.response)
    }

    const handleUser = (user) => {
        // console.log('user', user)
        dispatch(userActions.userSelected(user))
    }

    if (users === null) {
        return <Loading/>
    }
    return <ListWrapper>
        {users.map(user => <Item onClick={() => handleUser(user)}><PersonIcon/>{user.detalle}</Item>)}
    </ListWrapper>
}

export default ListUserAvaliable;

const ListWrapper = styled.div`
    width: 100%;
    height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: .5rem;
`

const Item = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    padding: 0 1rem;
    align-items: center;
    height: 50px;
    cursor: pointer;
    border-radius: 3px;
    background-color: #f5f5f5;
    font-weight: 600;
    transition: ease-in-out .3s all;
    :hover{
        background-color: #eeeeee;
        color: #333333;
    }
    svg{
        color: #aaaaaa;
        margin-right: 1rem;
    }
`