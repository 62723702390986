import axios from "axios";

export function getAdmin({ ondemand_id }) {
	return axios.get(
		`${process.env.REACT_APP_SISAPI_ONDEMAND}/admin/${ondemand_id}`
	);
}

export function changePhotoAdmin({ imagen, admin_id }) {
	return axios.post(
		`${process.env.REACT_APP_SISAPI_ONDEMAND}/actualizar_foto_admin/repositorio_id?id=${admin_id}`,
		{ base64_image: imagen }
	);
}
