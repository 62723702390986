import React, { useEffect } from "react";
import styled from "styled-components";

import Wrapper from "../../shared/common/Wrapper";
import Text from "../../shared/common/Text";
import CompanyFields from "./CompanyFields";
import CompanyPhoto from "./CompanyPhoto";
import { useDispatch, useSelector } from "react-redux";
import { userActions, utilsActions } from "../../redux/actions";
import Loading from "../../shared/common/Loading";

const Company = () => {
	// REDUX
	const dispatch = useDispatch();

	const ondemand_id = useSelector((state) => state.user.user.id);

	const company = useSelector((state) => state.user.company);
	const loadingCompany = useSelector((state) => state.user.loadingCompany);
	//const errorCompany = useSelector((state) => state.user.errorCompany);

	const countries = useSelector((state) => state.utils.countries);
	const loadingCountries = useSelector(
		(state) => state.utils.loadingCountries
	);
	//const errorCountries = useSelector((state) => state.utils.errorCountries);

	// EFFECTS
	useEffect(() => {
		if (company === null) {
			dispatch(userActions.getCompanyRequest({ ondemand_id }));
		}
		if (countries === null) {
			dispatch(utilsActions.getCountriesRequest());
		}
	}, []); // eslint-disable-line

	//RETURN
	if (
		company === null ||
		loadingCompany ||
		countries === null ||
		loadingCountries
	) {
		return <Loading padding="4rem" />;
	}

	return (
		<Wrapper padding="1.5rem">
			<Container>
				<Text fontSize="1rem" fontWeight="700" color="error">
					Empresa
				</Text>
				<BodyWrapper>
					<CompanyFields company={company} countries={countries} />
					<CompanyPhoto
						photo={company.imagen}
						ondemand_id={ondemand_id}
					/>
				</BodyWrapper>
			</Container>
		</Wrapper>
	);
};

export default Company;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
`;

const BodyWrapper = styled.div`
	display: grid;
	grid-template-columns: 8fr 2fr;
	grid-gap: 2rem;
`;
