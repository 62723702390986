import * as types from "../types";

const initialState = {
	admin: null,
	loadingAdmin: false,
	errorAdmin: null,
	// PHOTO
	loadingPhoto: false,
	errorPhoto: null,
};

export const admin = (state = initialState, action) => {
	switch (action.type) {
		// GET ADMIN
		case types.GET_ADMIN_REQUEST:
			return {
				...state,
				loadingAdmin: true,
				errorAdmin: null,
			};
		case types.GET_ADMIN_SUCCESS:
			return {
				...state,
				loadingAdmin: false,
				admin: action.payload,
			};
		case types.GET_ADMIN_FAIL:
			return {
				...state,
				loadingAdmin: false,
				errorAdmin: action.payload,
			};
		// CHANGE PHOTO COMPANY
		case types.PHOTO_COMPANY_REQUEST:
			return {
				...state,
				loadingPhoto: true,
				errorPhoto: null,
			};
		case types.PHOTO_COMPANY_SUCCESS:
			return {
				...state,
				loadingPhoto: false,
				company: { ...state.admin, imagen: action.payload },
			};
		case types.PHOTO_COMPANY_FAIL:
			return {
				...state,
				loadingPhoto: false,
				errorPhoto: action.payload,
			};
		default:
			return state;
	}
};
