import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const ActionButton = props => {
    const { title, action, icon, bgc, textColor, border } = props;
    let history = useHistory();

    return (
        <Container 
        bgc={bgc} 
        textColor={textColor} 
        onClick={() => history.push(action)}
        border={border}

        >
            {icon} {title}
        </Container>
    );
};

export default ActionButton;

const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.5rem;
    gap: 0.5rem;
    width: max-content;
    background: ${p => p.bgc};
    border:${p => p.border ? p.border : `1px solid ${p.bgc}`}; 
    border-radius: 3rem;
    color: ${p => p.textColor};
    font-size: 0.875rem;
    letter-spacing:0.001em;
    line-height: 1.25rem;
    font-weight: 600;
`;
