import React, { useEffect, useState } from 'react'; 
// import MDEditor from '@uiw/react-md-editor';
import MarkdownEditor from '@uiw/react-markdown-editor';

import styled from 'styled-components';


const MDEditor = (props) => {

  const { handleContent, initialContent} = props; 
  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    if( content !== null){
      handleContent(content)
    }
  }, [content])
  
  return (
    <Container
    data-color-mode="light"
    >
      <MarkdownEditorFake
        value={content}
        onChange={(cont) => {setContent(cont)}}
      />
    </Container >
  );
}

export default MDEditor; 

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MarkdownEditorFake = styled(MarkdownEditor)`
  /* &.wmde-markdown-var.w-md-editor.w-md-editor-show-live{
    height: 100% !important;
  } */

  &.md-editor{
    height: 100% !important;
    max-width: 900px;
    overflow: hidden;
  }

`;


