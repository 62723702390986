import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "@material-ui/core";
import { GroupAddRounded } from "@material-ui/icons";
import ModalForm from "../../shared/common/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import FormAddGroup from "./FormAddGroup";
import AddGroupSuccess from "./AddGroupSuccess";
import { groupActions } from "../../redux/actions";

const AddGroup = () => {
	// REDUX
	const dispatch = useDispatch();

	const newGroup = useSelector((state) => state.group.newGroup);

	// STATE
	const [isOpen, setIsOpen] = useState(false);

	// FUNCTIONS
	function handleOpen() {
		setIsOpen(true);
	}

	function handleClose() {
		setIsOpen(false);
		dispatch(groupActions.confirmCreateGroup());
	}

	// RETURN
	return (
		<>
			<Button
				variant="contained"
				color="primary"
				size="small"
				startIcon={<GroupAddRounded />}
				onClick={handleOpen}
			>
				Añadir grupo
			</Button>
			<ModalForm
				isOpen={isOpen}
				handleClose={handleClose}
				title={newGroup ? "" : "Nuevo grupo"}
			>
				<Container newGroup={newGroup}>
					{newGroup ? (
						<AddGroupSuccess handleClose={handleClose} />
					) : (
						<FormAddGroup />
					)}
				</Container>
			</ModalForm>
		</>
	);
};

export default AddGroup;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: ${(p) => (p.newGroup ? "center" : "")};
	row-gap: ${(p) => (p.newGroup ? "2rem" : "")};
	padding: 1.5rem;
	min-width: ${(p) => (p.newGroup ? "320px" : "768px")};
	max-width: ${(p) => (p.newGroup ? "320px" : "")};
`;
