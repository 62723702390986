import React, { useState } from 'react';
import styled from 'styled-components';
import ViewCourse from '../content/ViewCourse';
import { Checkbox, Menu, MenuItem } from '@mui/material';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Text from '../../shared/common/Text';
import Loading from '../../shared/common/Loading';

const CheckCourseCard = props => {
    const { value, artId, type, checked, handleChecked } = props;

    //Modales de vista previa
    const [openViewModal, setOpenViewModal] = useState(false);

    //vista previa
    const handleOpenViewModal = () => {
        setOpenViewModal(true);
    };
    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };


    if (value.length === 0) {
        return <Loading />;
    }
    return (
        <>
            <RowCard>
                <Checkbox
                    name={value.id.toString()}
                    checked={checked}
                    onChange={handleChecked}
                    sx={{
                        color: 'var(--secondary-four)',
                        '&.Mui-checked': {
                            color: 'var(--primary-one)',
                        },
                    }}
                />
                <CardFake>
                    <Body>
                        <Text fontSize="1rem" fontWeight="600" lineheight="1.5rem" fontcolor="#000000">
                            {value?.nombre_publicacion ? value?.nombre_publicacion : value?.title}
                        </Text>
                        {/* <Text fontSize="0.8rem" fontWeight="400" lineheight="1.5rem" fontcolor="#616161">
                            {value?.tag_id[0]}
                        </Text> */}
                    </Body>
                    <MenuItemFake onClick={handleOpenViewModal}>
                        <RemoveRedEyeIcon />
                    </MenuItemFake>
                </CardFake>
            </RowCard>
            {openViewModal === true && <ViewCourse course={value} openModal={openViewModal} closeModal={handleCloseViewModal} />}
        </>
    );
};

export default CheckCourseCard;

const CardFake = styled.div`
    width: 90%;
    height: 60px;
    padding: 0 2rem;
    background-color: #f1f1f1;
    /* cursor: pointer; */
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;
    display: grid;
    grid-template-columns: 4fr 0.5fr;
    align-items: center;
    align-content: center;
    justify-items: stretch;
    justify-content: space-between;
    overflow: hidden;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const MenuItemFake = styled(MenuItem)`
    /* li .MuiButtonBase-root
.MuiButtonBase-root-MuiMenuItem-root
.MuiButtonBase-root
.MuiMenuItem-root 
.MuiMenuItem-gutters 
.MuiMenuItem-root 
.MuiMenuItem-gutters{
    border: var(--primary-one);
    border-radius: 50px
} */
    li span .MuiTouchRipple-root {
        border-radius: 50px;
        :hover {
            background-color: transparent !important;
        }
    }

    .MuiButtonBase-root-MuiMenuItem-root:hover {
        background-color: transparent !important;
    }

    svg {
        color: var(--primary-one);
        width: 1rem;
    }
`;

const RowCard = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    align-items: center;
    align-content: center;
    justify-items: stretch;
    justify-content: space-between;

    span .Mui-checked .MuiButtonBase-root-MuiCheckbox-root .MuiCheckbox-indeterminate {
        color: var(--primary-one);
    }

    span {
        color: var(--primary-one);
    }
`;
