import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

// Components
import Layout from '../../shared/layout/Layout';
import Header from '../../shared/layout/header/Header';
import TabResources from './components/header/TabResources';

// Styles
import { ContainerResources } from './styled-components/resources.styled.components';
import styled from 'styled-components';
import Tabs from '../../shared/common/Tabs';

function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

const Resources = () => {
    const user = useSelector(state => state.user.user);
    const params = useQueryParams();
    const tabQuery = params.get('tab');

    // STATE
    const [tabValue, setTabValue] = useState(0); //por defecto RECURSOS de acropolis no customizadas

    const tabOptions = [
        { id: 0, label: 'Recursos ADEN' },
        { id: 1, label: 'Recursos Empresa' }, //customizables
    ];

    //EFECTS
    useEffect(() => {
        if (tabQuery) {
            setTabValue(tabQuery === 'recursos_aden' ? 0 : tabQuery === 'recursos_empresa' ? 1 : null);
        }
    }, [tabQuery]);

    // FUNCTIONS
    function handleTabValue(newValue) {
        setTabValue(newValue);
    }

    function getTabOption(option) {
        switch (option) {
            case 1:
                return <TabResources user={user} type="company" />; //recursos_empresa
            case 0:
                return <TabResources user={user} type="aden" />; //recursos_aden
            default:
                return null;
        }
    }

    return (
        <Layout header={<Header route="Recursos"></Header>}>
            <Wrapper>
                <ContainerResources>
                    <Tabs tabValue={tabValue} handleTabValue={handleTabValue} tabOptions={tabOptions} />
                </ContainerResources>
                {getTabOption(tabValue)}
            </Wrapper>
        </Layout>
    );
};

export default Resources;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    background-color: #fff;
    border-radius: 20px;
    padding: 2rem;
`