import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardAvance from './CardAvance';
import styled from "styled-components";
import DownloadUser from "../user/Download";
import DefaultWrapper from '../DefaultWrapper';
import Text from '../../../shared/common/Text';
import {Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import StatisticsWrapper from './StatisticsWrapper';
import DownloadCourse from '../course/DownloadCourse';
import DownloadGroup from '../group/DownloadGroup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '72%',
    flexShrink: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionDetails:{
    backgroundColor:"#F2F2F2",
    justifyContent: "center",
  }

}));

const AccordionProgress = (props) => {
  const {data, title , type , print, gtc} =props;

  const classes = useStyles();

  //STATES
	const [expanded, setExpanded] =useState(false);

  //FUNCTIONS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <DefaultWrapper columns="span 24">
      {
        type === "course" ?
            <DownloadCourse
              title="Avance por usuario - Todos los cursos"
              print={data}
            />

         : type === "user" ?
            <DownloadUser 
              title="Avance por curso - Todos los usuarios"
              print={data}
             />

         : type === "group" ?
            <DownloadGroup
              title={title}
              print={print}
            />
          : type=== "allGroups"? null

          : null
      }
      {
          data.map((item, index)=>(
            <Accordion expanded={expanded === `panel_${index}`} onChange={handleChange(`panel_${index}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id={`panel_${index}-header`}
                >
                {
                  type === "course" ?
                    <Text className={classes.heading} fontSize="12px" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px">Curso <br/>
                      <Text  fontSize="16px" fontWeight="700" fontcolor="#000000" >
                        {data[index].course}
                      </Text>
                    </Text>

                  : type === "user" ?
                    <Text className={classes.heading} fontSize="12px" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px">Usuario <br/>
                      <Text  fontSize="16px" fontWeight="700" fontcolor="#000000" >
                        {data[index].name}
                      </Text>
                    </Text>

                  : type === "group" ?
                      <Text className={classes.heading} fontSize="12px" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px">Curso <br/>
                        <Text  fontSize="16px" fontWeight="700" fontcolor="#000000" >
                          {data[index].course}
                        </Text>
                      </Text>

                  :  type === "allGroups" ?
                      <Text className={classes.heading} fontSize="12px" fontWeight="900" fontcolor="var(--secondary-four)" lineheight="14,4px">Curso <br/>
                        <Text  fontSize="16px" fontWeight="700" fontcolor="#000000" >
                          {data[index].course}
                        </Text>
                      </Text>

                  : ""
                }
                <Typography className={classes.secondaryHeading}> 
                    <StatisticsWrapper
                      data={item} 
                      type={type}
                    />
                 </Typography>
              </AccordionSummary>
              <AccordionDetailsFake 
                gtc={gtc}
                className={classes.accordionDetails}>
               
                {
                  type === "course" ?  //card avance per user
                      item.users.map((user)=>(
                        <CardAvance 
                          key={user.repo_id}
                          item={user}
                          type="course"
                          gtc="270px 1fr"
                        />
                      ))
                  : type === "user" ?
                      item.courses.map((course)=>( //card avance per course
                          <CardAvance 
                            key={course.repo_id}
                            item={course}
                            type="user"
                            gtc="270px 1fr"
                          />
                      ))

                  : type === "group" ?
                      item.users.map((user)=>( //card avance per group/course/user
                        <CardAvance 
                          key={user.repo_id}
                          item={user}
                          type="group"
                          gtc="160px 1fr" 
                        />
                      ))
                  
                    : type === "allGroups" ?
                      item.users.map((user)=>( //card avance per group/course/user
                        <CardAvance 
                          key={user.repo_id}
                          item={user}
                          type="group"
                          gtc="180px 1fr" 
                        />
                      ))
                      
                  : ""
                }
              </AccordionDetailsFake>
            </Accordion>
          ))
        }
    </DefaultWrapper>
  );
}

export default  AccordionProgress;

const AccordionDetailsFake = styled(AccordionDetails)`
	display:grid;
  grid-template-columns:  ${(p) => p.gtc ? p.gtc  : "repeat(auto-fill, minmax(170px, 1%))"};
	row-gap: 0.5rem;
	column-gap:1.5rem;
  padding: 1.5rem;  
`;


