import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { analyticsActions } from "../../redux/actions";
import NoWrapper from "../../shared/common/NoWrapper";
import AnalyticsUsersSkt from "../skeletons/AnalyticsUsersSkt";
import SelectUser from "./user/SelectUser";
import Activity from "./user/Activity";
import UserProgress from "./user/UserProgress";
import InscriptsStateUser from "./user/InscriptsStateUser";
import AccordionProgress from "./common/AccordionProgress";
import axios from 'axios';
import Loading from "../../shared/common/Loading";


const User = (props) => {
	const {userQuery} = props;

	//REDUX
	const dispatch = useDispatch();
	const ondemand_id = useSelector((state) => state.user.user.id);
	const {userAdvanceByCourses, loadingUserAdvanceByCourses} = useSelector((state) => state.analytics);

	//STATES
	const [selectedUser, setSelectedUser] = useState(userAdvanceByCourses ?
														userQuery !== null ? userAdvanceByCourses.find(user=> user.repo_id === userQuery)
														:userAdvanceByCourses[0]
													:null	
													);
	const [userProgress, setUserProgress] = useState(null);
	const [inscript, setInscript] = useState(null);


	//EFECTS
	useEffect(() => {
		if (userAdvanceByCourses === null) {
			dispatch(analyticsActions.getUserAdvanceByCoursesRequest(ondemand_id));
		}
	}, [userAdvanceByCourses, dispatch, ondemand_id ]); 
	
	//Aún no has seleccionado un usuario
	useEffect(() => {if(selectedUser === undefined ){setSelectedUser(false)}}, [selectedUser]);
	
	//Selecciona todos los usuarios
	useEffect(() => {if(selectedUser === "all" ){setInscript(userAdvanceByCourses)}}, [selectedUser, userAdvanceByCourses]); 
	
	//Slecciona 1 usuario
	useEffect(() => {
        if(userAdvanceByCourses!== null && selectedUser !== null ){
            userAdvanceByCourses.forEach(user => {
                if( user.repo_id  === selectedUser.repo_id){
					setUserProgress(user);
					setInscript(user);
                }
            } );
        } else{
			setUserProgress(false);
		}
    }, [userAdvanceByCourses, selectedUser]);

	//FUNCTIONS
	const handleSelected = (select) => {
		setSelectedUser(select)
	}

//console.log(`selectedUser`, selectedUser)
//console.log(`userAdvanceByCourses`, userAdvanceByCourses)
//console.log(`userQuery`, userQuery)
	//RETURN	
	return (
		<>
		{	loadingUserAdvanceByCourses === true || userAdvanceByCourses === null?
				<AnalyticsUsersSkt/>
			:
			<Container>
				<SelectUser 
					userAdvanceByCourses={userAdvanceByCourses}
					handleSelected={handleSelected}
					userQuery={userQuery} 
				/>
				
				{
					selectedUser==="all" ?
					<>
						<InscriptsStateUser columns="span 24" selectedUser={inscript}/>
						<AccordionProgress
							type="user"
							title={	`Avance por curso - todos los usuarios`} 
							data={userAdvanceByCourses}
							gtc="repeat(auto-fill, minmax(170px, 1%))"
						/>
					 </>

					:
					selectedUser === null?
						<NoWrapper
						title="Aún no has seleccionado un usuario" 
						columns="span 24"
						rows="span 12" 
						padding="15%"
						/>
					:
					<>
						<InscriptsStateUser columns="span 10" selectedUser={inscript}/>
						<Activity userProgress={userProgress}/>
						<UserProgress
							title= {true}
							overflowY={true} 
							userProgress={userProgress}
							height={"300px"} 
						/>
						
					</>
				}
				
			</Container>
		}
		</>
	)
};

export default User;

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	grid-gap: 1.5rem;
`;
