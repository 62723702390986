import React from 'react';
import styled from 'styled-components';

import Wrapper from '../../shared/common/Wrapper';
import Text from '../../shared/common/Text';
import ContentCard from '../../shared/cards/ContentCard';
import { ModalProvider } from '../../hooks/ModalContext';

const UserContent = props => {
    const { contents, type } = props;

    return (
        <Wrapper padding="1.5rem">
            <Container>
                <HeaderWrapper>
                    <Text fontSize="1.25rem" fontWeight="700" color="error">
                        {type === 'course' ? 'Cursos asignados al usuario' : 'Rutas Formativas asignadas al usuario'}
                    </Text>
                </HeaderWrapper>
                {contents.length === 0 ? (
                    <NotFoundWrapper>
                        <Text fontSize="1.25rem" fontWeight="400" color="primary">
                            {type === 'course' ? 'El usuario no tiene cursos asignados.' : 'El usuario no tiene Rutas Formativas asignadas.'}
                        </Text>
                    </NotFoundWrapper>
                ) : (
                    <GroupsGrid>
                        {contents.map((content, index) => (
                            <ModalProvider key={index}>
                                <ContentCard 
								content={content} 
								key={index} 
								type={type} />
                            </ModalProvider>
                        ))}
                    </GroupsGrid>
                )}
            </Container>
        </Wrapper>
    );
};

export default UserContent;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const GroupsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
`;

const NotFoundWrapper = styled.div`
    width: 100%;
    text-align: center;
    padding: 3rem 0;
`;
