import React from "react";
import styled from "styled-components";

const Loading = (props) => {
	const { padding } = props;

	// RETURN
	return (
		<Container padding={padding}>
			<Default>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</Default>
		</Container>
	);
};

export default Loading;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: ${(p) => `${p.padding} 0` || "1rem 0"};
`;

const Default = styled.div`
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;

	div {
		position: absolute;
		width: 4px;
		height: 4px;
		background: var(--primary-one);
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}

	div:nth-child(1) {
		animation-delay: 0s;
		top: 19px;
		left: 33px;
	}

	div:nth-child(2) {
		animation-delay: -0.1s;
		top: 11px;
		left: 31px;
	}

	div:nth-child(3) {
		animation-delay: -0.2s;
		top: 6px;
		left: 26px;
	}

	div:nth-child(4) {
		animation-delay: -0.3s;
		top: 4px;
		left: 19px;
	}

	div:nth-child(5) {
		animation-delay: -0.4s;
		top: 6px;
		left: 11px;
	}

	div:nth-child(6) {
		animation-delay: -0.5s;
		top: 11px;
		left: 6px;
	}

	div:nth-child(7) {
		animation-delay: -0.6s;
		top: 19px;
		left: 4px;
	}

	div:nth-child(8) {
		animation-delay: -0.7s;
		top: 26px;
		left: 6px;
	}

	div:nth-child(9) {
		animation-delay: -0.8s;
		top: 31px;
		left: 11px;
	}

	div:nth-child(10) {
		animation-delay: -0.9s;
		top: 33px;
		left: 19px;
	}

	div:nth-child(11) {
		animation-delay: -1s;
		top: 31px;
		left: 26px;
	}

	div:nth-child(12) {
		animation-delay: -1.1s;
		top: 26px;
		left: 31px;
	}

	@keyframes lds-default {
		0%,
		20%,
		80%,
		100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
`;
