import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const LicensesSkt = () => {

	return (
		<Wrapper>
			<LoadingContainer>
				<CircularProgress sx={{ color: "var(--primary-one)" }} />
			</LoadingContainer>
		</Wrapper>
	);
};

export default LicensesSkt;

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 360px;
	background-color: #CBCACA;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(${(props) => `${props.backgroundImage}`});
	background-size: 105% 110%;
	background-position: 50% 20%;
	box-shadow: 0px 4px 4px 0px #00000040;
`

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
