import React from 'react';
import styled from 'styled-components';

import { ErrorMessage, Field } from 'formik';
import { TextField } from '@material-ui/core';

const DefaultField = props => {
    const { name, type, label, placeholder, border, borderradius } = props;

    return (
        <Field
            as={TextField}
            name={name}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            type={type}
            label={label}
            color="primary"
            size="small"
            fullWidth
            placeholder={placeholder}
            border={border}
            borderradius={borderradius}
            {...props}
        />
    );
};

export default DefaultField;

// const FieldStyle = styled(Field)`
//     color: var(--primary-one);
//     padding-top: 0.5rem;
    
//     .MuiOutlinedInput-root {
//         border: ${p => p.border};
//         border-radius: ${p => p.borderradius};
//         :-webkit-autofill {
//             background-color: white !important;
//         }
//     }
//     .MuiInputBase-root {
//         border: none;
//         :-webkit-autofill {
//             /* -webkit-box-shadow: 0 0 0 1000px white inset !important; */
//             /* box-shadow: 0 0 0 1000px white inset !important; */
//             background-color: white !important;
//             /* -webkit-text-fill-color: palevioletred !important; */
//         }
//         :-internal-autofill-selected {
//             background-color: white !important;
//         }
//     }
// `;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--primary-one);
    border: ${p => p.border};
    border-radius: ${p => p.borderradius};
`;
