import { call, put, takeEvery } from "redux-saga/effects";
import { GET_ANALYTICS_STATUS_REQUEST , GET_ANALYTICS_COURSES_REQUEST , GET_COURSE_ADVANCES_BY_USERS_REQUEST,GET_USER_ADVANCE_BY_COURSES_REQUEST, GET_GROUP_ADVANCE_BY_COURSES_REQUEST } from "../types/analytics.types";
import { getAnalyticsCourses, getCourseAdvancesByUsers, getAnalyticsStatus, getUserAdvanceByCourses, getGroupAdvanceByCourses } from "../api/analytics";
import { analyticsActions } from "../actions";

//GENERAL
function* getAnalyticsStatusRequest(action) {
	try {
		const analytics = yield call(getAnalyticsStatus, action.payload);
		yield put( analyticsActions.getAnalyticsStatusSuccess(analytics.data.response_data));
	} catch (error) {
		yield put( analyticsActions.getAnalyticsStatusFail(error));
	}
}
function* getAnalyticsCoursesRequest(action) {
	try {
		const analytics = yield call(getAnalyticsCourses, action.payload);
		yield put( analyticsActions.getAnalyticsCoursesSuccess(analytics.data.response_data));
	} catch (error) {
		yield put( analyticsActions.getAnalyticsCoursesFail(error));
	}
}

//COURSES
function* getCourseAdvancesByUsersRequest(action) {
	try {
		const analytics = yield call(getCourseAdvancesByUsers, action.payload);
		yield put( analyticsActions.getCourseAdvancesByUsersSuccess(analytics.data.response_data));
	} catch (error) {
		yield put( analyticsActions.getCourseAdvancesByUsersFail(error));
	}
}

//USERS
function* getUserAdvanceByCoursesRequest(action) {
	try {
		const analytics = yield call(getUserAdvanceByCourses, action.payload);
		yield put( analyticsActions.getUserAdvanceByCoursesSuccess(analytics.data.response_data));
	} catch (error) {
		yield put( analyticsActions.getUserAdvanceByCoursesFail(error));
	}
}

//GROUPS
function* getGroupAdvanceByCoursesRequest(action) {
	try {
		const analytics = yield call(getGroupAdvanceByCourses, action.payload);
		yield put( analyticsActions.getGroupAdvanceByCoursesSuccess(analytics.data.response_data));
	} catch (error) {
		yield put( analyticsActions.getGroupAdvanceByCoursesFail(error));
	}
}

//WATCHERS
function* analyticsWatcher() {
	yield takeEvery(GET_ANALYTICS_COURSES_REQUEST, getAnalyticsCoursesRequest);
	yield takeEvery(GET_ANALYTICS_STATUS_REQUEST, getAnalyticsStatusRequest);
	yield takeEvery( GET_COURSE_ADVANCES_BY_USERS_REQUEST, getCourseAdvancesByUsersRequest);
	yield takeEvery( GET_USER_ADVANCE_BY_COURSES_REQUEST, getUserAdvanceByCoursesRequest);
	yield takeEvery( GET_GROUP_ADVANCE_BY_COURSES_REQUEST, getGroupAdvanceByCoursesRequest)
}

export { analyticsWatcher };
