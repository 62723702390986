import React from 'react';
import styled from 'styled-components';

import { Fade, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Text from './Text';

export default function ModalForm(props) {
    const { isOpen, handleClose, title, banner = 'transparent' } = props;

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Fade in={isOpen}>
                <Container>
                    {title ? (
                        <TitleWrapper banner={banner}>
                            <Text fontSize="1.25rem" fontWeight="700" color="secondary">
                                {title}
                            </Text>
                            <CloseRounded color="secondary" onClick={handleClose} style={{ cursor: 'pointer' }} />
                        </TitleWrapper>
                    ) : (
                        <FakeIconButton onClick={handleClose}>
                            <CloseRounded color="secondary" />
                        </FakeIconButton>
                    )}
                    {props.children}
                </Container>
            </Fade>
        </Modal>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border: none;
    background-color: #ffffff;
    height: max-content;
    border-radius: 1.25rem;
    min-height: 350px;
    min-width: 350px;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid #dadada;
    background-color: ${p => p.banner};
    border-radius: 1.25rem 1.25rem 0 0;

    p {
        color: ${p => (p.banner !== 'transparent' ? '#fafafa' : '#fafaf')};
        font-size: 1.25rem;
        font-weight: 700;
    }
`;

const FakeIconButton = styled(IconButton)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
`;
