import { Checkbox } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Text from '../common/Text';

const MemberCard = props => {
    const { participant, handleChecked, checked } = props;

    const participant_id = participant.participante_id || participant.repositorio_id;

    const getInitials = function (string) {
        let initials = string
            .match(/(\b\S)?/g)
            .join('')
            .toUpperCase();
        return initials;
    };


    // RETURN
    return (
        <Container>
            <InfoWrapper>
                <Circle>
                    {getInitials(participant.nombre)[0]}
                    {getInitials(participant.nombre)[1]}
                </Circle>
                <NameWrapper>
                    <Text fontSize="0.6rem" fontWeight="400" color="error">
                        {participant.email}
                    </Text>
                    <Text fontSize=".75rem" fontWeight="400">
                        {participant.nombre}
                    </Text>
                </NameWrapper>
            </InfoWrapper>
            <Checkbox name={participant_id.toString()} checked={checked} onChange={handleChecked} color="primary" />
        </Container>
    );
};

export default MemberCard;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    height: max-content;
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const NameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;

const Circle = styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.15rem;
    justify-content: center;
    align-items: center;
    border-radius: 6.25rem;
    background: var(--tertiary-five);
    overflow: hidden;
    text-transform: ellipsis;
`;
