import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../shared/layout/Layout';
import TabArticles from '../components/blog/TabArticles';
import DefaultTabs from '../shared/common/DefaultTabs';
import TabCategories from '../components/blog/TabCategories';


const Blog = (props) => {
    const { user } = props;

    // STATE
    const [tabValueBlog, setTabValueBlog] = useState(0);

    const tabOptions = [
        { id: 0, label: 'Artículos creados' },
        { id: 1, label: 'Categorías' },
        //     { id: 3, label: 'Comentarios' },
        //     { id: 2, label: 'Fuentes RSS' },
    ];

    // FUNCTIONS
    function handleTabValue(e, newValue) {
        console.log('newValue', newValue);
        setTabValueBlog(newValue);
    }

    function getTabOption(option) {
        switch (option) {
            case 0:
                return <TabArticles user={user} />;
            case 1:
                return <TabCategories user={user} />;
            default:
                return null;
        }
    }

    return (
        <LayoutContainer>
            <Container>
                <DefaultTabs 
                tabValue={tabValueBlog} 
                handleTabValue={handleTabValue} 
                tabOptions={tabOptions} 
                paddingleft="3rem"/>
            </Container>
            {getTabOption(tabValueBlog)}
        </LayoutContainer>
    );
};

export default Blog;

const LayoutContainer = styled.div`
    display: block;
    width: calc(100% - 100px);
    position: absolute;
    left: 100px;
    top: 200px;
    z-index: 9;
    border-top: 1px solid #f7f7f7;
    overflow: hidden;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
`;
