import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login";

const PrivateRoute = (props) => {
	const { isAuthenticated, ...rest } = props;
	return <Route {...rest} component={isAuthenticated ? Dashboard : Login} />;
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.user.user,
});

export default connect(mapStateToProps)(PrivateRoute);
