import * as types from "../types/groups.types";

const actions = {};

// GET NUMBER OF GROUPS
actions.getNumberOfGroupsRequest = (id) => {
	return {
		type: types.GET_NUMBER_OF_GROUPS_REQUEST,
		payload: id,
	};
};

actions.getNumberOfGroupsSuccess = (numbers) => {
	return {
		type: types.GET_NUMBER_OF_GROUPS_SUCCESS,
		payload: numbers,
	};
};

actions.getNumberOfGroupsFail = (error) => {
	return {
		type: types.GET_NUMBER_OF_GROUPS_FAIL,
		payload: error,
	};
};

// GET GROUPS
actions.getGroupsRequest = (payload) => {
	return {
		type: types.GET_GROUPS_REQUEST,
		payload: payload,
	};
};

actions.getGroupsSuccess = (groups) => {
	return {
		type: types.GET_GROUPS_SUCCESS,
		payload: groups,
	};
};

actions.getGroupsFail = (error) => {
	return {
		type: types.GET_GROUPS_FAIL,
		payload: error,
	};
};

// CREATE GROUP
actions.createGroupRequest = (payload) => {
	return {
		type: types.CREATE_GROUP_REQUEST,
		payload: payload,
	};
};

actions.createGroupSuccess = (group) => {
	return {
		type: types.CREATE_GROUP_SUCCESS,
		payload: group,
	};
};

actions.createGroupFail = (error) => {
	return {
		type: types.CREATE_GROUP_FAIL,
		payload: error,
	};
};

actions.confirmCreateGroup = () => {
	return {
		type: types.CONFIRM_CREATE_GROUP,
	};
};

// EDIT GROUP
actions.editGroupRequest = (payload) => {
	return {
		type: types.EDIT_GROUP_REQUEST,
		payload: payload,
	};
};

actions.editGroupSuccess = (payload) => {
	return {
		type: types.EDIT_GROUP_SUCCESS,
		payload: payload,
	};
};

actions.editGroupFail = (error) => {
	return {
		type: types.EDIT_GROUP_FAIL,
		payload: error,
	};
};

actions.confirmEditGroup = () => {
	return {
		type: types.CONFIRM_EDIT_GROUP,
	};
};

// REMOVE GROUP
actions.removeGroupRequest = (payload) => {
	return {
		type: types.REMOVE_GROUP_REQUEST,
		payload: payload,
	};
};

actions.removeGroupSuccess = (id) => {
	return {
		type: types.REMOVE_GROUP_SUCCESS,
		payload: id,
	};
};

actions.removeGroupFail = (error) => {
	return {
		type: types.REMOVE_GROUP_FAIL,
		payload: error,
	};
};

export { actions };
