import { call, put, takeEvery } from "redux-saga/effects";
import { GET_CATEGORIES_REQUEST, GET_CONTENT_REQUEST } from "../types/content.types";
import { getContent, getCategories } from "../api/content.api";
import { contentActions } from "../actions";

function* getContentRequesst(action) {
	try {
		const res = yield call(getContent, action.payload);
		yield put(contentActions.getContentSuccess(res.data));
	} catch (error) {
		yield put(contentActions.getContentFail(error));
	}
}

function* getCategoriesRequest(action) {
	try {
		const res = yield call(getCategories, action.payload);
		// console.log(res);
		yield put(contentActions.getCategoriesSuccess(res.data));
	} catch (error) {
		yield put(contentActions.getCategoriesFail(error));
	}
}

function* contentWatcher() {
	yield takeEvery(GET_CONTENT_REQUEST, getContentRequesst);
	yield takeEvery(GET_CATEGORIES_REQUEST, getCategoriesRequest);
}

export { contentWatcher };
