import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DefaultWrapper from "../DefaultWrapper";
import Loading from "../../../shared/common/Loading";
import CardAvance from "../common/CardAvance";
import DownloadCourse from "./DownloadCourse";

const UserPerCourseProgress = (props) => {
	const {courseProgress } = props;
	//STATES
	const [arrayUsers, setarrayUsers] = useState([])
	//EFECTS
	useEffect(() => {
		if(courseProgress !== null && courseProgress !== false){
			transformObject(courseProgress)
		}
	}, [courseProgress])
	const transformObject = (object)=> {
		let result=[] ;
		for (const key in object) {
			let obj = {};
			obj["key"] = key;
			obj["value"] = object[key];
			result.push(obj)
		}
		//result: course / repo_id / to_start / ongoing / finished / total / users
		let arrayUserss=result.slice(6)
		setarrayUsers(arrayUserss[0].value)
	}
	//RETURN
	if (courseProgress === null) {
		return <Loading/>
	}if(courseProgress === false){
		return 	<DefaultWrapper title="Seleccione un curso" columns="span 24"/>
	}if(courseProgress.length === 0){
		return 	<DefaultWrapper title={courseProgress? `En el curso "${courseProgress.name}" no hay usuarios inscriptos`  : ""} columns="span 24"/>
	}
	return (
		<DefaultWrapper columns="span 24">
			<DownloadCourse
			title={ `Avance por usuario - Curso: ${courseProgress.course}`} 
			print={courseProgress}/>
			<Container>
				{arrayUsers.map((item) => (
					<CardAvance
						key={item.repo_id}
						item={item}
						type="course"
					/>
				))}
			</Container>
		</DefaultWrapper>
	);
};

export default UserPerCourseProgress;

const Container = styled.div`
	display:grid;
  	grid-template-columns: repeat(auto-fill, minmax(170px, 1%));
	row-gap: 0.5rem;
	column-gap:1.5rem;
  	padding: 1.5rem;
	background-color: #F2F2F2;
	justify-content: center;
`;
