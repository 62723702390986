import * as types from "../types/analytics.types";

const initialState = {
    //Estado de actividad de Usuario
	analyticsStatus:null,
	loadingAnalyticsStatus:false,
	errorAnalyticsStatus:null,
    //GENERAL - Cantidad de inscriptos en cada curso
	analyticsCourses:null,
	loadingAnalyticsCourses:false,
	errorAnalyticsCourses:null,
    //Conteo de inscriptos por curso - Progreso por usuarios en dicho curso
    courseAdvancesByUsers:null,
	loadingCourseAdvancesByUsers:false,
	errorCourseAdvancesByUsers:null,
    //Progreso por participante en cursos en los que figura matriculado 
    userAdvanceByCourses:null,
	loadingUserAdvanceByCoursesRequest:false,
	errorUserAdvanceByCoursesRequest:null,
    //Progreso por GRUPO de participantes en cursos 
    groupAdvanceByCourses:null,
	loadingGroupAdvanceByCoursesRequest:false,
	errorGroupAdvanceByCoursesRequest:null,
};

export const analytics = (state = initialState, action) => {
	switch (action.type) {
        //Estado de actividad de Usuario
		case types.GET_ANALYTICS_STATUS_REQUEST:
            return {
                ...state,
                loadingAnalyticsStatus: true,
                errorAnalyticsStatus: null,
            };
        case types.GET_ANALYTICS_STATUS_SUCCESS:
            return {
                ...state,
                loadingAnalyticsStatus: false,
                analyticsStatus: action.payload,
            };
        case types.GET_ANALYTICS_STATUS_FAIL:
            return {
                ...state,
                loadingAnalyticsStatus: false,
                errorAnalyticsStatus: action.payload,
            };
        //GENERAL - Cantidad de inscriptos en cada curso
        case types.GET_ANALYTICS_COURSES_REQUEST:
            return {
                ...state,
                loadingAnalyticsCourses: true,
                errorAnalyticsCourses: null,
            };
        case types.GET_ANALYTICS_COURSES_SUCCESS:
            return {
                ...state,
                loadingAnalyticsCourses: false,
                analyticsCourses: action.payload,
            };
        case types.GET_ANALYTICS_COURSES_FAIL:
            return {
                ...state,
                loadingAnalyticsCourses: false,
                errorAnalyticsCourses: action.payload,
            };
        //Conteo de inscriptos por curso - Progreso por usuarios en dicho curso
        case types.GET_COURSE_ADVANCES_BY_USERS_REQUEST:
            return {
                ...state,
                loadingCourseAdvancesByUsers: true,
                errorCourseAdvancesByUsers: null,
            };
        case types.GET_COURSE_ADVANCES_BY_USERS_SUCCESS:
            return {
                ...state,
                loadingCourseAdvancesByUsers: false,
                courseAdvancesByUsers: action.payload,
            };
        case types.GET_COURSE_ADVANCES_BY_USERS_FAIL:
            return {
                ...state,
                loadingCourseAdvancesByUsers: false,
                errorCourseAdvancesByUsers: action.payload,
            };
		 //Progreso por participante en cursos en los que figura matriculado 
         case types.GET_USER_ADVANCE_BY_COURSES_REQUEST:
            return {
                ...state,
                loadingUserAdvanceByCoursesRequest: true,
                errorUserAdvanceByCoursesRequest: null,
            };
        case types.GET_USER_ADVANCE_BY_COURSES_SUCCESS:
            return {
                ...state,
                loadingUserAdvanceByCoursesRequest: false,
                userAdvanceByCourses: action.payload,
            };
        case types.GET_USER_ADVANCE_BY_COURSES_FAIL:
            return {
                ...state,
                loadingUserAdvanceByCoursesRequest: false,
                errorUserAdvanceByCoursesRequest: action.payload,
            };
         //Progreso por GRUPO de participantes en cursos 
         case types.GET_GROUP_ADVANCE_BY_COURSES_REQUEST:
            return {
                ...state,
                loadingGroupAdvanceByCoursesRequest: true,
                errorGroupAdvanceByCoursesRequest: null,
            };
        case types.GET_GROUP_ADVANCE_BY_COURSES_SUCCESS:
            return {
                ...state,
                loadingGroupAdvanceByCoursesRequest: false,
                groupAdvanceByCourses: action.payload,
            };
        case types.GET_GROUP_ADVANCE_BY_COURSES_FAIL:
            return {
                ...state,
                loadingGroupAdvanceByCoursesRequest: false,
                errorGroupAdvanceByCoursesRequest: action.payload,
            };
		default:
			return state;
	}
};