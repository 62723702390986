import React, { useState } from "react";
import styled from "styled-components";

import { /*Button,*/ TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/actions";

const CompanyFields = (props) => {
	const { company, countries } = props;
	// REDUX
	const dispatch = useDispatch();

	// STATE
	const [values, setValues] = useState({
		nombre: company.nombre,
		telefono: company.telefono,
		pais: company.pais,
	});

	// FUNCTIONS
	function handleChange(e) {
		setValues({ ...values, [e.target.name]: e.target.value });
	}

	function handleChangeAuto(e, newValue) {
		setValues({ ...values, pais: newValue });
	}

	function handleSubmit(e) {
		e.preventDefault();
		dispatch(userActions.putCompanyRequest(values));
	}

	// RETURN
	return (
		<Container onSubmit={handleSubmit}>
			<GridFields>
				{/** Razon social */}
				<TextField
					name="nombre"
					value={values.nombre}
					onChange={handleChange}
					type="text"
					label="Razón social"
					style={{ gridColumn: "span 8" }}
					variant="outlined"
					disabled
				/>
				{/** Numero de telefono */}
				<TextField
					name="telefono"
					value={values.telefono}
					onChange={handleChange}
					type="text"
					label="Número de teléfono"
					style={{ gridColumn: "span 4" }}
					variant="outlined"
					disabled
				/>
				{/** Pais */}
				<Autocomplete
					value={values.pais}
					onChange={handleChangeAuto}
					style={{ gridColumn: "span 12" }}
					options={countries}
					getOptionLabel={(option) => option.name}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="País"
						/>
					)}
					disabled
				/>
			</GridFields>
			{/*<Button
				type="submit"
				variant="contained"
				color="primary"
				size="small"
				style={{ width: "max-content" }}
			>
				Guardar datos
			</Button>*/}
		</Container>
	);
};

export default CompanyFields;

const Container = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

const GridFields = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 1rem;
`;
