export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			const {
				user: { authToken },
			} = store.getState();
			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`;
			}

			return config;
		},
		(err) => Promise.reject(err)
	);
	/*axios.interceptors.response.use((res) => {
		if (res.data.token) {
			localStorage.setItem("token", res.data.token);
		}
		return res;
	});*/
}
