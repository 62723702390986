import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { TableHead } from '@mui/material';
// import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
// import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import styled from 'styled-components';
import MenuActionBlog from './MenuActionsBlog';

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
        backgroundColor: '#F9F9F9',
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
    head: {
        backgroundColor: '#F9F9F9',
        color: '#616161',
        fontSize: '0.8rem',
        fontWeight: '700',
    },
});
const TableOfCategories = props => {
    const { rows, headCells, editCategory, deleteCategory } = props;

    const classes = useStyles2();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const menuActions = [
        {
            title: 'Editar',
            icon: <EditRoundedIcon />,
            to: '',
            action: 'editCategory',
        },
        // {
        //     title: 'Duplicar',
        //     icon: <ContentCopyRoundedIcon />,
        //     to:null,
        //     action: '/',
        // },
        // {
        //     title: 'Eliminar',
        //     icon: <DeleteRoundedIcon />,
        //     to:null,
        //     action: 'deleteCategory',
        // },
    ];

    // FUNCTIONS

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAction = (action, id_category) => {
        //   console.log('action', action)
        //   console.log('id_category', id_category)
        if (action === 'editCategory') {
            editCategory(id_category);
        }
        if (action === 'deleteCategory') {
            deleteCategory(id_category);
        }
    };

    return (
        <TableContainerFake component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead className={classes.head}>
                    <TableRow>
                        {headCells.map(h => (
                            <TableCell style={{ width: 160 }} align="center">
                                {h.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row, index) => (
                        <TableRowFake key={index}>
                            <TableCell component="th" scope="row" align="center">
                                {row.id}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.attributes.name}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.attributes.slug}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.attributes.blog_articles.data.length}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                <MenuActionBlog content={menuActions} handleAction={handleAction} idCategory={row.id} />
                            </TableCell>
                        </TableRowFake>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooterFake>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'Filas por página' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooterFake>
            </Table>
        </TableContainerFake>
    );
};

export default TableOfCategories;

const TableContainerFake = styled(TableContainer)`
    width: calc(100% - 8rem);
    margin: auto;
    display: flex;
    justify-self: center;
`;

const TableFooterFake = styled(TableFooter)`
    width: 100%;
    background-color: #f9f9f9;

    .MuiToolbar-root {
        display: flex;
        position: relative;
        align-items: center;
        width: calc(100% - 1rem);
        padding: 0.5rem;
    }
`;

const TableRowFake = styled(TableRow)`
    .MuiSvgIcon-root {
        fill: var(--secondary-four);
    }
`;
