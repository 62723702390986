import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button } from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
import ModalForm from "../../shared/common/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { groupActions } from "../../redux/actions";
import EditGroupSuccess from "./EditGroupSuccess";
import FormEditGroup from "./FormEditGroup";
import { useParams } from "react-router-dom";

const EditGroup = () => {
	const { id } = useParams();
	
	// REDUX
	const dispatch = useDispatch();
	const editGroup = useSelector((state) => state.group.editGroup);
	const editedConfirm = useSelector((state) => state.group.editGroup);
	const groups = useSelector((state) => state.group.groups);

	// STATE
	const [isOpen, setIsOpen] = useState(false);
	const [currentGroup, setCurrentGroup] = useState(null) 


	// FUNCTIONS
	function handleOpen() {
		setIsOpen(true);
		dispatch(groupActions.confirmEditGroup());
	}

	function handleClose() {
		setIsOpen(false);	
	}

    const getGroups = () => {
		if(groups === null){
            dispatch(groupActions.getGroupsRequest({id}));
        }
	}

	// EFFECTS
    useEffect(() => {
        getGroups()
    }, [groups, id, editGroup])

    useEffect(() => {
        if(id!== null && groups !== null){
            groups.forEach(group => {
                if( group.id === parseInt( id, 10)){
                    setCurrentGroup(group)
                }
            } );
        } 
    }, [id, groups]); 
	
	// RETURN
	return (
		<>
			<EditButton
				variant="contained"
				size="small"
				startIcon={<EditRounded />}
				onClick={handleOpen}
				>Editar
			</EditButton>
			<ModalForm
				isOpen={isOpen}
				handleClose={handleClose}
				title={editGroup ? "" : "Editar grupo"}
			>
				<Container editGroup={editGroup}>
					{editGroup === false && editedConfirm === false
					? <FormEditGroup group={currentGroup} />
					: <EditGroupSuccess handleClose={handleClose} />
					}
				</Container>
			</ModalForm>
		</>
	);
};

export default EditGroup;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: ${(p) => (p.editGroup ? "center" : "")};
	row-gap: ${(p) => (p.editGroup ? "2rem" : "")};
	padding: 1.5rem;
	min-width: ${(p) => (p.editGroup ? "320px" : "768px")};
	max-width: ${(p) => (p.editGroup ? "320px" : "")};
`;
const EditButton = styled(Button)`
	background-color: var(--primary-one);
	color: #FFFFFF;
    height:40px;
    display: flex;
	align-items: center;
	padding: 0 1.5rem;
    span{
        font-size:0.75rem;
        font-weight: 400;
    }
`;