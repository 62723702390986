import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../shared/common/Loading';
import Header from '../shared/layout/header/Header';
import Layout from '../shared/layout/Layout';
import { getOnePopUpSlide } from '../redux/api/announcements';
import MainPopup from '../components/announcementsAndNews/MainPopup';

const ViewPopUp = () => {
    const { id } = useParams();

    //STATES
    const [popUpToView, setPopUpToView] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getArticle(id);
        }
    }, []);

    //FUNCTIONS
    const getArticle = async id => {
        let req = await getOnePopUpSlide(id);
        if (!!req) {
            setPopUpToView(req.data.attributes);
        }
    };

    if (!popUpToView) {
        return <Loading />;
    }
    return (
        <Layout header={<Header route={`Previsualización Pop Up: ${popUpToView.title}`} />}>
            <MainPopup popUpToView={popUpToView} />
        </Layout>
    );
}

export default ViewPopUp

