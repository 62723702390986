import React, { useState } from 'react';
import styled from 'styled-components';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { DeleteOutlineRounded, MoreVertRounded } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { participantActions } from '../../../redux/actions';
import MessageModal from '../../../shared/common/MessageModal';
import Text from '../../../shared/common/Text';
import DeactivateButton from '../../../components/user/DeactivateButton';

const MoreMenu = props => {
    const { id, repositorio_id, status } = props;

    // REDUX
    const dispatch = useDispatch();
    const ondemand_id = useSelector(state => state.user.user.id);

    // STATE
    const [anchorEl, setAnchorEl] = useState(null);
    const [messageOpen, setMessageOpen] = useState(false);
    const open = Boolean(anchorEl);

    // FUNCTIONS
    function handleOpen(e) {
        setAnchorEl(e.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
        setMessageOpen(false);
    }

    function removeUser() {
        setMessageOpen(true);
    }

    function handleConfirm() {
        setAnchorEl(null);
        setMessageOpen(false);
        dispatch(participantActions.removeParticipantRequest({ id, ondemand_id }));
    }

    // RETURN
    return (
        <>
            <IconButton size="small" aria-controls="menu-appbar" onClick={handleOpen}>
                <MoreVertRounded fontSize="small" />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}>
                {status === 'activo' || status === 'bloqueado' ? <DeactivateButton id={id} repositorio_id={repositorio_id} status={status} /> : null}
                <FakeMenuItem onClick={removeUser}>
                    <DeleteOutlineRounded color="error" fontSize="small" />
                    <Text fontSize="0.75rem" fontWeight="400" color="error">
                        Eliminar
                    </Text>
                </FakeMenuItem>
            </Menu>
            {!!messageOpen && (
                <MessageModal
                    isOpen={messageOpen}
                    handleClose={handleClose}
                    message="¿Confirmas la eliminación de este participante?"
                    image="/images/desmatriculacion_usuario.svg"
                    subtitle=""
                    handleClickConfirm={handleConfirm}
                />
            )}
        </>
    );
};

export default MoreMenu;

const FakeMenuItem = styled(MenuItem)`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
