import React from 'react';
import { ButtonWithBorder, LabelAnswers, TitleOpction } from '../../../../styled-components/forms.styled.components';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Checkbox, FormControlLabel } from '@mui/material';
import DefaultField from '../../../../../../shared/common/DefaultField';
import useAnswers from '../../../../hooks/useAnswers';

const AnswersListMultipleOption = props => {
    const { formik, index } = props;

    const formikValues = formik.values.questions[index].data_question_options;

    const { handleInputChange, handleDeleteAnswer, handleAddAnswer } = useAnswers();

    //multi_opcion
    const handleCheckboxChange = (event, indexAnswers) => {
        const { checked } = event.target;
        const optionIndex = formik.values.questions[index].data_question_options.findIndex(option => option.indexanswers === indexAnswers);
        const updatedOptions = formik.values.questions[index].data_question_options.map((option, i) => {
            if (i === optionIndex) {
                return { ...option, selected: checked, es_opcion_correcta: !!checked ? 'True' : 'False' };
            }
            return option;
        });
        formik.setFieldValue(`questions[${index}].data_question_options`, updatedOptions);
    };

    return (
        <>
            {formikValues.map((option, optionIndex) => (
                <LabelAnswers key={optionIndex}>
                    <TitleOpction>
                        Opcion {optionIndex + 1}
                        {formikValues.length > 2 && <DeleteRoundedIcon onClick={() => handleDeleteAnswer(formik, index, option.indexanswers)} />}
                    </TitleOpction>
                    <DefaultField
                        name={`option[${optionIndex}].name`}
                        value={option.name}
                        onChange={e => handleInputChange(formik, index, optionIndex, e.target.value)}
                        type="text"
                        label=""
                        required
                        variant="outlined"
                        placeholder=""
                        border="1px solid #616161"
                        borderradius="10px"
                        InputProps={{
                            startAdornment: (
                                <FormControlLabel
                                    key={optionIndex}
                                    value={formik.values.questions[index].data_question_options[optionIndex].indexanswers}
                                    control={
                                        <Checkbox
                                            checked={option.selected}
                                            onChange={event => handleCheckboxChange(event, option.indexanswers)}
                                            sx={{
                                                color: 'var(--secondary-four)',
                                                '&.Mui-checked': {
                                                    color: 'var(--primary-one)',
                                                },
                                            }}
                                        />
                                    }
                                />
                            ),
                        }}
                    />
                </LabelAnswers>
            ))}
            <ButtonWithBorder onClick={() => handleAddAnswer(formik, index)} bordercolor="var(--primary-one)" variant="contained" type="button">
                {''} +{''} Agregar Respuesta
            </ButtonWithBorder>
        </>
    );
};

export default AnswersListMultipleOption;
