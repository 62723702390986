import React from "react";
import styled from "styled-components";

import { Typography } from "@material-ui/core";

const Text = (props) => {
	const { fontSize, fontWeight, fontcolor, lineheight, fontStyle = 'normal', textalign = 'initial' } = props;

	// RETURN
	return (
		<FakeTypography textalign={textalign} fontSize={fontSize} fontWeight={fontWeight} fontcolor={fontcolor} lineheight={lineheight} fontStyle={fontStyle}{...props}>
			{props.children}
		</FakeTypography>
	);
};

export default Text;

const FakeTypography = styled(Typography)`
	margin: 0;
	line-height: ${(p) => p.lineheight? p.lineheight : "normal"};
	font-size: ${(p) => p.fontSize};
	font-weight: ${(p) => p.fontWeight};
	color: ${(p) => p.fontcolor};
	text-align: ${p => p.textalign};
	font-style: ${p => p.fontStyle};
`;
