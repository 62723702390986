import axios from 'axios';

const instance = axios.create({ baseURL: `${process.env.REACT_APP_CMS}` });
instance.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_CMS_API_KEY}`;

export async function getStrapiCompany(company_repo_id) {
    return await instance
        .get(`/api/companies?filters[repo_id]=${company_repo_id}`)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

//POP-UP - Anuncios y noticias 
// user_roles: [4] (id del rol 'ondemand' dentro de Strapi)
// company: id de la compañía dentro de Strapi
// publication_date: se crea automáticamente al usar el ep para publicar

export  const getAllPopUpSlidesByCompany = async (company_id) => {
    return await instance
        // .get(`/api/pop-up-slides?populate=*&filters[company][repo_id][$eq]=${company_id}&filters[user_role][name][$eq]=docente`)
        .get(`/api/pop-up-slides?populate=*&filters[company][repo_id][$eq]=${company_id}`)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}


export const createPopUpSlide = async body => {
    console.log('body', body)
    return await instance
        .post(`api/pop-up-slides`, body)
        .then(res => {
            console.log('res-createPopUpSlide', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};

export async function getOnePopUpSlide(popup_id) {
    return await instance
        .get(`/api/pop-up-slides/${popup_id}?populate=*`)
        .then(res => {
            console.log('res-getOnePopUpSlide', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

export const updatePopUpSlide = async (popup_id, body) => {
    return await instance
        .put(`api/pop-up-slides/${popup_id}`, body)
        .then(res => {
            console.log('res-updatePopUpSlide', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};

export const publishPopUpSlide = async (popup_id, body) => {
    return await instance
        .put(`api/pop-up-slides/publish/${popup_id}`, body)
        .then(res => {
            console.log('res-publishPopUpSlide', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};


//BANNER - Slide
// user_roles: [4] (id del rol 'ondemand' dentro de Strapi)
// company: X (id de la compañía dentro de Strapi)
// publication_date: se crea automáticamente al usar el ep para publicar

export async function getAllSlidesByCompany(company_id) {
    return await instance
        .get(`/api/slides?populate=*&filters[company][repo_id][$eq]=${company_id}`)
        // .get(`/api/slides?populate=*&filters[company][repo_id][$eq]=${company_id}&filters[user_role][name][$eq]=Admin`)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

export const createBanner = async body => {
    console.log('body', body)
    return await instance
        .post(`api/slides`, body)
        .then(res => {
            console.log('res-createBanner', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};

export async function getOneBanner(banner_id) {
    return await instance
        .get(`/api/slides/${banner_id}?populate=*`)
        .then(res => {
            console.log('res-getOneBanner', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
}

export const updateBanner = async (banner_id, body) => {
    return await instance
        .put(`api/slides/${banner_id}`, body)
        .then(res => {
            console.log('res-updateBanner', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};

export const publishBanner = async (banner_id, body) => {
    return await instance
        .put(`api/slides/publish/${banner_id}`, body)
        .then(res => {
            console.log('res-publishBanner', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};


export const deleteArticle = async (article_id) => {
    return await instance
        .delete(`api/blog-articles/${article_id}`)
        .then(res => {
            console.log('res-deleteArticle', res);
            return res.data;
        })
        .catch(error => {
            return { error };
        });
};
