import { Button, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import DefaultWrapper from "../DefaultWrapper";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { GetAppRounded } from "@material-ui/icons";
import Loading from "../../../shared/common/Loading";


const options = [
	{ id: 0, label: "Día" },
	{ id: 1, label: "Semana" },
];

const Activity = (props) => {
	const {userProgress}=props

	// STATE
	const [option, setOption] = useState(0);
    const [array, setArray] = useState(null)

	//EFECTS
	useEffect(() => {
        if(userProgress !== null ){
            transformObject(userProgress.courses)
		}
	}, [userProgress]);

	// FUNCTIONS
	function handleOption(value) {
		setOption(value);
	}

	const transformObject = async(object)=> {
		let result=[] ;
		let allResult=[] ;
		for (const key in object) {
			let obj = {};
			obj["key"] = key;
			obj["value"] = object[key];
			result.push(obj)
		}
		await result.forEach((value) => {
			allResult.push({
							'courseName': value.value.course,
							'individualProgress': value.value.progress > 100 ? 100 : value.value.progress,
							'totalValue': value.value.total
						})
		})
			setArray(allResult)
	}

	// RETURN
	if(userProgress===null){
		return <Loading/>
	}
	return (
		<DefaultWrapper
			title="Actividad del usuario"
			columns="span 14"
			header={
				<HeaderWrapper>
					{options.map((item) => (
						<OptionButton
							variant="contained"
							color={option === item.id ? "primary" : "default"}
							key={item.id}
							onClick={() => handleOption(item.id)}
						>
							{item.label}
						</OptionButton>
					))}
					{/* <IconButton size="small">
						<GetAppRounded fontSize="small" />
					</IconButton> */}
				</HeaderWrapper>
			}
		>
			<Container>
				<ResponsiveContainer>
					<LineChart
						data={array? array : null}
						margin={{
							top: 16,
							right: 16,
							left: 0,
							bottom: 0,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="courseName" />
						<YAxis />
						<Tooltip />
						<Line
							//connectNulls
							type="monotone"
							dataKey="individualProgress"
							stroke="#8884d8"
							fill="#8884d8"
						/>
					</LineChart>
				</ResponsiveContainer>
			</Container>
		</DefaultWrapper>
	);
};

export default Activity;

const Container = styled.div`
	position: relative;
	height: 200px;
	width: 100%;
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;

const OptionButton = styled(Button)`
	padding: 0.1rem;
	border-radius: 150px;

	span {
		font-size: 0.75rem;
	}
`;
