import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import styled from 'styled-components';

const CsvReader = props => {
    const { columnsId, rowsCompleted, nameCsv, sizeCsv, data_participantes_csv } = props;
    const [csvData, setCsvData] = useState([]);
    const [csvHeaders, setCsvHeaders] = useState([]);

    useEffect(() => {
        if (csvData.length > 0) {
            if (
                (csvHeaders.length > 3) &
                csvHeaders.every(function (v, i) {
                    return v === columnsId[i];
                })
            ) {
                rowsCompleted(csvData);
            } else {
                alert('El archivo con extensión .csv no respeta las columnas establecidas');
            }
        }
        //eslint-disable-next-line
    }, [csvData]);

    const handleFileChange = e => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            if (fileName.endsWith('.csv')) {
                data_participantes_csv(file);
                nameCsv(e.target.files[0].name);
                sizeCsv(e.target.files[0].size);

                Papa.parse(file, {
                    complete: result => {
                        const headers = result.meta.fields;
                        const data = result.data;
                        setCsvHeaders(headers);
                        setCsvData(data);
                    },
                    header: true,
                });
            } else {
                alert('Por favor seleccione un archivo con extensión .csv');
                e.target.value = null;
            }
        }
    };

    return (
        <>
            <Label for="fileCsvInput">Cargar fichero</Label>
            <Input onChange={handleFileChange} id="fileCsvInput" type="file" accept=".csv" />
        </>
    );
};

export default CsvReader;

const Label = styled.label`
    width: calc(180px - 2rem);
    height: 40px;
    border-radius: 48px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: var(--primary-one);
    overflow: hidden;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 20px;
    letter-spacing: 0.1%;
    color: #fafafa;
`;

const Input = styled.input`
    display: none;
`;
