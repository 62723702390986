import * as types from "../types/myContent.types";

const initialState = {
	myContent: [
		{
			id: "0",
			name: "Curso Ingreso 2021",
			description:
				"Lorem ipsum dolor sit amet consectetur adipiscing elit, viverra sem ut netus feugiat potenti eros, tristique hendrerit quam nec per semper. Diam quisque sagittis etiam maecenas felis nibh sodales augue, vel mollis nostra orci justo neque ac nisl, inceptos fusce nulla dictum cras platea convallis. Tempor netus malesuada mattis eros auctor elementum purus, velit nibh libero ridiculus quis mollis.",
			duration: 2,
			category: 1,
			units: [
				{
					id: "0",
					name: "Introducción",
					contents: [
						{ id: "0", name: "Manual de conducta", type: 0, link: "https://www.pdf.com/po2i3pijdoaj" },
						{ id: "1", name: "Recorrido virtual", type: 1, link: "https://www.video.com/po2i3pijdoaj" },
					],
				},
				{
					id: "1",
					name: "Historia",
					contents: [
						{ id: "0", name: "Introducción", type: 0, link: "https://www.pdf.com/po2i3pijdoaj" },
						{ id: "1", name: "¿Cuando?", type: 0, link: "https://www.pdf.com/po2i3pijdoaj" },
						{ id: "2", name: "ADEN", type: 1, link: "https://www.video.com/po2i3pijdoaj" },
					],
				},
			],
		},
	],
	loading: false,
	error: null,
};

export const myContent = (state = initialState, action) => {
	switch (action.type) {
		// CREATE CONTENT
		case types.CREATE_CONTENT_REQUEST:
			return {
				...state,
				myContent: [...state.myContent, action.payload],
			};
		// DELETE CONTENT
		case types.DELETE_CONTENT_REQUEST:
			return {
				...state,
				myContent: state.myContent.filter((i) => i.id !== action.payload),
			};
		// CREATE UNIT
		case types.CREATE_UNIT_REQUEST:
			let findContent = state.myContent.find((i) => i.id === action.payload.id);
			// console.log(findContent);
			//let newUnit = [...findContent.units, action.payload];
			let newContent = { ...findContent, units: [...findContent.units, action.payload.values] };
			return {
				...state,
				myContent: [...state.myContent.filter((i) => i.id !== action.payload.id), newContent],
			};
		default:
			return state;
	}
};
