import React from 'react';

// Material UI
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import { ResourceChip } from './styled-components/table.styled.components';

const ResourceTypeChip = props => {
    const { type } = props;

    return (
        <ResourceChip>
            <icon>
                {type === 'evaluacion' ? (
                    <PlaylistAddCheckRoundedIcon />
                ) : type === 'audiovisual' ? (
                    <VideocamRoundedIcon />
                ) : (
                    type === 'lectura' && <MenuBookRoundedIcon />
                )}
            </icon>
            <span>{type}</span>
        </ResourceChip>
    );
};

export default ResourceTypeChip;