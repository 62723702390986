import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, Modal } from '@material-ui/core';
import CourseAccordion from './CourseAccordion';
import { ViewResource } from './ViewResource';
import Loading from '../../shared/common/Loading';

const ViewCourse = props => {
    const { course, openModal, closeModal } = props;

    // STATE
    const [isOpen, setIsOpen] = useState(openModal);
    const [selectedClass, setSelectedClass] = useState([]);
    const [openDetail, setOpenDetail] = useState(false);

    // FUNCTIONS

    function handleClose() {
        setIsOpen(false);
        closeModal();
        setTimeout(() => {}, 500);
    }

    function handleCloseDetail() {
        setOpenDetail(false);
        setIsOpen(true);
    }

    function getValidValue(value) {
        const properties = ['publication_name', 'name','nombre', 'nombre_publicacion', 'title'];
        for (let prop of properties) {
            if (value[prop] && value[prop].trim() !== '') {
                return value[prop];
            }
        }
        return '';
    }

    const encuestas = course?.survey?.type === 'autogestionable' ? course?.survey?.link : '';

    // RETURN
    if (course.length === 0) {
        return <Loading />;
    }
    return (
        <>
            <ModalContainer open={isOpen} onClose={handleClose}>
                <ListCourse>
                    <label>VISUALIZACIÓN RÁPIDA</label>
                    <h1>{getValidValue(course)} </h1>
                    {encuestas.length > 0 ? (
                        <LinkButton href={encuestas} target="no_blank">Completar la encuesta</LinkButton>
                    ) : (
                        <CourseAccordion setIsOpen={setIsOpen} setOpenDetail={setOpenDetail} course={course} setSelectedClass={setSelectedClass} />
                    )}
                </ListCourse>
            </ModalContainer>
            <ModalContainer open={openDetail} onClose={handleCloseDetail}>
                <ViewResourceContainer>
                    <ViewResourceSelected selectedClass={selectedClass}>
                        <ViewResource handleClose={handleCloseDetail} selectedClass={selectedClass} title={getValidValue(course)} />
                    </ViewResourceSelected>
                </ViewResourceContainer>
            </ModalContainer>
        </>
    );
};

export default ViewCourse;

const ModalContainer = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ListCourse = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 700px;
    height: 100%;
    max-height: 460px;
    border-right: 1px solid #ccc;
    background-color: var(--secondary-one);
    border-radius: 20px;
    padding: 2rem;

    label {
        font-size: 15px;
        font-weight: 400;
        color: var(--primary-two);
    }

    h1 {
        font-size: 24px;
        font-weight: 700;
        color: var(--primary-one);
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--primary-one);
    }

    @media (max-width: 768px) {
        max-height: 40vh;
    }
`;

const ViewResourceContainer = styled.div`
    width: 854px;
    height: 480px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ViewResourceSelected = styled.div`
    display: ${props => (props.selectedClass.length === 0 ? 'none' : 'contents')};
    width: 100%;
    height: 100%;
    max-height: 60vh;
    @media (max-width: 768px) {
        max-height: 50vh;
    }
`;

const LinkButton = styled(Link)`
    font-size: 1rem;
    font-weight: 700;
    border-radius: 150px;
    background-color: #ececec;
    color: #b7b7b7;
    padding: 1rem;
`;
