import axios from "axios"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

// const FONT = `data:@file/vnd.ms-opentype;base64,`
const REPO_API = process.env.REACT_APP_REPOURL
const COLOR_DEFAULT = 'var(--primary-one)'
const URL_REPO = 'oerp'

const useLayout = () => {

    const [layout, setLayout] = useState(false)
    const [loading, setLoading] = useState(false)
    const { id, subdominio_login } = useSelector(state => state.user.user)

    useEffect(() => {
        subdominio_login && getAlab()
    }, [])

    const getAlab = async () => {
        const layout = await getBySubDomain(subdominio_login)
        setLayout(layout)
    }

    const getBySubDomain = async (domain) => {
        const URL = `${REPO_API}/v1/repo_aden/ondemand/landing/${domain}`;
        try {
            const response = await axios.get(URL);
            return response.data;
        } catch (error) {
            return { error };
        }
    }

    const saveLayout = async (body) => {
        const URL = `${REPO_API}/v1/repo_aden/ondemand/landing/${id}`;
        try {
            const response = await axios.put(URL, body);
            return response.data;
        } catch (error) {
            return { error };
        }
    }

    const handleSubmit = async values => {
        // console.log(values?.imageLeft && !values?.imageLeft?.includes(URL_REPO) === false ? true : false)
        const body = {
            image_login_1: values?.imageLeft && !values?.imageLeft?.includes(URL_REPO) ? values?.imageLeft?.split("base64,")[1] : false,
            image_login_2: values?.imageRight && !values?.imageRight?.includes(URL_REPO) ? values?.imageRight?.split("base64,")[1] : false,
            logo_login: values?.logoAlab && !values?.logoAlab?.includes(URL_REPO) ? values?.logoAlab?.split("base64,")[1] : false,
            typography: values?.font
            ? values?.font?.split("base64,").length > 1
                ? values?.font?.split("base64,")[1]
                : values?.font?.split("base64,")[0]
            : false,
            subdomain: values?.subdomain,
            color_1: values?.primaryColor,
            color_2: values?.secondaryColor,
            color_3: values?.thirdColor,
            logo_color: values?.logoAlabColor,
            text_color: values?.textColor,
            text_button_color: values?.text_button_color,
            error_text: values?.textError,
            invert_form: values?.inverted === 'true',
            width_company_logo: values?.width
        }
        setLoading(true)
        const request = await saveLayout(body)
        if (request.error) {
            alert("Error")
        }
        setLoading(false)
    }

    return { 
        layoutLoad: !!layout,
        primaryColor: layout?.color_1 || COLOR_DEFAULT,
        secondaryColor: layout?.color_2 || false,
        thirdColor: layout?.color_3 || false,
        textButton: layout?.text_button_color || false,
        font: layout?.typography || false,
        textColor: layout?.text_color || false,
        imageLeft: layout?.image_login_1 || false,
        imageRight: layout?.image_login_2 || false,
        logoAlab: layout?.logo_login || false,
        logoAlabColor: layout?.logo_color || COLOR_DEFAULT,        
        inverted: layout?.invert_form === true ? "true" : "false",
        width: layout?.width_company_logo || false,
        textError: layout?.error_text || false,
        subdomain: layout?.subdomain,
        handleSubmit: handleSubmit,
        loading
    }
}

export default useLayout