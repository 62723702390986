import * as types from "../types/participant.types";

const actions = {};

// GET NUMBER OF PARTICIPANTS
actions.getNumberOfParticipantsRequest = (id) => {
	return {
		type: types.GET_NUMBER_OF_PARTICIPANTS_REQUEST,
		payload: id,
	};
};

actions.getNumberOfParticipantsSuccess = (numbers) => {
	return {
		type: types.GET_NUMBER_OF_PARTICIPANTS_SUCCESS,
		payload: numbers,
	};
};

actions.getNumberOfParticipantsFail = (error) => {
	return {
		type: types.GET_NUMBER_OF_PARTICIPANTS_FAIL,
		payload: error,
	};
};

// GET PARTICIPANTS
actions.getParticipantsRequest = (payload) => {
	return {
		type: types.GET_PARTICIPANTS_REQUEST,
		payload: payload,
	};
};

actions.getParticipantsSuccess = (participants) => {
	return {
		type: types.GET_PARTICIPANTS_SUCCESS,
		payload: participants,
	};
};

actions.getParticipantsFail = (error) => {
	return {
		type: types.GET_PARTICIPANTS_FAIL,
		payload: error,
	};
};

// CREATE PARTICIPANT
actions.createParticipantRequest = (payload) => {
	return {
		type: types.CREATE_PARTICIPANT_REQUEST,
		payload: payload,
	};
};

actions.createParticipantSuccess = (participant) => {
	return {
		type: types.CREATE_PARTICIPANT_SUCCESS,
		payload: participant,
	};
};

actions.createParticipantFail = (error) => {
	return {
		type: types.CREATE_PARTICIPANT_FAIL,
		payload: error,
	};
};

actions.confirmCreateParticipant = () => {
	return {
		type: types.CONFIRM_CREATE_PARTICIPANT,
	};
};

// REMOVE PARTICIPANT
actions.removeParticipantRequest = (payload) => {
	return {
		type: types.REMOVE_PARTICIPANT_REQUEST,
		payload: payload,
	};
};

actions.removeParticipantSuccess = (id) => {
	return {
		type: types.REMOVE_PARTICIPANT_SUCCESS,
		payload: id,
	};
};

actions.removeParticipantFail = (error) => {
	return {
		type: types.REMOVE_PARTICIPANT_FAIL,
		payload: error,
	};
};

// DEACTIVATE PARTICIPANT 
actions.deactivateParticipantRequest = (payload) => {
	return {
		type: types.DEACTIVATE_PARTICIPANT_REQUEST,
		payload: payload,
	};
};

actions.deactivateParticipantSuccess = (id) => {
	return {
		type: types.DEACTIVATE_PARTICIPANT_SUCCESS,
		payload: id,
	};
};

actions.deactivateParticipantFail = (error) => {
	return {
		type: types.DEACTIVATE_PARTICIPANT_FAIL,
		payload: error,
	};
};

// ASSIGN COURSE
actions.assignCourseRequest = (payload) => {
	return {
		type: types.ASSIGN_COURSE_REQUEST,
		payload: payload,
	};
};

actions.assignCourseSuccess = (course) => {
	return {
		type: types.ASSIGN_COURSE_SUCCESS,
		payload: course,
	};
};

actions.assignCourseFail = (error) => {
	return {
		type: types.ASSIGN_COURSE_FAIL,
		payload: error,
	};
};

actions.confirmAssignCourse = () => {
	return {
		type: types.CONFIRM_ASSIGN_COURSE,
	};
};

// GET PARTICIPANT BY ID
actions.getParticipantRequest = (payload) => {
	return {
		type: types.GET_PARTICIPANT_REQUEST,
		payload: payload,
	};
};

actions.getParticipantSuccess = (payload) => {
	return {
		type: types.GET_PARTICIPANT_SUCCESS,
		payload: payload,
	};
};

actions.getParticipantFail = (error) => {
	return {
		type: types.GET_PARTICIPANT_FAIL,
		payload: error,
	};
};

// ASSIGN SUBJECT
actions.assignSubjectRequest = (payload) => {
	return {
		type: types.ASSIGN_SUBJECT_REQUEST,
		payload: payload,
	};
};

actions.assignSubjectSuccess = (subject) => {
	return {
		type: types.ASSIGN_SUBJECT_SUCCESS,
		payload: subject,
	};
};

actions.assignSubjectFail = (error) => {
	return {
		type: types.ASSIGN_SUBJECT_FAIL,
		payload: error,
	};
};

actions.confirmAssignSubject = () => {
	return {
		type: types.CONFIRM_ASSIGN_SUBJECT,
	};
};

export { actions };
