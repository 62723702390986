import React from "react";

import { Field } from "formik";
import { MenuItem, Select } from "@material-ui/core";

const DefaultSelect = (props) => {
	const { name, options, initialValues } = props;

	// RETURN
	return (
		<Field 
		as={Select} 
		name={initialValues || name} 
		variant="outlined" 
		color="primary" 
		size="small" 
		fullWidth 
		{...props}
		>
			{options.map((value) => (
				<MenuItem value={value.id} key={value.id}>
					{value.name}
				</MenuItem>
			))}
		</Field>
	);
};

export default DefaultSelect;
